import React, { useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CustomButton from 'src/components/CustomButton';
import { Download01, Share05, ArrowUp, ArrowDown, UploadCloud02 } from '@untitled-ui/icons-react/build/cjs';
import { initAction } from './Staff.interface';
import { GoDotFill } from 'react-icons/go';
import StaffPaymentStatus from './StaffPaymentStatus';
import { perPageOptions } from 'src/utils/global-variables';
import { Skeleton } from 'primereact/skeleton';
import NoDataMessage from 'src/components/NoDataMessage';

const StaffPayment = ({ staff }: any) => {
    const { t } = useTranslation();
    const [isLoading] = useState(false);
    const [isDownloading] = useState<boolean>(false);
    const [payments, setpayments] = useState<any>([]);

    const [action, setAction] = useState<any>(initAction);
    const [lazyState, setLazyState] = useState<any>({
        first: 0,
        rows: perPageOptions[0].value,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
            type: { value: 'all' },
        },
    });

    const handleAction = (type: string) => () => {
        setAction((old: any) => ({ ...old, [type]: true }));
    };

    const handleModalClose = useCallback(() => {
        setAction(initAction);
    }, []);

    const handleBookingDownload = async () => {
        console.log('hello');
    };

    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink  NextPageLink ',
        CurrentPageReport: ({ first, last, totalRecords }: { first: number; last: number; totalRecords: number }) => (
            <div className="text-gray-700 text-sm flex items-center font-medium leading-5 mr-auto">
                Page {first} of {totalRecords}
            </div>
        ),
        PrevPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
                {t('Previous')}
            </CustomButton>
        ),
        NextPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick}>
                {t('Next')}
            </CustomButton>
        ),
    };

    const GetPaymentStatus = (row: any) => (
        <div className="flex items-center">
            <span
                className="border border-greenBorder rounded-full capitalize bg-greenBackground text-xs font-medium cursor-pointer text-greenText px-2 py-[1px] leading-[18px]"
                onClick={handleAction('PaymentStatus')}
            >
                in Progress
            </span>
        </div>
    );
    const GetDownload = (row: any) => (
        <div className="flex items-center justify-center">
            <Download01 className="w-4 text-gray-600" />
        </div>
    );

    const onPageHandle = (event: any) => {
        setLazyState((old: any) => ({ ...old, rows: event.value }));
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };
    const tabs = useMemo(
        () => [
            {
                name: 'Commission Payouts',
                type: 'commission_payouts',
            },
            {
                name: 'Tip Payouts',
                type: 'tip_payouts',
            },
        ],
        [],
    );
    const [activeTab, setActiveTab] = useState(tabs[0]);

    const KPIS = useMemo(() => {
        let name;
        let amount;
        let frequency;
        let day;
        if (staff.pay_structure.pay_structure_type === 'rent') {
            name = t('Rent Amount');
            amount = `$${staff.pay_structure.rent_money}`;
            frequency = staff.pay_structure.shop_payment.rent_frequency;
            day = frequency === 'monthly' ? `${staff.pay_structure.shop_payment.rent_month_day} day of month` : staff.pay_structure.shop_payment.rent_day;
        } else {
            name = t('Commission Percentage');
            amount = `${staff.pay_structure.commission}%`;
            frequency = staff.pay_structure.shop_payment.commission_frequency;
            day = frequency === 'monthly' ? `${staff.pay_structure.shop_payment.commission_month_day} day of month` : staff.pay_structure.shop_payment.commission_day;
        }
        return [
            {
                name: t('Payment type'),
                number: staff.pay_structure.pay_structure_type,
            },
            {
                name: t('Tip payout frequency'),
                number: staff.pay_structure.shop_payment.tip_frequency.replace(/_/g, '-'),
            },
            {
                name: name,
                number: amount,
            },
            {
                name: t('Payout frequency'),
                number: frequency.replace(/_/g, '-'),
            },
            {
                name: t('Payment day'),
                number: day,
            },
        ];
    }, [staff]);

    const handleTabClick = (tab: any) => (event: { preventDefault: () => void }) => {
        event.preventDefault();
        setActiveTab(tab);
    };

    const CommissionSkeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map(() => ({
                period_start_date: 'loading',
                period_end_date: 'loading',
                total: 'loading',
                taxes: 'loading',
                tips: 'loading',
                due_barber: 'loading',
                due_to_Shop: 'loading',
                payment_status: 'loading',
                download: 'loading',
            })),
        [lazyState],
    );

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    return (
        <div className="w-full flex flex-col">
            <div className="grid grid-cols-3 xl:grid-cols-5 gap-5 mt-8 mb-8">
                {KPIS.map((kpi: any, index) => (
                    <div key={index} className="flex border flex-col border-gray-200 rounded-md p-3">
                        <p className="text-gray-600 text-xs font-normal mb-1.5">{kpi.name}</p>
                        <p className="text-sm font-semibold capitalize">{kpi.number}</p>
                    </div>
                ))}
            </div>
            <div className="fl-tab-btn-view3 w-full ">
                {tabs.map((tab, index) => (
                    <button key={index} type="button" className={`fl-tab-link3 !max-w-[182px] ${activeTab.name === tab.name ? 'active' : ''}`} onClick={handleTabClick(tab)}>
                        {tab.name}
                    </button>
                ))}
            </div>
            {activeTab.type === 'commission_payouts' && (
                <div className="w-full border rounded-xl mt-6  flex-col mb-5 flex shadow datatable-custom  ">
                    <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                        <div className="flex flex-col">
                            <div className="flex gap-2 items-center">
                                <h2 className="view-table-title">Commission Payouts</h2>
                                <p className="userCounter">
                                    <GoDotFill size={12} color="#2E90FA" className="h-3 w-3 rounded-full flex mr-0.5" />
                                    <span>Bi-weekly</span>
                                </p>
                            </div>
                            <p className="view-table-subtitle">{t('View name full commission payout history.', { name: staff.first_name })}</p>
                        </div>
                        <div className="flex gap-4">
                            <div className="location-dropdown">
                                <CustomButton
                                    isLoading={isDownloading}
                                    disabled={isDownloading}
                                    secondary
                                    outlineSecondary
                                    icon={<UploadCloud02 className="w-[18px] h-[18px] mt-[2px]" />}
                                    onClick={handleBookingDownload}
                                    className="flex items-center"
                                >
                                    {t('Export')}
                                </CustomButton>
                            </div>
                            <SelectBox
                                name="page"
                                isClearable={false}
                                isSearchable={false}
                                options={perPageOptions}
                                onChangeFunc={onPageHandle}
                                value={perPageOptions.find((option) => option.value === lazyState.rows)}
                                classComp="w-[70px]"
                            />
                        </div>
                    </div>
                    <DataTable
                        lazy
                        paginatorTemplate={customPaginatorTemplate}
                        value={isLoading ? CommissionSkeletons : payments}
                        totalRecords={payments.length}
                        paginator={!isLoading && payments.length > lazyState.rows}
                        first={lazyState.first}
                        rows={lazyState.rows}
                        sortOrder={lazyState.sortOrder}
                        sortField={lazyState.sortField}
                        filters={lazyState.filters}
                        onPage={onPage}
                        onSort={onSort}
                        onFilter={onFilter}
                        dataKey="id"
                        className="rounded-b-xl overflow-hidden"
                        paginatorClassName="table-pagination"
                        emptyMessage={
                            <div className="rounded-xl min-h-[380px] flex justify-center items-center">
                                <NoDataMessage
                                    title={`${lazyState.filters.global.value ? t('No commission payouts found.') : t('No commission payouts recorded.')}`}
                                    description={`${
                                        lazyState.filters.global.value
                                            ? t('No statements found matching your search criteria. Try adjusting your filters or record new transactions.')
                                            : t('Add a team member with a commission-based payment structure to start tracking commission payouts.')
                                    }`}
                                />
                            </div>
                        }
                    >
                        <Column
                            field="period_start_date"
                            header={renderHeader(t('Period Start Date'), 'period_start_date')}
                            body={isLoading ? <Skeleton /> : undefined}
                            className="table_text"
                            sortable
                        ></Column>
                        <Column
                            field="period_end_date"
                            header={renderHeader(t('Period End Date'), 'period_end_date')}
                            body={isLoading ? <Skeleton /> : undefined}
                            className="table_text"
                            sortable
                        ></Column>
                        <Column field="total" header={renderHeader(t('Total'), 'total')} body={isLoading ? <Skeleton /> : undefined} className="table_text" sortable></Column>
                        <Column field="taxes" header={renderHeader(t('Taxes'), 'taxes')} body={isLoading ? <Skeleton /> : undefined} className="table_text" sortable></Column>
                        <Column field="tips" header={renderHeader(t('Tips'), 'tips')} body={isLoading ? <Skeleton /> : undefined} className="table_text" sortable></Column>
                        <Column field="due_barber" header={renderHeader(t('Due to Barber'), 'due_barber')} body={isLoading ? <Skeleton /> : undefined} className="table_text" sortable></Column>
                        <Column field="due_to_Shop" header={renderHeader(t('Due to Shop'), 'due_to_Shop')} body={isLoading ? <Skeleton /> : undefined} className="table_text" sortable></Column>
                        <Column
                            field="payment_status"
                            header={renderHeader(t('Payment Status'), 'payment_status')}
                            body={isLoading ? <Skeleton /> : GetPaymentStatus}
                            className="table_text"
                            sortable
                        ></Column>
                        <Column field="download" header={t('Download')} body={isLoading ? <Skeleton /> : GetDownload} className="table_text"></Column>
                    </DataTable>
                </div>
            )}
            {activeTab.type === 'tip_payouts' && (
                <div className="w-full border rounded-xl mt-6  flex-col mb-5 flex shadow  datatable-custom ">
                    <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                        <div className="flex flex-col">
                            <div className="flex gap-2 items-center">
                                <h2 className="view-table-title">Tip Payouts</h2>
                                <p className="userCounter">
                                    <GoDotFill size={12} color="#2E90FA" className="h-3 w-3 rounded-full flex mr-0.5" />
                                    <span>Bi-weekly</span>
                                </p>
                            </div>
                            <p className="view-table-subtitle">{t('View name full tip payout history.', { name: staff.first_name })}</p>
                        </div>
                        <div className="flex gap-4">
                            <div className="location-dropdown">
                                <CustomButton
                                    isLoading={isDownloading}
                                    disabled={isDownloading}
                                    secondary
                                    outlineSecondary
                                    icon={<UploadCloud02 className="w-[18px] h-[18px] mt-[2px]" />}
                                    onClick={handleBookingDownload}
                                    className="flex items-center"
                                >
                                    {t('Export')}
                                </CustomButton>
                            </div>
                            <SelectBox
                                name="page"
                                isClearable={false}
                                options={perPageOptions}
                                onChangeFunc={onPageHandle}
                                value={perPageOptions.find((option) => option.value === lazyState.rows)}
                                classComp="w-[70px]"
                            />
                        </div>
                    </div>
                    <DataTable
                        lazy
                        paginatorTemplate={customPaginatorTemplate}
                        loading={isLoading}
                        value={isLoading ? CommissionSkeletons : payments}
                        totalRecords={payments.length}
                        paginator={!isLoading && payments.length > lazyState.rows}
                        first={lazyState.first}
                        rows={lazyState.rows}
                        sortOrder={lazyState.sortOrder}
                        sortField={lazyState.sortField}
                        filters={lazyState.filters}
                        onPage={onPage}
                        onSort={onSort}
                        onFilter={onFilter}
                        dataKey="id"
                        className="rounded-b-xl overflow-hidden"
                        paginatorClassName="table-pagination"
                        emptyMessage={
                            <div className="rounded-xl min-h-[380px] flex justify-center items-center">
                                <NoDataMessage
                                    title={`${lazyState.filters.global.value ? t('No tip payouts found.') : t('No tip payouts recorded.')}`}
                                    description={`${
                                        lazyState.filters.global.value
                                            ? t('No statements found matching your search criteria. Try adjusting your filters or record new transactions.')
                                            : t('Add a team member with a tip-based payment structure to start tracking tip payouts.')
                                    }`}
                                />
                            </div>
                        }
                    >
                        <Column
                            field="period_start_date"
                            body={isLoading ? <Skeleton /> : undefined}
                            header={renderHeader(t('Period Start Date'), 'period_start_date')}
                            className="table_text"
                            sortable
                        ></Column>
                        <Column
                            field="period_end_date"
                            body={isLoading ? <Skeleton /> : undefined}
                            header={renderHeader(t('Period End Date'), 'period_end_date')}
                            className="table_text"
                            sortable
                        ></Column>
                        <Column field="total" header={renderHeader(t('Total'), 'total')} body={isLoading ? <Skeleton /> : undefined} className="table_text" sortable></Column>
                        <Column field="taxes" header={renderHeader(t('Taxes'), 'taxes')} body={isLoading ? <Skeleton /> : undefined} className="table_text" sortable></Column>
                        <Column field="tips" header={renderHeader(t('Tips'), 'tips')} body={isLoading ? <Skeleton /> : undefined} className="table_text" sortable></Column>
                        <Column field="due_barber" header={renderHeader(t('Due to Barber'), 'due_barber')} body={isLoading ? <Skeleton /> : undefined} className="table_text" sortable></Column>
                        <Column field="due_to_Shop" header={renderHeader(t('Due to Shop'), 'due_to_Shop')} body={isLoading ? <Skeleton /> : undefined} className="table_text" sortable></Column>
                        <Column
                            field="payment_status"
                            header={renderHeader(t('Payment Status'), 'payment_status')}
                            body={isLoading ? <Skeleton /> : GetPaymentStatus}
                            className="table_text"
                            sortable
                        ></Column>
                        <Column field="download" header={t('Download')} body={isLoading ? <Skeleton /> : GetDownload} className="table_text"></Column>
                    </DataTable>
                </div>
            )}
            {action.PaymentStatus && <StaffPaymentStatus handleClose={handleModalClose} />}
        </div>
    );
};

export default StaffPayment;
