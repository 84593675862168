import React, { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { INotification, IProps } from './Notification.interface';
import NotificationCardComponent from './NotificationCardComponent';
import { API } from 'src/constants/api';
import { axiosGet } from 'src/utils/requestClient';
import NotificationDrawers from './Drawers/NotificationDrawer';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { onMessageListener, requestForFirebaseToken } from 'src/utils/firebase';
import { notificationInfo, setNotificationToken } from 'src/app/Notification/Notification.slice';
import { axiosPost } from 'src/utils/requestClient';
import { errorCode } from 'src/constants/errorCode';
import { allShopLocations, userMe } from 'src/redux/services/common/Common.slice';
import SelectBox from 'src/components/SelectBox/SelectBox';
import NotificationNew from './NotificationNew';
import { FilterMatchMode } from 'primereact/api';
import { getSelectBoxOptions } from 'src/utils/global-functions';

const Notification = ({ show, notificationButtonRef, notificationCount, setNotificationCount, handleClose }: IProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const notificationData = useAppSelector(notificationInfo);
    const user = useAppSelector(userMe);
    const drawerRef = useRef(null);
    const [currentTab, setCurrentTab] = useState<'general' | 'appointment'>('general');
    const [isLoading, setIsLoading] = useState(false);
    const [notifications, setNotifications] = useState({
        all: [],
        general: [],
        appointment: [],
        statement: [],
    });
    const [notificationsRecords, setNotificationsRecords] = useState({
        all: [],
        general: [],
        appointment: [],
        statement: [],
    });
    const [isShowViewAllNotificationDrawer, setIsShowViewAllNotificationDrawer] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (drawerRef.current && !((drawerRef.current as any).contains(event.target) || notificationButtonRef.current?.contains(event.target))) {
                handleClose();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        getOrUpdateFirebaseNotificationToken();

        if (user) {
            fetchNotifications();

            onMessageListener()
                .then((payload: any) => {
                    fetchNotifications();
                    toast.success(payload?.notification?.body);
                })
                .catch((err) => err);
        }
    }, [user]);

    const getOrUpdateFirebaseNotificationToken = async () => {
        let FCM_TOKEN = await requestForFirebaseToken();
        if (!FCM_TOKEN) {
            FCM_TOKEN = await requestForFirebaseToken();
        }

        if (FCM_TOKEN && FCM_TOKEN !== notificationData.notificationToken && user) {
            dispatch(setNotificationToken(FCM_TOKEN));

            try {
                const response = await axiosPost(API.NOTIFICATION.USER_NOTIFICATION_CREATE_UPDATE, {
                    token: FCM_TOKEN,
                });
                if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                    return;
                }

                throw response.data;
            } catch (err: any) {
                toast.error(err?.message);
            }
        }
    };

    // const listNotification = async () => {
    //     setIsLoading((prev) => ({ ...prev, general: true, appointment: true }));

    //     await axiosGet(API.NOTIFICATION.LIST, {}, { rows: 5, filters: { type: { value: 'general' } } })
    //         .then((response) => {
    //             const data = response.data.data;
    //             setNotifications((prev) => ({ ...prev, general: data.data }));
    //             const totalRecords = data.data.filter((item: any) => !item.is_read).length;
    //             setNotificationCount((prev) => ({ ...prev, general: totalRecords }));
    //         })
    //         .catch((error) => toast.error(error.message))
    //         .finally(() => setIsLoading((prev) => ({ ...prev, general: false })));

    //     await axiosGet(API.NOTIFICATION.LIST, {}, { rows: 5, filters: { type: { value: 'appointment' } } })
    //         .then((response) => {
    //             const data = response.data.data;
    //             setNotifications((prev) => ({ ...prev, appointment: data.data }));
    //             const totalRecords = data.data.filter((item: any) => !item.is_read).length;
    //             setNotificationCount((prev) => ({ ...prev, appointment: totalRecords }));
    //         })
    //         .catch((error) => toast.error(error.message))
    //         .finally(() => setIsLoading((prev) => ({ ...prev, appointment: false })));
    // };

    const handleCloseAll = () => {
        handleClose();
    };

    const viewDetail = (notification: any) => () => {
        switch (notification.status) {
            case 'staff_invite':
                navigate(ROUTES.INVITE, { state: { shop_id: notification.shop_id } });
                break;
            default:
                break;
        }
    };

    const viewAll = () => {
        setIsShowViewAllNotificationDrawer(true);
    };

    const tabs = useMemo(
        () => [
            {
                name: 'View all',
                type: 'all',
            },
            {
                name: 'General',
                type: 'general',
            },
            {
                name: 'Appointments',
                type: 'appointment',
            },
            {
                name: 'Statements',
                type: 'statement',
            },
        ],
        [],
    );

    const shopLocationList = useAppSelector(allShopLocations);
    const [locationOptions, setLocationOptions] = useState<any[]>([]);
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const [lazyState, setlazyState] = useState<any>({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            type: { value: activeTab.type === 'all' ? '' : activeTab.type },
            shop_location_id: { value: null },
        },
    });
    const [filters, setFilters] = useState<any>({
        shop_location_id: { value: null },
    });
    const [selectedFilter, setSelectedFilter] = useState({
        shop_location_id: null,
    });

    useEffect(() => {
        const locationData = getSelectBoxOptions(shopLocationList, 'id', 'name', 'id', 'shortname', 'profile_image_url');
        setLocationOptions(locationData);
    }, []);

    const handleTabClick = (tab: any) => async (event: { preventDefault: () => void }) => {
        event.preventDefault();
        setlazyState((prevState: any) => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                type: {
                    ...prevState.filters.type,
                    value: tab.type === 'all' ? '' : tab.type,
                },
            },
        }));
        setActiveTab(tab);
    };

    const fetchNotifications = async () => {
        const typeKey = lazyState.filters.type.value === '' ? 'all' : lazyState.filters.type.value;
        setIsLoading(true);
        return axiosGet(API.NOTIFICATION.LIST, {}, lazyState)
            .then((response) => {
                const data = response.data.data;
                setNotifications((prev) => ({ ...prev, [typeKey]: data.data }));
                setNotificationsRecords((prev) => ({ ...prev, [typeKey]: data.totalRecords }));
                const totalRecords = data.data.filter((item: any) => !item.is_read).length;
                setNotificationCount((prev) => ({ ...prev, [typeKey]: totalRecords }));
            })
            .catch((error) => toast.error(error.message))
            .finally(() => {
                setFilters(lazyState.filters);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchNotifications();
    }, [activeTab, lazyState]);

    // const listNotification = async () => {
    //     await Promise.all(tabs.map((tab) => fetchNotifications(tab.type)));
    // };

    const handleFilter = (filter: string) => (event: any) => {
        const value = event ? event.value : event;
        setlazyState((old: any) => ({ ...old, filters: { ...old.filters, [filter]: { value } } }));
        setSelectedFilter((old: any) => ({ ...old, [filter]: event }));
    };

    return (
        <>
            {show && (
                <div className="fixed  right-5 md:right-[20px] z-[9999] top-20 flex-1 bottom-2 w-full max-w-[500px] " ref={drawerRef}>
                    {!isShowViewAllNotificationDrawer && (
                        <div className="flex flex-col bg-white shadow-[0px_5.3px_15.9px_0px_#928C9740] h-[calc(100vh-90px)] max-h-[calc(100vh-90px)] border rounded-xl border-gray-200  w-full ">
                            <div className="flex justify-between items-center p-4">
                                <div className="font-semibold text-xl text-mainTextColor">Notifications</div>
                                <div className="2xl:w-[200px] w-[160px]">
                                    <SelectBox
                                        options={locationOptions}
                                        value={selectedFilter.shop_location_id}
                                        noOptionsMessage="No Locations Found"
                                        placeholder="All locations"
                                        onChangeFunc={handleFilter('shop_location_id')}
                                        classComp="outline-select-box"
                                    />
                                </div>
                            </div>
                            <div className="fl-tab-underline w-full mt-5 px-4 mb-1.5">
                                {tabs.map((tab, index) => (
                                    <button key={index} type="button" className={`fl-tab-link ${activeTab.name === tab.name ? 'active' : ''}`} onClick={handleTabClick(tab)}>
                                        {tab.name}
                                    </button>
                                ))}
                            </div>
                            <NotificationNew
                                type={activeTab.type}
                                notifications={notifications}
                                notificationsRecords={notificationsRecords}
                                setlazyState={setlazyState}
                                lazyState={lazyState}
                                isLoading={isLoading}
                                fetchNotifications={fetchNotifications}
                                viewDetail={viewDetail}
                                filters={filters}
                            />
                            {/* {activeTab.type === 'general' ? (
                                <div className="p-2 min-h-[200px] max-h-[480px] overflow-x-auto scrollbar-hide">
                                    {notifications.general.length ? (
                                        notifications.general.map((notification: INotification, index) => (
                                            <div key={index} className={`header_notification_menu ${notification.is_read ? 'opacity-50' : ''}`}>
                                                <img src={RentCollection} alt="" className="w-10 h-10 " />
                                                <div className="header_notification_menu_profile">
                                                    <div>
                                                        <h3 className="header_notification_menu_profile_username">{notification.name}</h3>
                                                        <p className="header_notification_menu_profile_date">
                                                            {formatDistanceToNow(new Date(notification.created_at), {
                                                                addSuffix: true,
                                                            })}
                                                        </p>
                                                    </div>
                                                    <button className="header_notification_menu_view_button" onClick={viewDetail(notification)}>
                                                        <span>View Detail</span> <img src={RightArrow} alt="" className="w-4 h-4" />
                                                    </button>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="min-h-[248px] justify-center items-center flex">No notification</div>
                                    )}
                                </div>
                            ) : activeTab.type === 'appointment' ? (
                                <div className="p-2 min-h-[200px] max-h-[480px] overflow-x-auto scrollbar-hide">
                                    {notifications.appointment.length ? (
                                        notifications.appointment.map((notification: INotification, index) => (
                                            <NotificationCardComponent
                                                key={`${notification.name}_${notification.id}`}
                                                className={`gap-3 rounded-xl flex h-full md:gap-6 p-[15px] md:p-6 items-center w-full border border-lineColor mb-3 ${
                                                    notifications.appointment.length === index + 1 ? '' : 'border-b border-line'
                                                } `}
                                                isNegative={false}
                                                notificationRecord={notification}
                                                handleCloseAll={handleCloseAll}
                                            />
                                        ))
                                    ) : (
                                        <div className="min-h-[248px] justify-center items-center flex">No notification</div>
                                    )}
                                </div>
                            ) : (
                                <></>
                            )} */}

                            {/* <div className="flex flex-row border border-borderSecondary rounded-lg gap-3 p-4 relative mb-3">
                                <img src={ProfileImg} alt="same" className="w-10 h-10 min-w-10 min-h-10 rounded-[6px] object-cover"></img>
                                <div className="flex flex-col gap-[3px]">
                                    <div className="text-sm font-medium text-mainTextColor">
                                        Light Box <span className="text-sm font-normal text-secondaryTxtColor"> invited you</span>
                                    </div>
                                    <div className="text-xs font-medium text-[#475467] flex  items-center">
                                        Invitation
                                        <GoDotFill size={8} color="#667085" className="h-2.5 w-2.5 rounded-full flex mx-1 mt-[1px]" />
                                        <span className="text-xs font-normal text-secondaryTxtColor"> 2 hours ago</span>
                                    </div>
                                    <div className="flex items-center gap-2 mt-[3px]">
                                        <CustomButton secondary className="!h-[26px] !rounded-md !py-1 !px-[14px]">
                                            Decline
                                        </CustomButton>
                                        <CustomButton primary className="!h-[26px] !rounded-md !py-1 !px-[14px]">
                                            Accect
                                        </CustomButton>
                                    </div>
                                </div>
                                <div className="absolute top-1 right-[3px] p-1">
                                    <GoDotFill size={16} color="#17B26A" className="h-4 w-4 rounded-full flex mr-0.5" />
                                </div>
                            </div>
                            <div className="flex flex-row border border-borderSecondary rounded-lg gap-3 p-4 relative mb-3">
                                <img src={ProfileImg} alt="same" className="w-10 h-10 min-w-10 min-h-10 rounded-[6px] object-cover"></img>
                                <div className="flex flex-col gap-[3px]">
                                    <div className="text-sm font-medium text-mainTextColor">
                                        Natali Craig <span className="text-sm font-normal text-secondaryTxtColor"> has checkout appointment on</span> 01 Jul 2024 at 09:30AM
                                        <span className="text-sm font-normal text-secondaryTxtColor"> for</span> Hair Cut
                                    </div>
                                    <div className="text-xs font-medium text-[#475467] flex  items-center">
                                        Invitation
                                        <GoDotFill size={8} color="#667085" className="h-2.5 w-2.5 rounded-full flex mx-1 mt-[1px]" />
                                        <span className="text-xs font-normal text-secondaryTxtColor"> 2 hours ago</span>
                                    </div>
                                </div>
                                <div className="absolute top-1 right-[3px] p-1">
                                    <GoDotFill size={16} color="#17B26A" className="h-4 w-4 rounded-full flex mr-0.5" />
                                </div>
                            </div> */}

                            {/* <div className="p-2 rounded-md max-h-[60px]">
                                <div className="flex items-center gap-4">
                                    <div className="flex flex-wrap sm:flex-nowrap gap-1 w-full bg-gray-50 rounded-lg justify-between border border-gray-200 ">
                                        <div
                                            className={`m-1 flex justify-center items-center gap-2 w-full rounded-lg cursor-pointer ${currentTab === 'general' ? 'bg-blue-50' : ''}`}
                                            onClick={() => setCurrentTab('general')}
                                        >
                                            <div className={`tarcking-[-0.2px] block py-3 h-full font-semibold text-sm ${currentTab === 'general' ? 'text-blue-700' : 'text-gray-500'}`}>General</div>
                                            <div
                                                className={`w-6 h-[22px] rounded-full border  flex justify-center items-center text-xs leading-[18px] font-medium ${
                                                    currentTab === 'general' ? 'text-blue-700 border-blue-200' : 'text-gray-700 border-gray-200'
                                                }`}
                                            >
                                                {notificationCount.general}
                                            </div>
                                        </div>
                                        <div
                                            className={`m-1 flex justify-center items-center gap-2 w-full rounded-lg cursor-pointer ${currentTab === 'appointment' ? 'bg-blue-50' : ''} `}
                                            onClick={() => setCurrentTab('appointment')}
                                        >
                                            <div className={`tarcking-[-0.2px] block py-3 h-full font-semibold text-sm ${currentTab === 'appointment' ? 'text-blue-700' : 'text-gray-500'}`}>
                                                appointment
                                            </div>
                                            <div
                                                className={`w-6 h-[22px] rounded-full border  flex justify-center items-center text-xs leading-[18px] font-medium ${
                                                    currentTab === 'appointment' ? 'text-blue-700 border-blue-200' : 'text-gray-700 border-gray-200'
                                                }`}
                                            >
                                                {notificationCount.appointment}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* {currentTab === 'general' && (
                                <div className="p-2 min-h-[200px] max-h-[480px] overflow-x-auto scrollbar-hide">
                                    {notifications.general.length ? (
                                        notifications.general.map((notification: INotification, index) => (
                                            <div key={index} className={`header_notification_menu ${notification.is_read ? 'opacity-50' : ''}`}>
                                                <img src={RentCollection} alt="" className="w-10 h-10 " />
                                                <div className="header_notification_menu_profile">
                                                    <div>
                                                        <h3 className="header_notification_menu_profile_username">{notification.name}</h3>
                                                        <p className="header_notification_menu_profile_date">
                                                            {formatDistanceToNow(new Date(notification.created_at), {
                                                                addSuffix: true,
                                                            })}
                                                        </p>
                                                    </div>
                                                    <button className="header_notification_menu_view_button" onClick={viewDetail(notification)}>
                                                        <span>View Detail</span> <img src={RightArrow} alt="" className="w-4 h-4" />
                                                    </button>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="min-h-[248px] justify-center items-center flex">No notification</div>
                                    )}
                                </div>
                            )}
                            {currentTab === 'appointment' && (
                                <div className="p-2 min-h-[200px] max-h-[480px] overflow-x-auto scrollbar-hide">
                                    {notifications.appointment.length ? (
                                        notifications.appointment.map((notification: INotification, index) => (
                                            <NotificationCardComponent
                                                key={`${notification.name}_${notification.id}`}
                                                className={`gap-3 rounded-xl flex h-full md:gap-6 p-[15px] md:p-6 items-center w-full border border-lineColor mb-3 ${
                                                    notifications.appointment.length === index + 1 ? '' : 'border-b border-line'
                                                } `}
                                                isNegative={false}
                                                notificationRecord={notification}
                                                handleCloseAll={handleCloseAll}
                                            />
                                        ))
                                    ) : (
                                        <div className="min-h-[248px] justify-center items-center flex">No notification</div>
                                    )}
                                </div>
                            )} */}

                            {/* <div className="p-2 flex gap-4 ">
                                <button className="rounded-lg bg-white h-10 flex w-full justify-center items-center gap-[6px] text-sm font-semibold text-footerterm border border-blue-300 shadow-InputAndButton">
                                    <img src={SettingButton} alt="" className="w-5 h-5" />
                                    <span>Settings</span>
                                </button>
                                <button
                                    className="rounded-lg bg-footerterm h-10 flex w-full justify-center items-center gap-[6px] text-sm font-semibold text-white shadow-InputAndButton"
                                    onClick={viewAll}
                                >
                                    <span>View all</span>
                                    <img src={ArroRight} alt="" className="w-5 h-5" />
                                </button>
                            </div> */}
                        </div>
                    )}
                    <NotificationDrawers isOpen={isShowViewAllNotificationDrawer} handleCloseAll={handleCloseAll} handleClose={() => setIsShowViewAllNotificationDrawer(false)} type={'appointment'} />
                </div>
            )}
        </>
    );
};

export default Notification;
