import React, { useRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './../../utils/Datatable.scss';
import './../../utils/prime-react-datatable.scss';
import { Tabs, TabsRef } from 'flowbite-react';
import PopupModal from 'src/components/PopupModal';
import { useNavigate } from 'react-router-dom';
import General from '../Appearance/General/General';
import { Download01, File02, Image01, Wallet02 } from '@untitled-ui/icons-react/build/cjs';
import CustomButton from 'src/components/CustomButton';

const StaffPaymentStatus = ({ handleClose }: any) => {
    const { t } = useTranslation();
    const tabsRef = useRef<TabsRef>(null);

    const navigate = useNavigate();
    useEffect(() => {}, []);

    const Title = () => (
        <div className="flex flex-row gap-3 ">
            <div className="flex justify-center border shadow-sm border-gray-200 items-center h-12 w-12 rounded-[10px]">
                <Wallet02 className="text-gray-700" />
            </div>
            <div className="flex flex-col">
                <p className="text-lg font-semibold text-gray-900 mb-0.5">Payout Breakdown</p>
                <span className="text-xs font-normal text-gray-600 leading-[20px]">View and download full payout breakdown</span>
            </div>
        </div>
    );

    return (
        <>
            <PopupModal onClose={handleClose} size="w-[500px]" title={<Title />} primaryButton={t('Ok')} secondaryButton={t('Cancel')} acceptAction={handleClose} declineAction={handleClose}>
                <div className="flex flex-col overflow-x-auto mt-5">
                    <div className="flex justify-between pb-4 mb-4 border-b border-gray-200">
                        <div className="flex items-center">
                            <figure className="w-[40px] h-[40px] basis-[38px] grow-0 shrink-0 rounded-full overflow-hidden mr-2 flex text-xs font-medium justify-center items-center bg-primary text-white">
                                <Image01 />
                            </figure>
                            <div>
                                <p className="text-xs font-medium leading-[18px] text-gray-900 mb-[2px]">Koray Okumus</p>
                                <p className="text-xs font-normal text-gray-600 leading-[18px]">Junior Stylist</p>
                            </div>
                        </div>
                        <div className="text-right flex gap-1">
                            <CustomButton outlinePrimary icon={<Download01 className="w-4" />} type="button">
                                {t('Download Full Report')}
                            </CustomButton>
                        </div>
                    </div>
                    <div className="flex justify-between mb-3 items-center">
                        <h3 className="text-gray-900 text-base font-medium leading-[24px]">Payment Status</h3>
                        <div className="flex items-center">
                            <span className="border border-greenBorder rounded-full capitalize bg-greenBackground text-xs font-medium cursor-pointer text-greenText px-2 py-[1px] leading-[18px]">
                                Paid
                            </span>
                        </div>
                    </div>
                    <div className="flex justify-between  pb-3 border-b border-gray-200 items-center">
                        <h3 className="text-gray-900 text-base font-medium leading-[24px]">Payment Window</h3>
                        <div className="flex items-center">
                            <span className="text-xs font-semibold text-gray-600 leading-[18px]">1st Jan, 2024 to 15 Jan, 2024</span>
                        </div>
                    </div>
                    <div className="flex justify-between py-3 border-dashed border-b border-gray-200 items-center">
                        <h3 className="text-gray-600 text-sm font-medium leading-[20px]">Total appointments </h3>
                        <div className="flex items-center">
                            <span className="text-sm font-semibold text-gray-600 leading-[20px]">20</span>
                        </div>
                    </div>
                    <div className="flex justify-between py-3 border-dashed border-b border-gray-200 items-center">
                        <h3 className="text-gray-600 text-sm font-medium leading-[20px]">Total appointment sales </h3>
                        <div className="flex items-center">
                            <span className="text-sm font-semibold text-gray-600 leading-[20px]">$3720.58</span>
                        </div>
                    </div>
                    <div className="flex justify-between py-3 border-dashed border-b border-gray-200 items-center">
                        <h3 className="text-gray-600 text-sm font-medium leading-[20px]">Total products </h3>
                        <div className="flex items-center">
                            <span className="text-sm font-semibold text-gray-600 leading-[20px]">12</span>
                        </div>
                    </div>
                    <div className="flex justify-between py-3 border-dashed border-b border-gray-200 items-center">
                        <h3 className="text-gray-600 text-sm font-medium leading-[20px]">Total products sale </h3>
                        <div className="flex items-center">
                            <span className="text-sm font-semibold text-gray-600 leading-[20px]">$960.00</span>
                        </div>
                    </div>
                    <div className="flex justify-between py-3 border-b border-gray-200 items-center">
                        <h3 className="text-gray-600 text-sm font-medium leading-[20px]">Taxes </h3>
                        <div className="flex items-center">
                            <span className="text-sm font-semibold text-gray-600 leading-[20px]">$210.00</span>
                        </div>
                    </div>
                    <div className="flex justify-between py-3 border-dashed border-b border-gray-200 items-center">
                        <h3 className="text-gray-600 text-sm font-medium leading-[20px]">Sub Total </h3>
                        <div className="flex items-center">
                            <span className="text-sm font-semibold text-gray-600 leading-[20px]">$4890.58</span>
                        </div>
                    </div>
                    <div className="flex justify-between py-3 border-dashed border-b border-gray-200 items-center">
                        <h3 className="text-gray-600 text-sm font-medium leading-[20px]">Brand commission (40%)</h3>
                        <div className="flex items-center">
                            <span className="text-sm font-semibold text-gray-600 leading-[20px]">-$1956.23</span>
                        </div>
                    </div>
                    <div className="flex justify-between py-3 border-dashed border-b border-gray-200 items-center">
                        <h3 className="text-gray-600 text-sm font-medium leading-[20px]">Other</h3>
                        <div className="flex items-center">
                            <span className="text-sm font-semibold text-gray-600 leading-[20px]">-$0.00</span>
                        </div>
                    </div>
                    <div className="flex justify-between py-3 items-center">
                        <div>
                            <h3 className="tex-sm font-medium leading-[20px] text-gray-900">Total due to Koray</h3>
                            <span className="text-xs leading-[18px] text-gray-600 font-normal">Total received during this period</span>
                        </div>
                        <div className="flex items-center">
                            <span className="text-lg font-bold leading-7 text-gray-900">$2934.35</span>
                        </div>
                    </div>
                </div>
            </PopupModal>
        </>
    );
};

export default StaffPaymentStatus;
