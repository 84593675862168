import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { useTranslation } from 'react-i18next';
import InputWithLabel from 'src/components/InputWithLabel';
import { FONT_FAMILY, FONT_WEIGHT, LogoDisplaySettingsProps } from './Logo.interface';
const FontSizeOptions = [
    { label: '16px', value: 16 },
    { label: '18px', value: 18 },
    { label: '20px', value: 20 },
    { label: '22px', value: 22 },
    { label: '24px', value: 24 },
    { label: '26px', value: 26 },
    { label: '28px', value: 28 },
    { label: '30px', value: 30 },
];
const LogoHeightOptions = [
    { label: '40px', value: 40 },
    { label: '42px', value: 42 },
    { label: '44px', value: 44 },
    { label: '46px', value: 46 },
    { label: '48px', value: 48 },
    { label: '50px', value: 50 },
];
const LogoDisplaySettings: FC<LogoDisplaySettingsProps> = ({ setFormDetails, displayType }) => {
    const { t } = useTranslation();
    const { control } = useFormContext();

    return (
        <div className={`flex flex-wrap gap-4  mb-3`}>
            {(displayType === 'logo' || displayType === 'logo_text') && (
                <Controller
                    name="logo_height"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="logo_input_width">
                            <SelectBox
                                value={LogoHeightOptions.filter((item: any) => item.value === value)}
                                name="logo_height"
                                id="logo_height"
                                options={LogoHeightOptions}
                                isFontFamily
                                onChangeFunc={(selectedOption: any) => {
                                    setFormDetails((prevFormDetails: any) => ({
                                        ...prevFormDetails,
                                        logo_height: selectedOption ? selectedOption.value : '',
                                    }));
                                    onChange(selectedOption ? selectedOption.value : null);
                                }}
                                placeholder="Select Logo Height"
                                errorText={!!error}
                                isClearable={false}
                                isSearchable={false}
                            />
                            {error && <p className="text-error">{error?.message}</p>}
                        </div>
                    )}
                />
            )}
            {displayType === 'text' && (
                <>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field: { onChange, value: nameValue }, fieldState: { error } }: any) => (
                            <div className="logo_input_width">
                                <InputWithLabel
                                    label=""
                                    placeholder="Please Enter Text"
                                    inputControlClassName="text-xs form-control-md"
                                    onChange={(event: any) => {
                                        setFormDetails((prevFormDetails: any) => ({
                                            ...prevFormDetails,
                                            name: event.target.value,
                                        }));
                                        onChange(event.target.value);
                                    }}
                                    value={nameValue}
                                    name="name"
                                    isFocus={true}
                                    error={!!error}
                                />
                                {error && <p className="text-error">{error?.message}</p>}
                            </div>
                        )}
                    />
                    <Controller
                        name="font_size"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="logo_input_width">
                                <SelectBox
                                    value={FontSizeOptions.filter((item: any) => item.value === value)}
                                    name="font_size"
                                    id="font_size"
                                    options={FontSizeOptions}
                                    isFontFamily
                                    onChangeFunc={(selectedOption: any) => {
                                        setFormDetails((prevFormDetails: any) => ({
                                            ...prevFormDetails,
                                            font_size: selectedOption ? selectedOption.value : '',
                                        }));
                                        onChange(selectedOption ? selectedOption.value : null);
                                    }}
                                    placeholder="Select Font Size"
                                    errorText={!!error}
                                    isClearable={false}
                                    isSearchable={false}
                                />
                                {error && <p className="text-error">{error?.message}</p>}
                            </div>
                        )}
                    />
                    <Controller
                        name="logo_font_family"
                        control={control}
                        render={({ field: { onChange, value: valueFontFamily }, fieldState: { error } }: any) => (
                            <div className="logo_input_width">
                                <SelectBox
                                    value={FONT_FAMILY.filter((item: any) => item.value === valueFontFamily)}
                                    name="logo_font_family"
                                    id="logo_font_family"
                                    options={FONT_FAMILY}
                                    isFontFamily
                                    onChangeFunc={(selectedOption: any) => {
                                        setFormDetails((prevFormDetails: any) => ({
                                            ...prevFormDetails,
                                            logo_font_family: selectedOption ? selectedOption.value : '',
                                        }));
                                        onChange(selectedOption ? selectedOption.value : null);
                                    }}
                                    placeholder="Font family"
                                    errorText={!!error}
                                    isClearable={false}
                                    isSearchable={false}
                                />
                                {error && <p className="text-error">{error?.message}</p>}
                            </div>
                        )}
                    />
                    <Controller
                        name="font_weight"
                        control={control}
                        render={({ field: { onChange, value: valueFontWeight }, fieldState: { error } }: any) => (
                            <div className="logo_input_width">
                                <SelectBox
                                    value={FONT_WEIGHT.filter((item: any) => item.value === valueFontWeight)}
                                    name="font_weight"
                                    id="font_weight"
                                    isFontWeight
                                    options={FONT_WEIGHT}
                                    onChangeFunc={(selectedOption: any) => {
                                        setFormDetails((prevFormDetails: any) => ({
                                            ...prevFormDetails,
                                            font_weight: selectedOption ? selectedOption.value : '',
                                        }));
                                        onChange(selectedOption ? selectedOption.value : null);
                                    }}
                                    placeholder="Font weight"
                                    errorText={!!error}
                                    isClearable={false}
                                    isSearchable={false}
                                />
                                {error && <p className="text-error">{error?.message}</p>}
                            </div>
                        )}
                    />
                </>
            )}
            {displayType === 'logo_text' && (
                <>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field: { onChange, value: nameValue }, fieldState: { error } }: any) => (
                            <div className="logo_input_width">
                                <InputWithLabel
                                    label=""
                                    placeholder="Please Enter Text"
                                    inputControlClassName="text-xs form-control-md"
                                    onChange={(event: any) => {
                                        setFormDetails((prevFormDetails: any) => ({
                                            ...prevFormDetails,
                                            name: event.target.value,
                                        }));
                                        onChange(event.target.value);
                                    }}
                                    value={nameValue}
                                    name="name"
                                    isFocus={true}
                                    error={!!error}
                                />
                                {error && <p className="text-error">{error?.message}</p>}
                            </div>
                        )}
                    />
                    <div className="flex gap-4 w-[476px]">
                        <Controller
                            name="font_size"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                <div className="w-1/3">
                                    <SelectBox
                                        value={FontSizeOptions.filter((item: any) => item.value === value)}
                                        name="font_size"
                                        id="font_size"
                                        options={FontSizeOptions}
                                        isFontFamily
                                        onChangeFunc={(selectedOption: any) => {
                                            setFormDetails((prevFormDetails: any) => ({
                                                ...prevFormDetails,
                                                font_size: selectedOption ? selectedOption.value : '',
                                            }));
                                            onChange(selectedOption ? selectedOption.value : null);
                                        }}
                                        placeholder="Select Font Size"
                                        errorText={!!error}
                                        isClearable={false}
                                        isSearchable={false}
                                    />
                                    {error && <p className="text-error">{error?.message}</p>}
                                </div>
                            )}
                        />
                        <Controller
                            name="logo_font_family"
                            control={control}
                            render={({ field: { onChange, value: valueFontFamily }, fieldState: { error } }: any) => (
                                <div className="w-1/3">
                                    <SelectBox
                                        value={FONT_FAMILY.filter((item: any) => item.value === valueFontFamily)}
                                        name="logo_font_family"
                                        id="logo_font_family"
                                        options={FONT_FAMILY}
                                        isFontFamily
                                        onChangeFunc={(selectedOption: any) => {
                                            setFormDetails((prevFormDetails: any) => ({
                                                ...prevFormDetails,
                                                logo_font_family: selectedOption ? selectedOption.value : '',
                                            }));
                                            onChange(selectedOption ? selectedOption.value : null);
                                        }}
                                        placeholder="Font family"
                                        errorText={!!error}
                                        isClearable={false}
                                        isSearchable={false}
                                    />
                                    {error && <p className="text-error">{error?.message}</p>}
                                </div>
                            )}
                        />
                        <Controller
                            name="font_weight"
                            control={control}
                            render={({ field: { onChange, value: valueFontWeight }, fieldState: { error } }: any) => (
                                <div className="w-1/3">
                                    <SelectBox
                                        value={FONT_WEIGHT.filter((item: any) => item.value === valueFontWeight)}
                                        name="font_weight"
                                        id="font_weight"
                                        isFontWeight
                                        options={FONT_WEIGHT}
                                        onChangeFunc={(selectedOption: any) => {
                                            setFormDetails((prevFormDetails: any) => ({
                                                ...prevFormDetails,
                                                font_weight: selectedOption ? selectedOption.value : '',
                                            }));
                                            onChange(selectedOption ? selectedOption.value : null);
                                        }}
                                        placeholder="Font weight"
                                        errorText={!!error}
                                        isClearable={false}
                                        isSearchable={false}
                                    />
                                    {error && <p className="text-error">{error?.message}</p>}
                                </div>
                            )}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default LogoDisplaySettings;
