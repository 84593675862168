import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectBox from 'src/components/SelectBox/SelectBox';
import PageHeader from 'src/components/PageHeader';
import { useAppSelector } from 'src/redux/hooks';
import { allShopLocations, allShopStaff, currentRole, currentShop } from 'src/redux/services/common/Common.slice';
import { getDateRange, getSelectBoxOptions } from 'src/utils/global-functions';
import moment from 'moment';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import Overview from './Overview';
import Totalsaleschart from './Totalsaleschart';
import ClientAndPaymentSplit from './ClientAndPaymentSplit';
import DateRangePicker from 'src/components/DateRangePicker/DateRangePicker';
import AdvAnalytics from './AdvAnalytics';
import ServiceChartPie from './ServiceChartPie';
import ProductChartPie from './ProductChartPie';
import EmptyMsgWithBtn from 'src/components/EmptyMsgWithBtn';
import Slideshow from './Slideshow';
import { ROLES } from 'src/constants/common';
import { IAnalyticsDetails, IGraphOptions } from './Analytics.interface';
import Performance from './Performance';
import { AllLocationsOptions, AllTeamMembersOptions } from 'src/utils/global-variables';

const Analytics = () => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const shopLocationList: any = useAppSelector(allShopLocations);
    const allShopStaffList = useAppSelector(allShopStaff);
    const role = useAppSelector(currentRole);
    const [isLoading, setIsLoading] = useState(false);
    const [AnalyticsDetails, setAnalyticsDetails] = useState<IAnalyticsDetails | null>(null);
    const [currentTab, setCurrentTab] = useState('daily');
    const [locationOptions, setLocationOptions] = useState<any[]>([]);
    const [staffOptions, setStaffOptions] = useState<any[]>([]);
    const [startDate, setStartDate] = useState<Date | undefined>(moment().toDate());
    const [endDate, setEndDate] = useState<Date | undefined>(moment().toDate());
    const [selectedTimeRange, setSelectedTimeRange] = useState<string>('custom');
    const [selectedFilter, setSelectedFilter] = useState({
        location_id: null,
        staff_id: null,
    });
    const [boxLabel, setBoxLabel] = useState('Yesterday');

    const graphOptions: IGraphOptions = {
        label: 'Product Sales',
        value: 'product_amount',
        valueXField: 'booking_date',
        seriesArray: {
            owner: [
                { label: 'Product Sales', value: 'product_amount' },
                { label: 'Service Sales', value: 'service_amount' },
            ],
            rent: [
                { label: 'Product Sales', value: 'product_amount' },
                { label: 'Service Sales', value: 'service_amount' },
                { label: 'Tips', value: 'tip_amount' },
                { label: 'Taxes', value: 'taxes_and_fees' },
            ],
        },
    };

    const timePeriodTabs = useMemo(
        () => [
            {
                name: t('Today'),
                value: 'daily',
            },
            {
                name: t('This Week'),
                value: 'weekly',
            },
            {
                name: t('This Month'),
                value: 'monthly',
            },
            {
                name: t('This Year'),
                value: 'yearly',
            },
        ],
        [],
    );

    const processArray = async (array: any, groupBy: string) => {
        const resultMap: Record<string, any> = {};

        if (array.length === 0) {
            return [];
        }

        array = array.sort((a: any, b: any) => moment(a.booking_date).valueOf() - moment(b.booking_date).valueOf());

        array.forEach((item: any) => {
            const {
                booking_date: bookingDate,
                total_amount: totalAmount,
                service_amount: serviceAmount,
                product_amount: productAmount,
                taxes_and_fees: taxesAndFees,
                other_amount: otherAmount,
                tip_amount: tipAmount,
            } = item;

            let createdAt: string;
            let humanReadableDate: string;

            if (groupBy === 'yearly') {
                createdAt = moment(bookingDate).format('YYYY-MM');
                humanReadableDate = moment(bookingDate).format('MMMM YYYY'); // e.g., "August 2023"
            } else if (groupBy === 'daily') {
                createdAt = moment(bookingDate).format('YYYY-MM-DD-HH');
                humanReadableDate = moment(bookingDate).format('dddd, MMMM Do YYYY, h A'); // e.g., "Monday, August 14th 2023, 2 PM"
            } else {
                createdAt = moment(bookingDate).format('YYYY-MM-DD');
                humanReadableDate = moment(bookingDate).format('dddd, MMMM Do YYYY'); // e.g., "Monday, August 14th 2023"
            }

            const parsedTipAmount = tipAmount ? parseFloat(tipAmount) : 0;

            if (!resultMap[createdAt]) {
                resultMap[createdAt] = {
                    booking_date: bookingDate,
                    total_amount: totalAmount,
                    service_amount: serviceAmount,
                    product_amount: productAmount,
                    taxes_and_fees: taxesAndFees,
                    other_amount: otherAmount,
                    tip_amount: parsedTipAmount,
                    human_readable_date: humanReadableDate,
                };
            } else {
                resultMap[createdAt].total_amount += totalAmount;
                resultMap[createdAt].service_amount += serviceAmount;
                resultMap[createdAt].product_amount += productAmount;
                resultMap[createdAt].taxes_and_fees += taxesAndFees;
                resultMap[createdAt].other_amount += otherAmount;
                resultMap[createdAt].tip_amount += parsedTipAmount;
            }
        });

        const sortedArray = Object.entries(resultMap).sort(([, a], [, b]: [string, any]) => moment(a.bookingDate).valueOf() - moment(b.bookingDate).valueOf());

        return sortedArray.map(([, value]) => value);
    };

    useEffect(() => {
        const locationData = getSelectBoxOptions(shopLocationList, 'id', 'name', 'id', 'shortname', 'profile_image_url');
        setLocationOptions([AllLocationsOptions, ...locationData]);

        const staff = getSelectBoxOptions(allShopStaffList, 'id', 'full_name', 'id', 'full_name', 'profile_image_url');

        setStaffOptions([AllTeamMembersOptions, ...staff]);
    }, []);

    useEffect(() => {
        const fetchAnalytics = async () => {
            setIsLoading(true);
            const payload = {
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
                type: 'custom',
                ...(selectedFilter.location_id !== null && { location_id: selectedFilter.location_id }),
                ...(selectedFilter.staff_id !== null && { staff_id: selectedFilter.staff_id }),
            };
            await getAnalytics(payload);
        };
        fetchAnalytics();
    }, [selectedFilter, startDate, endDate]);

    useEffect(() => {
        if (startDate && endDate) {
            if (selectedTimeRange === 'all_time') {
                setBoxLabel('Previous period');
            } else if (selectedTimeRange === 'quarterly') {
                setBoxLabel('last quarter');
            } else if (currentTab === '') {
                let days = moment(endDate).diff(moment(startDate), 'days');
                days = selectedTimeRange === 'last_30_days' || selectedTimeRange === 'last_90_days' || selectedTimeRange === 'last_7_days' || selectedTimeRange === 'custom' ? days + 1 : days;

                setBoxLabel(`Previous ${days} days`);
            }
        }
    }, [startDate, endDate]);

    const getAnalytics = async (payload: any) => {
        await axiosGet(API.ANALYTICS.GET, { shop_id: shop.id }, payload)
            .then(async (response) => {
                const data = response.data.data;
                const sum = await processArray(data.graph, currentTab);
                const finalResult: any[] = Object.values(sum);
                data.graph = finalResult;
                setAnalyticsDetails(data);
            })
            .finally(() => setIsLoading(false));
    };

    const handleTab = (val: string) => () => {
        setCurrentTab(val);
    };

    useEffect(() => {
        if (currentTab !== '') {
            const { start, end } = getDateRange(currentTab);
            setStartDate(start);
            setEndDate(end);
        }
        if (currentTab === 'weekly') {
            setBoxLabel('last week');
            setSelectedTimeRange(currentTab);
        } else if (currentTab === 'monthly') {
            setBoxLabel('last month');
            setSelectedTimeRange(currentTab);
        } else if (currentTab === 'yearly') {
            setBoxLabel('last year');
            setSelectedTimeRange(currentTab);
        } else if (currentTab === 'daily') {
            setBoxLabel('yesterday');
            setSelectedTimeRange('custom');
        }
    }, [currentTab]);
    const handleFilter = (filter: string) => (event: any) => {
        const value = event ? event.value : event;
        setSelectedFilter((old: any) => ({ ...old, [filter]: value }));
    };
    const handleDatePickerChange = (date: any, timeRange?: string) => {
        if (timeRange === 'weekly' || timeRange === 'monthly' || timeRange === 'yearly') {
            setCurrentTab(timeRange);
        } else {
            setCurrentTab('');
            const [start, end] = date;

            setStartDate(start);
            setEndDate(end);
            if (!end) {
                setEndDate(start);
            }
        }
        if (timeRange) {
            setSelectedTimeRange(timeRange);
        }
    };

    return (
        <div className="inner-page-wrape">
            <PageHeader title={'Analytics'} subtitle="Manage your client and their account permissions here."></PageHeader>
            <div className=" side-spaching flex flex-1 flex-col w-full">
                <div className="flex flex-row justify-between items-center mb-3 xxl:mb-4">
                    <div className="flex max-2xl:flex-col flex-row gap-3">
                        <div className="fl-tab-btn-view2 min-h-[36px] w-full">
                            {timePeriodTabs.map((tab: any, index) => (
                                <button
                                    key={index}
                                    type="button"
                                    onClick={handleTab(tab.value)}
                                    className={`w-full fl-tab-link2 justify-center items-center min-h-[34px] min-w-fit ${tab.value === currentTab ? 'active' : ''}`}
                                >
                                    {tab.name}
                                </button>
                            ))}
                        </div>

                        <DateRangePicker
                            isDisplayAsBox={false}
                            handleDatePickerChange={handleDatePickerChange}
                            selectedTimeRange={selectedTimeRange}
                            startDate={startDate}
                            endDate={endDate}
                            allTimeStartDate={shop.created_at}
                            showYearDropdown={false}
                            isToday={currentTab === 'daily'}
                            isShowDropDownIcon={false}
                            containerClassName="left-0 "
                            parentClassName="sales-datepicker w-[280px] min-w-[280px] xlm:w-[310px] xlm:min-w-[310px] xxl:w-[340px] xxl:min-w-[340px]"
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            activeFilter={currentTab}
                            setActiveFilter={setCurrentTab}
                        />
                    </div>
                    <div className="flex flex-row gap-3 max-2xl:w-[49%] 2xl:w-[27%] xlm:w-[30%] xxl:w-[30%]">
                        <div className="2xl:w-[50%] xxl:w-[50%] w-[50%]">
                            <SelectBox
                                options={locationOptions}
                                value={locationOptions.find((option) => option.value === selectedFilter.location_id)}
                                noOptionsMessage="No Locations Found"
                                placeholder={t('All locations')}
                                onChangeFunc={handleFilter('location_id')}
                                classComp="outline-select-box"
                                isSearchable={false}
                                isClearable={false}
                            />
                        </div>
                        <div className="2xl:w-[50%] xxl:w-[50%] w-[50%]">
                            <SelectBox
                                options={staffOptions}
                                noOptionsMessage="No Team Found"
                                value={staffOptions.find((option) => option.value === selectedFilter.staff_id)}
                                placeholder={t('All Team members')}
                                onChangeFunc={handleFilter('staff_id')}
                                classComp="outline-select-box"
                                isSearchable={false}
                                isClearable={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="w-full flex max-2xl:flex-col flex-row gap-3 xxl:gap-4 mb-4 flex-1">
                    <div className=" flex flex-col gap-3  2xl:w-[calc(73%-12px)] xlm:w-[70%] xxl:w-[calc(70%_-_16px)]  max-2xl:w-full">
                        <Overview AnalyticsDetails={AnalyticsDetails} boxLabel={boxLabel} isLoading={isLoading} />
                        {AnalyticsDetails?.graph.length ? (
                            <div className="w-full flex flex-col flex-1 p-0 border border-borderSecondary rounded-xl  2xl:min-h-[389px]  xxl:min-h-[377px] xxl:max-h-[377px] shadow relative">
                                <div className="font-semibold text-lg 2xl:text-base xlm:text-xl text-mainTextColor absolute top-[14px] left-5">Total Sales</div>
                                <Totalsaleschart
                                    salesLineGraph={AnalyticsDetails?.graph || []}
                                    seriesArray={role.name === ROLES.STAFF_RENT ? graphOptions.seriesArray.rent : graphOptions.seriesArray.owner}
                                    graphType={role.name}
                                    valueXField={graphOptions.valueXField}
                                    activeFilter={currentTab}
                                />
                            </div>
                        ) : (
                            <>
                                <div className="border flex flex-col flex-1  border-borderSecondary rounded-xl shadow 2xl:min-h-[389px]  xxl:min-h-[377px] xxl:max-h-[377px] p-5">
                                    <EmptyMsgWithBtn title={t('No Total Sales Data')} description={t('No_Sales_Data_description')} />
                                </div>
                            </>
                        )}
                    </div>
                    <div className="w-[27%] xlm:w-[30%] max-2xl:w-full flex flex-1 analytics dashboard-table">
                        <div className="w-full border border-borderSecondary rounded-xl flex flex-1 flex-col  shadow ">
                            <div className="px-5 py-5  flex justify-between items-center">
                                <div className="font-semibold text-lg 2xl:text-base xlm:text-xl text-mainTextColor">
                                    {role.name === ROLES.STAFF_COMMISSION || role.name === ROLES.STAFF_RENT ? 'Top Clients' : 'Team Performance'}
                                </div>
                                {/* <div className="flex flex-row gap-1">
                                    <h4 className="font-semibold text-xs text-[#143CB3]">View All</h4>
                                    <ArrowUpRight className="w-4 h-4  text-[#143CB3]" />
                                </div> */}
                            </div>
                            <Performance
                                startDate={moment(startDate).format('YYYY-MM-DD')}
                                endDate={moment(endDate).format('YYYY-MM-DD')}
                                selectedFilter={selectedFilter}
                                role={role.name === ROLES.STAFF_COMMISSION || role.name === ROLES.STAFF_RENT ? 'rent' : 'other'}
                            />
                        </div>
                    </div>
                </div>
                <div className="w-full flex flex-1 max-2xl:flex-col flex-row max-2xl:gap-3 gap-5 mb-4">
                    <div className=" flex flex-col gap-3 xxl:gap-4 max-2xl:w-full w-[50%] flex-1">
                        <div
                            className={`flex flex-row gap-2 w-full border border-borderSecondary rounded-lg shadow ${
                                AnalyticsDetails?.products_graph && AnalyticsDetails?.products_graph.length > 0 ? 'min-h-[172px] max-h-[172px]' : 'h-[242px]'
                            }  p-5`}
                        >
                            {AnalyticsDetails?.services_graph && AnalyticsDetails?.services_graph.length > 0 ? (
                                <>
                                    <div
                                        className={`flex items-center  ${
                                            AnalyticsDetails?.products_graph && AnalyticsDetails?.products_graph.length > 0
                                                ? 'min-w-[132px] max-w-[132px]'
                                                : 'min-w-[196px] max-w-[196px] min-h-[196px] max-h-[196px]'
                                        } `}
                                    >
                                        <ServiceChartPie salesIncome={AnalyticsDetails?.services_graph} totalAmount={AnalyticsDetails?.total?.total_service_amount} />
                                    </div>

                                    <div className="flex w-full justify-between items-center relative ">
                                        <Slideshow salesIncome={AnalyticsDetails?.services_graph} isProductGraph={AnalyticsDetails?.products_graph && AnalyticsDetails?.products_graph.length > 0} />
                                    </div>
                                </>
                            ) : (
                                <div className="pie-chart-wrapper">
                                    <EmptyMsgWithBtn title={t('No Service Data')} description={t('No_Service_Data_description')} />
                                </div>
                            )}
                        </div>

                        {AnalyticsDetails?.products_graph && AnalyticsDetails?.products_graph.length > 0 && (
                            <div className="flex flex-row gap-4 w-full border border-borderSecondary rounded-lg min-h-[172px] max-h-[172px] p-5 shadow">
                                <div className="flex items-center min-w-[132px] max-w-[132px]">
                                    {AnalyticsDetails && <ProductChartPie salesIncome={AnalyticsDetails?.products_graph} totalAmount={AnalyticsDetails?.total?.total_product_amount} />}
                                </div>
                                <div className="flex w-full justify-between items-center relative">
                                    <Slideshow
                                        salesIncome={AnalyticsDetails?.products_graph}
                                        product={true}
                                        isProductGraph={AnalyticsDetails?.products_graph && AnalyticsDetails?.products_graph.length > 0}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div
                        className={`w-[50%] max-2xl:w-full flex flex-1 ${
                            AnalyticsDetails?.products_graph && AnalyticsDetails?.products_graph.length > 0 ? 'min-h-[172px] max-2xl:max-h-[242px] split-list2' : 'h-[242px] max-h-[242px]  split-list'
                        }`}
                    >
                        <ClientAndPaymentSplit paymentMethod={AnalyticsDetails?.payment_method_split || []} clientSplit={AnalyticsDetails?.user_split || []} isLoading={isLoading} />
                    </div>
                </div>
                {role.name !== ROLES.STAFF_COMMISSION && role.name !== ROLES.STAFF_RENT && (
                    <div className="w-full flex flex-row mb-5">
                        <AdvAnalytics selectedFilter={selectedFilter} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Analytics;
