import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './../../utils/Datatable.scss';
import './../../utils/prime-react-datatable.scss';
import PopupModal from 'src/components/PopupModal';
import { File02 } from '@untitled-ui/icons-react/build/cjs';

const StaffPolicy = ({ handleClose }: any) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('commission');

    const tabs = useMemo(
        () => [
            {
                name: 'Commission',
                type: 'commission',
            },
            {
                name: 'Rent',
                type: 'rent',
            },
            {
                name: 'Partner',
                type: 'partner',
            },
            {
                name: 'No tracking',
                type: 'notracking',
            },
        ],
        [],
    );
    const handleTabClick = (type: string) => async (event: { preventDefault: () => void }) => {
        event.preventDefault();
        setActiveTab(type);
    };

    const Title = () => (
        <div className="flex flex-row gap-3 ">
            <div className="flex justify-center border shadow-sm border-gray-200 items-center h-12 w-12 rounded-[10px]">
                <File02 className="text-gray-700" />
            </div>
            <div className="flex flex-col">
                <p className="text-lg font-semibold text-gray-900 ">{t('How does payment structure work?')}</p>
                <span className="text-xs font-normal text-gray-600">{t('Payment structure work inforamtion')}</span>
            </div>
        </div>
    );

    return (
        <>
            <PopupModal onClose={handleClose} size="w-[500px]" title={<Title />} primaryButton={t('Ok')} secondaryButton={t('Cancel')} acceptAction={handleClose} declineAction={handleClose}>
                <div className="overflow-x-auto">
                    <div className="fl-tab-btn-view w-full">
                        {tabs.map((tab, index) => (
                            <button key={index} type="button" className={`fl-tab-link !max-w-[182px] ${activeTab === tab.type ? 'active !bg-[#E0EAFF]' : ''}`} onClick={handleTabClick(tab.type)}>
                                {tab.name}
                            </button>
                        ))}
                    </div>
                    {activeTab === 'commission' && (
                        <div className="flex flex-col w-full">
                            <h2 className="info-title-text mt-5 mb-3">Commission-Based Employees:</h2>
                            <ul className="space-y-4 text-gray-600 font-normal text-sm list-disc list-outside pl-[23px]">
                                <li>
                                    <p className="text-sm font-normal">Commission Employees are not set up with Stripe.</p>
                                </li>
                                <li>
                                    <p className="text-sm font-normal">
                                        Control and Visibility: For commission-based employees, the brand owners (or admins) retain significant oversight and control. This includes visibility over the
                                        employee&apos;s clients, analytics, and the ability to set loyalty and referral programs specifically for these staff members.
                                    </p>
                                </li>
                                <li>
                                    <p className="text-sm font-normal">
                                        Payment Processing: Payments for services rendered by commission-based employees are divided between the employee and the brand owner, according to
                                        predetermined commission rates. The brand&apos;s administrative system is designed to handle these splits, ensuring accurate and timely distribution of funds.
                                        For cash or other forms of payments, a crediting system is in place to track and manage any outstanding balances, with specific policies dictating the division
                                        and management of these funds.
                                    </p>
                                </li>
                                <li>
                                    <p className="text-sm font-normal">
                                        Policy Setting: Commission payouts and the frequency of these payouts are set by the brand, aligning with cash management policies to regulate cash register
                                        balances and accountability. Loyalty points and referral codes are managed at the brand level, ensuring consistency across the brand.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    )}
                    {activeTab === 'rent' && (
                        <div className="flex flex-col w-full">
                            <h2 className="info-title-text mt-5 mb-3">
                                Rent-Based Employees: <span className="text-sm  text-gray-600">(Collective model is the same) </span>
                            </h2>
                            <ul className="space-y-4 text-gray-600 font-normal text-sm list-disc list-outside  pl-[23px]">
                                <li>
                                    <p className="text-sm font-normal">Rent Employees are set up with Stripe and can either use their own terminal or the shops terminal to checkout their clients.</p>
                                </li>
                                <li>
                                    <p className="text-sm font-normal">
                                        Control and Independence: Rent-based employees operate with a high degree of independence. They control their bookings, payments (using their own or the
                                        shop&apos;s payment terminals), and client interactions without admin oversight. The brand&apos;s role is limited to tracking monthly rent payments and ensuring
                                        the service provider is listed on the store&apos;s roster.
                                    </p>
                                </li>
                                <li>
                                    <p className="text-sm font-normal">
                                        Payment Processing: Rent-based employees direct all earnings to themselves, paying a fixed rent to the brand owners for the use of space and facilities. This
                                        model prioritizes autonomy in financial transactions, with the brand not involved in the service provider&apos;s revenue streams beyond collecting rent.
                                    </p>
                                </li>
                                <li>
                                    <p className="text-sm font-normal">
                                        Policy Setting: Rent-based employees set their own policies regarding services, pricing, and client management. The brand does not dictate loyalty programs,
                                        referral incentives, or service pricing for these individuals, allowing for tailored approaches that suit each rent-based employee&apos;s business strategy.
                                    </p>
                                </li>
                                <li>
                                    <p className="text-sm font-normal">Rent based staff have their own stripe, they have their own terminal.</p>
                                </li>
                            </ul>
                        </div>
                    )}
                    {activeTab === 'partner' && (
                        <div className="flex flex-col w-full">
                            <h2 className="info-title-text mt-5 mb-3">Partner-Based:</h2>
                            <ul className="space-y-4 text-gray-600 font-normal text-sm list-disc list-outside  pl-[23px]">
                                <li>
                                    <p className="text-sm font-normal">All same functionalities as rent staff (i cannot see their client list, nor analytics)</p>
                                </li>
                                <li>
                                    <p className="text-sm font-normal">No rent payment.</p>
                                </li>
                                <li>
                                    <p className="text-sm font-normal">Not part of the rent collection.</p>
                                </li>
                                <li>
                                    <p className="text-sm font-normal">Same dashboard as the rent member.</p>
                                </li>
                                <li>
                                    <p className="text-sm font-normal">Cannot access branding</p>
                                </li>
                                <li>
                                    <p className="text-sm font-normal">Rent based staff have their own stripe, they have their own terminal.</p>
                                </li>
                            </ul>
                        </div>
                    )}
                    {activeTab === 'notracking' && (
                        <div className="flex flex-col w-full">
                            <h2 className="info-title-text mt-5 mb-3">Partner-Based:</h2>
                            <ul className="space-y-4 text-gray-600 font-normal text-sm list-disc list-outside  pl-[23px]">
                                <li>
                                    <p className="text-sm font-normal">All same functionalities as rent staff (i cannot see their client list, nor analytics)</p>
                                </li>
                                <li>
                                    <p className="text-sm font-normal">No rent payment.</p>
                                </li>
                                <li>
                                    <p className="text-sm font-normal">Not part of the rent collection.</p>
                                </li>
                                <li>
                                    <p className="text-sm font-normal">Same dashboard as the rent member.</p>
                                </li>
                                <li>
                                    <p className="text-sm font-normal">Cannot access branding</p>
                                </li>
                                <li>
                                    <p className="text-sm font-normal">Rent based staff have their own stripe, they have their own terminal.</p>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </PopupModal>
        </>
    );
};

export default StaffPolicy;
