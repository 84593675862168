import React, { FC, useState } from 'react';
import { IResourceHeader } from './Calendar.Interface';
import { capitalizeFirstLetterSpace, getShortName } from 'src/utils/global-functions';

const ResourceHeader: FC<IResourceHeader> = ({ eventInfo }) => {
    const [isImageError, setIsImageError] = useState<boolean>(false);
    const handleImageError = () => {
        setIsImageError(true);
    };
    return (
        <div className="flex px-1.5 pb-[12px] pt-[10px] items-center">
            <figure className="NoImgName h-10 w-10 mr-2">
                {eventInfo.resource.profile_image_url && !isImageError ? (
                    <img src={eventInfo.resource.profile_image_url} alt="client" className="w-full h-full object-cover" onError={handleImageError} />
                ) : (
                    getShortName(`${eventInfo.resource.name}`)
                )}
            </figure>
            <div className="flex-1  text-left">
                <h3 className="text-gray-900 font-medium text-xs leading-[18px] truncate max-w-[86px] xlm:max-w-[90px] 2xlm:max-w-[128px] xxl:max-w-[136px]">
                    {capitalizeFirstLetterSpace(eventInfo.resource.name)}
                </h3>
                <p className="text-gray-600 font-normal text-xs leading-[18px] truncate max-w-[86px] xlm:max-w-[90px] 2xlm:max-w-[128px] xxl:max-w-[136px]">{eventInfo.resource.role}</p>
            </div>
        </div>
    );
};
export default ResourceHeader;
