import React, { FC, useEffect, useState } from 'react';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { Controller, FormProvider, Resolver, useFieldArray, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import WorkingHours from 'src/app/Location/WorkingHours';
import CustomButton from 'src/components/CustomButton';
import { axiosGet, axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { currentShop, me, userMe } from 'src/redux/services/common/Common.slice';
import { IFormData, INTERVAL_OPTIONS, IProps, IWorkingHoursValue, InitWorkingHours } from './WorkingHoursStaff.interface';
import { ChevronDown, ChevronRight, ClockCheck } from '@untitled-ui/icons-react/build/cjs';
import { BackgroundDesign } from 'src/theme/Images';
import { get } from 'lodash';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';
import Footer from 'src/app/Layout/Footer';
import StepProgressBar from 'src/components/StepProgressBar';

const WorkingHoursStaff: FC<IProps> = ({ setStep, inviteStaffShop, stepLenght, currentStep }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector(userMe);
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);
    const [isShow, setIsShow] = useState<any>({ 0: true });
    const [shopDetail] = useState<any>(inviteStaffShop ?? shop);

    const schema = Yup.object({
        frequency_booking: Yup.string().required(t('This field is required')),
        shop_locations: Yup.array()
            .of(
                Yup.object()
                    .shape({
                        shop_location_id: Yup.number().required(t('This field is required')).integer(t('The value must be an integer')).positive(t('The value must be a positive number')),
                        hours: Yup.array()
                            .of(
                                Yup.object().shape({
                                    day: Yup.string().required('Day is required').oneOf(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], 'Invalid day'),
                                    status: Yup.boolean().required('Status is required'),

                                    from_time_hours: Yup.string()
                                        .required('From time hours are required')
                                        .test({
                                            name: 'from_valid_hours_1',
                                            message: 'From hours must be between 01 to 12 and minutes must be between 00 to 59',
                                            test: function (value) {
                                                const { status } = this.parent;
                                                if (status) {
                                                    const parts = value.split(':');
                                                    const hours = parseInt(parts[0], 10);
                                                    const minutes = parseInt(parts[1], 10);
                                                    const isValidHours = !isNaN(hours) && hours >= 1 && hours <= 12;
                                                    const isValidMinutes = !isNaN(minutes) && minutes >= 0 && minutes < 60;
                                                    return isValidHours && isValidMinutes;
                                                }
                                                return true;
                                            },
                                        })
                                        .test({
                                            name: 'from_valid_hours_2',
                                            message: '"From" time must be less than "To" time',
                                            test: function (value) {
                                                const { from_time_type: fromTimeType, to_time_hours: toTimeHours, to_time_type: toTimeType, status } = this.parent;

                                                if (status) {
                                                    const fromMoment = moment(`${value} ${fromTimeType}`, 'hh:mm a');
                                                    const toMoment = moment(`${toTimeHours} ${toTimeType}`, 'hh:mm a');

                                                    if (fromMoment.isSameOrAfter(toMoment)) {
                                                        return false;
                                                    } else {
                                                        return true;
                                                    }
                                                }
                                                return true;
                                            },
                                        }),

                                    to_time_hours: Yup.string()
                                        .required('To time hours are required')
                                        .test({
                                            name: 'to_valid_hours_1',
                                            message: 'To hours must be between 01 to 12 and minutes must be between 00 to 59',
                                            test: function (value) {
                                                const { status } = this.parent;
                                                if (status) {
                                                    const parts = value.split(':');
                                                    const hours = parseInt(parts[0], 10);
                                                    const minutes = parseInt(parts[1], 10);
                                                    const isValidHours = !isNaN(hours) && hours >= 1 && hours <= 12;
                                                    const isValidMinutes = !isNaN(minutes) && minutes >= 0 && minutes < 60;
                                                    return isValidHours && isValidMinutes;
                                                }
                                                return true;
                                            },
                                        })
                                        .test({
                                            name: 'to_valid_hours_2',
                                            message: '"To" time must be greater than "From" time',
                                            test: function (value) {
                                                const { from_time_hours: fromTimeHours, from_time_type: fromTimeType, to_time_type: toTimeType, status } = this.parent;

                                                if (status) {
                                                    const fromMoment = moment(`${fromTimeHours} ${fromTimeType}`, 'hh:mm a');
                                                    const toMoment = moment(`${value} ${toTimeType}`, 'hh:mm a');

                                                    if (toMoment.isSameOrBefore(fromMoment)) {
                                                        return false;
                                                    } else {
                                                        return true;
                                                    }
                                                }
                                                return true;
                                            },
                                        }),

                                    from_time_type: Yup.string().required('From time type is required').oneOf(['am', 'pm'], 'Invalid from time type'),

                                    to_time_type: Yup.string().required('To time type is required').oneOf(['am', 'pm'], 'Invalid to time type'),
                                }),
                            )
                            .required(t('This field is required')),
                    })
                    .required(t('This field is required')),
            )
            .required(t('This field is required')),
    }).required();

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as Resolver<IFormData>,
        defaultValues: {
            frequency_booking: '',
            shop_locations: [
                {
                    shop_location_id: null,
                    hours: InitWorkingHours,
                    shop_location_name: null,
                },
            ],
        },
    });

    const {
        handleSubmit,
        setError,
        setValue,
        control,
        formState: { errors },
    } = methods;

    const { fields } = useFieldArray({
        control,
        keyName: 'uuid',
        name: 'shop_locations',
    });

    useEffect(() => {
        getStaff();
    }, []);

    const getStaff = async () => {
        setIsLoading(true);
        await axiosGet(API.STAFF.GET, { shop_id: shopDetail.id, id: user.id })
            .then((response) => {
                const data = response.data.data;

                let shopLocations: any[] = [];
                data.locations.forEach((location: any) => {
                    let hours = InitWorkingHours;
                    if (location.staff_working_hours.length) {
                        hours = location.staff_working_hours.map((hour: any) => {
                            const momentFromTime = moment(hour.from, 'HH:mm:ss');
                            const momentToTime = moment(hour.to, 'HH:mm:ss');

                            const fromTimeHours = momentFromTime.format('hh:mm');
                            const fromTimeType = momentFromTime.format('a');

                            const toTimeHours = momentToTime.format('hh:mm');
                            const toTimeType = momentToTime.format('a');

                            return {
                                day: hour.day,
                                status: hour.status,
                                from_time_hours: fromTimeHours,
                                to_time_hours: toTimeHours,
                                from_time_type: fromTimeType,
                                to_time_type: toTimeType,
                            };
                        });
                    }

                    shopLocations.push({
                        shop_location_id: location.id,
                        shop_location_name: location.name,
                        hours,
                    });
                });

                setValue('shop_locations', shopLocations);
                setValue('frequency_booking', data.locations[0].staff_working_hours.length ? data.locations[0].staff_working_hours[0].frequency_booking : '');
            })
            .finally(() => setIsLoading(false));
    };

    const handleSave = async (data: IFormData) => {
        setIsLoading(true);
        const cread = {
            shop_id: shopDetail.id,
            frequency_booking: data.frequency_booking,
            shop_locations: data?.shop_locations?.map((shop_location: any) => ({
                shop_location_id: shop_location.shop_location_id,
                hours: shop_location.hours.map((day: IWorkingHoursValue) => {
                    const from = moment(`${day.from_time_hours} ${day.from_time_type}`, 'hh:mm a').format('HH:mm:ss');
                    const to = moment(`${day.to_time_hours} ${day.to_time_type}`, 'hh:mm a').format('HH:mm:ss');
                    return {
                        day: day.day,
                        status: day.status,
                        from,
                        to,
                    };
                }),
            })),
        };

        await axiosPatch(API.USER.WORKINGHOURS, cread, { shop_id: shopDetail.id })
            .then(async (response) => {
                if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
                    await dispatch(me());
                    setStep(4);
                }
            })
            .catch((error) => {
                if (error.data.status === errorCode.unprocessable) {
                    if (error.data.data) {
                        Object.keys(error.data.data).forEach((field) => {
                            setError(field as keyof IFormData, {
                                type: 'manual',
                                message: error.data.data[field][0],
                            });
                        });
                    }
                    return;
                }
            })
            .finally(() => setIsLoading(false));
    };

    const handleCollapse = (index: number) => (event: any) => {
        event.stopPropagation();
        setIsShow((old: any) => ({ ...old, [index]: !old[index] }));
    };

    useFormErrorFocus<IFormData>({ errors });

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleSave)}>
                <main className="p-6 h-[calc(100vh-138px)] overflow-y-scroll flex items-center flex-col">
                    <div className="flex justify-center relative">
                        <img src={BackgroundDesign} alt="" className="absolute -top-6 z-[-1]" />
                        <div className="w-[600px] pt-6 ">
                            <div className="flex flex-col items-center justify-center gap-4 w-full max-w-[512px] mx-auto">
                                <span className="flex items-center justify-center h-[56px] w-[56px] rounded-xl text-gray-400  bg-white border border-gray-200 shadow-InputAndButton">
                                    {<ClockCheck className="h-7 w-7 cursor-pointer text-gray-700 " />}
                                </span>
                                <div className="text-center">
                                    <h1 className="text-3xl leading-[38px] text-gray-700 font-semibold">Set Your Working Hours</h1>
                                    <p className="font-normal text-base text-secondaryTxtColor">Set working hours of your business, you can always change them in the settings</p>
                                </div>
                            </div>

                            <div className="flex justify-between my-[33px] p-6 border rounded-2xl shadow">
                                <div className="w-full max-w-[312px]">
                                    <p className="text-xl leading-[30px] font-semibold mb-3">{t('Appointment intervals')}</p>
                                    <p className="font-normal text-sm text-secondaryTxtColor">Set the time intervals at which your calendar will have openings for client bookings.</p>
                                </div>
                                <div className="w-full max-w-[200px] login_service">
                                    <Controller
                                        name="frequency_booking"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div>
                                                <SelectBox
                                                    value={INTERVAL_OPTIONS.filter((item: any) => item.value === value)}
                                                    name="frequency_booking"
                                                    id="frequency_booking"
                                                    options={INTERVAL_OPTIONS}
                                                    required
                                                    onChangeFunc={(selectedOption: any) => onChange(selectedOption.value)}
                                                    placeholder={t('Select')}
                                                    errorText={!!error}
                                                    classComp={`!h-10 ${error ? 'is-invalid' : ''}`}
                                                    isClearable={false}
                                                />
                                                {/* {error && <p className="text-error">{error.message}</p>} */}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                            {fields.map((item: any, index: number) => (
                                <div key={item.uuid} className=" py-3 border-b border-borderSecondary ">
                                    <div onClick={handleCollapse(index)} className="flex items-center justify-between title-text px-2  cursor-pointer">
                                        <Controller
                                            key={`shop_locations.[${index}].shop_location_name`}
                                            name={`shop_locations.${index}.shop_location_name`}
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div>
                                                    {/* <SelectBox
                                                    value={locationOptions.filter((option: any) => option.value === value)}
                                                    name={`shop_locations.[${index}].shop_location_id`}
                                                    options={locationOptions}
                                                    onChangeFunc={(selectedOption: any) => onChange(parseInt(selectedOption.value))}
                                                    placeholder="Select your location"
                                                    errorText={!!error}
                                                />
                                                {error && <p className="text-error">{error.message}</p>} */}
                                                    {value}
                                                </div>
                                            )}
                                        />
                                        <button type="button" className="text-3xl">
                                            {isShow[index] ? <ChevronDown /> : <ChevronRight />}
                                        </button>
                                    </div>
                                    <div className={`flex flex-col gap-5  ${!isShow[index] ? 'd-none' : ''}`}>
                                        <WorkingHours baseTabIndex={index} errors={get(errors, `shop_locations[${index}]`)} name={`shop_locations.${index}.hours`} isLoginStep />
                                    </div>
                                </div>
                            ))}
                            {/* <CustomButton type="submit" className="w-full mt-4" primary isLoading={isLoading} disabled={isLoading}>
                                {t('Continue')}
                            </CustomButton> */}
                        </div>
                    </div>
                </main>
                <div className="bottom-0 right-0 max-2xl:start-[420px] start-[480px] fixed">
                    <div className="w-full mt-5   mb-2  gap-4 flex justify-center mx-auto">
                        <CustomButton secondary onClick={() => setStep(1)} size="w-[292px]">
                            Back
                        </CustomButton>
                        <CustomButton primary isLoading={isLoading} disabled={isLoading} type="submit" size="w-[292px]" className="w-[292px]">
                            {t('Continue')}
                        </CustomButton>
                    </div>
                    <div className="w-full bg-white">
                        <StepProgressBar steps={stepLenght} currentStep={currentStep} className="w-full mx-auto justify-center" />
                    </div>
                    <Footer />
                </div>
            </form>
        </FormProvider>
    );
};

export default WorkingHoursStaff;
