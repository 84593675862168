import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Switch from 'src/components/Switch/Switch';

const Appointment = () => {
    const { t } = useTranslation();
    const { control, formState, setValue, getValues } = useFormContext();
    return (
        <div className="flex flex-col flex-1">
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Cancellations')}</p>
                    <p className="subtitle-text">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}</p>
                </div>
                <Controller
                    name="cancellations"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Cancellation Past Limit')}</p>
                    <p className="subtitle-text">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}</p>
                </div>
                <Controller
                    name="cancellation_past_limit"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Reschedule')}</p>
                    <p className="subtitle-text">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}</p>
                </div>
                <Controller
                    name="reschedule"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Reschedule with Confirmation')}</p>
                    <p className="subtitle-text">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}</p>
                </div>
                <div className="flex flex-col w-[512px] gap-4 ">
                    <Controller
                        name="reschedule_confirmation"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <>
                                <div className="flex items-center gap-2">
                                    <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                    <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                    <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                    <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                                </div>
                            </>
                        )}
                    />
                </div>
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Appointment Modified (client)')}</p>
                    <p className="subtitle-text">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}</p>
                </div>
                <Controller
                    name="user_update_booking"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Appointment Modified (staff)')}</p>
                    <p className="subtitle-text">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}</p>
                </div>
                <Controller
                    name="staff_update_booking"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('New Appointment')}</p>
                    <p className="subtitle-text">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}</p>
                </div>
                <Controller
                    name="new_booking"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('New Appointment with Confirmation')}</p>
                    <p className="subtitle-text">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}</p>
                </div>
                <Controller
                    name="new_booking_confirmation"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('First Time Client')}</p>
                    <p className="subtitle-text">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}</p>
                </div>
                <Controller
                    name="new_user_booking"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('First Time Client w/ Referral')}</p>
                    <p className="subtitle-text">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}</p>
                </div>
                <Controller
                    name="new_user_booking_referral"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Added to Waitlist')}</p>
                    <p className="subtitle-text">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}</p>
                </div>
                <Controller
                    name="create_waitlist"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
        </div>
    );
};

export default Appointment;
