import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { currentShop, getAllShopCategory, getAllShopServices } from 'src/redux/services/common/Common.slice';
import { useTranslation } from 'react-i18next';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import InputWithLabel from 'src/components/InputWithLabel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ExistingFormData, IFormData, ISelectBox, SERVICE_ENGLISH } from './Services.interface';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { getSelectBoxOptions, onError } from 'src/utils/global-functions';
import { toast } from 'react-toastify';
import { errorCode } from 'src/constants/errorCode';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { axiosGet, axiosPatch, axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import CustomButton from 'src/components/CustomButton';
import { ArrowLeft, XClose } from '@untitled-ui/icons-react/build/cjs';
import Checkbox from 'src/components/Checkbox';
import TypeForm from './TypeForm';
import { LANGUAGE, LANGUAGES } from 'src/constants/common';
import Switch from 'src/components/Switch/Switch';
import { allShopLocations } from 'src/redux/services/common/Common.slice';
import { IconCheckdActive } from 'src/theme/Images';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';

const ServiceForm = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const shop = useAppSelector(currentShop);
    const shopLocationList: any = useAppSelector(allShopLocations);
    const [categoryOptions, setCategoryOptions] = useState<ISelectBox[]>([]);
    const [locationIds, setLocationIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [language, setLanguage] = useState(LANGUAGE);

    const schema = Yup.object({
        shop_category_id: Yup.string().required(t('This field is required')),
        shop_location_id: Yup.array().min(1, t('This field is required')),
        type: Yup.string().required(t('This field is required')),
        is_active: Yup.boolean().required(t('This field is required')),
        ...(language === 'en'
            ? {
                  name: Yup.string().required(t('This field is required')),
                  description: Yup.string().when('type', {
                      is: (val: string) => val !== 'variable',
                      then: (schm) => schm.required(t('This field is required')),
                      otherwise: (schm) => schm.notRequired(),
                  }),
                  options: Yup.array().when('type', {
                      is: (val: string) => val === 'variable',
                      then: (schm) =>
                          schm.of(
                              Yup.object({
                                  title: Yup.string().required(t('This field is required')),
                                  description: Yup.string().required(t('This field is required')),
                              }),
                          ),
                      otherwise: (schm) => schm.notRequired(),
                  }),
              }
            : {
                  fr_name: Yup.string().required(t('This field is required')),
                  fr_description: Yup.string().when('type', {
                      is: (val: string) => val !== 'variable',
                      then: (schm) => schm.required(t('This field is required')),
                      otherwise: (schm) => schm.notRequired(),
                  }),
                  fr_options: Yup.array().when('type', {
                      is: (val: string) => val === 'variable',
                      then: (schm) =>
                          schm.of(
                              Yup.object({
                                  fr_title: Yup.string().required(t('This field is required')),
                                  fr_description: Yup.string().required(t('This field is required')),
                              }),
                          ),
                      otherwise: (schm) => schm.notRequired(),
                  }),
              }),
    }).required();

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as any,
        defaultValues: {
            name: '',
            type: '',
            description: '',
            shop_category_id: '',
            is_active: true,
            shop_location_id: [],
            options: [{ title: '', description: '' }],
            fr_name: '',
            fr_description: '',
            fr_options: [{ fr_title: '', fr_description: '' }],
        },
    });

    const {
        handleSubmit,
        setValue,
        setError,
        getValues,
        control,
        watch,
        formState: { errors },
    } = methods;

    useEffect(() => {
        const ids = shopLocationList.map((item: any) => item.id);
        setLocationIds(ids);
        getCategory();
    }, [shop]);

    useEffect(() => {
        if (id) {
            getService();
        }
    }, [id, shop]);

    const getService = async () => {
        setIsLoading(true);
        await axiosGet(API.SERVICE.GET, { shop_id: shop.id, id })
            .then((response) => {
                const data = response.data.data;

                const options = Array.isArray(data.variables)
                    ? data.variables.map((variable: any) => ({
                          title: variable.name,
                          description: variable.description,
                      }))
                    : [{ title: '', description: '' }];
                const frOptions = Array.isArray(data.fr_variables)
                    ? data.fr_variables.map((variable: any) => ({
                          fr_title: variable.name,
                          fr_description: variable.description,
                      }))
                    : [{ title: '', description: '' }];

                const locations = data.locations.map((item: any) => item.id);

                const existingData: ExistingFormData = {
                    name: data.name || '',
                    type: data.type,
                    description: data.description || '',
                    is_active: data.is_active,
                    shop_category_id: data.shop_category_id || '',
                    shop_location_id: locations,
                    options: options,
                    fr_name: data.fr_name || '',
                    fr_description: data.fr_description || '',
                    fr_shop_location_id: Array.isArray(data.fr_shop_location_id) ? data.fr_shop_location_id : [],
                    fr_options: frOptions,
                };
                Object.keys(existingData).forEach((key) => {
                    setValue(key as keyof IFormData, existingData[key]);
                });
            })
            .catch(() => navigate(ROUTES.SERVICES.LIST))
            .finally(() => setIsLoading(false));
    };

    const getCategory = async () => {
        const result = await dispatch(getAllShopCategory({ shop_id: shop.id }));
        if (result.type === getAllShopCategory.fulfilled.toString()) {
            const options: ISelectBox[] | [] = getSelectBoxOptions(result.payload.data, 'id');
            setCategoryOptions(options);
        }
    };

    const handleSave = async (data: IFormData) => {
        setIsLoading(true);

        const payload = {
            ...data,
            variable_name: data.options.map((option: any) => option.title),
            variable_description: data.options.map((option: any) => option.description),
        };

        if (data.type === 'regular') {
            delete payload.variable_name;
            delete payload.variable_description;
        } else {
            delete payload.description;
        }

        if (id) {
            await axiosPatch(API.SERVICE.UPDATE, payload, { shop_id: shop.id, id })
                .then(async () => {
                    await dispatch(getAllShopServices({ shop_id: shop.id }));
                    navigate(ROUTES.SERVICES.LIST);
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            Object.keys(response.data).forEach((field) => {
                                setError(field as keyof IFormData, {
                                    type: 'manual',
                                    message: response.data[field][0],
                                });
                            });
                        }
                        return;
                    }
                    toast.error(response.message);
                })
                .finally(() => setIsLoading(false));
        } else {
            await axiosPost(API.SERVICE.CREATE, payload, { shop_id: shop.id })
                .then(async () => {
                    await dispatch(getAllShopServices({ shop_id: shop.id }));
                    navigate(ROUTES.SERVICES.LIST);
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            Object.keys(response.data).forEach((field) => {
                                setError(field as keyof IFormData, {
                                    type: 'manual',
                                    message: response.data[field][0],
                                });
                            });
                        }
                        return;
                    }
                    toast.error(response.message);
                })
                .finally(() => setIsLoading(false));
        }
    };

    const resetForm = (event: any) => {
        navigate(ROUTES.SERVICES.LIST);
        /* event.preventDefault();
        reset(); */
    };

    const handleLocationChange = (selectedId: number, onChange: (value: number[]) => void, value: number[]) => () => {
        const newChecked = value.includes(selectedId)
            ? value.filter((currentId) => currentId !== selectedId) // Remove unchecked item
            : [...value, selectedId]; // Add checked item

        onChange(newChecked);
    };

    const handleLanguage = (lang: string) => () => {
        setLanguage(lang);
    };

    const watchServiceName = watch('name');
    useFormErrorFocus<IFormData>({ errors });

    return (
        <div className="inner-page-wrape !px-0">
            <div className="flex-1">
                <div className="flex justify-between gray-banner-bg bg-gray-50 relative h-[130px] text-secondaryTxtColor pt-5 pb-3 xlm:px-[30px] px-5  gap-1.5 rounded-tl-3xl items-start">
                    <Link to={ROUTES.SERVICES.LIST} className="flex items-center cursor-pointer text-gray-600 h-[36px] text-xs font-semibold gap-1.5">
                        <ArrowLeft className="w-4 cursor-pointer text-gray-600" /> {t('Back to Service')}
                    </Link>
                    <Link to={ROUTES.SERVICES.LIST} className="close-page-btn">
                        <XClose className="xl:w-5 xl:h-5 w-4 h-4 text-gray-900" />
                    </Link>
                </div>
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(handleSave)}>
                        <div className="relative mb-[10px] w-full xl:pt-[24px] xlm:px-8 px-5 pt-2 pb-2 flex sms:gap-4 gap-2 items-center">
                            <div className="flex flex-col flex-1">
                                <h1 className="mr-3 text-gray-900 xlm:text-3xl sm:text-xl text-base inline align-middle font-semibold tracking-[-0.72px]">{watchServiceName || t('Service Name')}</h1>
                                <p className="flex gap-2.5 text-gray-600 sm:text-sm text-xs mt-1 font-normal items-center">{getValues('description')}</p>
                            </div>
                            <div className="flex gap-3">
                                <CustomButton secondary type="reset" onClick={resetForm} disabled={isLoading}>
                                    {t('Cancel')}
                                </CustomButton>
                                <CustomButton primary type="submit" isLoading={isLoading} disabled={isLoading}>
                                    {t('Save Changes')}
                                </CustomButton>
                            </div>
                        </div>
                        <div className="flex flex-col xlm:px-8 px-5 border-t">
                            <div className="flex justify-start h-[38px] gap-2 bg-gray-50 mt-5 p-1 rounded-lg border border-gray-200 ">
                                {LANGUAGES.map((lang: any, index) => (
                                    <CustomButton
                                        key={index}
                                        type="button"
                                        onClick={handleLanguage(lang.shortName)}
                                        className={`px-14 h-[28px] py-2 w-full max-w-[182px] shadow-none text-sm font-bold ${
                                            lang.shortName === language ? '!text-blue-700 !bg-blue-50' : 'text-gray-500'
                                        }`}
                                    >
                                        {lang.name}
                                    </CustomButton>
                                ))}
                            </div>
                            {language === 'en' ? (
                                <div className="flex gap-5 py-6 border-b border-gray-200">
                                    <div className="title-block w-[280px]">
                                        <h3 className="font-semibold text-sm text-gray-700">{t('Service details')}</h3>
                                        <p className="text-xs leading-[18px] text-secondaryTxtColor font-normal ">{t('Describe the general details of your service.')}</p>
                                    </div>
                                    <div className="data-wrap-block w-full max-w-[666px] flex flex-col gap-4">
                                        <div className="flex gap-4">
                                            <Controller
                                                name="name"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState }: any) => (
                                                    <div className="flex-1">
                                                        <InputWithLabel
                                                            label={t('Service Name')}
                                                            required
                                                            placeholder={t('Enter service name')}
                                                            onChange={onChange}
                                                            value={value}
                                                            name="name"
                                                            id="name"
                                                            error={!!fieldState.error}
                                                        />
                                                        {/* {fieldState.error && <p className="text-error">{fieldState.error.message}</p>} */}
                                                    </div>
                                                )}
                                            />
                                            <Controller
                                                name="shop_category_id"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState }: any) => (
                                                    <div className="flex-1">
                                                        <SelectBox
                                                            name="shop_category_id"
                                                            label={t('Select Service Category')}
                                                            required
                                                            placeholder={t('Select service category')}
                                                            options={categoryOptions}
                                                            id="shop_category_id"
                                                            onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                                            value={categoryOptions.find((option) => option.value === value)}
                                                            error={!!fieldState.error}
                                                        />
                                                        {/* {fieldState.error && <p className="text-error">{fieldState.error.message}</p>} */}
                                                    </div>
                                                )}
                                            />
                                            <Controller
                                                name="type"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState }: any) => (
                                                    <div className="flex-1">
                                                        <SelectBox
                                                            name="type"
                                                            label={t('Service Type')}
                                                            required
                                                            isClearable={false}
                                                            isSearchable={false}
                                                            id="type"
                                                            placeholder={t('Select service type')}
                                                            options={SERVICE_ENGLISH}
                                                            onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                                            value={SERVICE_ENGLISH.find((option) => option.value === value)}
                                                            error={!!fieldState.error}
                                                        />
                                                        {/* {fieldState.error && <p className="text-error">{fieldState.error.message}</p>} */}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        <div>
                                            <TypeForm language={language} />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="flex gap-5 py-6 border-b border-gray-200">
                                    <div className="title-block w-[280px]">
                                        <h3 className="font-semibold text-sm text-gray-700">Détails des services</h3>
                                        <p className="text-xs leading-[18px] text-secondaryTxtColor font-normal">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit')}</p>
                                    </div>
                                    <div className="data-wrap-block w-full max-w-[666px] flex flex-col gap-4">
                                        <div className="flex gap-4">
                                            <Controller
                                                name="fr_name"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState }: any) => (
                                                    <div className="flex-1">
                                                        <InputWithLabel
                                                            label="Nom du service"
                                                            id="fr_name"
                                                            placeholder="Nom du service"
                                                            required
                                                            onChange={onChange}
                                                            value={value}
                                                            name="fr_name"
                                                            error={!!fieldState.error}
                                                        />
                                                        {/* {fieldState.error && <p className="text-error">{fieldState.error.message}</p>} */}
                                                    </div>
                                                )}
                                            />
                                            <Controller
                                                name="shop_category_id"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState }: any) => (
                                                    <div className="flex-1">
                                                        <SelectBox
                                                            name="shop_category_id"
                                                            id="shop_category_id"
                                                            label="Catégorie de service"
                                                            required
                                                            placeholder="Sélectionnez la catégorie de service"
                                                            options={categoryOptions}
                                                            onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                                            value={categoryOptions.find((option) => option.value === value)}
                                                            error={!!fieldState.error}
                                                        />
                                                        {/* {fieldState.error && <p className="text-error">{fieldState.error.message}</p>} */}
                                                    </div>
                                                )}
                                            />
                                            <Controller
                                                name="type"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState }: any) => (
                                                    <div className="flex-1">
                                                        <SelectBox
                                                            name="type"
                                                            id="type"
                                                            label="Type de service"
                                                            isClearable={false}
                                                            isSearchable={false}
                                                            required
                                                            placeholder="Sélectionnez le type de service"
                                                            options={SERVICE_ENGLISH}
                                                            onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                                            value={SERVICE_ENGLISH.find((option) => option.value === value)}
                                                            error={!!fieldState.error}
                                                        />
                                                        {/* {fieldState.error && <p className="text-error">{fieldState.error.message}</p>} */}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        <div>
                                            <TypeForm language={language} />
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="flex gap-5 py-6 border-b border-gray-200">
                                <div className="title-block w-[280px]">
                                    <h3 className="font-semibold text-sm text-gray-700">
                                        {t('Service locations')}
                                        <span className="asterisk">*</span>
                                    </h3>
                                    <p className="text-xs leading-[18px] text-secondaryTxtColor font-normal ">{t('Select the location where the service will be offered.')}</p>
                                </div>
                                <div className="data-wrap-block w-[calc(100%-280px)]">
                                    <Controller
                                        name="shop_location_id"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState }) => (
                                            <div id="shop_location_id">
                                                <div className="flex items-center gap-2 text-sm font-medium text-gray-700">
                                                    <Switch
                                                        onChange={(e: any) => {
                                                            const isChecked = e.value;
                                                            if (isChecked) {
                                                                onChange([...locationIds]);
                                                            } else {
                                                                onChange([]);
                                                            }
                                                        }}
                                                        isChecked={locationIds.length === value.length && locationIds.every((locationId) => value.includes(locationId))}
                                                    />
                                                    <span>{t('All locations')}</span>
                                                </div>
                                                <div className="gap-5 mt-5 grid grid-cols-3 xxl:grid-cols-4">
                                                    {shopLocationList.map((location: any) => (
                                                        <label
                                                            className={`border rounded-xl p-4 cursor-pointer ${value.includes(location.id) ? 'border-primary' : ''} w-full relative ${
                                                                fieldState.error ? 'is-invalid' : ''
                                                            }`}
                                                            key={location.id}
                                                            htmlFor={`location_${location.id}`}
                                                        >
                                                            <img src={location.profile_image_url} alt="Location Img" className="h-[200px] w-full rounded-xl object-cover" onError={onError} />
                                                            <div className="text-wrap mt-4">
                                                                <h2 className="text-base font-semibold capitalize-first">{location.name}</h2>
                                                                <p className="text-sm font-normal text-secondaryTxtColor mt-2">{`${location.street}, ${location.city}, ${location.country}`}</p>
                                                                <div className="d-none">
                                                                    <Checkbox
                                                                        id={`location_${location.id}`}
                                                                        name="shop_location_id"
                                                                        value={location.id} //{Number(location.id)}
                                                                        checked={value ? value.includes(location.id) : false}
                                                                        onChange={handleLocationChange(location.id, onChange, value)}
                                                                    />
                                                                </div>
                                                                <div className={`absolute -top-[7px] -right-[7px] ${value.includes(location.id) ? 'block' : 'hidden'}`}>
                                                                    <i className="icon">
                                                                        <img src={IconCheckdActive} alt="" className="" />
                                                                    </i>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    ))}
                                                </div>
                                                {/* {fieldState.error && <p className="text-error">{fieldState.error.message}</p>} */}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="flex gap-5 items-center  py-6 border-b border-gray-200">
                                <div className="title-block w-[280px]">
                                    <h3 className="font-semibold text-sm text-gray-700">{t('Service listing')}</h3>
                                    <p className="text-xs leading-[18px] text-secondaryTxtColor font-normal">{t('Toggle to update service visibility in your online booking portal.')}</p>
                                </div>
                                <div className="data-wrap-block">
                                    <div className="flex flex-col gap-5">
                                        <Controller
                                            name="is_active"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState }: any) => (
                                                <div>
                                                    <div className="flex items-center gap-2 text-sm font-medium text-gray-700">
                                                        <Switch onChange={onChange} isChecked={value} />
                                                        <span>{t('List service')}</span>
                                                    </div>
                                                    {fieldState.error && <p className="text-error">{fieldState.error.message}</p>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex gap-3 justify-end mb-36 pt-5">
                                <CustomButton secondary type="reset" onClick={resetForm} disabled={isLoading}>
                                    {t('Cancel')}
                                </CustomButton>
                                <CustomButton primary type="submit" isLoading={isLoading} disabled={isLoading}>
                                    {t('Save Changes')}
                                </CustomButton>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};

export default ServiceForm;
