import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from 'src/components/PageHeader';
import StaffTable from './StaffTable';
import StaffPayoutTable from './StaffPayoutTable';
import StaffTipTable from './StaffTipTable';
import StaffProductComissionTable from './StaffProductComissionTable';

const Staff = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('overview');
    const tabs = useMemo(
        () => [
            {
                name: 'Overview',
                type: 'overview',
            },
            {
                name: 'Commision Payouts',
                type: 'commision',
            },
            {
                name: 'Rent Collection',
                type: 'rent',
            },
            {
                name: 'Tip Payouts',
                type: 'tip',
            },
            {
                name: 'Product commission',
                type: 'product',
            },
        ],
        [],
    );

    const handleTabClick = (type: string) => (event: { preventDefault: () => void }) => {
        event.preventDefault();
        switch (type) {
            case 'overview':
                document.title = t('Titles.Team Overview');
                break;
            case 'commision':
                document.title = t('Titles.Team Commision Payouts');
                break;
            case 'rent':
                document.title = t('Titles.Team Rent Collection');
                break;
            case 'tip':
                document.title = t('Titles.Team Tip Payouts');
                break;
            case 'product':
                document.title = t('Titles.Team Product Commission');
                break;
            default:
                break;
        }
        setActiveTab(type);
    };

    return (
        <div className="inner-page-wrape flex flex-col">
            <div className="flex-1 items-center flex flex-col">
                <PageHeader title={t('Team Management')} subtitle={t('Manage and keep track of all your team members, commission payouts and rent collection statements.')}>
                    {/* <CustomButton primary onClick={handleClick} icon={<Plus width="16" />} className="!px-4 py-[9px] rounded-lg shadow-InputAndButton">
                        {t('Add team member')}
                    </CustomButton> */}
                </PageHeader>
                <div className="side-spaching flex flex-1 flex-col w-full">
                    <div className="fl-tab-btn-view w-full">
                        {tabs.map((tab, index) => (
                            <button key={index} type="button" className={`fl-tab-link !max-w-[182px] ${activeTab === tab.type ? 'active' : ''}`} onClick={handleTabClick(tab.type)}>
                                {tab.name}
                            </button>
                        ))}
                    </div>
                    {activeTab === 'overview' && <StaffTable />}
                    {activeTab === 'commision' && <StaffPayoutTable dataType="commission" />}
                    {activeTab === 'rent' && <StaffPayoutTable dataType="rent" />}
                    {activeTab === 'tip' && <StaffTipTable />}
                    {activeTab === 'product' && <StaffProductComissionTable />}
                </div>
            </div>
        </div>
    );
};

export default Staff;
