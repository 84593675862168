import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomButton';
import InputWithLabel from 'src/components/InputWithLabel';
import { Plus, Copy02, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import Switch from 'src/components/Switch/Switch';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { getSelectBoxOptions } from 'src/utils/global-functions';
import { useAppSelector } from 'src/redux/hooks';
import { allShopLocations } from 'src/redux/services/common/Common.slice';
import { ISelectBox } from './Products.interface';

const sizeOptions = [
    {
        label: 'XXS',
        value: 'XXS',
    },
    {
        label: 'XS',
        value: 'XS',
    },
    {
        label: 'S',
        value: 'S',
    },
    {
        label: 'M',
        value: 'M',
    },
    {
        label: 'L',
        value: 'L',
    },
    {
        label: 'XL',
        value: 'XL',
    },
    {
        label: 'XXL',
        value: 'XXL',
    },
    {
        label: 'One size',
        value: 'One size',
    },
];

const ProductVariantsForm = () => {
    const { t } = useTranslation();
    const shopLocationList: any = useAppSelector(allShopLocations);
    const { control, getValues, watch } = useFormContext();
    const [locationOptions, setLocationOptions] = useState<ISelectBox[]>([]);

    useEffect(() => {
        const locationData: ISelectBox[] = getSelectBoxOptions(shopLocationList, 'id', 'name');
        setLocationOptions(locationData);
    }, [shopLocationList]);

    const {
        fields: variantsOptionFields,
        append: appendOption,
        remove: removeOption,
    } = useFieldArray({
        control,
        name: 'variants',
    });

    const handleAppendOption = () => {
        const newOption: any = {
            id: 0,
            size: '',
            color: '',
            price: '',
            cost: '',
            quantity: '',
            stock_alert_quantity: '',
            is_stock_alert: false,
            shop_location_id: '',
        };
        appendOption(newOption);
    };

    const handleCopyOption = (index: number) => {
        const fieldValues = watch(`variants.${index}`);
        appendOption({ ...fieldValues, id: 0 });
    };

    // const watchedVariants = watch('variants');

    // const getFilteredSizeOptions = (index: any) => {
    //     const variantSizeArr = watchedVariants.map((variant: any) => variant.size);
    //     const filteredVariantSizeArr = filter(variantSizeArr, (item) => item !== '');
    //     if (filteredVariantSizeArr.length === 0) {
    //         return sizeOptions;
    //     }
    //     const uniqueValueArray = difference(
    //         sizeOptions.map((item: any) => item.value),
    //         filteredVariantSizeArr,
    //     );
    //     return sizeOptions.filter((option) => uniqueValueArray.includes(option.value));
    // };

    // useEffect(() => {
    //     if (getValues('unit_of_measure') === 'units' && watchedVariants.length > 9) {
    //         const excessCount = watchedVariants.length - 8;
    //         for (let i = 0; i < excessCount; i++) {
    //             removeOption(watchedVariants.length - 1 - i);
    //         }
    //     }
    // }, [getValues('unit_of_measure'), variantsOptionFields, removeOption]);
    return (
        <>
            <div className="flex flex-col w-full border  border-gray-200 shadow-sm rounded-xl  max-w-[666px] 2xl:max-w-[810px] xlm:max-w-[910px] xxl:max-w-[1120px]  overflow-hidden  ">
                <div className="flex justify-between items-center py-3 px-5 border-b w-full">
                    <div className="flex flex-col gap-1">
                        <h2 className="text-gray-900 text-base font-semibold leading-6 capitalize">Product Variants</h2>
                        <h6 className="text-gray-600 text-xs font-normal leading-[18px]">Lorem ipsum is placeholder text commonly</h6>
                    </div>
                    <div className="flex items-center justify-center">
                        <CustomButton outlinePrimary onClick={handleAppendOption} icon={<Plus className="h-4 w-4" />}>
                            {t('Add new variant')}
                        </CustomButton>
                    </div>
                </div>
                <div className="flex flex-col w-full     max-w-[666px] 2xl:max-w-[810px] xlm:max-w-[910px] xxl:max-w-[1120px]  overflow-hidden overflow-x-auto ">
                    <table className="min-w-full bg-white">
                        <thead>
                            <tr className="bg-gray-50 border-b h-[44px] *:text-gray-600 *:text-xs *:font-medium *:leading-[18px] *:pl-5 *:text-left">
                                <th className="min-w-[100px]">{t('Size(unit)')}</th>
                                <th className="min-w-[100px]">{t('Unit Cost')}</th>
                                <th className="min-w-[120px]">{t('Unit Sell Price')}</th>
                                <th className="min-w-[150px]">{t('Initial Stock Quantity')}</th>
                                {watch(`unit_of_measure`) === 'units' && <th className="min-w-[100px]">{t('Custom colour')}</th>}
                                <th className="min-w-[150px]">{t('Location')}</th>
                                <th className="min-w-[150px]">{t('Enable Stock Alerts')}</th>
                                <th className="min-w-[150px]">{t('Stock Alert Quantity')}</th>
                                <th className="min-w-[100px]">{t('Action')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {variantsOptionFields.map((item, index) => (
                                <tr key={item.id} className="border-b *:pl-5 h-[64px] last:border-b-0 ">
                                    <td className="">
                                        {getValues('unit_of_measure') === 'units' ? (
                                            <Controller
                                                name={`variants.${index}.size`}
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                    <div className="">
                                                        <SelectBox
                                                            name={`variants.${index}.size`}
                                                            id={`variants.${index}.size`}
                                                            // label={t('Size')}
                                                            required
                                                            placeholder={t('Select Size')}
                                                            options={sizeOptions}
                                                            // options={getFilteredSizeOptions(index)}
                                                            onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                                            value={sizeOptions.find((option) => option.value === value)}
                                                            error={!!error}
                                                        />
                                                        {/* {error && <p className="text-error">{error.message}</p>} */}
                                                    </div>
                                                )}
                                            />
                                        ) : (
                                            <Controller
                                                name={`variants.${index}.size`}
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                    <div>
                                                        <InputWithLabel
                                                            type="number"
                                                            name={`variants.${index}.size`}
                                                            id={`variants.${index}.size`}
                                                            // label={t('Size')}
                                                            required
                                                            placeholder={t('Size')}
                                                            onChange={onChange}
                                                            value={value}
                                                            error={!!error}
                                                        />
                                                        {/* {error && <p className="text-error">{error.message}</p>} */}
                                                    </div>
                                                )}
                                            />
                                        )}
                                    </td>
                                    <td className="">
                                        <Controller
                                            name={`variants.${index}.cost`}
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <div>
                                                    <InputWithLabel
                                                        type="number"
                                                        name={`variants.${index}.cost`}
                                                        id={`variants.${index}.cost`}
                                                        // label={t('Cost')}
                                                        required
                                                        placeholder={t('Cost')}
                                                        onChange={onChange}
                                                        value={value}
                                                        error={!!error}
                                                    />
                                                    {/* {error && <p className="text-error">{error.message}</p>} */}
                                                </div>
                                            )}
                                        />
                                    </td>
                                    <td className=" ">
                                        <Controller
                                            name={`variants.${index}.price`}
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <div>
                                                    <InputWithLabel
                                                        type="number"
                                                        name={`variants.${index}.price`}
                                                        id={`variants.${index}.price`}
                                                        // label={t('Price')}
                                                        required
                                                        placeholder={t('Sell price')}
                                                        onChange={onChange}
                                                        value={value}
                                                        error={!!error}
                                                    />
                                                    {/* {error && <p className="text-error">{error.message}</p>} */}
                                                </div>
                                            )}
                                        />
                                    </td>
                                    <td className="">
                                        <Controller
                                            name={`variants.${index}.quantity`}
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <div>
                                                    <InputWithLabel
                                                        type="number"
                                                        name={`variants.${index}.quantity`}
                                                        id={`variants.${index}.quantity`}
                                                        // label={t('Initial Stock Quantity')}
                                                        required
                                                        placeholder={t('Quantity')}
                                                        onChange={onChange}
                                                        value={value}
                                                        error={!!error}
                                                    />
                                                    {/* {error && <p className="text-error">{error.message}</p>} */}
                                                </div>
                                            )}
                                        />
                                    </td>
                                    {watch(`unit_of_measure`) === 'units' && (
                                        <td className="">
                                            <Controller
                                                name={`variants.${index}.color`}
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                    <div>
                                                        <InputWithLabel
                                                            id={`variants.${index}.color`}
                                                            name={`variants.${index}.color`}
                                                            required
                                                            placeholder={t('85f563')}
                                                            onChange={onChange}
                                                            value={value}
                                                            error={!!error}
                                                        />
                                                        {/* {error && <p className="text-error">{error.message}</p>} */}
                                                    </div>
                                                )}
                                            />
                                        </td>
                                    )}
                                    <td className="  ">
                                        <Controller
                                            name={`variants.${index}.shop_location_id`}
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="">
                                                    <SelectBox
                                                        name={`variants.${index}.shop_location_id`}
                                                        id={`variants.${index}.shop_location_id`}
                                                        required
                                                        // label={t('Locations')}
                                                        options={locationOptions}
                                                        placeholder={t('Select location')}
                                                        noOptionsMessage="No Locations Found"
                                                        value={locationOptions.find((option) => option.value === value)}
                                                        // onChangeFunc={(option: any) => handleLocation(option, onChange)}
                                                        onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                                        error={!!error}
                                                    />
                                                    {/* {error && <p className="text-error">{error.message}</p>} */}
                                                </div>
                                            )}
                                        />
                                    </td>
                                    <td className=" ">
                                        <Controller
                                            name={`variants.${index}.is_stock_alert`}
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <div className="flex items-center gap-2">
                                                    <Switch onChange={onChange} isChecked={value} />
                                                    {/* <span className="text-gray-700 text-sm font-medium">{t('Enable Minimum Stock Alert')}</span> */}
                                                </div>
                                            )}
                                        />
                                    </td>
                                    <td className="">
                                        <Controller
                                            name={`variants.${index}.stock_alert_quantity`}
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <div>
                                                    <InputWithLabel
                                                        name={`variants.${index}.stock_alert_quantity`}
                                                        id={`variants.${index}.stock_alert_quantity`}
                                                        // label={t('Stock Alert Quantity')}
                                                        placeholder={t('Alert quantity')}
                                                        onChange={onChange}
                                                        value={value}
                                                        required
                                                        error={!!error}
                                                    />
                                                    {/* {error && <p className="text-error">{error.message}</p>} */}
                                                </div>
                                            )}
                                        />
                                    </td>
                                    <td className="flex gap-2.5 py-[14px] px-5 min-h-[64px] h-[64px]">
                                        <button type="button" onClick={() => handleCopyOption(index)}>
                                            <Copy02 className="text-gray-600 h-4 w-4 " />
                                        </button>
                                        {variantsOptionFields.length > 1 && (
                                            <button type="button" onClick={() => removeOption(index)}>
                                                <Trash01 className="text-gray-600 h-4 w-4" />
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default ProductVariantsForm;
