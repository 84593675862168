import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { ArrowLeft, Check, ChevronRight, XClose } from '@untitled-ui/icons-react/build/cjs';
import { useTranslation } from 'react-i18next';
import { defaultImage } from 'src/theme/Images';
import CustomButton from 'src/components/CustomButton';
import SClientBooking from './SClientBooking';

const SClientDetails = () => {
    const { t } = useTranslation();
    return (
        <div className="flex-1 flex flex-col mb-4">
            <div className="flex justify-between gray-banner-bg bg-gray-50 relative h-[130px] text-secondaryTxtColor pt-5 pb-3 xlm:px-[30px] px-5  gap-1.5 rounded-tl-3xl items-start">
                <div className="flex items-center justify-between w-full">
                    <Link to={ROUTES.CLIENT.LIST} className="flex items-center cursor-pointer text-gray-600 h-[36px] text-xs font-semibold gap-1.5">
                        <ArrowLeft className="w-4 cursor-pointer text-gray-600" /> {t('Back to Client')}
                    </Link>
                    <Link to={ROUTES.CLIENT.LIST} className="close-page-btn">
                        <XClose className="xl:w-5 xl:h-5 w-4 h-4 text-gray-900" />
                    </Link>
                </div>
            </div>
            <div className="flex flex-col flex-1">
                <div className="xlm:px-8 px-5 relative mb-[26px] w-full xl:pt-[10px] pt-2 pb-2 flex sms:gap-4 gap-2 xs:items-end items-start">
                    <div className="max-2xl:w-[112px] max-2xl:h-[112px] w-[144px] h-[144px] rounded-xl object-cover border-4 border-white drop-shadow-lg flex-none -mt-[62px] custom-loading">
                        <img src={defaultImage} alt="" />
                    </div>
                    <div className="flex justify-between items-center w-full">
                        <div className="flex gap-2">
                            <div className="flex flex-col">
                                <h1 className="mr-3 text-gray-900 xlm:text-3xl sm:text-xl text-base inline align-middle font-semibold tracking-[-0.72px] custom-loading">loadinging</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border-t border-[#EAECF0] py-[30px] bg-white flex flex-wrap flex-1 xlm:px-8 px-5">
                    <div className="flex flex-col xl:w-[calc(100%_-_370px)] xls:w-[calc(100%_-_342px)]  w-full xl:mr-8 xls:mr-4 lg:mr-2 mr-0 xls:mb-0 mb-8 flex-1">
                        <div className="fl-tab-btn-view w-full">
                            <div className="w-[1220px] h-[25px]">
                                {[...Array(4)].map((_$, index) => (
                                    <button key={index} type="button" className={`w-full`}></button>
                                ))}
                            </div>
                        </div>
                        <div>
                            <div className="mb-8">
                                <div className="flex justify-between items-center gap-2 mb-3 mt-6">
                                    <h2 className="text-lg font-semibold">{t('Overview')}</h2>
                                    <CustomButton
                                        className="!text-sm text-btnBg font-semibold flex items-center gap-1.5 h-[20px] shadow-none pr-0.5"
                                        rightIcon={<ChevronRight className="w-4 text-btnBg" />}
                                    >
                                        {t('View more')}
                                    </CustomButton>
                                </div>
                                <div className="grid grid-cols-4 gap-4">
                                    {[...Array(12)].map((_$, index) => (
                                        <div key={index} className="border flex flex-col rounded-lg p-3">
                                            <div>
                                                <span className="text-gray-600 text-xs font-normal mb-1.5 custom-loading">loadingloadinging</span>
                                                <p className="text-gray-900 text-xs font-medium custom-loading">loading</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="mb-8 datatable-custom">
                                <SClientBooking />
                            </div>
                        </div>
                    </div>
                    <div className="w-full xl:w-[306px] xls:w-[306px]  xl:ml-8 xls:ml-4 lg:ml-2 ml-0">
                        <div className="flex flex-col">
                            <div className="grid grid-cols-1 gap-4 mb-5">
                                <div className="title-block">
                                    <p className="sideBarHeading">{t('E-mail')}</p>
                                    <h3 className="text-primary text-sm font-medium flex items-center truncate custom-loading">loadinging@</h3>
                                </div>

                                <div className="title-block">
                                    <p className="sideBarHeading">{t('Account creation')}</p>
                                    <h3 className="sideBarSubHeading custom-loading">July 9th, 2024</h3>
                                </div>
                                <div className="title-block">
                                    <p className="sideBarHeading">{t('Last Action')}</p>
                                    <h3 className="sideBarSubHeading custom-loading">July 9th, 2024</h3>
                                </div>
                            </div>
                            <div className="flex justify-between mb-3 items-center">
                                <h5 className="sideBarHeading">Payment method</h5>
                            </div>
                            <div className="mb-5">
                                <div className="mx-auto bg-gray-50 border border-gray-300 rounded-xl mb-4">
                                    <div className="p-3 flex flex-col">
                                        <div className="flex items-center gap-3 h-[29px] mb-4">
                                            <div className="w-12 h-auto object-contain custom-loading">
                                                <img src={defaultImage} alt="Visa" />
                                            </div>
                                            <div className="flex gap-1 items-center">
                                                <p className="text-sm font-medium text-gray-900 capitalize leading-5 custom-loading">Visa Ending In 4242</p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-2 gap-4">
                                            <div className="flex flex-col">
                                                <p className="text-xs leading-[18px] text-gray-600 font-normal mb-1">Expires</p>
                                                <p className="text-gray-600 font-semibold leading-[18px] text-xs custom-loading">11/2030</p>
                                            </div>
                                            <div className="flex flex-col">
                                                <p className="text-xs leading-[18px] mb-1 text-gray-600 font-normal">Date added</p>
                                                <p className="text-gray-600 font-semibold leading-[18px] text-xs custom-loading">Tue, Jul 9, 6:27 PM</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-1">
                                <p className="text-base mb-1.5 font-semibold text-gray-900 leading-6">{t('Appointment Payment Settings')}</p>
                                <p className="text-xs font-normal leading-[18px] text-gray-600">{t('Add client specific appointment booking settings')}</p>
                                <div className="border rounded-xl p-3 mt-4 flex  space-x-4">
                                    <div className="flex gap-3">
                                        <div className="w-4">
                                            <Check className=" text-blue-600 w-[18px] " />
                                        </div>
                                        <div className="flex flex-col flex-1">
                                            <p className="text-gray-900 font-semibold text-sm truncate max-w-[230px] custom-loading">Reserve with card</p>
                                            <p className="text-gray-600 text-xs font-normal custom-loading mt-1">
                                                Enable this setting in order to require this specific client to have a card on file to
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SClientDetails;
