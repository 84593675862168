import React, { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Label, Radio } from 'flowbite-react';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CropperModal from 'src/components/CropperModal/CropperModal';
import Switch from 'src/components/Switch/Switch';
import { checkFileTypeValidation, convertBase64ToFile } from 'src/utils/global-functions';
import { GLOBALVARIABLE } from 'src/utils/global-variables';
import * as Yup from 'yup';
import { s3Upload } from 'src/utils/s3Operations';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { FileType, FONT_FAMILY, FONT_WEIGHT, IFormData, LogoType } from './Logo.interface';
import LogoDisplaySettings from './LogoDisplaySettings';
import CustomButton from 'src/components/CustomButton';
import { allShopSettings, currentShop, me } from 'src/redux/services/common/Common.slice';
import LogoDisplayScreen from './LogoDisplayScreen';
import { Save01, XClose } from '@untitled-ui/icons-react/build/cjs';
import DeletePopupModal from 'src/components/DeletePopupModal/DeletePopupModal';
import ImageUpload from 'src/components/ImageUpload';

export const initAction = {
    confirm: false,
};

const Logo = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [upload, setUpload] = useState(false);
    const shopSettingInfo: any = useAppSelector(allShopSettings).find((setting) => setting.type === 'appearance')?.value;
    const shop = useAppSelector(currentShop);

    const INIT_CROPDATA = {
        logo_image_name: {
            data: shop?.logo_image_url || null,
            default_crop_type: 'Square',
            title: t('Adjust brand logo image'),
            description: t('Upload a max 800Kb image for best results'),
            file: null,
            name: null,
            cropTypes: ['Rounded', 'Square', 'Rectangular'],
        },
        // TODO: add favicon
        favicon: {
            data: shopSettingInfo?.favicon ? `https://media.getflair.ca/images/shop_favicons/${shopSettingInfo?.favicon}` : null,
            default_crop_type: 'Rectangular',
            title: t('Adjust banner image'),
            description: t('Image should be rectangular for best results'),
            file: null,
            name: null,
            cropTypes: ['Rounded', 'Square'],
        },
        admin_logo_image_name: {
            data: shop?.admin_logo_image_url || null,
            default_crop_type: 'Rounded',
            title: t('Adjust admin image'),
            description: t('Upload a max 800Kb image for best results'),
            file: null,
            name: null,
            cropTypes: ['Rounded', 'Square'],
        },
    };
    const [cropData, setCropData] = useState<any>(INIT_CROPDATA);

    const [fileType, setFileType] = useState<FileType>(null);
    const [action, setAction] = useState(initAction);
    const [image, setImage] = useState<any>(null || '');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const schema = Yup.object({
        logo_image_name: Yup.string().required('This field is required'),
        admin_logo_image_name: Yup.string().required('This field is required'),
        favicon: Yup.string().required('This field is required'),
        logo_type: Yup.mixed<LogoType>().oneOf(['logo', 'logo_text', 'text'], 'Invalid logo type. Choose from logo, logo_text, or text.').required('This field is required'),
        is_same_as_brand_logo: Yup.boolean().required('This field is required'),
        name: Yup.string()
            .nullable()
            .when('logo_type', ([logoType], customSchema) =>
                logoType && (logoType === 'logo_text' || logoType === 'text') ? customSchema.required('This field is required') : customSchema.nullable(),
            ),
        logo_font_family: Yup.string()
            .nullable()
            .when('logo_type', ([logoType], customSchema) => (logoType === 'logo_text' || logoType === 'text' ? customSchema.required('This field is required') : customSchema)),
        font_weight: Yup.string()
            .nullable()
            .when('logo_type', ([logoType], customSchema) => (logoType === 'logo_text' || logoType === 'text' ? customSchema.required('This field is required') : customSchema)),
    }).required();

    const [formDetails, setFormDetails] = useState<any>({
        logo_image_name: shop?.logo_image_url || '',
        favicon: shopSettingInfo?.favicon || '',
        is_same_as_brand_logo: shopSettingInfo?.is_same_as_brand_logo || false,
        logo_type: shopSettingInfo?.logo_type || 'logo',
        name: shopSettingInfo?.name ?? shop?.business_name,
        logo_font_family: shopSettingInfo?.logo_font_family || FONT_FAMILY[0].value,
        font_weight: shopSettingInfo?.font_weight || FONT_WEIGHT[0].value,
        font_size: shop?.font_size || '',
        logo_height: shopSettingInfo?.logo_height || '',
    });

    const logoDispalyDefaultvalue = {
        name: shopSettingInfo?.name ?? shop?.business_name,
        logo_font_family: shopSettingInfo?.logo_font_family || FONT_FAMILY[0].value,
        font_weight: shopSettingInfo?.font_weight || FONT_WEIGHT[0].value,
    };

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as Resolver<IFormData>,
        defaultValues: {
            logo_image_name: shop?.logo_image_name || '',
            favicon: shopSettingInfo?.favicon || '',
            is_same_as_brand_logo: shopSettingInfo?.is_same_as_brand_logo || false,
            logo_type: shopSettingInfo?.logo_type || 'logo',
            name: shopSettingInfo?.name ?? shop?.business_name,
            logo_font_family: shopSettingInfo?.logo_font_family || FONT_FAMILY[0].value,
            font_weight: shopSettingInfo?.font_weight || FONT_WEIGHT[0].value,
            admin_logo_image_name: shop?.admin_logo_image_name || '',
            logo_height: shopSettingInfo?.logo_height || '',
            font_size: shop?.font_size || '',
        },
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        getValues,
        setValue,
        watch,
        clearErrors,
    } = methods;
    const isSameAsBrandLogo = watch('is_same_as_brand_logo');
    const logoImageName = watch('logo_image_name');

    const onChangeCrop = (innerFileType: FileType) => (e: any) => {
        if (innerFileType === null) {
            return;
        }
        setFileType(innerFileType);
        setUpload(false);
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const { result, message } = checkFileTypeValidation(files, GLOBALVARIABLE.accountImageSize);
        if (result) {
            clearErrors(innerFileType);
            const reader = new FileReader();
            reader.onloadstart = () => {
                setImage('');
                setUpload(false);
            };
            reader.onloadend = () => {
                setImage(reader.result as any);
                setUpload(true);
            };
            reader.readAsDataURL(files[0]);
            e.target.value = null;
            // setUploadProgress(0);
        } else {
            setError(innerFileType, {
                type: 'manual',
                message: message,
            });
        }
    };

    const handleLogoTypeChange = (value: LogoType) => () => {
        if (value === 'logo') {
            setValue('name', '');
            setValue('logo_font_family', FONT_FAMILY[0].value);
            setValue('font_weight', FONT_WEIGHT[0].value);
            setFormDetails((prevFormDetails: any) => ({
                ...prevFormDetails,
                logo_type: value,
                name: '',
                logo_font_family: FONT_FAMILY[0].value,
                font_weight: FONT_WEIGHT[0].value,
            }));
        } else {
            setFormDetails((prevFormDetails: any) => ({
                ...prevFormDetails,
                logo_type: value,
                ...logoDispalyDefaultvalue,
            }));
            Object.keys(logoDispalyDefaultvalue).forEach((field) => {
                setValue(field as keyof IFormData, logoDispalyDefaultvalue[field as keyof typeof logoDispalyDefaultvalue]);
            });
        }
        clearErrors(['name', 'logo_font_family', 'font_weight']);

        setValue('logo_type', value);
    };

    useEffect(() => {
        setFormDetails((prevFormDetails: any) => ({
            ...prevFormDetails,
            logo_image_name: cropData.logo_image_name.data,
            favicon: cropData.favicon.data,
        }));
    }, [cropData]);

    const handleIsSameAsBrandLogo = (value: boolean) => () => {
        setFormDetails((prevFormDetails: any) => ({
            ...prevFormDetails,
            is_same_as_brand_logo: !value,
        }));
        setValue('is_same_as_brand_logo', !value);
    };
    useEffect(() => {
        handleFevicon(isSameAsBrandLogo);
    }, [logoImageName, isSameAsBrandLogo]);

    const handleFevicon = (sameAsBrandLogo: boolean) => {
        if (sameAsBrandLogo) {
            setCropData((old: any) => ({ ...old, favicon: { ...old.favicon, data: old.logo_image_name.data, file: old.logo_image_name.file } }));
            setValue('favicon', logoImageName);
        } else {
            if (cropData.logo_image_name.data === cropData.favicon.data) {
                clearErrors('favicon');
                setCropData((old: any) => ({ ...old, favicon: { ...old.favicon, data: null, file: null } }));
                setValue('favicon', '');
            }
        }
    };

    const imageUpload = (data: any) => {
        const convertedFile = convertBase64ToFile(data);
        if (convertedFile && convertedFile.filename && fileType) {
            setValue(fileType, convertedFile.filename);
            setCropData((old: any) => ({ ...old, [fileType]: { ...old[fileType], file: convertedFile.convertedFile, name: convertedFile.filename } }));
        }
    };
    const handleCropData = (data: any) => {
        if (fileType) {
            setCropData((old: any) => ({ ...old, [fileType]: { ...old[fileType], data } }));
        }
    };

    const handleChange = async (data: IFormData) => {
        setAction({ confirm: true });
    };

    const updateDetails = async (data: IFormData) => {
        setIsLoading(true);

        let logoImage = {
            status: errorCode.updateSuccess,
        };
        let faviconImage = {
            status: errorCode.updateSuccess,
        };
        let adminImage = {
            status: errorCode.updateSuccess,
        };
        if (cropData.logo_image_name.file) {
            const logoImageResponse: any = await s3Upload(cropData.logo_image_name.file, `images/business_logos/${cropData.logo_image_name.name}`);
            logoImage.status = logoImageResponse.status;
        }
        if (cropData.favicon.file) {
            const faviconImageResponse: any = await s3Upload(cropData.favicon.file, `images/shop_favicons/${cropData.favicon.name}`);
            faviconImage.status = faviconImageResponse.status;
        }
        if (cropData.admin_logo_image_name.file) {
            const adminImageResponse: any = await s3Upload(cropData.admin_logo_image_name.file, `images/admin_logos/${cropData.admin_logo_image_name.name}`);
            adminImage.status = adminImageResponse.status;
        }

        if (logoImage.status === errorCode.updateSuccess && faviconImage.status === errorCode.updateSuccess && adminImage.status === errorCode.updateSuccess) {
            submitForm(data);
        }
    };
    const submitForm = async (data: IFormData) => {
        const payload =
            data.logo_type === 'logo_text' || data.logo_type === 'text'
                ? {
                      logo_image_name: data.logo_image_name,
                      favicon: data.favicon,
                      admin_logo_image_name: data.admin_logo_image_name,
                      is_same_as_brand_logo: data.is_same_as_brand_logo,
                      logo_type: data.logo_type,
                      name: data.name,
                      logo_font_family: data.logo_font_family,
                      font_weight: data.font_weight,
                      shop_id: shop.id,
                      font_size: data.font_size,
                      ...(data.logo_type === 'logo_text' && { logo_height: data.logo_height }),
                  }
                : {
                      logo_image_name: data.logo_image_name,
                      favicon: data.favicon,
                      admin_logo_image_name: data.admin_logo_image_name,
                      is_same_as_brand_logo: data.is_same_as_brand_logo,
                      logo_type: data.logo_type,
                      shop_id: shop.id,
                      logo_height: data.logo_height,
                  };
        axiosPost(API.THEME.LOGO, payload, { shop_id: shop.id })
            .then(async () => {
                await dispatch(me());
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as keyof IFormData, {
                                type: 'manual',
                                message: response.data[field][0],
                            });
                        });
                    }
                    return;
                }
                toast.error(response.message);
            })
            .finally(() => {
                setIsLoading(false);
                setAction({ confirm: false });
            });
    };
    // const handleRemoveCrop = (type: 'logo_image_name' | 'favicon') => () => {
    //     if (isSameAsBrandLogo) {
    //         clearErrors(['logo_image_name', 'favicon']);
    //         setCropData((old: any) => ({ ...old, logo_image_name: { ...old.logo_image_name, data: null, file: null, name: null }, favicon: { ...old.favicon, data: null, file: null, name: null } }));
    //         setValue('logo_image_name', '');
    //         setValue('favicon', '');
    //     } else {
    //         clearErrors(type);
    //         setCropData((old: any) => ({ ...old, [type]: { ...old[type], data: null, file: null } }));
    //         setValue(type, '');
    //     }
    // };

    const handleModalClose = useCallback(
        (status: boolean = false) =>
            async () => {
                if (status) {
                    setIsLoading(true);
                    updateDetails(getValues());
                } else {
                    setAction({ confirm: false });
                }
            },
        [action],
    );

    return (
        <div className="w-full pt-5 px-2">
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(handleChange)} className="flex flex-col gap-5">
                    <div className="flex flex-row">
                        <div className="flex flex-col flex-1">
                            <div className="fl-data-block">
                                <div className="title-block w-[280px] xxl:w-[310px] min-w-[280px]">
                                    <h3 className="title-text">{t('Brand logo')}</h3>
                                    <p className="subtitle-text">{t('Configure your brand logo that will be displayed on your website.')}</p>
                                </div>
                                <div className="data-wrap-block max-w-[480px] min-w-[480px] w-[480px] ">
                                    <div className="flex items-center gap-5 w-full">
                                        <ImageUpload isIcon cropData={cropData.logo_image_name.data} onChangeCrop={onChangeCrop('logo_image_name')} id="logo_image_name" />

                                        {/* <div className={`h-[80px] w-[80px] rounded-md object-contain ${cropData.logo_image_name.data ? '' : 'border'}`}>
                                            <img src={cropData.logo_image_name.data || defaultImage} alt="logo" className="w-full h-full object-contain" />
                                        </div>
                                        <div>
                                            <div className={`relative cursor-pointer border rounded-lg ${errors.logo_image_name ? 'is-invalid' : ''}`}>
                                                <input type="file" id="logo_image_name" className="w-full absolute opacity-0" onChange={onChangeCrop('logo_image_name')} />
                                                <label htmlFor="logo_image_name" className="w-full text-xs px-4 py-[7px] font-medium flex items-center justify-between">
                                                    <Edit02 className="w-5 h-5 mr-2 text-gray-700" />
                                                    {t('Update Logo')}
                                                </label>
                                            </div>
                                            {errors.logo_image_name && <p className="text-error">{errors.logo_image_name.message}</p>}
                                        </div> */}
                                        {/* {cropData.logo_image_name.data && (
                                    <Button className="w-9 h-9 bg-red-50 flex items-center justify-center rounded-lg" onClick={handleRemoveCrop('logo_image_name')}>
                                        <Trash01 color="red" width={16} />
                                    </Button>
                                )} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-2">
                            <CustomButton secondary>{t('Discard')}</CustomButton>

                            <CustomButton primary isLoading={isLoading} disabled={isLoading} onClick={handleSubmit(handleChange)}>
                                {t('Save changes')}
                            </CustomButton>
                        </div>
                    </div>
                    <hr className="divider" />
                    <div className="fl-data-block">
                        <div className="title-block w-[280px] xxl:w-[310px] min-w-[280px]">
                            <h3 className="title-text">{t('Admin profile picture')}</h3>
                            <p className="subtitle-text">{t('Configure your admin picture that will be displayed as a profile picture.')}</p>
                        </div>
                        <div className="data-wrap-block max-w-[480px] min-w-[480px] w-[480px]">
                            <div className="flex items-center gap-5 w-full">
                                <ImageUpload
                                    isIcon
                                    cropData={cropData.admin_logo_image_name.data}
                                    onChangeCrop={onChangeCrop('admin_logo_image_name')}
                                    id="admin_logo_image_name"
                                    error={errors.admin_logo_image_name?.message}
                                />

                                {/* <div className={`h-[80px] w-[80px] rounded-md object-contain ${cropData.admin_logo_image_name.data ? '' : 'border'}`}>
                                    <img src={cropData.admin_logo_image_name.data || defaultImage} alt="logo" className="w-full rounded-md h-full object-contain" />
                                </div>
                                <div>
                                    <div className={`relative cursor-pointer border rounded-lg ${errors.admin_logo_image_name ? 'is-invalid' : ''}`}>
                                        <input type="file" id="logo_image_name" className="w-full absolute opacity-0" onChange={onChangeCrop('admin_logo_image_name')} />
                                        <label htmlFor="logo_image_name" className="w-full text-xs px-4 py-[7px] font-medium flex items-center justify-between">
                                            <Edit02 className="w-5 h-5 mr-2 text-gray-700" />
                                            {t('Update profile')}
                                        </label>
                                    </div>

                                    {errors.logo_image_name && <p className="text-error">{errors.logo_image_name.message}</p>}
                                </div> */}
                                {/* {cropData.logo_image_name.data && (
                                    <Button className="w-9 h-9 bg-red-50 flex items-center justify-center rounded-lg" onClick={handleRemoveCrop('logo_image_name')}>
                                        <Trash01 color="red" width={16} />
                                    </Button>
                                )} */}
                            </div>
                        </div>
                    </div>
                    <hr className="divider" />
                    <div className="fl-data-block">
                        <div className="title-block w-[280px] xxl:w-[310px] min-w-[280px]">
                            <h3 className="title-text">{t('Icon')}</h3>
                            <p className="subtitle-text">{t('Configure your brand icon that will be displayed in smaller resolutions.')}</p>
                        </div>
                        <div className="data-wrap-block flex flex-auto items-center">
                            <div className="max-w-[620px] w-full mr-5 ">
                                <Controller
                                    name={`is_same_as_brand_logo`}
                                    control={control}
                                    render={({ field: { value } }: any) => (
                                        <>
                                            <div className="flex mb-3 items-center">
                                                <Switch className="" onChange={handleIsSameAsBrandLogo(value)} isChecked={value} />
                                                <span className="ml-2 font-medium text-sm text-gray-700">Use my brand logo</span>
                                            </div>
                                            {!value && (
                                                <div className="flex items-center gap-4 max-w-[480px] min-w-[480px] w-[480px] ">
                                                    <ImageUpload isIcon cropData={cropData.favicon.data} onChangeCrop={onChangeCrop('favicon')} id="favicon" error={errors.favicon?.message} />

                                                    {/* <div className={`h-[80px] w-[80px] rounded-md border ${cropData.favicon.data ? '' : 'border'}`}>
                                                        <img src={cropData.favicon.data || defaultImage} alt="logo" className="w-full rounded-md h-full" />
                                                    </div>
                                                    <div> */}
                                                    {/* <div className={`relative cursor-pointer border rounded-lg ${errors.favicon ? 'is-invalid' : ''}`}>
                                                            <input type="file" id="favicon" className="w-full absolute opacity-0" onChange={onChangeCrop('favicon')} />
                                                            <label htmlFor="favicon" className="w-full px-4 py-[7px] text-xs font-medium flex items-center justify-between">
                                                                <AiOutlineCloudUpload size="20" className="mr-2" />
                                                                {t('Upload Fevicon')}
                                                            </label>
                                                        </div> */}

                                                    {/* {errors.favicon && <p className="text-error">{errors.favicon.message}</p>} */}
                                                    {/* </div> */}
                                                    {/* {cropData.favicon.data && (
                                                        <Button className="w-9 h-9 bg-red-50 flex items-center justify-center rounded-lg" onClick={handleRemoveCrop('favicon')}>
                                                            <Trash01 color="red" width={16} />
                                                        </Button>
                                                    )} */}
                                                </div>
                                            )}
                                        </>
                                    )}
                                />
                            </div>
                            <div className="flex items-center">
                                <div className="bg-gray-100 rounded-t-xl pt-[10px] px-[10px] flex gap-2 overflow-hidden w-[280px] xxl:w-[280px]">
                                    <div className="flex gap-1 items-center">
                                        <span className="w-[10px] h-[10px] bg-red-500 rounded-full"></span>
                                        <span className="w-[10px] h-[10px] bg-yellow-500 rounded-full"></span>
                                        <span className="w-[10px] h-[10px] bg-success-500 rounded-full"></span>
                                    </div>
                                    <div className="bg-white rounded-t-xl flex items-center justify-between px-3 py-1 h-10 shadow-lg w-full">
                                        {/* favicon logo is rectangular */}
                                        <div className="flex flex-row ">
                                            <div
                                                className={`${
                                                    !formDetails.favicon || formDetails.favicon === '' ? 'w-4 bg-gray-300 object-contain' : 'w-4 object-contain'
                                                } h-4 grow-0 shrink-0 flex items-center justify-center`}
                                            >
                                                {formDetails.favicon && formDetails.favicon !== '' && <img src={formDetails.favicon} alt="logo" className="w-full h-full" />}
                                            </div>
                                            <div className="ml-[10px] text-xs font-semibold text-mainTextColor w-full  max-w-[132px] truncate text-clip">{shop.business_name}</div>
                                        </div>
                                        <div className="text-mainTextColor px-2">
                                            <XClose width={16} />
                                        </div>
                                    </div>
                                </div>
                                {/* {cropData.favicon.data && (
                                    <Button className="w-10 h-10 bg-red-50 flex items-center justify-center rounded-lg ml-[10px]" onClick={handleRemoveCrop('favicon')}>
                                        <Trash01 color="red" width={16} />
                                    </Button>
                                )} */}
                            </div>
                        </div>
                    </div>
                    <hr className="divider" />
                    <div className="fl-data-block">
                        <div className="title-block w-[280px] xxl:w-[310px] min-w-[280px]">
                            <h3 className="title-text">{t('Logo display settings')}</h3>
                            <p className="subtitle-text">
                                {t('Configure the display settings of your brand icon. For compact circular and square logo’s we recommend a combination of Logo + Typeface.')}
                            </p>
                        </div>
                        <div className="data-wrap-block flex flex-auto items-start">
                            <div className="max-w-[620px] mr-5 w-full">
                                <Controller
                                    name={`logo_type`}
                                    control={control}
                                    render={({ field: { value } }: any) => (
                                        <>
                                            <div className="flex mb-3">
                                                <Radio
                                                    className="custom-round-radio mt-[3px]"
                                                    id="logo-type-logo"
                                                    name="logo-type"
                                                    value={'logo'}
                                                    onChange={handleLogoTypeChange('logo')}
                                                    checked={value === 'logo'}
                                                />
                                                <Label htmlFor="logo-type-logo" className="ml-2 text-md text-gray-700">
                                                    {t('Logo')}
                                                    <span className="block text-xs text-gray-600 font-normal ">{t('Display only my logo.')}</span>
                                                </Label>
                                            </div>
                                            {value === 'logo' && <LogoDisplaySettings setFormDetails={setFormDetails} displayType={value} />}
                                            <div className="flex  mb-3">
                                                <Radio
                                                    className="custom-round-radio mt-[3px]"
                                                    id="logo-type-logo-text"
                                                    name="logo-type"
                                                    value={'logo_text'}
                                                    onChange={handleLogoTypeChange('logo_text')}
                                                    checked={value === 'logo_text'}
                                                />
                                                <Label htmlFor="logo-type-logo-text" className="ml-2 text-md text-gray-700">
                                                    {t('Logo + Typeface (Font)')}
                                                    <span className="block text-xs text-gray-600 font-normal ">{t('Display a combination of my logo and my brand name.')}</span>
                                                </Label>
                                            </div>
                                            {value === 'logo_text' && <LogoDisplaySettings setFormDetails={setFormDetails} displayType={value} />}
                                            <div className="flex mb-3 w-full">
                                                <Radio
                                                    className="custom-round-radio mt-[3px]"
                                                    id="logo-type-text"
                                                    name="logo-type"
                                                    value={'text'}
                                                    onChange={handleLogoTypeChange('text')}
                                                    checked={value === 'text'}
                                                />
                                                <Label htmlFor="logo-type-text" className="ml-2 text-md text-gray-700">
                                                    {t('Typeface (Font)')}
                                                    <span className="block text-xs text-gray-600 font-normal ">{t('Display only my brand name as a font.')}</span>
                                                </Label>
                                            </div>

                                            {value === 'text' && <LogoDisplaySettings setFormDetails={setFormDetails} displayType={value} />}
                                        </>
                                    )}
                                />
                            </div>
                            <div className="flex items-center">
                                <LogoDisplayScreen formDetails={formDetails} />

                                {/* <Button className="w-10 h-10 bg-red-50 flex items-center justify-center rounded-lg ml-[10px]" onClick={handleRemoveCrop('logo_image_name')}>
                                    <Trash01 color="red" width={16} />
                                </Button> */}
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-2 border-t pt-5 justify-end  mb-36">
                        <CustomButton secondary>{t('Discard')}</CustomButton>
                        <CustomButton primary isLoading={isLoading} disabled={isLoading} onClick={handleSubmit(handleChange)}>
                            {t('Save changes')}
                        </CustomButton>
                    </div>
                    {upload && fileType && (
                        <CropperModal
                            title={cropData[fileType].title}
                            description={cropData[fileType].description}
                            imageUpload={imageUpload}
                            imageUrl={image}
                            setUpload={setUpload}
                            setCropData={handleCropData}
                            defaultCropType={cropData[fileType].default_crop_type}
                            types={cropData[fileType].cropTypes}
                        />
                    )}
                </form>
            </FormProvider>
            {action.confirm && (
                <DeletePopupModal
                    primary
                    headerIcon={<Save01 className="w-6 h-6 text-gray-700" />}
                    confirmButtonText="Save"
                    onClose={handleModalClose}
                    size="w-[400px]"
                    title={t('Update Details')}
                    description={t('Are you sure you want to update details?')}
                    isLoading={isLoading}
                />
            )}
        </div>
    );
};

export default Logo;
