import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { currentShop, me } from 'src/redux/services/common/Common.slice';
import { useTranslation } from 'react-i18next';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import InputWithLabel from 'src/components/InputWithLabel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FieldName, ISelectBox, IFormData, currencyOptions, InitWorkingHours, ExistingFormData } from './Location.interface';
import Number from 'src/components/Number';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { getCountry } from 'src/redux/services/country/Country.slice';
import { convertBase64ToFile, onError } from 'src/utils/global-functions';
import CropperModal from 'src/components/CropperModal/CropperModal';
import { defaultImage } from 'src/theme/Images';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import SalesTaxesForm from './SalesTaxesForm';
import TipsForm from './TipsForm';
import WorkingHours from './WorkingHours';
import moment from 'moment';
import LocationMap from './LocationMap';
import { toast } from 'react-toastify';
import { errorCode } from 'src/constants/errorCode';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { s3Upload } from 'src/utils/s3Operations';
import { axiosGet, axiosPatch, axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import CustomButton from 'src/components/CustomButton';
import { ArrowLeft, MarkerPin01, XClose } from '@untitled-ui/icons-react/build/cjs';
import { usePhone } from 'src/hooks/usePhone';
import { filter, uniq } from 'lodash';
import { PATH } from 'src/constants/path';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';

const LocationForm = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const { isPhoneValid } = usePhone();
    const shop = useAppSelector(currentShop);
    const [countryOptions, setCountryOptions] = useState<ISelectBox[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [initCropData] = useState({
        profile_image_name: {
            data: null,
            default_crop_type: 'Square',
            title: t('Adjust Location profile image'),
            description: t('Upload a max 800Kb image for best results'),
            file: null,
            name: null,
        },
        location_image_name: {
            data: null,
            default_crop_type: 'Rectangular',
            title: t('Adjust banner image'),
            description: t('Image should be rectangular for best results'),
            file: null,
            name: null,
        },
    });

    const [cropData, setCropData] = useState<any>(initCropData);
    const [fieldName, setFieldName] = useState<FieldName>(null);
    const [image, setImage] = useState<any>('');
    const [isUpload, setIsUpload] = useState(false);

    const schema = Yup.object({
        name: Yup.string().required(t('This field is required')),
        phone_country_code: Yup.string().required(t('This field is required')),
        phone: Yup.string()
            .required(t('This field is required'))
            .test('phone-invalid', t('Invalid phone number'), function (value) {
                const { phone_country_code: phoneCountryCode } = this.parent;
                const isValid = isPhoneValid(value, phoneCountryCode);
                return isValid;
            }),
        timezone: Yup.string().required(t('This field is required')),
        currency: Yup.string().required(t('This field is required')),
        profile_image_name: Yup.string().required(t('This field is required')),
        location_image_name: Yup.string().required(t('This field is required')),
        tax_id: Yup.string().required(t('This field is required')),
        sales_tax_id: Yup.string().required(t('This field is required')),
        country: Yup.string().required(t('This field is required')),
        city: Yup.string().required(t('This field is required')),
        street: Yup.string().required(t('This field is required')),
        postal_code: Yup.string().required(t('This field is required')),
        latitude: Yup.string().required(t('This field is required')),
        longitude: Yup.string().required(t('This field is required')),
        tips_type: Yup.string().required(t('This field is required')),
        custom_tip: Yup.string(),
        tips: Yup.array()
            .test('tips-required', t('This field is required'), function (value) {
                const { custom_tip: customTip } = this.parent;
                return (value && value.some((data) => data !== '')) || !!customTip;
            })
            .test({
                message: 'Please choose a unique tip',
                test: function (value) {
                    const { custom_tip: customTip } = this.parent;
                    if (value) {
                        if (value.some((data) => data === customTip) && customTip) {
                            return false;
                        } else {
                            const cleanedValues = filter(value, (val) => val !== '');
                            const uniqueValues = uniq(cleanedValues);

                            if (uniqueValues.length !== cleanedValues.length) {
                                return false;
                            }
                        }
                    }
                    return true;
                },
            })
            .required(t('This field is required')),
        hours: Yup.array()
            .of(
                Yup.object().shape({
                    day: Yup.string().required('Day is required').oneOf(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], 'Invalid day'),
                    status: Yup.boolean().required('Status is required'),

                    from_time_hours: Yup.string()
                        .required('From time hours are required')
                        .test({
                            name: 'from_valid_hours_1',
                            message: 'From hours must be between 01 to 12 and minutes must be between 00 to 59',
                            test: function (value) {
                                const { status } = this.parent;
                                if (status) {
                                    const parts = value.split(':');
                                    const hours = parseInt(parts[0], 10);
                                    const minutes = parseInt(parts[1], 10);
                                    const isValidHours = !isNaN(hours) && hours >= 1 && hours <= 12;
                                    const isValidMinutes = !isNaN(minutes) && minutes >= 0 && minutes < 60;
                                    return isValidHours && isValidMinutes;
                                }
                                return true;
                            },
                        })
                        .test({
                            name: 'from_valid_hours_2',
                            message: '"From" time must be less than "To" time',
                            test: function (value) {
                                const { from_time_type: fromTimeType, to_time_hours: toTimeHours, to_time_type: toTimeType, status } = this.parent;

                                if (status) {
                                    const fromMoment = moment(`${value} ${fromTimeType}`, 'hh:mm a');
                                    const toMoment = moment(`${toTimeHours} ${toTimeType}`, 'hh:mm a');

                                    if (fromMoment.isSameOrAfter(toMoment)) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }
                                return true;
                            },
                        }),

                    to_time_hours: Yup.string()
                        .required('To time hours are required')
                        .test({
                            name: 'to_valid_hours_1',
                            message: 'To hours must be between 01 to 12 and minutes must be between 00 to 59',
                            test: function (value) {
                                const { status } = this.parent;
                                if (status) {
                                    const parts = value.split(':');
                                    const hours = parseInt(parts[0], 10);
                                    const minutes = parseInt(parts[1], 10);
                                    const isValidHours = !isNaN(hours) && hours >= 1 && hours <= 12;
                                    const isValidMinutes = !isNaN(minutes) && minutes >= 0 && minutes < 60;
                                    return isValidHours && isValidMinutes;
                                }
                                return true;
                            },
                        })
                        .test({
                            name: 'to_valid_hours_2',
                            message: '"To" time must be greater than "From" time',
                            test: function (value) {
                                const { from_time_hours: fromTimeHours, from_time_type: fromTimeType, to_time_type: toTimeType, status } = this.parent;

                                if (status) {
                                    const fromMoment = moment(`${fromTimeHours} ${fromTimeType}`, 'hh:mm a');
                                    const toMoment = moment(`${value} ${toTimeType}`, 'hh:mm a');

                                    if (toMoment.isSameOrBefore(fromMoment)) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }
                                return true;
                            },
                        }),

                    from_time_type: Yup.string().required('From time type is required').oneOf(['am', 'pm'], 'Invalid from time type'),

                    to_time_type: Yup.string().required('To time type is required').oneOf(['am', 'pm'], 'Invalid to time type'),
                }),
            )
            .required(t('This field is required')),
    }).required();

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as Resolver<IFormData>,
        defaultValues: {
            name: '',
            phone_country_code: '',
            phone: '',
            timezone: '',
            currency: '',
            profile_image_name: '',
            location_image_name: '',
            tax_id: '',
            sales_tax_id: '',
            country: '',
            city: '',
            street: '',
            postal_code: '',
            latitude: '',
            longitude: '',
            tips_type: 'percentage',
            custom_tip: '',
            tips: ['10', '15', '18'],
            hours: InitWorkingHours,
        },
    });

    const {
        handleSubmit,
        setValue,
        setError,
        getValues,
        trigger,
        control,
        formState: { errors },
        watch,
    } = methods;

    useEffect(() => {
        countryList();
    }, []);

    useEffect(() => {
        if (id) {
            getLocation();
        } else {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    const currentLatitude = position.coords.latitude;
                    const currentLongitude = position.coords.longitude;
                    setValue('latitude', currentLatitude.toString());
                    setValue('longitude', currentLongitude.toString());
                });
            }
        }
    }, [id, shop]);

    const getLocation = async () => {
        setIsLoading(true);
        await axiosGet(API.LOCATION.GET, { shop_id: shop.id, id })
            .then((response) => {
                const data = response.data.data;

                setCropData((old: any) => ({
                    ...old,
                    profile_image_name: { ...old.profile_image_name, data: data.profile_image_url },
                    location_image_name: { ...old.location_image_name, data: data.location_image_url },
                }));
                let customTip = '';
                let tipsArr = [];
                if (data.tips.length > 3) {
                    tipsArr = data.tips.slice(0, 3).map((tip: any) => tip);
                    customTip = data.tips[data.tips.length - 1];
                } else {
                    tipsArr = data.tips.map((tip: any) => tip);
                    while (tipsArr.length < 3) {
                        tipsArr.push('');
                    }
                }

                const hours = data.working_hours.map((hour: any) => {
                    const momentFromTime = moment(hour.from, 'HH:mm:ss');
                    const momentToTime = moment(hour.to, 'HH:mm:ss');

                    const fromTimeHours = momentFromTime.format('hh:mm');
                    const fromTimeType = momentFromTime.format('a');

                    const toTimeHours = momentToTime.format('hh:mm');
                    const toTimeType = momentToTime.format('a');

                    return {
                        day: hour.day,
                        status: hour.status,
                        from_time_hours: fromTimeHours,
                        to_time_hours: toTimeHours,
                        from_time_type: fromTimeType,
                        to_time_type: toTimeType,
                    };
                });

                const existingData: ExistingFormData = {
                    name: data.name,
                    phone_country_code: data.phone_country_code,
                    phone: data.phone,
                    timezone: data.timezone,
                    currency: data.currency,
                    profile_image_name: data.profile_image_name,
                    location_image_name: data.location_image_name,
                    tax_id: data.tax_id,
                    sales_tax_id: data.sales_tax_id,
                    country: data.country,
                    city: data.city,
                    street: data.street,
                    postal_code: data.postal_code,
                    latitude: data.latitude,
                    longitude: data.longitude,
                    tips_type: data.tips_type,
                    custom_tip: customTip,
                    tips: tipsArr,
                    hours,
                };
                Object.keys(existingData).forEach((key) => {
                    setValue(key as keyof IFormData, existingData[key]);
                });
                // reset(existingData);
            })
            .catch(() => navigate(ROUTES.LOCATION.LIST))
            .finally(() => setIsLoading(false));
    };

    const countryList = async () => {
        const result = await dispatch(getCountry());
        if (result.type === getCountry.fulfilled.toString()) {
            const options: ISelectBox[] | [] = result.payload.data.map((country: any) => ({
                value: country.timezone,
                label: `${country.timezone_name} (${country.name})`,
            }));
            setCountryOptions(options);
        }
    };
    const handleSave = async (data: IFormData) => {
        setIsLoading(true);

        const hours = data.hours.map((day) => {
            const from = moment(`${day.from_time_hours} ${day.from_time_type}`, 'hh:mm a').format('HH:mm:ss');
            const to = moment(`${day.to_time_hours} ${day.to_time_type}`, 'hh:mm a').format('HH:mm:ss');
            return {
                day: day.day,
                status: day.status,
                from,
                to,
            };
        });

        let tips = data.tips.filter((tip) => tip !== '').map((tip) => tip);
        tips = data.custom_tip ? tips.concat(data.custom_tip) : tips;

        const payload = {
            ...data,
            hours,
            tips,
        };

        let profileImage = {
            status: errorCode.updateSuccess,
        };
        let locationImage = {
            status: errorCode.updateSuccess,
        };
        if (cropData.profile_image_name.file) {
            const profileImageResponse: any = await s3Upload(cropData.profile_image_name.file, `images/location_profiles/${cropData.profile_image_name.name}`);
            profileImage.status = profileImageResponse.status;
        }
        if (cropData.location_image_name.file) {
            const locationImageResponse: any = await s3Upload(cropData.location_image_name.file, `images/location_logos/${cropData.location_image_name.name}`);
            locationImage.status = locationImageResponse.status;
        }

        if (profileImage.status === errorCode.updateSuccess && locationImage.status === errorCode.updateSuccess) {
            if (id) {
                await axiosPatch(API.LOCATION.UPDATE, payload, { shop_id: shop.id, id })
                    .then(async () => {
                        await dispatch(me());
                        navigate(ROUTES.LOCATION.LIST);
                    })
                    .catch((error) => {
                        const response = error.response.data;
                        if (response.status === errorCode.unprocessable) {
                            if (response.data) {
                                Object.keys(response.data).forEach((field) => {
                                    setError(field as keyof IFormData, {
                                        type: 'manual',
                                        message: response.data[field][0],
                                    });
                                });
                            }
                            return;
                        }
                        toast.error(response.message);
                    })
                    .finally(() => setIsLoading(false));
            } else {
                await axiosPost(API.LOCATION.CREATE, payload, { shop_id: shop.id })
                    .then(async () => {
                        await dispatch(me());
                        navigate(ROUTES.LOCATION.LIST);
                    })
                    .catch((error) => {
                        const response = error.response.data;
                        if (response.status === errorCode.unprocessable) {
                            if (response.data) {
                                Object.keys(response.data).forEach((field) => {
                                    setError(field as keyof IFormData, {
                                        type: 'manual',
                                        message: response.data[field][0],
                                    });
                                });
                            }
                            return;
                        }
                        toast.error(response.message);
                    })
                    .finally(() => setIsLoading(false));
            }
        }
    };

    const resetForm = () => {
        navigate(`${PATH.LOCATION.VIEW}/${id}`);
    };

    const imageUpload = (data: any) => {
        const file = convertBase64ToFile(data);
        if (file && file.filename && fieldName) {
            setValue(fieldName, file.filename);
            setCropData((old: any) => ({ ...old, [fieldName]: { ...old[fieldName], file: file.convertedFile, name: file.filename } }));
            trigger([fieldName]);
        }
    };

    const onChangeCrop = (type: FieldName) => (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onloadstart = () => {
            setImage('');
            setIsUpload(false);
        };
        reader.onloadend = () => {
            setImage(reader.result as any);
            setIsUpload(true);
        };
        reader.readAsDataURL(files[0]);
        e.target.value = null;
        setFieldName(type);
    };

    const handleCropData = (data: any) => {
        if (fieldName) {
            setCropData((old: any) => ({ ...old, [fieldName]: { ...old[fieldName], data } }));
        }
    };
    const watchName = watch('name');

    useFormErrorFocus<IFormData>({ errors });

    return (
        <div className="inner-page-wrape !px-0">
            <div className="flex-1 flex flex-col mb-4">
                <div className="flex justify-between gray-banner-bg bg-gray-50 relative h-[130px] text-secondaryTxtColor pt-5 pb-3 xlm:px-[30px] px-5  gap-1.5 rounded-tl-3xl items-start">
                    <Link to={ROUTES.LOCATION.LIST} className="flex items-center cursor-pointer text-gray-600 h-[36px] text-xs font-semibold gap-1.5">
                        <ArrowLeft className="w-4 cursor-pointer text-gray-600" /> {t('Back to Location')}
                    </Link>
                    <Link to={ROUTES.LOCATION.LIST} className="close-page-btn">
                        <XClose className="xl:w-5 xl:h-5 w-4 h-4 text-gray-900" />
                    </Link>
                </div>

                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(handleSave)} className="flex flex-col">
                        <div className="xlm:px-8 px-5 relative mb-[26px] w-full xl:pt-[10px] pt-2 pb-2 flex sms:gap-4 gap-2 xs:items-end items-start ">
                            <img
                                src={cropData.profile_image_name.data || defaultImage}
                                alt={watchName || t('Location Name')}
                                title={watchName || t('Location Name')}
                                onError={onError}
                                className="w-[144px] h-[144px] rounded-xl object-cover border-4 border-white drop-shadow-lg flex-none -mt-[62px]"
                            />
                            <div className="flex  items-center w-full">
                                <div className="flex-1">
                                    <h1 className="mr-3 text-gray-900 xlm:text-3xl sm:text-xl text-base inline align-middle font-semibold tracking-[-0.72px] ">{watchName || t('Location Name')}</h1>
                                    <div className="flex gap-2.5 text-gray-600 sm:text-sm text-xs mt-1 font-normal items-center">
                                        <MarkerPin01 className="h-4 w-4" />
                                        <p className="text-sm font-normal text-secondaryTxtColor">{t('Location address here')}</p>
                                    </div>
                                </div>
                                <div className="flex gap-[10px]">
                                    <CustomButton secondary type="reset" onClick={resetForm} disabled={isLoading}>
                                        {t('Cancel')}
                                    </CustomButton>
                                    <CustomButton primary type="submit" isLoading={isLoading} disabled={isLoading}>
                                        {t('Save Changes')}
                                    </CustomButton>
                                </div>
                            </div>
                        </div>
                        <div className="xlm:px-8 px-5 flex flex-col border-t border-gray-200 gap-4">
                            <div className="fl-data-block mt-4">
                                <div className="title-block w-[280px]">
                                    <h3 className="location-form-label-heading">
                                        {t('Location Name')}
                                        <span className="asterisk">*</span>
                                    </h3>
                                    <p className="location-form-label-subheading">{t('Enter the official display name of the barbershop branch.')}</p>
                                </div>
                                <div className="data-wrap-block w-full max-w-[400px]">
                                    <Controller
                                        name="name"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState }: any) => (
                                            <>
                                                <InputWithLabel placeholder="Enter location name" onChange={onChange} required value={value} name="name" id="name" error={!!fieldState.error} />
                                                {/* {fieldState.error && <p className="text-error">{fieldState.error.message}</p>} */}
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                            <hr className="divider" />
                            <div className="fl-data-block">
                                <div className="title-block w-[280px]">
                                    <h3 className="location-form-label-heading">
                                        {t('Contact Number')}
                                        <span className="asterisk">*</span>
                                    </h3>
                                    <p className="location-form-label-subheading">{t('Provide a phone number for customer inquiries and bookings.')}</p>
                                </div>
                                <div className="data-wrap-block w-full max-w-[400px]">
                                    <Controller
                                        name="phone"
                                        control={control}
                                        render={({ field: { onChange, value } }: any) => (
                                            <Number
                                                errors={errors}
                                                value={value}
                                                name="phone"
                                                required
                                                id="phone"
                                                defaultValues={{ phone: getValues('phone'), phone_country_code: getValues('phone_country_code') }}
                                                onNumberChange={(country: any, phone: any, code: any, isValid: any) => {
                                                    setValue('phone', `+${code}${phone}`);
                                                    setValue('phone_country_code', country);
                                                    trigger(['phone', 'phone_country_code']);
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <hr className="divider" />
                            <div className="fl-data-block">
                                <div className="title-block w-[280px]">
                                    <h3 className="location-form-label-heading">
                                        {t('Location Time Zone')}
                                        <span className="asterisk">*</span>
                                    </h3>
                                    <p className="location-form-label-subheading ">{t('Select the time zone for this barbershop location.')}</p>
                                </div>
                                <div className="data-wrap-block w-full max-w-[400px]">
                                    <Controller
                                        name="timezone"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState }: any) => (
                                            <>
                                                <SelectBox
                                                    name="timezone"
                                                    id="timezone"
                                                    placeholder={t('Select time zone')}
                                                    options={countryOptions}
                                                    onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                                    value={countryOptions.find((option) => option.value === value)}
                                                    error={!!fieldState.error}
                                                    className={'!max-h-9'}
                                                />
                                                {/* {fieldState.error && <p className="text-error">{fieldState.error.message}</p>} */}
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                            <hr className="divider" />
                            <div className="fl-data-block">
                                <div className="title-block w-[280px]">
                                    <h3 className="location-form-label-heading">
                                        {t('Default Currency')}
                                        <span className="asterisk">*</span>
                                    </h3>
                                    <p className="location-form-label-subheading">{t('Choose the currency used for transactions at this location.')}</p>
                                </div>
                                <div className="data-wrap-block w-full max-w-[400px]">
                                    <Controller
                                        name="currency"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState }: any) => (
                                            <>
                                                <SelectBox
                                                    name="currency"
                                                    id="currency"
                                                    placeholder="Select currency"
                                                    options={currencyOptions}
                                                    onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                                    value={currencyOptions.find((option) => option.value === value)}
                                                    error={!!fieldState.error}
                                                    isSearchable={false}
                                                    isClearable={false}
                                                />
                                                {/* {fieldState.error && <p className="text-error">{fieldState.error.message}</p>} */}
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                            <hr className="divider" />
                            <div className="fl-data-block">
                                <div className="title-block w-[280px]">
                                    <h3 className="location-form-label-heading">
                                        {t('Location Profile Image')}
                                        <span className="asterisk">*</span>
                                    </h3>
                                    <p className="location-form-label-subheading ">{t('Upload a main profile image representing this location.')}</p>
                                </div>
                                <div className="data-wrap-block max-w-[512px] xxl:max-w-[700px]">
                                    <div className="flex items-center gap-5">
                                        <div className="h-[126px] w-[126px] rounded-lg border">
                                            <img id="profile_image_name" src={cropData.profile_image_name.data || defaultImage} alt="logo" className="w-full h-full rounded-lg" onError={onError} />
                                        </div>
                                        <div>
                                            <div className={`relative cursor-pointer border border-borderGray rounded-lg hover:bg-gray-50 ${errors.profile_image_name ? 'is-invalid' : ''}`}>
                                                <input type="file" id="profile_image_name" className="w-full absolute opacity-0" onChange={onChangeCrop('profile_image_name')} />
                                                <label
                                                    htmlFor="profile_image_name"
                                                    className="w-full px-3 hover:bg-gray-50 cursor-pointer py-[9px] flex items-center justify-between text-xs leading-[18px] font-semibold text-gray"
                                                >
                                                    <AiOutlineCloudUpload size="20" className="mr-[6px]" />
                                                    {t('Upload profile image')}
                                                </label>
                                            </div>
                                            {/* {errors.profile_image_name && <p className="text-error">{errors.profile_image_name.message}</p>} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="divider" />
                            <div className="fl-data-block">
                                <div className="title-block w-[280px]">
                                    <h3 className="location-form-label-heading">
                                        {t('Location Banner Image')}
                                        <span className="asterisk">*</span>
                                    </h3>
                                    <p className="location-form-label-subheading ">{t('Upload a high-quality banner image that will be used to display your location in higher resolution.')}</p>
                                </div>
                                <div className="data-wrap-block">
                                    <div className="flex items-center gap-5">
                                        <div className="h-[126px] w-[400px] rounded-lg border">
                                            <img
                                                id="location_image_name"
                                                src={cropData.location_image_name.data || defaultImage}
                                                alt="logo"
                                                className="w-full h-full rounded-lg object-cover"
                                                onError={onError}
                                            />
                                        </div>
                                        <div>
                                            <div className={`relative cursor-pointer border border-borderGray rounded-lg hover:bg-gray-50  ${errors.location_image_name ? 'is-invalid' : ''}`}>
                                                <input type="file" id="location_image_name" className="w-full absolute opacity-0" onChange={onChangeCrop('location_image_name')} />
                                                <label
                                                    htmlFor="location_image_name"
                                                    className="w-full px-3 hover:bg-gray-50 cursor-pointer py-[9px] flex items-center justify-between text-xs leading-[18px] font-semibold text-gray"
                                                >
                                                    <AiOutlineCloudUpload size="20" className="mr-[6px]" />
                                                    {t('Upload banner image')}
                                                </label>
                                            </div>
                                            {/* {errors.location_image_name && <p className="text-error">{errors.location_image_name.message}</p>} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="divider" />
                            <div className="fl-data-block">
                                <div className="title-block w-[280px]">
                                    <h3 className="location-form-label-heading">
                                        {t('Select a Location Address')}
                                        <span className="asterisk">*</span>
                                    </h3>
                                    <p className="location-form-label-subheading ">{t('Enter the complete address for this barbershop branch.')}</p>
                                </div>
                                <div id="longitude" className="data-wrap-block flex">
                                    <LocationMap errors={errors} />
                                </div>
                            </div>
                            <hr className="divider" />
                            <div className="fl-data-block">
                                <div className="title-block w-[280px]">
                                    <h3 className="location-form-label-heading">
                                        {t('Tax Details')}
                                        <span className="asterisk">*</span>
                                    </h3>
                                    <p className="location-form-label-subheading  ">{t('Specify the tax rates and information applicable to this location.')}</p>
                                </div>
                                <div className="data-wrap-block w-full max-w-[600px]">
                                    <SalesTaxesForm />
                                </div>
                            </div>
                            <hr className="divider" />
                            <div className="fl-data-block">
                                <div className="title-block w-[280px]">
                                    <h3 className="location-form-label-heading">{t('Working Hours')}</h3>
                                    <p className="location-form-label-subheading  ">{t('Set the business hours for each day of the week.')}</p>
                                </div>
                                <div className="data-wrap-block w-full max-w-[555px]">
                                    <WorkingHours errors={errors} />
                                </div>
                            </div>
                            <hr className="divider" />
                            <div className="fl-data-block">
                                <div className="title-block w-[280px]">
                                    <h3 className="location-form-label-heading">{t('Default Tipping Options')}</h3>
                                    <p className="location-form-label-subheading  ">{t('Define the preset tipping options for this location.')}</p>
                                </div>
                                <div className="data-wrap-block w-full max-w-[400px] ">
                                    <TipsForm errors={errors} />
                                </div>
                            </div>
                            {/* <hr className="divider mb-5" /> */}
                            <div className="flex-1 text-right pt-5 flex justify-end mb-36 gap-4 border-t">
                                <CustomButton secondary type="reset" onClick={resetForm} disabled={isLoading}>
                                    {t('Cancel')}
                                </CustomButton>
                                <CustomButton primary type="submit" isLoading={isLoading} disabled={isLoading}>
                                    {t('Save Changes')}
                                </CustomButton>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </div>
            {isUpload && fieldName && (
                <CropperModal
                    title={cropData[fieldName].title}
                    description={cropData[fieldName].description}
                    defaultCropType={cropData[fieldName].default_crop_type}
                    isCropType={false}
                    imageUrl={image}
                    imageUpload={imageUpload}
                    setUpload={setIsUpload}
                    setCropData={handleCropData}
                    btnTitle={t('Confirm')}
                />
            )}

            {/* <DeletePopupModal
                onClose={(status1: boolean) => () => {}}
                size="w-[400px]"
                title={'Unsaved changes'}
                description={'Do you want to save or discard changes?'}
                buttonColor={'bg-blue-500'}
                buttonText="Confirm"
                HeaderIcon={<Save01 className="w-6 h-6" />}
            /> */}
        </div>
    );
};

export default LocationForm;
