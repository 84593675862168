import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GoDotFill } from 'react-icons/go';
import { useAppSelector } from 'src/redux/hooks';
import SearchBar from 'src/components/SearchBar/SearchBar';
import SelectBox from 'src/components/SelectBox/SelectBox';
import './../../utils/Datatable.scss';
import './../../utils/prime-react-datatable.scss';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { capitalizeFirstLetterAndUnderscore, getDateRange, getSelectBoxOptions, getShortName } from 'src/utils/global-functions';
import { allShopLocations, allShopStaff, currentShop } from 'src/redux/services/common/Common.slice';
import { Users01, ArrowUp, ArrowDown, SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import CustomButton from 'src/components/CustomButton';
import NoDataMessage from 'src/components/NoDataMessage';
import moment from 'moment';
import { AllLocationsOptions, AllTeamMembersOptions, perPageOptions } from 'src/utils/global-variables';
import { Skeleton } from 'primereact/skeleton';

const SalesTable = ({ setActiveTab, clickedIndicator = 'all' }: any) => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const shopLocationList = useAppSelector(allShopLocations);
    const allShopStaffList = useAppSelector(allShopStaff);
    const [clientArrayList, setClientArrayList] = useState<any>([]);
    const [totalClients, setTotalClients] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [activeFilter, setActiveFilter] = useState('daily');
    const [startDate, setStartDate] = useState<Date | undefined>(moment().toDate());
    const [endDate, setEndDate] = useState<Date | undefined>(moment().toDate());

    const INIT_STATE = {
        FILTER: {
            shop_location_id: null,
            shop_admin_id: null,
        },
        LAZY_STATE: {
            first: 0,
            rows: 25,
            page: 1,
            sortField: null,
            sortOrder: null,
            filters: {
                global: { value: null },
                type: { value: clickedIndicator },
                shop_location_id: { value: null },
                shop_admin_id: { value: null },
            },
        },
    };
    const [lazyState, setLazyState] = useState<any>(INIT_STATE.LAZY_STATE);
    const [filters, setFilters] = useState<any>({
        global: { value: null },
        type: { value: clickedIndicator },
        shop_location_id: { value: null },
        shop_admin_id: { value: null },
    });
    const [locationOptions, setLocationOptions] = useState<any[]>([]);
    const [staffOptions, setStaffOptions] = useState<any[]>([]);
    const [selectedFilter, setSelectedFilter] = useState(INIT_STATE.FILTER);

    useEffect(() => {
        const locationData = getSelectBoxOptions(shopLocationList, 'id', 'name', 'id', 'shortname', 'profile_image_url');
        const updateLocationData = [AllLocationsOptions, ...locationData];
        setLocationOptions(updateLocationData);

        const staff = getSelectBoxOptions(allShopStaffList, 'id', 'full_name', 'id', 'full_name', 'profile_image_url');
        const updateTeamData = [AllTeamMembersOptions, ...staff];
        setStaffOptions(updateTeamData);
        setSelectedFilter(INIT_STATE.FILTER);
        setLazyState(INIT_STATE.LAZY_STATE);
    }, [shop]);

    useEffect(() => {
        listClients();
    }, [lazyState, shop, startDate, endDate]);
    useEffect(() => {
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, type: { value: clickedIndicator } } }));
    }, [clickedIndicator]);

    const listClients = async () => {
        setIsLoading(true);
        const payload = {
            ...lazyState,
            type: 'all',
            start_date: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
            end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
        };
        await axiosPost(API.BOOKING.LIST, payload, { shop_id: shop.id })
            .then((response) => {
                setClientArrayList(response.data.data.data);
                setTotalClients(response.data.data.totalRecords);
            })
            .finally(() => {
                setFilters(lazyState.filters);
                setIsLoading(false);
            });
    };

    const handleSearch = (event: any) => {
        const value = event.target.value;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, global: { value } } }));
    };

    const handleFilter = (filter: string) => (event: any) => {
        const value = event ? event.value : event;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, [filter]: { value } } }));
        setSelectedFilter((old: any) => ({ ...old, [filter]: event }));
    };

    const onPageHandle = (event: any) => {
        setLazyState((old: any) => ({ ...old, rows: event.value }));
    };

    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink NextPageLink ',
        PrevPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
                {t('Previous')}
            </CustomButton>
        ),
        NextPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick}>
                {t('Next')}
            </CustomButton>
        ),
        CurrentPageReport: ({ first, rows, totalRecords }: { first: number; rows: number; totalRecords: number }) => {
            const currentPage = Math.floor(first / rows) + 1;
            const totalPages = Math.ceil(totalRecords / rows);

            return (
                <div className="text-gray-700 flex items-center text-sm font-medium mr-auto">
                    <span>
                        Page {currentPage} of {totalPages}
                    </span>
                </div>
            );
        },
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const GetStylist = (row: any) => (
        <div className="flex items-center">
            <figure className="NoImgName">
                {row.staff.profile_image_url ? <img src={row.staff.profile_image_url} alt="client" className="w-full h-full object-cover" /> : getShortName(row.staff.full_name)}
            </figure>
            <div>
                <p className="text-xs font-medium text-gray-900 leading-[18px] w-[80px] min-w-[80px] 2xl:w-[85px] 2xl:min-w-[85px] 2xlm:w-[100px] 2xlm:min-w-[100px] xxl:w-[110px] xxl:min-w-[110px] truncate">
                    {row.staff.full_name}
                </p>
                {/* <p className="text-xs font-normal text-gray-600 leading-[18px] w-[80px] min-w-[80px]  max-w-[80px] 2xl:w-[120px] 2xl:max-w-[120px] 2xl:min-w-[120px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate">
                    {moment(row.created_at).fromNow()}
                </p> */}
            </div>
        </div>
    );
    const getCheckoutMethod = (row: any) => {
        const transactionArray = row.booking_transaction;
        return transactionArray.length > 0 ? (
            <p className="text-xs font-normal  w-[80px] min-w-[80px] 2xl:w-[85px] 2xl:min-w-[85px] 2xlm:w-[100px] 2xlm:min-w-[100px] xxl:w-[110px] xxl:min-w-[110px] truncate capitalize">
                {`${transactionArray[0].payment_method} ${transactionArray.length > 1 ? `+${transactionArray.length - 1}` : ''}`}
            </p>
        ) : (
            <>-</>
        );
    };
    const GetClient = (row: any) => (
        <div className="flex items-center">
            <figure className="NoImgName">
                {row.user.profile_image_url ? <img src={row.user.profile_image_url} alt="client" className="w-full h-full object-cover" /> : getShortName(row.user.full_name)}
            </figure>
            <div>
                <p className="text-xs font-medium text-gray-900 leading-[18px]  w-[80px] min-w-[80px] 2xl:w-[85px] 2xl:min-w-[85px] 2xlm:w-[100px] 2xlm:min-w-[100px] xxl:w-[110px] xxl:min-w-[110px] truncate capitalize-first">
                    {row.user.full_name}
                </p>
                {/* <p className="text-xs font-normal text-gray-600 leading-[18px] w-[80px] min-w-[80px]  max-w-[80px] 2xl:w-[120px] 2xl:max-w-[120px] 2xl:min-w-[120px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate">
                    {moment(row.created_at).fromNow()}
                </p> */}
            </div>
        </div>
    );
    const GetTotal = (row: any) => `$${row.total}`;
    const GetSubtotal = (row: any) => `$${row.subtotal}`;
    const GetTip = (row: any) => `$${row.tip_transaction[0] ? row.tip_transaction[0].total_amount : 0}`;
    const GetTaxAndFees = (row: any) => `$${row.taxes_and_fees}`;

    const GetService = (row: any) => {
        const servicesArray = row.booking_services;

        return servicesArray.length > 0 ? (
            <p className="text-xs font-normal  w-[90px] min-w-[90px] 2xl:w-[85px] 2xl:min-w-[85px] 2xlm:w-[100px] 2xlm:min-w-[100px] xxl:w-[110px] xxl:min-w-[110px] truncate">{`${
                servicesArray[0].staff_service.service.name
            } ${servicesArray.length > 1 ? `+${servicesArray.length - 1}` : ''}`}</p>
        ) : (
            '-'
        );
    };
    const GetProduct = (row: any) => {
        const productArray = row.booking_products;

        return productArray.length > 0 ? (
            <p className="text-xs font-normal  w-[90px] min-w-[90px] 2xl:w-[85px] 2xl:min-w-[85px] 2xlm:w-[100px] 2xlm:min-w-[100px] xxl:w-[110px] xxl:min-w-[110px] truncate">{`${
                productArray[0].product.name
            } ${productArray.length > 1 ? `+${productArray.length - 1}` : ''}`}</p>
        ) : (
            '-'
        );
    };
    const GetDateTime = (row: any) => `${moment(row.booking_date, 'YYYY-MM-DD').format('D MMM')} ${moment(row.booking_start_time, 'HH:mm:ss').format('h:mm A')}`;

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map(() => ({
                first_name: 'loading',
                phone: 'loading',
                email: 'loading',
                average_days: 'loading',
                average_amount: 'loading',
                bookings_count: 'loading',
                status: 'loading',
            })),
        [lazyState],
    );

    const getStatusColor = (status: string) => {
        switch (status) {
            case 'active':
                return 'text-[#175CD3]';
            case 'loyal':
                return 'text-[#067647]';
            case 'recent':
                return 'text-[#F38744]';
            case 'new':
                return 'text-[#EE46BC]';
            case 'lapsed':
                return 'text-[#B54708]';
            default:
                return 'text-blueLight-700';
        }
    };
    const tabs = useMemo(
        () => [
            {
                value: 'daily',
                name: 'Today',
            },
            {
                value: 'weekly',
                name: 'This Week',
            },
            {
                value: 'monthly',
                name: 'This Month',
            },
            {
                value: 'yearly',
                name: 'This Year',
            },
            {
                value: 'all_time',
                name: 'All Time',
            },
        ],
        [],
    );

    const GetStatus = (row: any) => {
        const statusClass = getStatusColor(row.status);
        return (
            <div className="flex items-center">
                <span
                    className={`px-[6px] pl-0.5 py-[2px] border flex shadow items-center justify-start rounded-md border-borderPrimary text-center text-[10px] leading-[14px] font-medium capitalize gap-0.5 w-max `}
                >
                    <GoDotFill size={12} className={`${statusClass}`} />
                    {capitalizeFirstLetterAndUnderscore(row.status)}
                </span>
            </div>
        );
    };
    const handleFilterTab = (value: string) => () => {
        setActiveFilter(value);
    };
    useEffect(() => {
        if (activeFilter !== '') {
            if (activeFilter === 'all_time') {
                setStartDate(undefined);
                setEndDate(undefined);
            } else {
                const { start, end } = getDateRange(activeFilter);
                setStartDate(start);
                setEndDate(end);
            }
        }
    }, [activeFilter]);
    return (
        <div
            className={`w-full border rounded-xl flex-1 flex-col flex shadow  staff_service_table datatable-custom-service ${
                clientArrayList.length === 0 ? 'datatable-full-height datatable-noshow h-full' : ''
            }`}
        >
            <div className={`flex items-center w-full flex-col `}>
                <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                    <div className="flex flex-col flex-1">
                        <div className="flex justify-start items-center gap-1.5 xxl:gap-1.5">
                            <h2 className="table-title">{t('All Sales')}</h2>
                            <p className={`userCounter ${isLoading ? 'custom-loading' : ''}`}>
                                <GoDotFill size={12} color="#17B26A" className="h-3 w-3 rounded-full flex mr-0.5" />
                                {totalClients} {t('products')}
                            </p>
                        </div>
                        <p className="table-subtitle">{t('View and manage your client list.')}</p>
                    </div>
                </div>
                <div className="flex items-start px-5 py-3 justify-between w-full  ">
                    <div className="max-2xl:flex max-2xl:flex-col max-2xl:gap-1.5 ">
                        <div className="fl-tab-btn-view2 h-[36px] w-auto  flex ">
                            {tabs.map((tab, index) => (
                                <button
                                    key={index}
                                    type="button"
                                    className={`w-full flex fl-tab-link2 justify-center items-center min-h-[34px] !max-w-max xxl:!min-w-[91px] 2xl:!min-w-[72px] ${
                                        activeFilter === tab.value ? 'active' : ''
                                    }`}
                                    onClick={handleFilterTab(tab.value)}
                                >
                                    {tab.name}
                                </button>
                            ))}
                        </div>
                        <div className="table-searchInput 2xl:hidden !w-full">
                            <SearchBar placeholder={t('Search')} className="form-control-md " onChange={handleSearch} />
                        </div>
                    </div>
                    <div className="flex gap-3">
                        <div className="table-searchInput max-2xl:hidden ">
                            <SearchBar placeholder={t('Search')} className="form-control-md " onChange={handleSearch} />
                        </div>

                        <div className="w-[170px] xl:w-[170px] xlm:w-[185px] xxl:w-[200px]">
                            <SelectBox
                                options={locationOptions}
                                value={locationOptions.find((option) => option.value === selectedFilter.shop_location_id)}
                                noOptionsMessage="No Locations Found"
                                placeholder={t('All locations')}
                                onChangeFunc={handleFilter('shop_location_id')}
                                classComp=""
                                isSearchable={false}
                                isClearable={false}
                            />
                        </div>
                        <div className="w-[170px] xl:w-[170px] xlm:w-[185px] xxl:w-[200px]">
                            <SelectBox
                                options={staffOptions}
                                noOptionsMessage="No Team Found"
                                value={staffOptions.find((option) => option.value === selectedFilter.shop_admin_id)}
                                placeholder={t('All Team members')}
                                onChangeFunc={handleFilter('shop_admin_id')}
                                classComp="outline-select-box"
                                isSearchable={false}
                                isClearable={false}
                            />
                        </div>
                        <div className="w-[70px] xl:w-[70px] page-dropdown">
                            <SelectBox
                                name="page"
                                options={perPageOptions}
                                isClearable={false}
                                onChangeFunc={onPageHandle}
                                value={perPageOptions.find((option) => option.value === lazyState.rows)}
                                classComp="outline-select-box"
                                isSearchable={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col flex-1">
                <DataTable
                    lazy
                    paginatorTemplate={customPaginatorTemplate}
                    value={isLoading ? skeletons : clientArrayList}
                    totalRecords={totalClients}
                    paginator={!isLoading && totalClients > lazyState.rows}
                    first={lazyState.first}
                    rows={lazyState.rows}
                    sortOrder={lazyState.sortOrder}
                    sortField={lazyState.sortField}
                    filters={lazyState.filters}
                    // onRowClick={handleRowClick}
                    onPage={onPage}
                    onSort={onSort}
                    onFilter={onFilter}
                    // selectionMode="single"
                    dataKey="id"
                    className="border-t rounded-b-xl overflow-hidden w-full overflow-x-auto"
                    paginatorClassName="table-pagination"
                    emptyMessage={
                        <NoDataMessage
                            title={`${filters.global.value || filters.shop_location_id.value || filters.shop_admin_id.value || filters.type.value ? 'No clients found.' : 'No clients added.'}`}
                            description={`${
                                filters.global.value || filters.shop_location_id.value || filters.shop_admin_id.value || filters.type.value
                                    ? 'Try adjusting your filters or add new clients.'
                                    : 'Add a clients, to start using the clients collection.'
                            }`}
                            iconComponent={
                                filters.global.value || filters.shop_location_id.value || filters.shop_admin_id.value || filters.type.value ? (
                                    <SearchLg className="text-gray-700" />
                                ) : (
                                    <Users01 className="text-gray-700" />
                                )
                            }
                        />
                    }
                    // rowClassName={(data) => 'cursor-pointer'}
                >
                    <Column
                        field="staff_name"
                        header={renderHeader(t('Team Member'), 'staff_name')}
                        className="text-xs leading-[18px] font-medium text-mainTextColor"
                        body={isLoading ? <Skeleton /> : GetStylist}
                        style={{ width: '160px', minWidth: '160px', maxWidth: '160px' }}
                        sortable
                    ></Column>
                    <Column
                        field="status"
                        header={renderHeader(t('Status'), 'status')}
                        body={isLoading ? <Skeleton /> : GetStatus}
                        style={{ width: '130px', minWidth: '130px', maxWidth: '130px' }}
                        className="text-xs font-medium text-secondaryTxtColor"
                        sortable
                    ></Column>
                    <Column
                        field="user_name"
                        header={renderHeader(t('Client'), 'user_name')}
                        className="text-xs leading-[18px] font-medium text-mainTextColor"
                        body={isLoading ? <Skeleton /> : GetClient}
                        style={{ width: '160px', minWidth: '160px', maxWidth: '160px' }}
                        sortable
                    ></Column>
                    <Column
                        field="booking_date"
                        header={renderHeader(t('Date and Time'), 'booking_date')}
                        body={isLoading ? <Skeleton /> : GetDateTime}
                        className="text-xs text-secondaryTxtColor font-normal truncate"
                        // style={{ width: '15%' }}
                        style={{ width: '130px', minWidth: '130px', maxWidth: '130px' }}
                        sortable
                    ></Column>
                    <Column
                        field="service_name"
                        header={renderHeader(t('Service'), 'service_name')}
                        body={isLoading ? <Skeleton /> : GetService}
                        className="text-xs text-secondaryTxtColor font-normal truncate"
                        // style={{ width: '20%' }}
                        style={{ width: '110px', minWidth: '110px', maxWidth: '110px' }}
                        sortable
                    ></Column>
                    <Column
                        field="product_name"
                        header={renderHeader(t('Product'), 'product_name')}
                        body={isLoading ? <Skeleton /> : GetProduct}
                        className="text-xs text-secondaryTxtColor font-normal truncate"
                        // style={{ width: '20%' }}
                        style={{ width: '110px', minWidth: '110px', maxWidth: '110px' }}
                        sortable
                    ></Column>

                    <Column
                        field="subtotal"
                        header={renderHeader(t('Sub Total'), 'subtotal')}
                        body={isLoading ? <Skeleton /> : GetSubtotal}
                        // style={{ width: '12.50%' }}
                        style={{ width: '95px', minWidth: '95px', maxWidth: '95px' }}
                        className="text-xs font-normal text-secondaryTxtColor truncate"
                        sortable
                    ></Column>
                    <Column
                        field="tips"
                        header={renderHeader(t('Tip'), 'tips')}
                        body={isLoading ? <Skeleton /> : GetTip}
                        // style={{ width: '12.50%' }}
                        style={{ width: '75px', minWidth: '75px', maxWidth: '75px' }}
                        className="text-xs font-normal text-secondaryTxtColor truncate"
                        sortable
                    ></Column>
                    <Column
                        field="taxes_and_fees"
                        header={renderHeader(t('Taxes '), 'taxes_and_fees')}
                        body={isLoading ? <Skeleton /> : GetTaxAndFees}
                        // style={{ width: '12.50%' }}
                        style={{ width: '95px', minWidth: '95px', maxWidth: '95px' }}
                        className="text-xs font-normal text-secondaryTxtColor truncate"
                        sortable
                    ></Column>
                    <Column
                        field="total"
                        header={renderHeader(t('Total'), 'total')}
                        body={isLoading ? <Skeleton /> : GetTotal}
                        // style={{ width: '12.50%' }}
                        style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}
                        className="text-xs font-normal text-secondaryTxtColor truncate"
                        sortable
                    ></Column>
                    <Column
                        field="payment_method"
                        header={renderHeader(t('Checkout Method'), 'payment_method')}
                        body={isLoading ? <Skeleton /> : getCheckoutMethod}
                        // style={{ width: '12.50%' }}
                        style={{ width: '140px', minWidth: '140px', maxWidth: '140px' }}
                        className="text-xs font-normal text-secondaryTxtColor truncate"
                        sortable
                    ></Column>
                </DataTable>
            </div>
        </div>
    );
};

export default SalesTable;
