import React, { FC, useState } from 'react';
import CropperModal from 'src/components/CropperModal/CropperModal';
import { convertBase64ToFile, onError } from 'src/utils/global-functions';
import { useTranslation } from 'react-i18next';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { defaultImage, InstagramColor } from 'src/theme/Images';
import { Controller, Resolver, useForm } from 'react-hook-form';
import CustomButton from 'src/components/CustomButton';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import ImageInfo from 'src/app/Appearance/Template/Image/ImageInfo';
import InputWithLabel from 'src/components/InputWithLabel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { IFormData, IProps } from './Profile.interface';
import { errorCode } from 'src/constants/errorCode';
import { s3Upload } from 'src/utils/s3Operations';
import { s3Path } from 'src/constants/s3Path';
import { axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { currentShop, me, userMe } from 'src/redux/services/common/Common.slice';
import { UploadCloud02, UserEdit } from '@untitled-ui/icons-react/build/cjs';
import { BackgroundDesign } from 'src/theme/Images';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';
import ImageUpload from 'src/components/ImageUpload';
import StepProgressBar from 'src/components/StepProgressBar';
import Footer from 'src/app/Layout/Footer';

const Profile: FC<IProps> = ({ setStep, inviteStaffShop, stepLenght, currentStep }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    const user = useAppSelector(userMe);
    const [shopDetail] = useState<any>(inviteStaffShop ?? shop);
    const profileImageUrl = user.profile_image_url ? user.profile_image_url : null;

    const [initCropData] = useState({
        profile_image_name: {
            data: profileImageUrl,
            default_crop_type: 'Square',
            title: t('Adjust profile image'),
            description: t('Upload a max 800Kb image for best results'),
            file: null,
            name: null,
        },
    });
    const [isLoading, setIsLoading] = useState(false);
    const [cropData, setCropData] = useState<any>(initCropData);
    const [fieldName, setFieldName] = useState<any>(null);
    const [image, setImage] = useState<any>('');
    const [isUpload, setIsUpload] = useState(false);

    const imageUpload = (data: any) => {
        const file = convertBase64ToFile(data);
        if (file && file.filename && fieldName) {
            setValue(fieldName, file.filename);
            setCropData((old: any) => ({ ...old, [fieldName]: { ...old[fieldName], file: file.convertedFile, name: file.filename } }));
        }
    };

    const handleCropData = (data: any) => {
        if (fieldName) {
            setCropData((old: any) => ({ ...old, [fieldName]: { ...old[fieldName], data } }));
        }
    };

    const onChangeCrop = (type: any) => (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onloadstart = () => {
            setImage('');
            setIsUpload(false);
        };
        reader.onloadend = () => {
            setImage(reader.result as any);
            setIsUpload(true);
        };
        reader.readAsDataURL(files[0]);
        e.target.value = null;
        setFieldName(type);
    };

    const onChangeCropMultiple = (fieldNew: any) => (e: any) => {
        e.preventDefault();
        let files;

        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const promises = Array.from(files).map(
            (file: any) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const newFile = convertBase64ToFile(reader.result);
                        if (newFile && newFile.filename && newFile.convertedFile) {
                            resolve({
                                file: newFile.convertedFile as File,
                                url: reader.result as string,
                                name: newFile.filename,
                            });
                        } else {
                            reject(new Error('Invalid file'));
                        }
                    };
                    reader.onerror = () => reject(reader.error);
                    reader.readAsDataURL(file);
                }),
        );
        Promise.all(promises).then((results: any) => {
            const currentImages = watch('gallery_image_names') || [];
            setValue('gallery_image_names', [...currentImages, ...results]);
        });
        e.target.value = null;
    };

    const handleRemoveImage = (index: number) => (event: any) => {
        event.preventDefault();
        const filterImage: any = imageData?.filter((_: any, i: number) => i !== index);
        setValue('gallery_image_names', filterImage);
    };

    const schema = Yup.object({
        profile_image_name: Yup.string().required(t('This field is required')),
        instagram_link: Yup.string().required(t('This field is required')),
        about: Yup.string().required(t('This field is required')),
    }).required();

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        setError,
        watch,
    } = useForm<IFormData>({
        resolver: yupResolver(schema) as Resolver<IFormData>,
        defaultValues: {
            profile_image_name: user.profile_image_name ?? '',
            about: user.about ?? '',
            instagram_link: user.instagram_link ?? '',
            gallery_image_names: user.galleries.length
                ? user.galleries.map((gallery: any) => ({
                      name: gallery.name,
                      url: gallery.image_url,
                  }))
                : null,
        },
    });

    const imageData = watch('gallery_image_names');

    const submitForm = async (creadData: any) => {
        await axiosPatch(API.USER.STAFFPROFILE, creadData, { shop_id: creadData.shop_id })
            .then(async (response) => {
                if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
                    await dispatch(me());
                    setStep(3);
                }
            })
            .catch((error) => {
                if (error.data.status === errorCode.unprocessable) {
                    if (error.data.data) {
                        Object.keys(error.data.data).forEach((field) => {
                            setError(field as keyof IFormData, {
                                type: 'manual',
                                message: error.data.data[field][0],
                            });
                        });
                    }
                    return;
                }
            })
            .finally(() => setIsLoading(false));
    };

    const handleChange = async (data: any) => {
        setIsLoading(true);

        let cread;
        let galleryImageStatus;
        if (imageData && imageData.length > 0) {
            let galleryImgNames: string[] = [];
            data.gallery_image_names.forEach((galleryImg: any) => {
                galleryImgNames.push(galleryImg.name);
            });
            cread = {
                shop_id: shopDetail.id,
                profile_image_name: data.profile_image_name,
                about: data.about,
                instagram_link: data.instagram_link,
                gallery_image_names: galleryImgNames,
            };

            galleryImageStatus = errorCode.updateSuccess;
            const imagePromises = imageData?.map((galleryImageData: any) =>
                galleryImageData?.file && galleryImageData?.name ? s3Upload(galleryImageData.file, `${s3Path.STAFF_GALLERY}${galleryImageData.name}`) : Promise.resolve(null),
            );

            if (imagePromises) {
                const imageResponses = await Promise.allSettled(imagePromises);
                const isSuccess = imageResponses.every((response: any) => response.status === 'fulfilled' && (response.value?.status === errorCode.updateSuccess || response?.value === null));
                if (isSuccess) {
                    galleryImageStatus = errorCode.updateSuccess;
                }
            } else {
                setIsLoading(false);
            }
        } else {
            cread = {
                shop_id: shopDetail.id,
                profile_image_name: data.profile_image_name,
                about: data.about,
                instagram_link: data.instagram_link,
            };
        }

        let profileImage = {
            status: errorCode.updateSuccess,
        };
        if (cropData.profile_image_name.file) {
            const profileImageResponse: any = await s3Upload(cropData.profile_image_name.file, `${s3Path.ADMIN_PROFILE}${cropData.profile_image_name.name}`);
            profileImage.status = profileImageResponse.status;
        }

        if (imageData && imageData.length > 0) {
            if (galleryImageStatus === errorCode.updateSuccess) {
                submitForm(cread);
            }
        } else {
            submitForm(cread);
        }
    };
    useFormErrorFocus<IFormData>({ errors });

    return (
        <form onSubmit={handleSubmit(handleChange)}>
            <main className="p-6 h-[calc(100vh-138px)] overflow-y-scroll flex items-center flex-col">
                <div className="flex justify-center relative">
                    <img src={BackgroundDesign} alt="" className="absolute -top-6 z-[-1]" />
                    <div className="w-[600px] pt-6 ">
                        <div className="flex flex-col items-center justify-center gap-4 w-full max-w-[512px] mx-auto">
                            <span className="flex items-center justify-center h-[56px] w-[56px] rounded-xl text-gray-400 bg-white border border-gray-200 shadow-InputAndButton">
                                {<UserEdit className="h-7 w-7 cursor-pointer text-gray-700 " />}
                            </span>
                            <div className="text-center">
                                <h2 className="text-3xl leading-[38px] text-gray-700 font-semibold">Welcome, {user.first_name} 👋</h2>
                                <p className="text-base font-normal mb-[33px] text-gray-600">Tell us a bit about yourself</p>
                            </div>
                        </div>

                        <div className="space-y-5">
                            <div id="profile_image_name" className="data-wrap-block max-w-[512px] xxl:max-w-[700px]">
                                <div className="flex items-center gap-4">
                                    {/* <div className={`h-[70px] w-[70px] rounded-[10px] border-2 border-dashed border-blue-25 ${errors && errors?.profile_image_name ? 'is-invalid' : ''}`}>
                                    <input type="file" id="profile_image_name" className="h-[70px] w-[70px] absolute opacity-0" onChange={onChangeCrop('profile_image_name')} />
                                    <label htmlFor="profile_image_name">
                                        <img src={cropData.profile_image_name.data || defaultImage} alt="logo" className="w-full h-full rounded-lg" onError={onError} />
                                    </label>
                                </div> */}
                                    {/* <div>
                                    <div className={`relative rounded-lg `}> */}
                                    {/* <input type="file" id="profile_image_name" className="w-full absolute opacity-0" onChange={onChangeCrop('profile_image_name')} /> */}
                                    {/* <span className="w-full pb-1 flex items-center justify-between text-base font-semibold font-gray-700">{t('Upload a profile picture')}</span> */}
                                    {/* </div>
                                    <p className="text-base font-normal text-blue-500">{t('Upload JPG or PNG file')}</p> */}

                                    {/* {errors && errors?.profile_image_name && <p className="text-error">{errors?.profile_image_name?.message}</p>} */}
                                    {/* </div> */}
                                    <div className="w-full">
                                        <label className={`fl-field-title-label text-start`}>
                                            {t('Add a Profile Picture')} <span className="asterisk">*</span>
                                        </label>
                                        <ImageUpload
                                            cropData={cropData.profile_image_name.data}
                                            onChangeCrop={onChangeCrop('profile_image_name')}
                                            id="profile_image_name"
                                            error={errors && errors?.profile_image_name && errors?.profile_image_name?.message}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex gap-4 mb-5">
                                <Controller
                                    name="about"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full profile_textarea">
                                            <InputWithLabel
                                                label={t('About')}
                                                placeholder={t('Write a quick sentence about your experience, this will be visible on your profile.')}
                                                onChange={onChange}
                                                value={value}
                                                required
                                                error={!!error}
                                                name="about"
                                                id="about"
                                                textArea
                                                textAearClassName="h-[74px] rounded-lg resize-none !text-sm"
                                                maxLenght={275}
                                                labelClassName={'text-xs font-medium text-gray-700 mb-0'}
                                                inputWrapClassName={'!text-sm font-normal text-gray-600'}
                                                inputControlClassName={'px-[14px] py-3 !text-sm'}
                                            />
                                            {/* {error && <p className="text-error">{error.message}</p>} */}
                                        </div>
                                    )}
                                />
                            </div>

                            <div className="flex flex-col mt-5 w-full justify-center items-center relative">
                                <Controller
                                    name="instagram_link"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                label="Link your instagram"
                                                placeholder="Instagram Page"
                                                required
                                                onChange={onChange}
                                                value={value}
                                                name="instagram_link"
                                                id="instagram_link"
                                                isFocus={true}
                                                error={!!error}
                                                labelIcon={
                                                    <div className="flex flex-row gap-1.5 items-center">
                                                        <InstagramColor />
                                                        <p className="text-xs font-medium text-mainTextColor">instagram.com/</p>
                                                    </div>
                                                }
                                                inputControlClassName="pl-3"
                                                inputTextWrapper="border-borderGray"
                                                labelIconClassName="border-r border-secondaryBorder text-xs text-gray-500"
                                            />

                                            {/* {error && <p className="text-error">{error.message}</p>} */}
                                        </div>
                                    )}
                                />
                            </div>

                            <div className="w-full">
                                <div>
                                    <p className="block mb-1.5 text-xs font-medium text-gray-700">Add photo to gallery (optional)</p>
                                    <Controller
                                        name="gallery_image_names"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <>
                                                <div id="gallery_image_names" className="relative cursor-pointer border border-gray-200 rounded-lg px-5 py-4 flex  items-center bg-white hover:shadow">
                                                    <input
                                                        type="file"
                                                        id="image"
                                                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                                        onChange={onChangeCropMultiple('gallery_image_names')}
                                                        multiple
                                                    />
                                                    <div className="flex flex-row justify-center w-full cursor-pointer gap-4">
                                                        <div className=" w-9 h-9 border border-[#EAECF0] bg-white shadow rounded-lg flex items-center justify-center">
                                                            <UploadCloud02 width={20} height={20} />
                                                        </div>
                                                        <div className="text-secondaryTxtColor text-xs font-normal text-start">
                                                            <p className="text-xs font-normal text-gray-600">
                                                                <span className="text-primary font-semibold pr-1">Click to upload </span> or drag and drop
                                                            </p>
                                                            <p className="text-[11px] leading-[18px]">SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                {value && Array.isArray(value) && (
                                                    <div className="flex gap-2.5 overflow-x-auto custom-scroll mt-[10px]">
                                                        {value &&
                                                            value.map((item, index) => (
                                                                <ImageInfo
                                                                    key={index}
                                                                    name={item.name}
                                                                    url={item.url}
                                                                    onRemove={handleRemoveImage(index)}
                                                                    className="w-full max-w-[192px] py-4 px-4"
                                                                    usedData={'200 KB'}
                                                                    ImageInfoClassname={'max-w-[68px]'}
                                                                />
                                                            ))}
                                                    </div>
                                                )}

                                                {error && <p className="text-red-500 text-xs italic">{error.message}</p>}
                                            </>
                                        )}
                                    />
                                </div>
                            </div>

                            {/* <div className="flex w-full justify-center">
                                <CustomButton type="submit" isLoading={isLoading} disabled={isLoading} primary className="w-full text-sm">
                                    {'Continue'}
                                </CustomButton>
                            </div> */}
                        </div>
                    </div>

                    {isUpload && fieldName && (
                        <CropperModal
                            title={cropData[fieldName].title}
                            description={cropData[fieldName].description}
                            defaultCropType={cropData[fieldName].default_crop_type}
                            isCropType={false}
                            imageUrl={image}
                            imageUpload={imageUpload}
                            setUpload={setIsUpload}
                            setCropData={handleCropData}
                        />
                    )}
                </div>
            </main>
            <div className="bottom-0 right-0 max-2xl:start-[420px] start-[480px] fixed">
                <div className="w-full mt-5   mb-2  gap-4 flex justify-center mx-auto">
                    <CustomButton secondary onClick={() => setStep(1)} size="w-[292px]">
                        Back
                    </CustomButton>
                    <CustomButton primary isLoading={isLoading} disabled={isLoading} type="submit" size="w-[292px]" className="w-[292px]">
                        {t('Continue')}
                    </CustomButton>
                </div>
                <div className="w-full bg-white">
                    <StepProgressBar steps={stepLenght} currentStep={currentStep} className="w-full mx-auto justify-center" />
                </div>
                <Footer />
            </div>
        </form>
    );
};

export default Profile;
