import React, { FC, useEffect, useState } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { allShopSettings, currentShop } from 'src/redux/services/common/Common.slice';
import { selectCalendarData } from 'src/app/Calendar/Calendar.slice';
import Switch from 'src/components/Switch/Switch';
import CustomButton from 'src/components/CustomButton';
import { useForm, useFormContext } from 'react-hook-form';
import { IInitPaymentStatus, IPaymentValidation, initValidation } from './Payment.interface';
import CashPayment from './CashPayment';
import PosPayment from './PosPayment';
import LoyaltyPayment from './LoyaltyPayment';
import CardPayment from './CardPayment';
import OtherPayment from './OtherPayment';
import RemainingCharge from './RemainingCharge';
import { toast } from 'react-toastify';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { IPaymentMethod, IPaymentState } from 'src/app/Calendar/Calendar.Interface';
import AppointmentStatus from 'src/app/Calendar/Sidebar/AppointmentStatus';
import { IInitAction } from '../CheckoutSidebar.Interface';

const INIT_PAYMENT_STATUS = {
    processing: false,
    success: false,
    error: false,
    retry: false,
};
interface IProps {
    handleAction: (type: keyof IInitAction) => () => void;
    className?: string;
    setPaymentArray: React.Dispatch<React.SetStateAction<any>>;
    paymentArray: IPaymentState;
    appointmentCalulation: any;
    dataService: any;
    cardList: any;
    loyalPointAmount: any;
}
const Payment: FC<IProps> = ({ className, handleAction, setPaymentArray, paymentArray, appointmentCalulation, dataService, cardList, loyalPointAmount }) => {
    const calendarData: any = useAppSelector(selectCalendarData);
    // const bookedSlotInfo = calendarData.bookedSlotInfo;
    const [isTaxable, setIsTaxable] = useState<boolean>(true);
    const [totalChargableAmount, setTotalChargableAmount] = useState(appointmentCalulation.total);
    const [remainingChargeAmount, setRemainingChargedAmount] = useState(appointmentCalulation.total);
    const [returnAmount, setReturnAmount] = useState<number>(0);
    const [errors, setErrors] = useState<IPaymentValidation>(initValidation);
    const [totalOptions, setTotalOptions] = useState<number[]>([]);
    const [selectedMethod, setSelectedMethod] = useState<string | null>(null);
    const shopSettingInfo = useAppSelector(allShopSettings).find((setting: any) => setting.type === 'loyalty')?.value;
    const shopId = useAppSelector(currentShop).id;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [paymentStatus, setPaymentStatus] = useState<IInitPaymentStatus>(INIT_PAYMENT_STATUS);
    const selectedLocation: any = useAppSelector(selectCalendarData).selectedLocation;
    const [remainingPoints, setRemainingPoints] = useState(0);
    const { getValues } = useFormContext();
    const bookingInfo = getValues();
    const methods = useForm<any>({
        // resolver: yupResolver(schema) as Resolver<IFormData>,
        defaultValues: {
            tip: 0,
            other_payment_methods: '',
        },
    });

    const handlePaymentStatus = (status: keyof IInitPaymentStatus) => {
        setPaymentStatus({
            ...INIT_PAYMENT_STATUS,
            [status]: true,
        });
    };

    // const { handleSubmit, reset, trigger, setError } = methods;
    const { control, setValue, watch, handleSubmit } = methods;
    const tip = watch('tip');
    const isPaymentMethodAvailable = (type: string) => paymentArray.payments.some((payment: IPaymentMethod) => payment.type === type);
    const updateError = (type: any, status: boolean, message: string = '') => {
        setErrors((currentErrors) => ({
            ...currentErrors,
            [type]: {
                status: status,
                message: message,
            },
        }));
    };

    const remainingPointsFun = (tipAmount = tip) => {
        if (shopSettingInfo === undefined) return;
        // const loyaltyPointValue = +(shopSettingInfo.spend.amount / shopSettingInfo.spend.point).toFixed(3);
        const loyaltyPointValue = +(loyalPointAmount / bookingInfo.user_info.total_loyalty_points).toFixed(3);

        const remainingPointsCount = Math.floor((loyalPointAmount - totalChargableAmount - tipAmount) / +loyaltyPointValue);
        setRemainingPoints(remainingPointsCount);
        setRemainingChargedAmount(0);
    };
    // const handleBack = () => {
    //     dispatch(setClearPayment());
    //     dispatch(setCalendarStep('checkoutAppointment'));
    // };

    useEffect(() => {
        paymentArray.payments.some((payment: IPaymentMethod) => payment.type === 'loyalty') && shopSettingInfo !== undefined && remainingPointsFun();
    }, []);

    const generateSequence = (start: number, no: number = 2, increment: number = 10) => {
        if (start === 0) {
            setTotalOptions([]);
            return;
        }
        const ceilAmount = +start;
        const nextDivisibleBy10 = Math.ceil(+ceilAmount / 10) * 10;
        const secondValue = nextDivisibleBy10 === ceilAmount ? +ceilAmount + 10 : +nextDivisibleBy10;
        const totalSequence = [parseFloat(ceilAmount.toFixed(2)), parseFloat(secondValue.toFixed(2)), parseFloat((secondValue + 10).toFixed(2))];
        setTotalOptions(totalSequence);
    };
    useEffect(() => {
        if (!isPaymentMethodAvailable('loyalty')) {
            const total = +(isTaxable ? appointmentCalulation.total : appointmentCalulation.total - appointmentCalulation.taxes_and_fees);
            generateSequence(+total);
            setTotalChargableAmount(total);
            const collectedAmount = paymentArray.payments.reduce((sum: number, payment: { amount: number }) => sum + payment.amount, 0);
            const remainingAmount = Number(Number(total - collectedAmount).toFixed(2));
            setRemainingChargedAmount(remainingAmount <= 0 ? 0 : remainingAmount);
            setReturnAmount(remainingAmount <= 0 ? Math.abs(remainingAmount) : 0);
        } else {
            const total = +(isTaxable ? appointmentCalulation.total : appointmentCalulation.total - appointmentCalulation.taxes_and_fees);
            setTotalChargableAmount(total);
        }
    }, [isTaxable, appointmentCalulation]);
    useEffect(() => {
        if (!isPaymentMethodAvailable('loyalty')) {
            const total = appointmentCalulation.payment_option === 'pay_now' ? 0 : +(isTaxable ? appointmentCalulation.total : appointmentCalulation.total - appointmentCalulation.taxes_and_fees);
            generateSequence(+total + +tip);
        }
    }, [tip]);

    useEffect(() => {
        if (isPaymentMethodAvailable('loyalty') && shopSettingInfo !== undefined) {
            remainingPointsFun();
        }
    }, [totalChargableAmount, isTaxable, appointmentCalulation, tip]);

    const getPaymentMethods = async (type?: any, value?: number) => {
        let paymentMethods: any = paymentArray;
        if (type !== undefined && value !== undefined) {
            paymentMethods = paymentArray.payments.map((payment: { type: string }) => (payment.type === type ? { ...payment, amount: +value } : payment));
        }
        const collectedAmount = paymentMethods.reduce((sum: number, payment: { amount: number }) => sum + +payment.amount, 0);
        const remainingAmount = Number(Number(+totalChargableAmount - +collectedAmount).toFixed(2));
        return {
            paymentMethods: paymentMethods,
            remainingAmount: remainingAmount,
        };
    };

    const handleReturnAmountCalculation = async (event: any) => {
        const value = Number(event.target.value);
        const paymentMethodsInfo = await getPaymentMethods();
        if (paymentMethodsInfo.remainingAmount <= 0) {
            let maxReturnAmount = Number(Number(Math.abs(paymentMethodsInfo.remainingAmount)).toFixed(2));
            const cashAmount = +paymentArray.find((payment: IPaymentMethod) => payment.type === 'cash').amount;

            if (maxReturnAmount < value || cashAmount < value) {
                const updateMaxReturnAmount = maxReturnAmount > cashAmount ? cashAmount : maxReturnAmount;
                updateError('returnAmount', true, `Please do not exceed the maximum return amount of $${updateMaxReturnAmount}.`);
            } else {
                updateError('returnAmount', false);
            }
            const updatedTipAmount = +Number(Number(maxReturnAmount - value).toFixed(2));
            // setTip(updatedTipAmount >= 0 ? updatedTipAmount : 0);
            setValue('tip', updatedTipAmount >= 0 ? updatedTipAmount : 0);
            // dispatch(setPayment({ tip: updatedTipAmount >= 0 ? updatedTipAmount : 0 }));
            // setPaymentArray({ tip: updatedTipAmount >= 0 ? updatedTipAmount : 0 });
            setPaymentArray((prevState: IPaymentState) => ({
                ...prevState,
                tip: updatedTipAmount >= 0 ? updatedTipAmount : 0,
            }));

            setReturnAmount(+value.toFixed(2));
        }
    };

    const setTipReturnAmount = async (paymentMethods?: any) => {
        const paymentMethodsInfo = (await paymentMethods) ?? (await getPaymentMethods());
        const remainingAmountWithTip = Number(Number(+tip + +paymentMethodsInfo.remainingAmount).toFixed(2));

        if (!paymentMethodsInfo.paymentMethods.some((payment: IPaymentState) => payment.type === 'cash')) {
            if (remainingAmountWithTip < 0) {
                // setTip(Number(Math.abs(remainingAmountWithTip)));
                setValue('tip', +Math.abs(paymentMethodsInfo.remainingAmount));
                // dispatch(setPayment({ tip: +Math.abs(paymentMethodsInfo.remainingAmount) }));
                // setPaymentArray({ tip: +Math.abs(paymentMethodsInfo.remainingAmount) });
                setPaymentArray((prevState: IPaymentState) => ({
                    ...prevState,
                    tip: +Math.abs(paymentMethodsInfo.remainingAmount),
                }));

                setReturnAmount(0);
                setRemainingChargedAmount(0);
            } else {
                setRemainingChargedAmount(+(remainingAmountWithTip < 0 ? 0 : remainingAmountWithTip).toFixed(2));
            }
        }
        if (paymentMethodsInfo.paymentMethods.some((payment: IPaymentState) => payment.type === 'cash')) {
            if (remainingAmountWithTip < 0) {
                const remainingChargeAmountCalculation = Math.abs(remainingAmountWithTip);
                const cashAmount = paymentMethodsInfo.paymentMethods.find((payment: IPaymentMethod) => payment.type === 'cash').amount;

                if (remainingChargeAmountCalculation > cashAmount) {
                    setReturnAmount(+Math.abs(cashAmount).toFixed(2));
                    // setTip(remainingChargeAmountCalculation - cashAmount);
                    setValue('tip', +(remainingChargeAmountCalculation - cashAmount));
                    // dispatch(setPayment({ tip: +(remainingChargeAmountCalculation - cashAmount) }));
                    // setPaymentArray({ tip: +(remainingChargeAmountCalculation - cashAmount) });
                    setPaymentArray((prevState: IPaymentState) => ({
                        ...prevState,
                        tip: +(remainingChargeAmountCalculation - cashAmount),
                    }));
                } else {
                    setReturnAmount(+Math.abs(remainingChargeAmountCalculation).toFixed(2));
                }
                setRemainingChargedAmount(0);
            } else {
                setRemainingChargedAmount(+(remainingAmountWithTip < 0 ? 0 : remainingAmountWithTip).toFixed(2));
                setReturnAmount(0);
            }
        }
    };
    const handlePaymentInput = (type: any, value: number) => async () => {
        setValue(type, value);
        const paymentMethodsInfo = await getPaymentMethods(type, value);

        setTipReturnAmount(paymentMethodsInfo);
        // setPaymentArray(paymentMethodsInfo.paymentMethods);
        // setPaymentArray({ payments: paymentMethodsInfo.paymentMethods });
        setPaymentArray((prevState: IPaymentState) => ({
            ...prevState,
            payments: paymentMethodsInfo.paymentMethods,
        }));

        // dispatch(setPayment({ payments: paymentMethodsInfo.paymentMethods }));
    };

    const handleBlurInput = (event: any) => {
        const value = event.target.value;
        const name = event.target.name;
        if (!value) {
            if (name === 'tip') {
                setValue('tip', 0);
                // dispatch(setPayment({ tip: 0 }));
                // setPaymentArray({ tip: 0 });
                setPaymentArray((prevState: IPaymentState) => ({
                    ...prevState,
                    tip: 0,
                }));
            }
            name === 'cash' || name === 'stripe' || (name === 'pos' && handlePaymentInput(name, 0)());
        }
    };
    const handleRadioChange = (method: string) => () => {
        setSelectedMethod((currentMethod) => (currentMethod === method ? null : method));
    };
    const handleTipWithoutCalculation =
        (value: any, isFixed: boolean = false) =>
        () => {
            let tipAmount = value;

            if (isFixed && selectedLocation && selectedLocation.tips_type !== 'value') {
                tipAmount = Number(((Number(appointmentCalulation.service) * Number(tipAmount)) / 100).toFixed(2));
            }

            if (tipAmount >= 0) {
                const collectedAmount = paymentArray.payments.reduce((sum: number, payment: { amount: number }) => sum + payment.amount, 0);
                const total = +(isTaxable ? appointmentCalulation.total : appointmentCalulation.total - appointmentCalulation.taxes_and_fees);
                let remainingAmount = Number(Number(+total + -+collectedAmount).toFixed(2));
                if (!isPaymentMethodAvailable('cash') && !isPaymentMethodAvailable('loyalty')) {
                    if (remainingAmount < 0 && Math.abs(remainingAmount) > tipAmount) {
                        updateError('tip', true, `Please enter a minimum tip of $${Math.abs(remainingAmount)}`);
                    } else {
                        updateError('tip', false);
                        remainingAmount = +(+remainingAmount + +tipAmount).toFixed(2);
                        setRemainingChargedAmount(+remainingAmount.toFixed(2));
                    }
                }
                remainingAmount = +remainingAmount + +tipAmount;
                if (isPaymentMethodAvailable('cash')) {
                    if (remainingAmount < 0) {
                        let maxReturnAmount = Math.abs(remainingAmount);
                        const cashAmount = paymentArray.payments.filter((payment: IPaymentMethod) => payment.type === 'cash')[0].amount;
                        const updatedMaxReturnAmount = maxReturnAmount - cashAmount;
                        if (updatedMaxReturnAmount > tipAmount) {
                            updateError('tip', true, `Please enter a minimum tip of $${Math.abs(updatedMaxReturnAmount)}`);
                        } else {
                            updateError('tip', false);
                        }
                        setReturnAmount(+maxReturnAmount.toFixed(2));
                        setRemainingChargedAmount(0);
                    } else {
                        setRemainingChargedAmount(+Math.abs(remainingAmount).toFixed(2));
                        setReturnAmount(0);
                    }
                }

                // setReturnAmount(updatedTipAmount >= 0 ? updatedTipAmount : 0);
                if (isPaymentMethodAvailable('loyalty')) {
                    const maxTipAmount = Math.round(+loyalPointAmount - totalChargableAmount);
                    setRemainingChargedAmount(0);
                    if (maxTipAmount < tipAmount) {
                        updateError('tip', true, `You can enter a maximum tip of $${Math.abs(maxTipAmount)}`);
                    } else {
                        updateError('tip', false);
                    }
                }
                setValue('tip', tipAmount);
                // setPaymentArray({ tip: tipAmount });
                setPaymentArray((prevState: IPaymentState) => ({
                    ...prevState,
                    tip: tipAmount,
                }));

                // dispatch(setPayment({ tip: tipAmount }));
            }
        };

    const handleTaxAndFees = () => {
        setIsTaxable(!isTaxable);
    };

    const handleCompletePayment = (): boolean => {
        const areAllStatusesFalse = Object.values(errors).every((error) => error.status === false);
        const allPaymentsGreaterThanZero = paymentArray.payments.every((payment: IPaymentMethod) => payment.amount > 0);

        if (isLoading) {
            return true;
        }

        if (isPaymentMethodAvailable('other')) {
            if (areAllStatusesFalse && selectedMethod && remainingChargeAmount === 0) {
                return false;
            }
            return true;
        }
        if ((remainingChargeAmount === 0 || isPaymentMethodAvailable('loyalty')) && areAllStatusesFalse && allPaymentsGreaterThanZero) {
            return false;
        }
        return true;
    };
    useEffect(() => {
        handleCompletePayment();
    }, [paymentArray]);

    const handleSubmit1 = async (data: any) => {
        const isValid = !handleCompletePayment();
        setIsLoading(false);
        // handlePaymentStatus('processing');

        if (isValid) {
            // let API_URL = API.BOOKING.COMPLETE;
            let payload: any = {};

            // if (calendarData.bookedSlotInfo.payment_option !== 'pay_now') {
            let paymentInfo: any = paymentArray.payments.map((payment: IPaymentMethod) => {
                if (payment.type === 'cash' && payment.amount - returnAmount - tip > 0) {
                    return { ...payment, amount: +(payment.amount - returnAmount - tip).toFixed(2) };
                } else if (payment.type === 'cash') {
                    return null; // Mark for exclusion
                } else if (payment.type === 'loyalty') {
                    return { ...payment, amount: (+totalChargableAmount).toFixed(2), type: 'loyalty' };
                } else {
                    return paymentArray.payments.length === 1
                        ? { ...payment, amount: parseFloat((+payment.amount - +tip).toFixed(2)) }
                        : { ...payment, amount: parseFloat((+payment.amount).toFixed(2)) };
                }
            });
            const products = dataService.products.map((product: any) => ({
                id: product.value,
                quantity: 1,
            }));
            const services = dataService.services.map((service: any) => ({
                id: service.id,
                quantity: 1,
                price: service.price,
                duration: service.duration,
            }));
            payload = {
                ...payload,
                payment_method: paymentInfo,
                services: services,
                products: products,
                staff_id: dataService.staff_id,
                user_id: dataService.user_id,
                is_taxable: isTaxable,
                shop_location_id: calendarData.selectedLocation.id,
            };

            if (paymentArray.tip) {
                payload = {
                    ...payload,
                    tip: paymentArray.tip,
                };
            }
            if (selectedMethod && isPaymentMethodAvailable('other')) {
                payload = {
                    ...payload,
                    extra_payment_method: selectedMethod,
                };
            }

            await axiosPost(API.BOOKING.CHECKOUT, payload, {
                shop_id: shopId,
            })
                .then(async () => {
                    handlePaymentStatus('success');
                })
                .catch((err: any) => {
                    handlePaymentStatus('retry');
                    toast.error(err?.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        setIsLoading(false);

        return;
    };

    // const handleContinueOrBack = async () => {
    //     const info = await getBookingInfo();
    //     const updateState = {
    //         bookedSlotInfo: info,
    //         getBookingList: true,
    //     };
    //     await dispatch(setData(updateState));
    //     await dispatch(setCalendarStep('bookedAppointment'));
    // };
    const handleRetry = async () => {
        handleSubmit(handleSubmit1);
    };

    const isAllPaymentStatusFalse = (): boolean => Object.values(paymentStatus).every((value) => value === false);

    return !isAllPaymentStatusFalse() ? (
        <AppointmentStatus paymentStatus={paymentStatus} handleContinueOrBack={() => {}} handleRetry={handleRetry} />
    ) : (
        <>
            <div className="p-4 flex items-center border-b gap-3">
                {/* <div className="close-btn cursor-pointer" onClick={handleBack}>
                    <ArrowLeft className="w-[18px] text-gray-700" />
                </div> */}

                <div className="flex flex-col">
                    <span className="text-base font-semibold leading-6 text-gray-900">New Checkout</span>
                    {/* <span className="text-gray-600 text-xs font-medium leading-[18px]"> Appointment #{calendarData.bookedSlotInfo.receipt_code}</span> */}
                </div>
            </div>
            <div className={`p-4 flex-1 overflow-y-auto appearance-switch-btn ${className}`}>
                <div className="flex justify-between mb-[10px] pb-[10px] border-b border-gray-200">
                    <div className="flex flex-row gap-2">
                        <p className="text-xs font-medium leading-[18px] text-gray-900">Service</p>
                        {/* {calendarData.bookedSlotInfo.payment_option === 'pay_now' && (
                            <span className="h-[22px] ml-2.5 flex items-center border border-success-200 bg-success-50 rounded-md gap-1 py-0.5 pr-1.5 pl-1 text-success-700 text-xs font-medium leading-[18px]">
                                <Check className="w-[14px] h-[14px] text-success-600" /> Paid
                            </span>
                        )} */}
                    </div>
                    <p className="ml-auto text-gray-600 font-semibold text-xs leading-[18px]">${(appointmentCalulation.service || 0).toFixed(2)}</p>
                </div>
                {appointmentCalulation.product > 0 && (
                    <div className="flex justify-between mb-[10px] pb-[10px] border-b border-gray-200">
                        <div className="flex flex-row gap-2">
                            <p className="text-xs font-medium leading-[18px] text-gray-900">Product</p>
                            {/* {calendarData.bookedSlotInfo.payment_option === 'pay_now' && (
                            <span className="h-[22px] ml-2.5 flex items-center border border-success-200 bg-success-50 rounded-md gap-1 py-0.5 pr-1.5 pl-1 text-success-700 text-xs font-medium leading-[18px]">
                                <Check className="w-[14px] h-[14px] text-success-600" /> Paid
                            </span>
                        )} */}
                        </div>
                        <p className="ml-auto text-gray-600 font-semibold text-xs leading-[18px]">${appointmentCalulation.product}</p>
                    </div>
                )}
                <div className="flex justify-between mb-[10px] pb-[10px] border-b border-gray-200">
                    <div className="flex flex-row gap-2">
                        <p className="text-xs font-medium leading-[18px] text-gray-900">Subtotal</p>
                        {/* {calendarData.bookedSlotInfo.payment_option === 'pay_now' && (
                            <span className="h-[22px] ml-2.5 flex items-center border border-success-200 bg-success-50 rounded-md gap-1 py-0.5 pr-1.5 pl-1 text-success-700 text-xs font-medium leading-[18px]">
                                <Check className="w-[14px] h-[14px] text-success-600" /> Paid
                            </span>
                        )} */}
                    </div>
                    <p className="ml-auto text-gray-600 font-semibold text-xs leading-[18px]">${appointmentCalulation.subtotal}</p>
                </div>
                <div className="flex justify-between mb-[12px] pb-[10px] border-b border-gray-200">
                    <div className="flex flex-row gap-2">
                        <p className="text-xs font-medium leading-[18px] text-gray-900">Tax and Fees</p>

                        <div className="mt-[1px]">
                            <Switch isChecked={isTaxable} onChange={handleTaxAndFees} className="mt-2" />
                        </div>
                    </div>
                    <p className="ml-auto text-gray-600 font-semibold text-xs leading-[18px]">${appointmentCalulation.taxes_and_fees}</p>
                </div>
                {tip > 0 && (
                    <div className="flex justify-between mb-[12px] pb-[10px] border-b border-gray-200">
                        <div className="flex flex-row gap-2">
                            <p className="text-xs font-medium leading-[18px] text-gray-900">Tip</p>
                        </div>
                        <p className="ml-auto text-gray-600 font-semibold text-xs leading-[18px]">${tip}</p>
                    </div>
                )}

                <div className="flex justify-between mb-6 pb-3 border-b border-gray-200">
                    <p className="text-gray-900 font-bold text-sm leading-5">Total</p>
                    <p className="ml-auto text-gray-900 font-bold text-sm leading-5">${parseFloat((+totalChargableAmount + +tip).toFixed(2))}</p>
                </div>

                <div className="flex flex-col">
                    {paymentArray.payments.map((payment: IPaymentMethod, index: number) => {
                        if (payment.type === 'cash') {
                            return (
                                <CashPayment
                                    totalOptions={totalOptions}
                                    handlePaymentInput={handlePaymentInput}
                                    handleTipWithoutCalculation={handleTipWithoutCalculation}
                                    handleReturnAmountCalculation={handleReturnAmountCalculation}
                                    handleBlurInput={handleBlurInput}
                                    errors={errors}
                                    returnAmount={returnAmount}
                                    control={control}
                                    key={index}
                                    payment={payment}
                                />
                            );
                        } else if (payment.type === 'pos') {
                            return (
                                <PosPayment
                                    handlePaymentInput={handlePaymentInput}
                                    handleTipWithoutCalculation={handleTipWithoutCalculation}
                                    handleBlurInput={handleBlurInput}
                                    errors={errors}
                                    control={control}
                                    key={index}
                                    paymentArray={paymentArray}
                                />
                            );
                        } else if (payment.type === 'loyalty' && shopSettingInfo !== undefined) {
                            return (
                                <LoyaltyPayment
                                    handleTipWithoutCalculation={handleTipWithoutCalculation}
                                    errors={errors}
                                    control={control}
                                    remainingPoints={remainingPoints}
                                    key={index}
                                    loyalPointAmount={loyalPointAmount}
                                />
                            );
                        } else if (payment.type === 'stripe') {
                            return (
                                <CardPayment
                                    handlePaymentInput={handlePaymentInput}
                                    handleTipWithoutCalculation={handleTipWithoutCalculation}
                                    handleBlurInput={handleBlurInput}
                                    errors={errors}
                                    control={control}
                                    key={index}
                                    cardList={cardList}
                                    paymentArray={paymentArray}
                                />
                            );
                        } else if (payment.type === 'other') {
                            return (
                                <OtherPayment
                                    handlePaymentInput={handlePaymentInput}
                                    handleTipWithoutCalculation={handleTipWithoutCalculation}
                                    handleBlurInput={handleBlurInput}
                                    errors={errors}
                                    methods={methods}
                                    handleRadioChange={handleRadioChange}
                                    selectedMethod={selectedMethod}
                                    key={index}
                                    paymentArray={paymentArray}
                                />
                            );
                        }
                    })}

                    {remainingChargeAmount > 0 && <RemainingCharge returnAmount={remainingChargeAmount} />}
                </div>
            </div>
            <div className="border-t px-4 py-4 w-full">
                <CustomButton disabled={handleCompletePayment()} isLoading={isLoading} primary onClick={handleSubmit(handleSubmit1)} type="button" className="w-full">
                    Complete Payment
                </CustomButton>
            </div>
        </>
    );
};
export default Payment;
