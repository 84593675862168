import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { currentShop, me } from 'src/redux/services/common/Common.slice';
import { Controller, useFormContext } from 'react-hook-form';
import InputWithLabel from 'src/components/InputWithLabel';
import { convertBase64ToFile } from 'src/utils/global-functions';
import CropperModal from 'src/components/CropperModal/CropperModal';
import { useTranslation } from 'react-i18next';
import { RightGreen, Url } from 'src/theme/Images';
import { UploadCloud02 } from '@untitled-ui/icons-react/build/cjs';
import CustomButton from 'src/components/CustomButton';
import { API } from 'src/constants/api';
import { axiosPost } from 'src/utils/requestClient';
import { toast } from 'react-toastify';
import QRCode from 'react-qr-code';

const BusinessInfo = ({ errors, setFile, cropData, setCropData }: any) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);

    const { watch, control, setValue } = useFormContext();
    const slugValue = watch('slug');
    const [image, setImage] = useState<any>('');
    const [upload, setUpload] = useState(false);
    const [publishLoading, setPublishLoading] = useState(false);

    const imageUpload = (data: any) => {
        const appLogoImage = data;
        const fileInfo = convertBase64ToFile(appLogoImage);
        setFile(fileInfo);
        if (fileInfo && fileInfo.filename) {
            setValue('logo_image_name', fileInfo.filename);
        }
    };
    useEffect(() => {
        setImage(shop.logo_image_url);
    }, [shop]);

    const onChangeCrop = (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onloadstart = () => {
            setImage('');
            setUpload(false);
        };
        reader.onloadend = () => {
            setImage(reader.result as any);
            setUpload(true);
        };
        reader.readAsDataURL(files[0]);
        e.target.value = null;
    };

    const handlepublished = () => {
        setPublishLoading(true);
        axiosPost(API.THEME.SLUG, { is_slug_active: !shop.is_slug_active }, { shop_id: shop.id })
            .then(async () => {
                await dispatch(me());
            })
            .catch((error: any) => toast.error(error?.message))
            .finally(() => setPublishLoading(false));
    };

    const onImageDownload = (Id: string) => {
        const svg = document.getElementById(Id);

        if (svg) {
            const svgData = new XMLSerializer().serializeToString(svg);
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            if (ctx) {
                const img = new Image();

                img.onload = () => {
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
                    const pngFile = canvas.toDataURL('image/png');
                    const downloadLink = document.createElement('a');
                    downloadLink.download = Id;
                    downloadLink.href = `${pngFile}`;
                    downloadLink.click();
                };

                img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
            } else {
                console.error('2D context not available');
            }
        } else {
            console.error('SVG element not found');
        }
    };

    return (
        <>
            <div className="w-full flex flex-row gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Brand Name')}</p>
                </div>
                <div className="flex flex-row max-2xl:w-[400px] w-[512px] gap-4">
                    <Controller
                        name="business_name"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="w-[512px]">
                                <InputWithLabel name="business_name" placeholder={t('Business name')} value={value} onChange={onChange} error={!!error} />
                            </div>
                        )}
                    />
                </div>
            </div>

            <div className="w-full flex flex-row gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]  flex flex-col">
                    <p className="title-text ">{t('Brand Logo')}</p>
                    <p className="subtitle-text">{t('This will be displayed on your profile.')}</p>
                </div>
                <div className="flex flex-row max-2xl:w-[400px] w-[512px] gap-4 items-start">
                    {cropData && <img src={cropData} alt="avatar" className="w-[56px] min-w-[56px] max-w-[56px] h-[56px] min-h-[56px] border-[0.75px] border-black/[0.08] rounded-full object-cover" />}
                    <div
                        className={`  w-[428px] h-[112px] rounded-xl border border-[#d0d5dd] hover:shadow cursor-pointer flex-auto items-center justify-center py-3.5 px-5 ${
                            errors.profile_image_name ? 'is-invalid' : ''
                        }`}
                    >
                        <div className="relative overflow-hidden cursor-pointer">
                            <input type="file" id="profile_image_name" className="upload-pic-input" onChange={onChangeCrop} accept="image/png, image/jpg, image/jpeg" />
                            <label htmlFor="profile_image_name" className=" cursor-pointer   text-center ">
                                {''}

                                <div className="mx-auto mb-3 w-9 h-9 border border-[#EAECF0] bg-white rounded-lg flex items-center justify-center shadow">
                                    {/* <img src={AddImgIcon} alt="" className="mb-2 w-10 h-10 inline-block" /> */}
                                    <UploadCloud02 width={20} height={20} />
                                </div>
                                <div className=" text-secondaryTxtColor text-xs font-normal">
                                    <p>
                                        <span className="text-[#2422DD] font-semibold pr-1">Click to upload </span>or drag and drop
                                    </p>
                                    <p className="text-[11px] leading-[18px]">SVG, PNG or JPG (max. 800x400px)</p>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px] min-w-[310px]">
                    <p className="title-text">{t('Booking URL')}</p>
                </div>
                <div className="flex flex-row w-full justify-between gap-4">
                    <div className="max-2xl:w-[400px] w-[512px]">
                        <Controller
                            name={`slug`}
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                <div className="data-wrap-block max-w-[512px] gap-4 flex xxl:max-w-[700px]">
                                    <div className="w-full max-w-[512px]">
                                        <InputWithLabel
                                            labelIcon={<Url />}
                                            inputControlClassName="pl-0"
                                            labelEndIcon={
                                                <div className="flex text-xs leading-[18px] font-semibold text-[#344054] flex-row gap-1 items-center">
                                                    {'.getflair.ca'}
                                                    <RightGreen />
                                                </div>
                                            }
                                            onChange={onChange}
                                            value={value}
                                            name="slug"
                                            error={!!error}
                                        />
                                    </div>
                                </div>
                            )}
                        />
                    </div>
                    <CustomButton outlinePrimary isLoading={publishLoading} disabled={publishLoading} onClick={handlepublished} className="h-[36px]">
                        {shop.is_slug_active ? 'Unpublished' : 'Published'}
                    </CustomButton>
                </div>
            </div>

            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className="w-[310px] min-w-[310px]">
                    <p className="title-text">{t('Hyperlinks')}</p>
                    <p className="subtitle-text">{t('Lorem ipsum dolor sit amet, consectetur.')}</p>
                </div>
                <div className="flex items-center justify-between gap-5 w-full">
                    {slugValue && <QRCode className="min-w-[120px]  w-[120px] h-[120px] inline-block object-cover" value={slugValue} level="H" id="WebQRCode" />}
                    <CustomButton outlinePrimary onClick={() => onImageDownload('WebQRCode')} className=" h-[36px]">
                        Download
                    </CustomButton>
                </div>
            </div>
            {upload && <CropperModal defaultCropType="Rounded" imageUrl={image} imageUpload={imageUpload} setUpload={setUpload} setCropData={setCropData} />}
        </>
    );
};

export default BusinessInfo;
