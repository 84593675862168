import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from 'src/components/PageHeader';
import ClientTable from './ClientTable';

const Client = () => {
    const { t } = useTranslation();

    return (
        <div className="inner-page-wrape pb-5">
            <PageHeader title={t('Clients')} subtitle={t('Manage your client and their account permissions here.')}></PageHeader>
            <div className="side-spaching flex flex-1 flex-col w-full">
                <ClientTable />
            </div>
        </div>
    );
};

export default Client;
