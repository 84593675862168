import React, { useState } from 'react';
import moment from 'moment';
import { Box } from '@untitled-ui/icons-react/build/cjs';
import { useTranslation } from 'react-i18next';
import EmptyMsgWithBtn from 'src/components/EmptyMsgWithBtn';
import NoDataMessage from 'src/components/NoDataMessage';
import { usePhone } from 'src/hooks/usePhone';
import { mapLocationPin } from 'src/theme/Images';

import { capitalizeFirstLetterAndUnderscore, formatDate, formatName, getShortName, getStatusClass } from 'src/utils/global-functions';

const LatestBookingActivity = ({ handleViewClick, latestData, isLoading }: any) => {
    const CreateBooking = () => {};
    const { t } = useTranslation();
    const { getCustomFormatPhone } = usePhone();
    const [isImageError, setIsImageError] = useState<boolean>(false);

    return (
        <div className="bg-white  h-auto mt-3 xxl:mt-4">
            <div className="flex items-center mb-[14px]">
                <h3 className="text-sm font-medium text-secondaryTxtColor">{t('Latest Booking Activity')}</h3>
                {/* <button type="button" onClick={handleViewClick('latestBookingActivity')} className="text-primary ml-auto -tracking-[0.3] font-medium">
                    {t('View all')}
                </button> */}
            </div>
            {isLoading ? (
                Array(8)
                    .fill(null)
                    .map((item: any, index: number) => (
                        <div key={index} className="py-2.5 flex justify-between items-center ">
                            <div className="flex items-center ">
                                <figure className="w-[40px] h-[40px] basis-[40px] grow-0 shrink-0 rounded-full overflow-hidden mr-3 flex text-xs font-medium justify-center items-center custom-loading ">
                                    12
                                </figure>
                                <div className="">
                                    <p className="text-xs font-medium mb-[2px] text-mainTextColor leading-[18px] -tracking-[0.12px] custom-loading max-w-[70px] max-2xl:max-w-[50px]">loading</p>
                                    <p className="text-xs font-normal leading-[18px] -tracking-[0.12px] text-secondaryTxtColor custom-loading max-2xl:max-w-[70px]">loadingloadingloading</p>
                                </div>
                            </div>
                            <p className="text-xs border border-borderSecondary bg-[#F9FAFB] font-medium leading-[150%] text-mainTextColor -tracking-[0.12px]  px-2 py-[2px] rounded-full custom-loading ">
                                {' '}
                                booking date
                            </p>
                        </div>
                    ))
            ) : latestData.length > 0 ? (
                <div className="">
                    {latestData.map((item: any) => (
                        <div key={item.id} className="py-2.5 flex justify-between items-center">
                            <div className="flex items-center">
                                <figure className="w-[40px] h-[40px] basis-[40px] grow-0 shrink-0 rounded-full overflow-hidden mr-3 flex text-xs font-medium justify-center items-center ">
                                    {item.staff.profile_image_url ? (
                                        <img src={item.staff.profile_image_url} alt="client" className="w-full h-full object-cover" />
                                    ) : item.staff.full_name ? (
                                        getShortName(`${item.staff.full_name}`)
                                    ) : (
                                        '+'
                                    )}
                                </figure>
                                <div className="">
                                    <p className="text-xs font-medium mb-[2px] text-mainTextColor leading-[18px] -tracking-[0.12px] max-2xl:max-w-[50px] truncate">
                                        {item.staff.full_name ? `${formatName(item.staff.full_name)}` : `${getCustomFormatPhone(item.staff.phone, item.staff.phone_country_code)}`}
                                    </p>
                                    <p className="text-xs font-normal leading-[18px] -tracking-[0.12px] text-secondaryTxtColor max-2xl:max-w-[70px] truncate">
                                        {formatDate(`${item.booking_date}' ' ${item.booking_start_time}`)}
                                    </p>
                                </div>
                            </div>
                            <p className="text-xs border border-borderSecondary bg-[#F9FAFB] font-medium leading-[150%] text-mainTextColor -tracking-[0.12px]  px-2 py-[2px] rounded-full">
                                {moment(`${item.booking_date}' ' ${item.booking_start_time}`, 'YYYY-MM-DD HH:mm:ss').fromNow()}
                            </p>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="h-[500px] flex justify-center items-center">
                    <NoDataMessage title={t('No latest booking activity')} description={t('No latest booking activity available')} iconComponent={<Box className="text-gray-700 " />} />
                </div>
            )}
        </div>
    );
};

export default LatestBookingActivity;
