import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import CustomButton from 'src/components/CustomButton';
import ServiceTable from './ServiceTable';
import PageHeader from 'src/components/PageHeader';
import { Plus } from '@untitled-ui/icons-react/build/cjs';
import { currentRole } from 'src/redux/services/common/Common.slice';
import { useAppSelector } from 'src/redux/hooks';
import { ROLES } from 'src/constants/common';
import StaffServiceTable from './StaffServiceTable';
import ShopServiceTable from './ShopServiceTable';
import { useRolePermission } from 'src/hooks/useRolePermission';

const Services = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPermission } = useRolePermission();
    const role = useAppSelector(currentRole);
    const [activeTab, setActiveTab] = useState('staff');

    const handleClick = (path: string) => () => {
        navigate(path);
    };

    const handleTabClick = (tab: string) => async (event: { preventDefault: () => void }) => {
        event.preventDefault();
        setActiveTab(tab);
    };

    const tabs = useMemo(
        () => [
            {
                name: 'My services',
                type: 'staff',
            },
            {
                name: 'Brand services',
                type: 'shop',
            },
        ],
        [],
    );

    return (
        <div className="inner-page-wrape pb-5">
            <PageHeader title={t('Services')} subtitle={t('Easily view, add, and manage all the services your brand offers.')}>
                {/* {hasPermission('service_add') && (
                    <>
                        <CustomButton secondary onClick={handleClick(ROUTES.CATEGORY.CREATE)} icon={<Plus width="16" />} className="!px-4 py-[9px] rounded-lg shadow-InputAndButton">
                            {t('Add new category')}
                        </CustomButton>
                        <CustomButton primary onClick={handleClick(ROUTES.SERVICES.CREATE)} icon={<Plus width="16" />} className="!px-4 py-[9px] rounded-lg shadow-InputAndButton">
                            {t('Add new service')}
                        </CustomButton>
                    </>
                )} */}
            </PageHeader>
            <div className="side-spaching flex flex-1 flex-col w-full">
                {role.name === ROLES.STAFF_COMMISSION || role.name === ROLES.STAFF_RENT ? (
                    <div className="mt-1 flex flex-col flex-1">
                        <div className="fl-tab-btn-view w-full">
                            {tabs.map((tab, index) => (
                                <button key={index} type="button" className={`fl-tab-link !max-w-[182px] ${activeTab === tab.type ? 'active' : ''}`} onClick={handleTabClick(tab.type)}>
                                    {tab.name}
                                </button>
                            ))}
                        </div>
                        {activeTab === 'staff' && <StaffServiceTable />}
                        {activeTab === 'shop' && <ShopServiceTable setActiveTab={setActiveTab} />}
                    </div>
                ) : (
                    <ServiceTable />
                )}
            </div>
        </div>
    );
};

export default Services;
