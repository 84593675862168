import React, { useEffect, useRef, useState } from 'react';
import { IColor, IColorPicker } from './ColorPicker.interface';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';
import { IconCheckdActive } from 'src/theme/Images';

const ColorPicker: React.FC<IColorPicker> = ({ setOneColor, customBranding, error, className }) => {
    const [color, setColor] = useState<IColor>({ hex: setOneColor || '#5e72e4' });
    const colorPickerRef = useRef<HTMLDivElement>(null);
    const [showPicker, setShowPicker] = useState(false);

    const handleClickOutside = (event: MouseEvent) => {
        if (showPicker && colorPickerRef.current && !colorPickerRef.current.contains(event.target as Node)) {
            setShowPicker(false);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showPicker]);

    const handleColorChange = (Color: IColor) => {
        setColor({ hex: Color.hex.toUpperCase() });
        customBranding({ hex: Color.hex.toUpperCase() });
    };

    const onClick = () => {
        setShowPicker(!showPicker);
    };

    const styles = reactCSS({
        default: {
            color: {
                padding: 0,
                height: '1.75rem',
                width: '1.75rem',
                borderRadius: '24px',
                overflow: 'hidden',
                borderColor: '#F0F0F0',
                background: setOneColor || color.hex,
            },
            popover: {
                position: 'absolute',
                zIndex: '3',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
            swatch: {
                padding: '6px',
                background: '#ffffff',
                borderRadius: '2px',
                cursor: 'pointer',
                display: 'inline-block',
                boxShadow: '0 0 0 1px rgba(0,0,0,.2)',
            },
        },
    });
    return (
        <>
            <div className={` flex items-center space-x-4 relative ${className}`} onClick={onClick}>
                <div style={styles.color} className={`w-7 h-7 cursor-pointer items-center relative ${setOneColor && 'ring-2 ring-offset-2'} `} />
            </div>
            <div ref={colorPickerRef}>{showPicker && <SketchPicker color={setOneColor || color.hex} presetColors={[]} disableAlpha={true} onChange={handleColorChange} />}</div>

            <i className="icon absolute top-[-5px] right-[-7px]">
                <img src={IconCheckdActive} alt="" className="" />
            </i>
        </>
    );
};

export default ColorPicker;
