import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomButton';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { Download01, Box, Share05, ArrowUp, ArrowDown, UploadCloud02 } from '@untitled-ui/icons-react/build/cjs';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import NoDataMessage from 'src/components/NoDataMessage';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import moment from 'moment';
import { DATE_FORMAT } from 'src/constants/common';
import { Button } from 'primereact/button';
import { perPageOptions } from 'src/utils/global-variables';
import { Skeleton } from 'primereact/skeleton';
import { useParams } from 'react-router-dom';
import { GoDotFill } from 'react-icons/go';

const Transactions = ({ client }: any) => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isDownloading, setIsDownloading] = useState<any>({ receipt: { loading: false, id: null }, export: { loading: false } });
    const [transactionList, setTransactionList] = useState([]);
    const [totalTransaction, setTotalTransaction] = useState<number>(0);
    const [lazyState, setLazyState] = useState<any>({
        first: 0,
        rows: perPageOptions[0].value,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
        },
    });

    useEffect(() => {
        getTransactionList();
    }, [lazyState, shop]);

    const getTransactionList = async () => {
        setIsLoading(true);
        await axiosGet(API.CLIENT.TRANSACTION, { shop_id: shop.id, id: client?.id }, lazyState)
            .then((response) => {
                setTransactionList(response.data.data.data);
                setTotalTransaction(response.data.data.totalRecords);
            })
            .finally(() => setIsLoading(false));
    };

    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink  NextPageLink ',
        CurrentPageReport: ({ first, last, totalRecords }: { first: number; last: number; totalRecords: number }) => (
            <div className="text-gray-700 text-sm flex items-center font-medium leading-5 mr-auto">
                Page {first} of {totalRecords}
            </div>
        ),
        PrevPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
                {t('Previous')}
            </CustomButton>
        ),
        NextPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick}>
                {t('Next')}
            </CustomButton>
        ),
    };

    const onPageHandle = (event: any) => {
        setLazyState((old: any) => ({ ...old, rows: event.value }));
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const getStatusColor = (status: string) => {
        switch (status) {
            case 'stripe':
                return ' text-blueLight-700';
            case 'cash':
                return 'text-greenText';
            case 'pos':
                return ' text-[#F37744]';
            case 'loyalty':
                return ' text-blueLight-700';
            case 'other':
                return ' text-blueLight-700';
            default:
                return ' text-blueLight-700';
        }
    };

    const GetStatus = (row: any) => {
        const statusClass = getStatusColor(row.payment_method);

        return (
            <div className="flex items-center">
                <span
                    className={`px-[6px] pl-0.5 py-[2px] border flex shadow items-center justify-start rounded-md border-borderPrimary text-center text-[10px] leading-[14px] font-medium capitalize gap-0.5 w-max `}
                >
                    <GoDotFill size={12} className={`${statusClass}`} />
                    {row.payment_method === 'stripe' ? 'Online' : row.payment_method}
                </span>
            </div>
        );
    };
    const handleHistoryDownload = async () => {
        setIsDownloading((prev: any) => ({
            ...prev,
            export: { loading: true },
        }));

        await axiosGet(API.CLIENT.TRANSACTION_HISTORY, { shop_id: shop.id, id: id })
            .then(async (response) => {
                const exportResponse = response.data.data;
                const byteCharacters = atob(exportResponse);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {
                    type: 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet',
                });

                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `transaction_details_${client.full_name}_${moment().format('YYYY-MM-DD')}.xlsx`;

                document.body.appendChild(link);
                await link.click();

                document.body.removeChild(link);
                await URL.revokeObjectURL(url);
            })
            .finally(() =>
                setIsDownloading((prev: any) => ({
                    ...prev,
                    export: { loading: false },
                })),
            );
    };
    const handleReceiptDownload = (transactionData: any) => async () => {
        setIsDownloading((prev: any) => ({
            ...prev,
            receipt: { loading: true, id: transactionData.booking_id },
        }));
        await axiosGet(API.BOOKING.RECEIPT, { shop_id: shop.id, id: transactionData.booking_id })
            .then(async (response) => {
                const receiptResponse = response.data.data.receipt;
                const byteCharacters = atob(receiptResponse);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/pdf' });

                // Create download link
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${transactionData.booking.receipt_code}-appointment.pdf`;

                // Append the link to the body and trigger the click event
                document.body.appendChild(link);
                await link.click();

                // Clean up
                document.body.removeChild(link);
                await URL.revokeObjectURL(url);
            })
            .finally(() =>
                setIsDownloading((prev: any) => ({
                    ...prev,
                    receipt: { loading: false, id: null },
                })),
            );
    };

    const GetAction = (row: any) => {
        if (row.booking.status !== 'confirmed' && row.booking.status !== 'canceled') {
            return (
                <div className="flex items-center justify-center">
                    {/* <Eye className="w-5 text-gray-600 mr-3" /> */}
                    {isDownloading.receipt.loading && isDownloading.receipt.id ? (
                        row.booking_id === isDownloading.receipt.id ? (
                            <Button loading={isDownloading.receipt.loading} rounded text />
                        ) : (
                            <Button icon={<Download01 />} rounded className="w-5 h-5" />
                        )
                    ) : (
                        <Button icon={<Download01 />} onClick={handleReceiptDownload(row)} rounded className="w-5 h-5" />
                    )}
                </div>
            );
        }

        return null;
    };

    const GetItem = (row: any) => {
        const servicesArray = row.booking.booking_services;

        return (
            <div className="flex items-center">
                <div>
                    <p className="text-xs font-medium w-[100px] min-w-[100px]  max-w-[100px] 2xl:w-[100px] 2xl:max-w-[100px] 2xl:min-w-[100px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate">{`${
                        servicesArray[0].service.name
                    } ${servicesArray.length > 1 ? `+${servicesArray.length - 1}` : ''}`}</p>
                    <p className="text-xs font-normal text-secondaryTxtColor w-[100px] min-w-[100px]  max-w-[100px] 2xl:w-[100px] 2xl:max-w-[100px] 2xl:min-w-[100px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate">
                        ID: {row.booking.receipt_code}
                    </p>
                </div>
            </div>
        );
    };

    const GetTransactionDate = (row: any) => (row.created_at ? moment(row.created_at, moment.ISO_8601).format(DATE_FORMAT) : '-');
    const GetTransactionId = (row: any) => (row.payment_intent_id ? row.payment_intent_id : '-');
    const GetSubTotal = (row: any) => `${row.payment_method === 'stripe' ? `$${row.app_amount}` : `$${row.total_amount}`}`;
    const GetTax = (row: any) => `$${row.stripe_fee}`;
    const GetTotal = (row: any) => `$${row.total_amount}`;

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map(() => ({
                shop_services: { name: 'loading' },
                bookings: { subtotal: 'loading', taxes_and_fees: 'loading', total: 'loading', payment_type: 'loading' },
                payment_method: 'loading',
                payment_intent_id: 'loading',
                average_spend: 'loading',
                tip: 'loading',
                created_at: 'loading',
                status: 'loading',
            })),
        [lazyState],
    );

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    return (
        <div className="w-full border rounded-xl mt-6  flex-col mb-5 flex shadow  datatable-custom">
            <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                <div className="flex flex-col">
                    <h2 className="view-table-title">{t('Transaction history')}</h2>
                    <p className="view-table-subtitle">{t('View transaction activity.')}</p>
                </div>
                <div className="flex flex-row gap-4">
                    <CustomButton
                        isLoading={isDownloading.export.loading}
                        disabled={isDownloading.export.loading || !totalTransaction}
                        secondary
                        outlineSecondary
                        icon={<UploadCloud02 className="w-[18px] h-[18px] mt-[2px]" />}
                        onClick={handleHistoryDownload}
                        className="flex w-full items-center"
                    >
                        {t('Export')}
                    </CustomButton>
                    <div className="location-dropdown">
                        <SelectBox
                            name="page"
                            isClearable={false}
                            isSearchable={false}
                            options={perPageOptions}
                            onChangeFunc={onPageHandle}
                            value={perPageOptions.find((option) => option.value === lazyState.rows)}
                            classComp="w-[70px]"
                        />
                    </div>
                </div>
            </div>
            <DataTable
                lazy
                paginatorTemplate={customPaginatorTemplate}
                value={isLoading ? skeletons : transactionList}
                totalRecords={totalTransaction}
                paginator={!isLoading && totalTransaction > lazyState.rows}
                first={lazyState.first}
                rows={lazyState.rows}
                sortOrder={lazyState.sortOrder}
                sortField={lazyState.sortField}
                filters={lazyState.filters}
                onPage={onPage}
                onSort={onSort}
                onFilter={onFilter}
                dataKey="id"
                className=" rounded-b-xl overflow-hidden"
                paginatorClassName="table-pagination"
                emptyMessage={
                    <div className="rounded-xl min-h-[380px] flex justify-center items-center">
                        <NoDataMessage title="No transactions." description={`Add a bookings, to start using the transactions collection.`} iconComponent={<Box className="text-gray-700" />} />
                    </div>
                }
                rowClassName={(data) => 'max-w-12 truncate'}
            >
                <Column
                    field="shop_services.name"
                    header={renderHeader(t('Item'), 'shop_services.name')}
                    body={isLoading ? <Skeleton /> : GetItem}
                    style={{ width: '130px', minWidth: '130px', maxWidth: '130px' }}
                    className="font-normal text-mainTextColor truncate"
                    sortable
                ></Column>
                <Column
                    field="app_amount"
                    header={renderHeader(t('Subtotal'), 'app_amount')}
                    body={isLoading ? <Skeleton /> : GetSubTotal}
                    style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}
                    className="text-xs font-normal text-secondaryTxtColor truncate"
                    sortable
                ></Column>
                <Column
                    field="stripe_fee"
                    header={renderHeader(t('Tax'), 'stripe_fee')}
                    body={isLoading ? <Skeleton /> : GetTax}
                    style={{ width: '80px', minWidth: '80px', maxWidth: '80px' }}
                    className="text-xs font-normal text-secondaryTxtColor truncate"
                    sortable
                ></Column>
                {/* <Column
                    field="tip"
                    header={renderHeader(t('Tip'), 'status')}
                    style={{ width: '70px', minWidth: '70px', maxWidth: '70px' }}
                    body={isLoading ? <Skeleton /> : '$0'}
                    className="text-xs font-medium text-secondaryTxtColor truncate"
                    sortable
                ></Column> */}
                <Column
                    field="total_amount"
                    header={renderHeader(t('Total'), 'total_amount')}
                    body={isLoading ? <Skeleton /> : GetTotal}
                    style={{ width: '80px', minWidth: '80px', maxWidth: '80px' }}
                    className="text-xs font-medium text-mainTextColor truncate"
                    sortable
                ></Column>
                <Column
                    field="payment_method"
                    header={renderHeader(t('Type'), 'payment_method')}
                    style={{ width: '80px', minWidth: '80px', maxWidth: '80px' }}
                    body={isLoading ? <Skeleton /> : GetStatus}
                    className="font-medium"
                    sortable
                ></Column>
                <Column
                    field="payment_intent_id"
                    header={renderHeader(t('Transaction ID'), 'payment_intent_id')}
                    body={isLoading ? <Skeleton /> : GetTransactionId}
                    style={{ width: '180px', minWidth: '180px', maxWidth: '180px' }}
                    className="font-medium text-secondaryTxtColor text-xs truncate"
                    sortable
                ></Column>
                <Column
                    field="created_at"
                    header={renderHeader(t('Transaction Date'), 'created_at')}
                    body={isLoading ? <Skeleton /> : GetTransactionDate}
                    style={{ width: '150px', minWidth: '150px', maxWidth: '150px' }}
                    className="font-medium text-secondaryTxtColor text-xs truncate"
                    sortable
                ></Column>
                <Column
                    field="status"
                    header={'Action'}
                    style={{ width: '60px', minWidth: '60px', maxWidth: '60px' }}
                    body={isLoading ? <Skeleton /> : GetAction}
                    className="font-medium text-mainTextColor flex justify-center items-center"
                ></Column>
            </DataTable>
        </div>
    );
};

export default Transactions;
