import React from 'react';
import { CalendarPlus02, Edit02, EyeOff, XCircle } from '@untitled-ui/icons-react/build/cjs';
import { SlArrowRight } from 'react-icons/sl';
import { TfiPencilAlt } from 'react-icons/tfi';

export const appointmentStatus = [
    { label: 'Pending', value: 'pending' },
    { label: 'Confirmed', value: 'confirmed' },
    { label: 'Declined', value: 'declined' },
];

export const actionBtnIcon = [
    {
        label: <span className="text-gray-900 leading-[18px] font-medium text-xs">Cancel</span>,
        value: 'cancel_appointment',
        icon1: <XCircle className="text-gray-500 w-4 h-4" />,
        icon1Position: 'left',
    },
    {
        label: <span className="text-gray-900 leading-[18px] font-medium text-xs">Cancel And Refund</span>,
        value: 'cancel_refund_appointment',
        icon1: <XCircle className="text-gray-500 w-4 h-4" />,
        icon1Position: 'left',
    },
    {
        label: <span className="text-gray-900 leading-[18px] font-medium text-xs">Decline</span>,
        value: 'declined_appointment',
        icon1: <XCircle className="text-gray-500 w-4 h-4" />,
        icon1Position: 'left',
    },
    {
        label: <span className="text-gray-900 leading-[18px] font-medium text-xs">No-Show</span>,
        value: 'no_show_appointment',
        icon1: <EyeOff className="text-gray-500 w-4 h-4" />,
        icon1Position: 'left',
    },
    {
        label: <span className="text-gray-900 leading-[18px] font-medium text-xs">Edit</span>,
        value: 'edit_appointment',
        icon1: <Edit02 className="text-gray-500 w-4 h-4" />,
        icon1Position: 'left',
    },
    {
        label: <span className="text-gray-900 leading-[18px] font-medium text-xs">Reschedule</span>,
        value: 'reschedule_appointment',
        icon1: <CalendarPlus02 className="text-gray-500 w-4 h-4" />,
        icon1Position: 'left',
    },
    {
        label: <span className="text-gray-900 leading-[18px] font-medium text-xs">Cancel Modal</span>,
        value: 'cancel_modal',
        icon1: <EyeOff className="text-gray-500 w-4 h-4" />,
        icon1Position: 'left',
    },
];
