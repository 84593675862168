import { Messages, NavigateAction, SlotInfo, View, ViewsProps } from 'react-big-calendar';

export interface IToolbar {
    onNavigate: (navigate: NavigateAction, date?: Date) => void;
    date: Date;
    onView: (view: View) => void;
    view: View;
    handleAppointmentStatus: (status: string) => void;
    filter: string[];

    // handleLocation: (e: any) => void;
}

export interface IEventData {
    id: number;
    title: string;
    start: Date;
    end: Date;
    resourceId: number;
}

interface User {
    id: number;
    location_id: number | null;
    referrer_id: number | null;
    stripe_customer_id: string;
    email: string;
    phone: string;
    first_name: string;
    last_name: string;
    birthdate: string;
    profile_image_name: string | null;
    referral_code: string;
    phone_country_code: string;
    device_token: string | null;
    login_type: string;
    gender: string;
    is_user_verified: boolean;
    is_allowed_notification: boolean;
    remember_me: number;
    email_verified_at: string | null;
    created_at: string;
    updated_at: string;
    profile_image_url: string | null;
}

interface Staff {
    id: number;
    created_by: number;
    stripe_customer_id: string;
    stripe_account_id: string;
    first_name: string;
    last_name: string;
    phone_country_code: string;
    phone: string;
    email: string;
    birth_date: string;
    gender: string | null;
    about: string;
    profile_image: string;
    instagram_link: string;
    is_notification: boolean;
    is_verified_stripe_account: boolean;
    notification: {
        whats_new: boolean;
        booking_confirm: boolean;
        booking_canceled: boolean;
        first_time_client: boolean;
        booking_reschedule: boolean;
    };
    login_at: string;
    email_verified_at: string;
    phone_verified_at: string | null;
    deleted_at: string | null;
    created_at: string;
    updated_at: string;
    profile_image_url: string;
}

interface Location {
    id: number;
    shop_id: number;
    sales_tax_id: number;
    name: string;
    country: string;
    city: string;
    street: string;
    postal_code: string;
    latitude: string;
    longitude: string;
    location_image_name: string;
    tips_type: string;
    tips: string[];
    timezone: string;
    deleted_at: string | null;
    created_at: string;
    updated_at: string;
    location_image_url: string;
}

interface Service {
    id: number;
    shop_id: number;
    shop_category_id: number;
    name: string;
    tags: string[];
    description: string;
    is_active: boolean;
    deleted_at: string | null;
    created_at: string;
    updated_at: string;
    laravel_through_key: number;
}

interface BookingService {
    id: number;
    booking_id: number;
    staff_service_id: number;
    is_custom_price: boolean;
    price: string;
    quantity: number;
    duration: string;
    created_at: string;
    updated_at: string;
    service: Service;
}

interface TipTransaction {
    id: number;
    shop_id: number;
    staff_id: number;
    user_id: number;
    location_id: number;
    booking_id: number;
    payment_intent_id: string;
    app_amount: number;
    staff_amount: number;
    stripe_fee: number;
    total_amount: number;
    card_brand: string;
    card_last_digit: string;
    payment_type: string;
    status: string;
    notes: string | null;
    created_at: string;
    updated_at: string;
}

interface BookingTransaction {
    // Define the structure of BookingTransaction if needed
}
export interface IOptions {
    tips: any;
    id?: number;
    value: number;
    label: string;
}

export interface IResourceMap {
    name: string;
    staff_id: any;
    profile_image_url: any;
    role: any;
}
export interface IResourceHeader {
    eventInfo: any;
}
interface Booking {
    id: number;
    shop_id: number;
    shop_location_id: number;
    staff_id: number;
    user_id: number;
    receipt_code: string;
    subtotal: string;
    taxes_and_fees: string;
    tips: string | null;
    total: string;
    booking_date: string;
    booking_start_time: string;
    booking_end_time: string;
    total_duration: string;
    status: string;
    is_transaction_init: boolean;
    is_tip_paid: boolean;
    is_cancellation_paid: boolean;
    is_no_show_paid: boolean;
    payment_method: string;
    canceled_by: string | null;
    canceled_by_name: string | null;
    checkout_by: string | null;
    review: string | null;
    created_at: string;
    updated_at: string;
    user: User;
    staff: Staff;
    location: Location;
    booking_services: BookingService[];
    booking_transaction: BookingTransaction | null;
    tip_transaction: TipTransaction | null;
}

export interface ApiResponse {
    status: number;
    message: null;
    data: Record<string, Booking[]>;
}

export type IBookingType = 'new' | 'edit' | 'canceled' | 'confirm' | 'checkout';
export type IActionType = {
    newAppointment: boolean;
    bookedAppointment: boolean;
    cancelAppointment: boolean;
    noShowAppointment: boolean;
    paymentAppointment: boolean;
    checkoutAppointment: boolean;
    updateAppointment: boolean;
    confirmedCancelAppointment: boolean;
    completedPaymentAppointment: boolean;
    blockTime: boolean;
    rescheduleAppointment: boolean;
    waitList: boolean;
};
// export type IPaymentType = 'cash' | 'stripe' | 'pos' | 'loyalty' | 'other';
export interface IChargeableAmount {
    charge: number | null;
    charge_type: '$' | '%';
}
export interface ITipInfo {
    isTip: boolean;
    tipAmount: number;
}
export interface IPaymentMethod {
    type: 'cash' | 'stripe' | 'other' | 'pos' | 'loyalty';
    amount: number;
}

export interface IPaymentValidationState {
    status: boolean;
    message: string;
}

export interface IPaymentState {
    [x: string]: any;
    payments: IPaymentMethod[];
    tip?: number;
    extra_payment_method?: string;
    isInputTip?: boolean;
}

export interface IBookingState {
    data: {
        newSlotInfo: SlotInfo | null;
        timeZone: string | null;
        isLoading: boolean;
        calendarDate: Date;
        defaultDate: Date;
        getBookingCalendarDate: Date;
        view: View;
        isResourceListLoad: boolean;
        appointmentList: any[]; // Consider defining a more specific type
        staffOptions: any[]; // Consider defining a more specific type
        locationOptions: any[];
        selectedLocation: IOptions | null;
        selectedStaff: any;
        selectedSlotStaff: any;
        calendarSlotInfo: any; // Consider defining a more specific type
        calendarStep: IActionType;
        bookedSlotInfo: any;
        serviceList: any[]; // Replace 'any' with the actual type
        resourceList: IResourceMap[];
        clientList: any[]; // Replace 'any' with the actual type
        selectedDate: Date | null;
        selectedTime: Date | null;
        bookingInfo: null | Object; // Replace 'any' with the actual type
        isNewBooking: boolean;
        bookingType: IBookingType;
        selectedSlotStaffId: number | null; // Replace 'any' with the actual type
        selectedClientInfo: any; // Replace 'any' with the actual type
        isSidebarOpen: boolean;
        events: any;
        action: IActionType;
        getBookingList: boolean;
        // paymentType: IPaymentType | null;
        payments: IPaymentState | null;
        isCharge: boolean;
        isSlotAvailable: boolean;
        chargeableAmount: IChargeableAmount;
        removeBlockTime: {
            isRemoveBlockTime: boolean;
            blockTimeId: null | number;
            staffId: null | number;
        };
        cancelAppointment: {
            type: null | 'confirm' | 'process' | 'success' | 'error';
            status: boolean;
            message: string;
        };
        disabledServices: number[];
        overlapBooking: {
            status: boolean;
            message: string;
            info: any;
        };
    };
    loading: boolean;
    error: string | null;
}

export interface CalendarState {
    newSlotInfo: SlotInfo | null;
    timeZone: string | null;
    isLoading: boolean;
    view: View;
    calendarDate: Date;
    defaultDate: Date;
    getBookingCalendarDate: Date;
    isResourceListLoad: boolean;
    selectedStaff: any; // Consider defining a more specific type
    selectedLocation: any; // Consider defining a more specific type
    appointmentList: any[]; // Consider defining a more specific type
    resourceList: any[]; // Consider defining a more specific type
    staffOptions: any[]; // Consider defining a more specific type
    selectedSlotStaffId: any; // Consider defining a more specific type
    calendarSlotInfo: any; // Consider defining a more specific type
    calendarStep: IActionType;
    isSidebarOpen: boolean;
    bookedSlotInfo: any;
}

export const DURATION_FILTER_OPTIONS = [
    { label: 'Day', value: 'day' },
    { label: 'Week', value: 'week' },
];
export const STATUS_OPTIONS = [
    {
        label: 'Confirmed',
        value: 'confirmed',
    },
    {
        label: 'Completed ',
        value: 'completed',
    },
    {
        label: 'No-Show',
        value: 'no_show',
    },
    {
        label: 'Cancelled',
        value: 'canceled',
    },
];

export type Status = 'confirmed' | 'pending' | 'canceled' | 'completed' | 'no_show' | 'declined' | 'request_canceled';

export interface StatusLabelProps {
    status: Status;
}
