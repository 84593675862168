import React, { Fragment, useEffect, useState } from 'react';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import Loader from 'src/components/Loader/Loader';
import { selectCalendarData, setData, setOverlapBooking } from '../../Calendar.slice';
import CustomButton from 'src/components/CustomButton';
import { Plus, X } from '@untitled-ui/icons-react/build/cjs';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { toast } from 'react-toastify';
import { format } from 'date-fns';

const Service = ({}) => {
    const dispatch = useAppDispatch();
    const calendarData = useAppSelector(selectCalendarData);
    const shop = useAppSelector(currentShop);
    const [serviceOptionShow, setServiceOptionShow] = useState<boolean>(false);
    useEffect(() => {
        const shouldHideServiceOptions = calendarData.calendarStep.updateAppointment || calendarData.calendarStep.rescheduleAppointment;
        setServiceOptionShow(!shouldHideServiceOptions);
    }, [calendarData.calendarStep]);
    const {
        control,
        setValue,
        watch,
        formState: { errors },
    } = useFormContext();
    const { fields, remove, append } = useFieldArray({
        control,
        keyName: 'uuid',
        name: 'services',
    });

    const handleRemoveService = (index: number) => async () => {
        remove(index);
        const updatedFields = fields.filter((_, i) => i !== index);
        setValue('services', updatedFields);
    };
    const handleChangeService = (value: any) => {
        const selectedService = calendarData.serviceList.find((service: { value: any }) => service.value === value.value);
        if (selectedService) {
            const updatedDisabledServices = calendarData.disabledServices.length > 0 ? [...calendarData.disabledServices, value.value] : [];

            dispatch(setData({ disabledServices: updatedDisabledServices }));
        }
        setServiceOptionShow(false);

        const changeServiceData = {
            name: selectedService?.name,
            price: selectedService?.price,
            id: selectedService?.id,
        };
        addServices(changeServiceData);
    };
    const addServices = (info: any) => {
        const duration = moment.duration(info.price.duration);
        const formattedDuration = moment.utc(duration.asMilliseconds()).format(duration.hours() > 0 ? 'H [hours] m [minutes]' : 'm [minutes]');
        {
            calendarData.selectedSlotStaff &&
                append({
                    name: info.name,
                    price: info.price.price,
                    staff_name: `${calendarData.selectedSlotStaff.full_name}`,
                    time_slot: moment(calendarData.selectedDate).format('hh:mm A'),
                    formattedDuration: formattedDuration,
                    duration: info.price.duration,
                    id: info.id,
                    is_custom_price: false,
                    quantity: 1,
                });
        }
    };
    const watchService = watch('services');

    const overlapBooking = async () => {
        if (
            watchService &&
            watchService.length > 0 &&
            calendarData.selectedDate &&
            calendarData.selectedTime &&
            calendarData.selectedSlotStaffId &&
            calendarData.selectedLocation &&
            (calendarData.calendarStep.updateAppointment || calendarData.calendarStep.newAppointment)
        ) {
            let payload = {
                staff_id: calendarData.selectedSlotStaffId,
                booking_date: format(calendarData.selectedDate, 'yyyy-MM-dd'),
                booking_time: format(calendarData.selectedTime, 'HH:mm:ss'),
                services: watchService,
                shop_location_id: calendarData.selectedLocation.id,
                booking_id: null,
            };
            if (calendarData.calendarStep.updateAppointment) {
                payload.booking_id = calendarData.bookedSlotInfo?.id;
            }
            axiosPost(API.BOOKING.OVERLAP, payload, { shop_id: shop.id })
                .then(async (result: any) => {
                    console.log('result', result.data);
                    console.log(' result.data.data.lenght', result.data.data.length);
                    const data: { status: boolean; info: any } = {
                        status: result.data.data.length > 0,
                        info: result.data.data.length > 0 ? result.data.data : null,
                    };
                    dispatch(setOverlapBooking(data));
                    // await dispatch(me());
                })
                .catch((error) => {
                    const response = error.response.data;
                    // if (response.status === errorCode.unprocessable) {
                    //     if (response.data) {
                    //         Object.keys(response.data).forEach((field) => {
                    //             // setError(field as keyof IFormData, {
                    //             //     type: 'manual',
                    //             //     message: response.data[field][0],
                    //             // });
                    //         });
                    //     }
                    //     return;
                    // }
                    toast.error(response.message);
                })
                .finally(() => {
                    // setIsLoading(false);
                    // setAction({ confirm: false });
                });
        } else {
            dispatch(setOverlapBooking({ status: false, info: null }));
        }
    };

    useEffect(() => {
        if (
            watchService &&
            watchService.length > 0 &&
            calendarData.selectedDate &&
            calendarData.selectedTime &&
            calendarData.selectedSlotStaffId &&
            calendarData.selectedLocation &&
            (calendarData.calendarStep.updateAppointment || calendarData.calendarStep.newAppointment)
        ) {
            overlapBooking();
        } else {
            dispatch(setOverlapBooking({ status: false, info: null }));
        }
    }, [calendarData.selectedDate, calendarData.selectedTime, calendarData.selectedSlotStaffId, watchService]);
    console.log('watchServicewatchService', watchService);

    return (
        <>
            {!calendarData.isLoading ? (
                <div className="mb-4">
                    {calendarData.serviceList.length > 0 && (
                        <div className="">
                            <div className="flex justify-between">
                                <label htmlFor="newclient" className={`fl-field-title-label normal-case text-gray-700 text-xs font-semibold leading-[18px] mb-1.5`}>
                                    Select Service(s)
                                </label>
                                {!serviceOptionShow &&
                                    ((calendarData.calendarStep.updateAppointment && calendarData.bookedSlotInfo?.booking_transaction?.length === 0) ||
                                        calendarData.calendarStep.rescheduleAppointment ||
                                        calendarData.calendarStep.newAppointment) && (
                                        <CustomButton
                                            type="button"
                                            onClick={() => setServiceOptionShow(true)}
                                            className="!text-xs text-btnBg font-semibold flex items-center gap-1.5 h-[15px] shadow-none pr-0.5 "
                                            icon={<Plus width="16" />}
                                        >
                                            Add Service
                                        </CustomButton>
                                    )}
                            </div>
                        </div>
                    )}
                    <div className="flex flex-col gap-2">
                        {serviceOptionShow &&
                            ((calendarData.calendarStep.updateAppointment && calendarData.bookedSlotInfo?.booking_transaction?.length === 0) ||
                                calendarData.calendarStep.rescheduleAppointment ||
                                calendarData.calendarStep.newAppointment) && (
                                <Controller
                                    name="services"
                                    control={control}
                                    render={({ fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <SelectBox
                                                isSearchable
                                                name="services"
                                                errorText={!!error}
                                                id="services"
                                                value={null}
                                                options={calendarData.serviceList}
                                                onChangeFunc={handleChangeService}
                                                className=""
                                                placeholder="Select a service"
                                            />
                                            {error && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                            )}
                        {fields.length > 0 && (
                            <div className="flex flex-col gap-2">
                                {fields.map((item: any, index: any) => (
                                    <Fragment key={item.uuid}>
                                        {(calendarData.calendarStep.updateAppointment && calendarData.bookedSlotInfo?.booking_transaction?.length === 0) ||
                                        calendarData.calendarStep.rescheduleAppointment ||
                                        calendarData.calendarStep.newAppointment ? (
                                            <div className="flex h-[36px] select_border input-group  justify-between items-center p-2 border border-gray-300 rounded-lg shadow-InputAndButton text-xs">
                                                <div className="flex font-medium select-box-custom ">
                                                    <span className="font-medium">{item.name}</span>
                                                    <Controller
                                                        name={`services.${index}.duration`}
                                                        control={control}
                                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                            <div>
                                                                <span className="font-normal ml-1">{`(`}</span>
                                                                <input
                                                                    id="duration"
                                                                    required
                                                                    onChange={(e) => {
                                                                        onChange(moment.utc(moment.duration(e.target.value, 'minutes').asMilliseconds()).format('HH:mm:ss'));
                                                                        overlapBooking();
                                                                    }}
                                                                    value={moment.duration(value).asMinutes()}
                                                                    className="!font-medium text-xs  !max-w-[22px]   !border-0 !bg-transparent !pl-0 !pr-0 !outline-none"
                                                                />
                                                                <span className="font-normal ml-1">{`minutes)`}</span>
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="flex items-center space-x-2">
                                                    <Controller
                                                        name={`services.${index}.price`}
                                                        control={control}
                                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                            <div>
                                                                <span className="font-normal ml-1">{`$`}</span>
                                                                <input
                                                                    id="price"
                                                                    required
                                                                    type="number"
                                                                    onChange={(e) => onChange(e.target.value)}
                                                                    value={value}
                                                                    className="font-normal  !max-w-[49px] !border-0 !bg-transparent !pl-0 !pr-0 !outline-none"
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                    <button className="text-gray-500 hover:text-red-500" type="button" onClick={handleRemoveService(index)}>
                                                        <X width={16} height={16} />
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="flex justify-between items-center  text-xs">
                                                <div className="flex font-medium select-box-custom w-[270px] items-center">
                                                    <span className="font-normal max-w-[160px]">{item.name}</span>
                                                    <span className="font-normal ml-1 text-[#475467]">({item.formattedDuration})</span>
                                                </div>
                                                <div className="flex items-center space-x-2">
                                                    <span className="font-medium ">${item.price}</span>
                                                </div>
                                            </div>
                                        )}
                                    </Fragment>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <Loader />
            )}
        </>
    );
};

export default Service;
