import React, { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Overview from './Overview';
import Appointments from './Appointments';
import Transactions from './Transactions';
import Notes from './Notes';
import Analytics from './Analytics';

const ClientTab = ({ client }: any) => {
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState('overview');

    const handleTab = (name: string) => () => {
        setCurrentTab(name);
    };

    const TABS = useMemo(
        () => [
            {
                display_name: t('Overview'),
                name: 'overview',
            },
            {
                display_name: t('Appointments'),
                name: 'appointments',
            },
            {
                display_name: t('Transactions'),
                name: 'transactions',
            },
            {
                display_name: t('Notes'),
                name: 'notes',
            },
            {
                display_name: t('Analytics'),
                name: 'analytics',
            },
        ],
        [],
    );

    const TabData = ({ tabs, currentClient }: any) => {
        switch (tabs) {
            case 'overview':
                document.title = t('Titles.Client Overview');
                return <Overview client={currentClient} />;
            case 'appointments':
                document.title = t('Titles.Client Appointment History');

                return <Appointments client={currentClient} />;
            case 'transactions':
                document.title = t('Titles.Client Transaction History');
                return <Transactions client={currentClient} />;
            case 'notes':
                document.title = t('Titles.Client Notes');
                return <Notes client={currentClient} />;
            case 'analytics':
                document.title = t('Titles.Client Analytics');
                return <Analytics client={currentClient} />;
            default:
                return <></>;
        }
    };

    return (
        <>
            <div className="fl-tab-btn-view w-full">
                {TABS.map((tab: any, index) => (
                    <button key={index} type="button" onClick={handleTab(tab.name)} className={`w-full fl-tab-link !max-w-[182px] ${tab.name === currentTab ? '!text-primary !bg-blue-50' : ''}`}>
                        {tab.display_name}
                    </button>
                ))}
            </div>
            <TabData tabs={currentTab} currentClient={client} />
        </>
    );
};

export default memo(ClientTab);
