import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { IFormData } from './Regular.interface';
import InputWithLabel from 'src/components/InputWithLabel';
import InputPasswordWithLabel from 'src/components/InputPasswordWithLabel';
import Button from 'src/components/Button';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { errorCode } from 'src/constants/errorCode';
import { axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { currentShop, me, userMe } from 'src/redux/services/common/Common.slice';
import { NavLink, useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { FaArrowLeft } from 'react-icons/fa6';
import { logout } from 'src/utils/global-functions';
import CustomButton from 'src/components/CustomButton';
type FieldKey = 'first_name' | 'last_name' | 'password' | 'confirm_password' | 'is_accept_terms';

const Regular = () => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const user = useAppSelector(userMe);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const passwordValidation = Yup.string()
        .required(t('This field is required'))
        .min(8, t('Password must be at least 8 characters long'))
        .matches(/(?=.*[A-Z])/, t('Password must contain one uppercase and numerical character.'))
        .matches(/(?=.*\d)/, t('Password must contain one uppercase and numerical character.'));

    const schema = Yup.object({
        first_name: Yup.string().required(t('First name required')),

        last_name: Yup.string().required(t('Last name required')),
        password: passwordValidation,
        confirm_password: Yup.string()
            .required(t('This field is required'))
            .oneOf([Yup.ref('password'), ''], t('Passwords do not match.')),
        is_accept_terms: Yup.boolean().oneOf([true], t('Terms agreement required')).required(t('Terms agreement required')),
    }).required();
    const {
        handleSubmit,
        control,
        setError,
        formState: { errors },
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            first_name: user.first_name || '',
            last_name: user.last_name || '',
            password: '',
            confirm_password: '',
            is_accept_terms: false,
        },
    });

    const handleChange = async (data: IFormData) => {
        setIsLoading(true);
        const apiData = {
            payload: {
                shop_id: shop.id,
                first_name: data.first_name,
                last_name: data.last_name,
                password: data.password,
                password_confirmation: data.confirm_password,
            },
            params: {
                shop_id: shop.id,
            },
        };

        try {
            const response = await axiosPatch(API.USER.ACCOUNT, apiData.payload, apiData.params);
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                await dispatch(me());
                navigate(ROUTES.SIGNUP);
                return;
            }

            throw response.data;
        } catch (err: any) {
            if (err.status === errorCode.unprocessable) {
                if (err.data) {
                    Object.keys(err.data).forEach((field) => {
                        setError(field as FieldKey, {
                            type: 'manual',
                            message: err.data[field][0],
                        });
                    });
                }
                return;
            }
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <div className="content mt-[84px] flex justify-center">
            <div className="w-[380px]">
                <h1 className=" mb-[6px] text-[30px] -tracking-[1.2px] leading-[38px] font-semibold text-mainTextColor">{t('Create an account')}</h1>
                <p className="text-[14px] opacity-70 -tracking-[0.3px] leading-5 text-secondaryTxtColor ">{t("Let's get you setup. Start by creating an account.")}</p>
                <div className="mt-[30px]">
                    <form onSubmit={handleSubmit(handleChange)}>
                        <div className="flex gap-4 flex-wrap">
                            <Controller
                                name="first_name"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="w-[100%]">
                                        <InputWithLabel
                                            label={t('First Name')}
                                            required
                                            placeholder={t('Input your first name')}
                                            onChange={onChange}
                                            value={value}
                                            name="first_name"
                                            error={errors.first_name && true}
                                            disabled={isLoading}
                                            isFocus={true}
                                        />
                                        {/* <p className="text-error">{errors.first_name && errors.first_name.message}</p> */}
                                    </div>
                                )}
                            />
                            <Controller
                                name="last_name"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="w-[100%]">
                                        <InputWithLabel
                                            label={t('Last Name')}
                                            required
                                            placeholder={t('Input your last name')}
                                            onChange={onChange}
                                            value={value}
                                            name="last_name"
                                            error={errors.last_name && true}
                                            disabled={isLoading}
                                            // isFocus={true}
                                        />
                                        {/* <p className="text-error">{errors.last_name && errors.last_name.message}</p> */}
                                    </div>
                                )}
                            />
                            <Controller
                                name="password"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="w-[100%]">
                                        <InputPasswordWithLabel
                                            label={t('Password')}
                                            placeholder={t('Input password')}
                                            onChange={onChange}
                                            value={value}
                                            required
                                            name="password"
                                            inputClass={` ${errors.password ? 'is-invalid' : ''}`}
                                            error={errors.password && true}
                                            //isFocus={true}
                                        />
                                        <p className="text-error">{errors.password && errors.password.message}</p>
                                    </div>
                                )}
                            />
                            <Controller
                                name="confirm_password"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="w-[100%]">
                                        <InputPasswordWithLabel
                                            label={t('Confirm Password')}
                                            placeholder={t('Confirm your password')}
                                            onChange={onChange}
                                            value={value}
                                            required
                                            name="confirm_password"
                                            inputClass={` ${errors.confirm_password ? 'is-invalid' : ''}`}
                                            error={errors.confirm_password && true}
                                            //isFocus={true}
                                        />
                                        <p className="text-error">{errors.confirm_password && errors.confirm_password.message}</p>
                                    </div>
                                )}
                            />
                            <div>
                                <Controller
                                    name="is_accept_terms"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <>
                                            <div className="flex">
                                                <input
                                                    // id="is_accept_terms"
                                                    type="checkbox"
                                                    value={value}
                                                    className={`fl-custom-checkbox-bg mt-[6px] w-5 h-5 rounded-md cursor-pointer ${
                                                        errors.is_accept_terms && errors.is_accept_terms.message ? 'is-invalid' : ''
                                                    }`}
                                                    onChange={onChange}
                                                    checked={value}
                                                    disabled={isLoading}
                                                />
                                                <label htmlFor="is_accept_terms" className={`ml-2 text-sm font-medium pt-1 leading-5`}>
                                                    {
                                                        <span className="text-sm -tracking-[0.3px]">
                                                            {t('I have read and accept the')}
                                                            <NavLink to="https://www.getflair.ca/terms-of-service" target="_blank" className="font-medium text-primary -tracking-[0.12px]">
                                                                {' '}
                                                                {t('Terms & Conditions')}
                                                            </NavLink>
                                                            &nbsp;{t('and')}{' '}
                                                            <NavLink to="https://www.getflair.ca/privacy-policy" target="_blank" className="font-medium text-primary -tracking-[0.12px]">
                                                                {t('Privacy Policy')}
                                                            </NavLink>
                                                        </span>
                                                    }
                                                </label>
                                            </div>
                                            {/* {errors.is_accept_terms && errors.is_accept_terms.message && <p className="text-error">{errors.is_accept_terms.message}</p>} */}
                                        </>
                                    )}
                                />
                            </div>
                            <CustomButton primary type="submit" isLoading={isLoading} disabled={isLoading} size="w-full" className="mt-1 !text-[14px] !leading-5 !font-semibold">
                                {t('Continue')}
                            </CustomButton>
                        </div>
                        <div className="text-center mt-8">
                            <Button type="button" className="text-sm font-semibold" onClick={logout}>
                                <FaArrowLeft size={14} className="mr-2" /> {t('Back to log in')}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Regular;
