import React, { useEffect, useLayoutEffect, useState } from 'react';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import { FaCheck } from 'react-icons/fa6';
import en from 'react-phone-number-input/locale/en.json';
import { usePhone } from 'src/hooks/usePhone';
import { INumberList, ISelectedNumber, initSelectedNumber } from './Number.interface';
import { CountryCode, formatIncompletePhoneNumber, parseIncompletePhoneNumber } from 'libphonenumber-js';
import IconClose from 'src/assets/img/icons/icon-close.svg';
import { useTranslation } from 'react-i18next';
import { ChevronDown } from '@untitled-ui/icons-react/build/cjs';

const Number = ({ index, getNumber, onNumberChange, parentClassName = 'min-h-[38px]', disabled = false, errors, name, defaultValues, isFocus = false, isButtonClicked, id }: any) => {
    const { setFormatPhone, isPhoneValid, phoneInfo } = usePhone();
    const { t } = useTranslation();
    const [Switch, setSwitch] = useState(false);
    const [selectedNumber, setSelectedNumber] = useState<ISelectedNumber>(initSelectedNumber);
    const [numberList, setNumberList] = useState<INumberList[]>([]);
    const [isInit, setIsInit] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterList, setFilterList] = useState<INumberList[]>([]);

    useEffect(() => {
        const handler = () => setSwitch(false);
        window.addEventListener('click', handler);
        return () => {
            window.removeEventListener('click', handler);
        };
    }, []);

    useEffect(() => {
        if (isInit) {
            setIsInit(false);
            return;
        }
        const filteredList = numberList.filter((country) => country.name.toLowerCase().includes(searchTerm));
        if (searchTerm === '') {
            setFilterList(numberList);
        } else {
            setFilterList(filteredList);
        }
    }, [searchTerm]);

    useEffect(() => {
        let existSelectedNumber: ISelectedNumber = { ...initSelectedNumber };
        if (defaultValues?.phone && defaultValues?.phone_country_code) {
            existSelectedNumber = { ...selectedNumber };
            const getphoneinfo = phoneInfo(defaultValues.phone, defaultValues.phone_country_code);
            if (getphoneinfo) {
                if (getphoneinfo.countryCallingCode) {
                    existSelectedNumber.code = getphoneinfo.countryCallingCode;
                }
                if (getphoneinfo.nationalNumber) {
                    existSelectedNumber.number = getphoneinfo.nationalNumber;
                }
            }
            // existSelectedNumber.image = require(`src/assets/flags/${defaultValues.phone_country_code}.svg`);
            existSelectedNumber.name = en[defaultValues.phone_country_code as CountryCode];
            existSelectedNumber.country = defaultValues.phone_country_code;
        }
        setSelectedNumber((old) => ({ ...old, ...existSelectedNumber }));
    }, [defaultValues]);

    const handlerInputClik = (e: any) => {
        e.stopPropagation();
        if (!disabled) {
            setSwitch(!Switch);
        }
    };

    const handleCountryCode = (data: ISelectedNumber) => () => {
        setSelectedNumber((old) => ({ ...old, ...data }));
        const isValid = isPhoneValid(selectedNumber.number, data.country);
        onNumberChange(data.country, selectedNumber.number, data.code, isValid);
    };

    const handleInputNumberChange = (e: any) => {
        let number = parseIncompletePhoneNumber(e.target.value);
        if (number === selectedNumber.number) {
            const newValueFormatted = formatIncompletePhoneNumber(number, selectedNumber.country);
            if (newValueFormatted.indexOf(e.target.value) === 0) {
                // Trim the last digit (or plus sign).
                number = number.slice(0, -1);
            }
        }
        setSelectedNumber((old) => ({ ...old, number }));
        const isValid = isPhoneValid(number, selectedNumber.country);
        onNumberChange(selectedNumber.country, number, selectedNumber.code, isValid);
    };

    useLayoutEffect(() => {
        const fetchData = async () => {
            let list = getCountries().map((country) => ({
                // image: require(`src/assets/flags/${country}.svg`),
                code: getCountryCallingCode(country),
                name: en[country],
                country: country,
            }));
            const priorityCountries = ['Canada', 'United States'];

            const customSortValue = (country: string) => {
                const priorityIndex = priorityCountries.indexOf(country);
                return priorityIndex !== -1 ? priorityIndex : priorityCountries.length + 1;
            };

            const shortedList = list.sort((a, b) => customSortValue(a.name) - customSortValue(b.name) || a.name.localeCompare(b.name));
            setNumberList(shortedList);
            setFilterList(shortedList);
        };

        fetchData();
    }, []);

    const handleSearchTermClick = (e: { stopPropagation(): unknown; preventDefault: () => void }) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
    };

    const clearSearchTerm = (event: { stopPropagation(): unknown; preventDefault: () => void }) => {
        event.preventDefault();
        event.stopPropagation();
        setSearchTerm('');
    };

    return (
        <div className="relative">
            <div
                className={`fl-input-field fl-phone-field form_input_control custom-hover-effect ${parentClassName} ${Switch && ''} ${
                    errors?.phonenumber || errors?.phone || (!isPhoneValid(selectedNumber.number, selectedNumber.country) && isButtonClicked) ? 'is-invalid' : 'border'
                }`}
            >
                <span className={`flex items-center ${disabled ? '!cursor-not-allowed' : ''}`} onClick={handlerInputClik}>
                    <span className="text-xs min-w-5 leading-[18px] text-placeholderTxt font-medium">{selectedNumber.country}</span>
                    <ChevronDown width={20} className="ml-[2px] text-placeholderTxt" />
                </span>
                <span className="ml-2 mr-1 text-xs leading-[18px] font-medium text-placeholderTxt"> +{selectedNumber.code}</span>
                <input
                    className={`text-sm rounded-r-3xl focus:ring-0 focus:ring-offset-0 focus-visible:outline-none w-full border-none form_input_control !h-[33px] ${
                        disabled ? 'cursor-not-allowed' : ''
                    }`}
                    type="text"
                    name={name}
                    id={id}
                    disabled={disabled}
                    value={setFormatPhone(selectedNumber.code, selectedNumber.number, selectedNumber.country)}
                    onChange={handleInputNumberChange}
                    placeholder={setFormatPhone(selectedNumber.code, '8708708866', selectedNumber.country)}
                    autoFocus={isFocus}
                    maxLength={20}
                />
            </div>
            {/* {errors && errors.phone ? <p className="text-error">{errors && errors.phone ? errors.phone.message : 'Invalid Phone number'}</p> : ''} */}
            {Switch && (
                <div id="dropdownDelay" className="z-10 absolute top-15 mt-[2px] bg-white rounded-lg  w-full fl-dropdown-shadow">
                    <div className="p-1">
                        <div className="input-group">
                            <input
                                type="text"
                                placeholder={t('Search Country')}
                                value={searchTerm}
                                onChange={handleSearchTermChange}
                                onClick={handleSearchTermClick}
                                autoFocus
                                className="form_input_control"
                            />
                            <button className="px-3" onClick={clearSearchTerm}>
                                <img src={IconClose} alt="" className="w-4" />
                            </button>
                        </div>
                    </div>
                    <ul className="country-code-dropdown-list" aria-labelledby="dropdownDelayButton">
                        {filterList.map((country: any, indexNumberCode: number) => (
                            <li
                                onClick={handleCountryCode(country)}
                                className={`country-code-dropdown-li ${selectedNumber.name === country.name && 'active'}`}
                                key={indexNumberCode}
                                value={country.code}
                            >
                                <div className="flex items-center">
                                    <span className="w-6 h-auto max-h-6 rounded-md">{country.country}</span>
                                    <span className="w-10 font-normal mx-2">+{country.code}</span>
                                    <span className=" font-normal">{country.name}</span>
                                </div>
                                {selectedNumber.country === country.country && <FaCheck size={16} className="text-primary" />}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Number;
