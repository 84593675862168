import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Switch from 'src/components/Switch/Switch';

const General = () => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    return (
        <div className="flex flex-col flex-1">
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Daily Checkout')}</p>
                    <p className="subtitle-text">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}</p>
                </div>
                <Controller
                    name="daily_checkout"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('New Client')}</p>
                    <p className="subtitle-text">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}</p>
                </div>
                <Controller
                    name="new_user"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>

            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Monthly Statement Available')}</p>
                    <p className="subtitle-text">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}</p>
                </div>
                <div className="flex flex-col w-[636px] gap-4 ">
                    <Controller
                        name="monthly_statement"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="flex flex-col w-[512px] gap-4 ">
                                <div className="flex items-center gap-2">
                                    <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                    <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                    <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                    <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                                </div>
                            </div>
                        )}
                    />
                </div>
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Commission Statement Available')}</p>
                    <p className="subtitle-text">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}</p>
                </div>
                <div className="flex flex-col w-[636px] gap-4 ">
                    <Controller
                        name="commission_statement"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <>
                                <div className="flex items-center gap-2">
                                    <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                    <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                    <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                    <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                                </div>
                            </>
                        )}
                    />
                </div>
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Rent Collection Statement Available')}</p>
                    <p className="subtitle-text">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}</p>
                </div>
                <div className="flex flex-col w-[636px] gap-4 ">
                    <Controller
                        name="rent_statement"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <>
                                <div className="flex items-center gap-2">
                                    <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                    <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                    <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                    <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                                </div>
                            </>
                        )}
                    />
                </div>
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Staff has completed his Registration')}</p>
                    <p className="subtitle-text">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}</p>
                </div>
                <Controller
                    name="staff_completed_registration"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
        </div>
    );
};

export default General;
