import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconCheckdActive, Light } from 'src/theme/Images';
import TemplateSettings from './Sections/TemplateSettings';
import { ITemplatePayload, TemplateType } from './Sections/TemplateSettings.interface';
import { axiosPost } from 'src/utils/requestClient';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { API } from 'src/constants/api';
import { allShopSettings, currentShop, getAllShopSettings } from 'src/redux/services/common/Common.slice';
import Switch from 'src/components/Switch/Switch';
const Template = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const templateInfo: any = useAppSelector(allShopSettings).find((setting) => setting.type === 'template')?.value?.template;
    const settingIsActiveTemplate: any = useAppSelector(allShopSettings).find((setting) => setting.type === 'template')?.value?.is_active_template;
    const [template, setTemplate] = useState<TemplateType>(templateInfo || 'template_1');
    const [isActiveTemplate, setIsActiveTemplate] = useState<boolean>(settingIsActiveTemplate || false);
    const shop = useAppSelector(currentShop);

    const handleTemplate = (value: TemplateType) => async () => {
        setTemplate(value);
        const payload = {
            template: value,
            is_active_template: isActiveTemplate,
        };
        await handleTemplateApi(payload);
    };
    const handleActiveTemplate = async () => {
        setIsActiveTemplate(!isActiveTemplate);
        const payload = {
            template: template,
            is_active_template: !isActiveTemplate,
        };
        await handleTemplateApi(payload);
    };

    const handleTemplateApi = async (payload: ITemplatePayload) => {
        await axiosPost(API.THEME.TEMPLATE.TEMPLATE, payload, {
            shop_id: shop.id,
        })
            .then(async (response) => {
                await dispatch(getAllShopSettings({ shop_id: shop.id }));
                return;
            })
            .catch((error) => {})
            .finally(() => {});
    };
    return (
        <div className="w-full pt-5 px-2 flex flex-col gap-5 ">
            <div className="fl-data-block border-b border-borderSecondary pb-5">
                <div className="title-block w-[310px]">
                    <h3 className="title-text">{t('Enable templates')}</h3>
                    <p className="subtitle-text">{t('Preface your website with a pre-made, modern marketing template to enhance your brand appearance.')}</p>
                </div>
                <div className="data-wrap-block max-w-[512px] xxl:max-w-[700px]">
                    <div className="flex gap-5">
                        <Switch onChange={handleActiveTemplate} isChecked={isActiveTemplate} />
                    </div>
                </div>
            </div>
            <div className="fl-data-block">
                <div className="title-block w-[310px]">
                    <h3 className="title-text">{t('Base template')}</h3>
                    <p className="subtitle-text">{t('Preview and select a base template for your marketing website.')}</p>
                </div>
                <div className="data-wrap-block max-w-[512px] xxl:max-w-[700px]">
                    <div className="flex gap-5">
                        <div className="w-[196px]">
                            <div className={`theme-box p-1 mb-3 ${template === 'template_1' ? 'active' : ''}`} onClick={handleTemplate('template_1')}>
                                <i className="icon">
                                    <img src={IconCheckdActive} alt="" className="" />
                                </i>
                                <div className="theme-block-thumb rounded-xl overflow-hidden">
                                    <img src={Light} height={'132px'} width={'192px'} alt="Template" />
                                </div>
                            </div>
                            <p className="theme-box-title">Template 1</p>
                        </div>
                        <div className="w-[196px]">
                            <div className={`theme-box p-1 mb-3 ${template === 'template_2' ? 'active' : ''}`} onClick={handleTemplate('template_2')}>
                                <i className="icon">
                                    <img src={IconCheckdActive} alt="" className="" />
                                </i>
                                <div className="theme-block-thumb rounded-xl overflow-hidden">
                                    <img src={Light} height={'132px'} width={'192px'} alt="Template" />
                                </div>
                            </div>
                            <p className="theme-box-title">Template 2</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${!isActiveTemplate ? 'hidden' : ''}`}>
                <TemplateSettings template={template} />
            </div>
        </div>
    );
};

export default Template;
