import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { currentRole, currentShop, me, userMe } from 'src/redux/services/common/Common.slice';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { IBusinessDetails, IFieldKey } from './BusinessDetails.interface';
import { errorCode } from 'src/constants/errorCode';
import { s3Upload } from 'src/utils/s3Operations';
import { toast } from 'react-toastify';
import BusinessInfo from './BusinessInfo';
import OnlineLinks from './OnlineLinks';
import { useTranslation } from 'react-i18next';
import { InfoCircle } from '@untitled-ui/icons-react/build/cjs';
import CustomButton from 'src/components/CustomButton';
import { Tooltip } from 'flowbite-react';
import { ROLES } from 'src/constants/common';
import { axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const BusinessDetails = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector(userMe);
    const shop = useAppSelector(currentShop);
    const role = useAppSelector(currentRole);
    const [file, setFile] = useState<any>({ convertedFile: '', filename: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [cropData, setCropData] = useState<any>('');
    const [isSaveEnabled, setSaveEnabled] = useState(false);

    useEffect(() => {
        setCropData(shop.logo_image_url);
    }, [shop]);

    const urlSchema = Yup.string()
        .url('Please enter a valid URL')
        .transform((value, originalValue) => {
            if (originalValue && originalValue.startsWith('www')) {
                return `http://${originalValue}`;
            }
            return value;
        })
        .nullable();

    const schema = Yup.object().shape({
        business_name: Yup.string().required(t('This field is required')),
        logo_image_name: Yup.string().required(t('This field is required')),
        slug: Yup.string().required(t('This field is required')),
        google_review: Yup.string().nullable(),
        facebook_page: Yup.string().nullable(),
        instagram_page: Yup.string().nullable(),
        admin_emails: Yup.array()
            .of(
                Yup.object().shape({
                    value: Yup.string().matches(emailRegex, t('Invalid email format')).required(t('Email is required')),
                }),
            )
            .notRequired(),
    });
    const [defaultValues, setDefaultValues] = useState<IBusinessDetails>({
        business_name: shop.business_name,
        slug: shop.slug,
        logo_image_name: shop.logo_image_name,
        google_review: shop.google_review || '',
        facebook_page: shop.facebook_page || '',
        instagram_page: shop.instagram_page || '',
        admin_emails: [],
    });

    const methods = useForm<IBusinessDetails>({
        resolver: yupResolver<IBusinessDetails>(schema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { errors },
        setError,
        reset,
        watch,
        formState: { isDirty },
    } = methods;

    const watchAllFields = watch();

    useEffect(() => {
        setSaveEnabled(isDirty || file.filename !== '');
    }, [watchAllFields, isDirty, cropData, file]);

    useEffect(() => {
        setDefaultValues({
            business_name: shop.business_name,
            slug: shop.slug,
            logo_image_name: shop.logo_image_name,
            google_review: shop.google_review || '',
            facebook_page: shop.facebook_page || '',
            instagram_page: shop.instagram_page || '',
            admin_emails: user.shop_admin_invites.map((item: any) => item.email) ?? [],
        });
    }, [shop]);

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues]);

    const handleSave = async (data: IBusinessDetails) => {
        let adminEmailsPayload = [];
        if (Array.isArray(data.admin_emails)) {
            adminEmailsPayload = data.admin_emails.map((item: any) => item.value);
        }
        const cread: IBusinessDetails = {
            ...data,
            admin_emails: adminEmailsPayload,
        };
        await axiosPatch(API.USER.COMPANY, cread, { shop_id: shop.id })
            .then(async (response) => {
                dispatch(me());
            })
            .catch((error: any) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        let message = '';
                        Object.keys(response.data).forEach((field) => {
                            message = response.data[field][0];
                            setError(field as IFieldKey, {
                                type: 'manual',
                                message: message,
                            });
                        });
                    }
                    return;
                }
                toast.error(response.message);
            })
            .finally(() => setIsLoading(false));
    };

    const handleChange = async (data: IBusinessDetails) => {
        setIsLoading(true);
        if (file.convertedFile && file?.filename) {
            const imgUploadResult: any = await s3Upload(file?.convertedFile, `images/business_logos/${file?.filename}`);
            if (imgUploadResult.status === 201) {
                const updatedData = {
                    ...data,
                    logo_image_name: file.filename,
                };
                handleSave(updatedData);
            }
        } else {
            handleSave(data);
        }
    };

    const resetForm = () => {
        setCropData(shop.logo_image_url);
        setFile({ convertedFile: '', filename: '' });
        reset();
    };

    return (
        <div className="w-[full] flex-1">
            {/* <div className="w-full flex flex-row py-[14px] mt-5 gap-6 items-center">
                <img src={cropData ? cropData : defaultImage} alt="brand" className="w-[140px] min-w-[140px] h-[140px] rounded-lg object-contain" />
                <div className="flex flex-col gap-1">
                    <div className="font-semibold text-3xl text-mainTextColor">{shop.business_name}</div>
                    <div className="text-base text-secondaryTxtColor font-normal flex flex-row gap-1.5">
                        {shop.slug}.getflair.ca
                        <a href={`https://${shop.slug}.getflair.ca`} target="_blank" rel="noopener noreferrer">
                            <LinkExternal01 className="text-[#D0D5DD] w-5 " />
                        </a>
                    </div>
                </div>
            </div> */}
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(handleChange)} className="w-full px-2 py-1">
                    <div className="detail-page-title-block !py-4 !px-0 border-borderSecondary">
                        <div className="flex flex-col ">
                            <h2 className=" flex flex-row gap-1.5 items-center">
                                <div className="title-text-header">{t('Brand Settings')}</div>
                                <Tooltip className="bg-black text-white" content={role.name === ROLES.OWNER || role.name === ROLES.INDIVIDUAL ? 'Brand owner' : 'Team Member'}>
                                    <InfoCircle className="text-[#98A2B3] w-4 h-4 custom-tooltip-btn" />
                                </Tooltip>
                            </h2>
                            <p className="subtitle-text">{t('Update your brand details here.')}</p>
                        </div>
                    </div>
                    <div className="detail-page-data-block !border-b-0 !p-0">
                        {/* <div className="title-block">
                        <h3 className="title-h3">{t('Business info')}</h3>
                        <p className="text-xs text-secondaryTxtColor leading-[150%] -tracking-[0.12px]">{t('Business_info_description')}</p>
                    </div> */}
                        <div className="data-wrap-block w-full ">
                            <BusinessInfo errors={errors} setFile={setFile} cropData={cropData} setCropData={setCropData} />
                        </div>
                    </div>

                    <div className="detail-page-data-block !border-b-0 !p-0">
                        <div className="data-wrap-block w-full">
                            <OnlineLinks errors={errors} />
                        </div>
                    </div>
                    <div className="flex flex-row gap-3 w-full justify-end pt-5 mb-36">
                        <CustomButton secondary disabled={isLoading} type="button" onClick={resetForm} className="!h-[36px]">
                            {t('Cancel')}
                        </CustomButton>
                        <CustomButton type="submit" isLoading={isLoading} disabled={isLoading || !isSaveEnabled} primary className="!h-[36px]">
                            {t('Save Changes')}
                        </CustomButton>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};

export default BusinessDetails;
