import React, { useEffect, useMemo, useState } from 'react';
import SelectBox from 'src/components/SelectBox/SelectBox';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { allShopSettings, currentRole, currentShop, me } from 'src/redux/services/common/Common.slice';
import { INotifications } from './ClientNotifications.interface';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { API } from 'src/constants/api';
import { axiosPost } from 'src/utils/requestClient';
import { errorCode } from 'src/constants/errorCode';
import { useTranslation } from 'react-i18next';
import { InfoCircle } from '@untitled-ui/icons-react/build/cjs';
import CustomButton from 'src/components/CustomButton';
import Switch from 'src/components/Switch/Switch';
import { Tooltip } from 'flowbite-react';
import { ROLES } from 'src/constants/common';
type FieldKey = 'first_reminder' | 'second_reminder';

const ClientNotifications = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const role = useAppSelector(currentRole);
    const shop: any = useAppSelector(currentShop);
    const shopSettings = useAppSelector(allShopSettings);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaveEnabled, setSaveEnabled] = useState(false);

    const hours = useMemo(
        () => [
            { label: '1 hours', value: '1' },
            { label: '2 hours', value: '2' },
            { label: '3 hours', value: '3' },
            { label: '6 hours', value: '6' },
            { label: '8 hours', value: '8' },
            { label: '12 hours', value: '12' },
            { label: '16 hours', value: '16' },
            { label: '24 hours', value: '24' },
            { label: '48 hours', value: '48' },
            { label: '72 hours', value: '72' },
        ],
        [],
    );

    const schema = Yup.object().shape({
        first_reminder: Yup.string().required(t('This field is required')),
        second_reminder: Yup.string()
            .required('This field is required')
            .when('first_reminder', ([first_reminder], sch: any) =>
                sch.test('is-not-greater-than-first', 'Second reminder must be less than or equal to the first reminder', function (value: any) {
                    return parseInt(value, 10) <= parseInt(first_reminder, 10);
                }),
            ),
        is_first_reminder: Yup.boolean().required(),
        is_second_reminder: Yup.boolean().required(),
    });

    const extractClientNotificationsData = () => {
        const notificationReminder = shopSettings.find((setting: any) => setting.type === 'notification_reminder');
        if (notificationReminder) {
            const firstReminder = hours.find((item: any) => Number(item.value) === notificationReminder.value.first_reminder)?.value || hours[0].value;
            const secondReminder = hours.find((item: any) => Number(item.value) === notificationReminder.value.second_reminder)?.value || hours[0].value;
            const isFirstReminder = notificationReminder.value.is_first_reminder ? notificationReminder.value.is_first_reminder : false;
            const isSecondReminder = notificationReminder.value.is_first_reminder ? notificationReminder.value.is_first_reminder : false;
            setValue('first_reminder', firstReminder);
            setValue('second_reminder', secondReminder);
            setValue('is_first_reminder', isFirstReminder);
            setValue('is_second_reminder', isSecondReminder);
            reset({
                first_reminder: firstReminder,
                second_reminder: secondReminder,
                is_first_reminder: isFirstReminder,
                is_second_reminder: isSecondReminder,
            });
        } else {
            reset();
        }
    };

    useEffect(() => {
        extractClientNotificationsData();
    }, [shopSettings]);

    const {
        handleSubmit,
        control,
        setError,
        setValue,
        watch,
        reset,
        formState: { isDirty },
    } = useForm<INotifications>({
        resolver: yupResolver(schema),
        defaultValues: {
            first_reminder: hours[0].value,
            second_reminder: hours[0].value,
            is_first_reminder: false,
            is_second_reminder: false,
        },
    });

    const watchAllFields = watch();

    useEffect(() => {
        setSaveEnabled(isDirty);
    }, [watchAllFields, isDirty]);

    const handleSelectBoxChange = (value: boolean, type: any = null) => {
        if (type === 'is_first_reminder') {
            if (!value) {
                setValue('is_second_reminder', false);
            }
        } else if (type === 'is_second_reminder') {
            if (value) {
                setValue('is_first_reminder', true);
            }
        }
    };

    const handleChange = async (data: any) => {
        const payload = { ...data, first_reminder: Number(data.first_reminder), second_reminder: Number(data.second_reminder) };
        setIsLoading(true);
        await axiosPost(API.SETTING.REMINDER, payload, { shop_id: shop.id })
            .then(async (response) => {
                await dispatch(me());
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as FieldKey, {
                                type: 'manual',
                                message: response.data[field][0],
                            });
                        });
                    }
                    return;
                }
                toast.error(response.message);
            })
            .finally(() => setIsLoading(false));
    };

    const resetForm = () => {
        if (shopSettings) {
            extractClientNotificationsData();
        } else {
            reset();
        }
    };
    return (
        <div className="w-[full] flex-1">
            <form onSubmit={handleSubmit(handleChange)} className="w-full px-2 py-1">
                <div className="detail-page-title-block !py-4 border-borderSecondary !px-0">
                    <div className="flex flex-col">
                        <h2 className=" flex flex-row gap-1.5 items-center">
                            <div className="title-text-header">{t('Client Notifications')}</div>
                            <Tooltip className="bg-black text-white" content={role.name === ROLES.OWNER || role.name === ROLES.INDIVIDUAL ? 'Brand owner' : 'Team Member'}>
                                <InfoCircle className="text-[#98A2B3] w-4 h-4 custom-tooltip-btn" />
                            </Tooltip>
                        </h2>
                        <p className="subtitle-text">{t('Lorem ipsum dolor sit amet consectetur')}</p>
                    </div>
                    {/* <div className="flex flex-row gap-3">
                        <CustomButton secondary disabled={isLoading} onClick={resetForm} className="!h-[36px]">
                            {t('Cancel')}
                        </CustomButton>
                        <CustomButton primary type="submit" isLoading={isLoading} disabled={isLoading} className="!h-[36px]">
                            {t('Save Changes')}
                        </CustomButton>
                    </div> */}
                </div>

                <div className="detail-page-data-block gap-[32px] border-b  border-borderSecondary py-4 p-0 flex flex-1">
                    <div className=" w-[310px]">
                        <h3 className="title-text">{t('Set Appointment Reminders')}</h3>
                        <p className="subtitle-text">{t('Choose the timeframe to send automated reminder notifications to clients.')}</p>
                    </div>
                    <div className="data-wrap-block ">
                        <div className="flex flex-wrap mb-5">
                            <Controller
                                name="first_reminder"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                    <div className="w-[310px]">
                                        <SelectBox
                                            isClearable={false}
                                            isSearchable={false}
                                            name="first_reminder"
                                            id="first_reminder"
                                            label={t('First reminder')}
                                            options={hours}
                                            onChangeFunc={(option: any) => {
                                                onChange(option ? option.value : null);
                                            }}
                                            value={hours.filter((item: any) => item.value === value)}
                                            error={!!error}
                                        />
                                    </div>
                                )}
                            />
                            <div className="ml-2.5 ">
                                <Controller
                                    name="is_first_reminder"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="w-full mt-8">
                                            <Switch
                                                onChange={(event: any) => {
                                                    onChange(event.target.value);
                                                    handleSelectBoxChange(event.target.value, 'is_first_reminder');
                                                }}
                                                id="is_first_reminder"
                                                isChecked={value}
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap">
                            <Controller
                                name="second_reminder"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                    <div className="w-[310px]">
                                        <SelectBox
                                            isClearable={false}
                                            isSearchable={false}
                                            name="second_reminder"
                                            label={t('Second reminder')}
                                            id="second_reminder"
                                            options={hours}
                                            onChangeFunc={(option: any) => {
                                                onChange(option ? option.value : null);
                                            }}
                                            value={hours.filter((item: any) => item.value === value)}
                                            error={!!error}
                                        />
                                    </div>
                                )}
                            />

                            <div className="ml-2.5 ">
                                <Controller
                                    name="is_second_reminder"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="w-full mt-8">
                                            <Switch
                                                onChange={(event: any) => {
                                                    onChange(event.target.value);
                                                    handleSelectBoxChange(event.target.value, 'is_second_reminder');
                                                }}
                                                id="is_second_reminder"
                                                isChecked={value}
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" flex flex-row gap-3 w-full pt-5 mb-36 justify-end ">
                    <CustomButton secondary disabled={isLoading} onClick={resetForm} className="!h-[36px]">
                        {t('Cancel')}
                    </CustomButton>
                    <CustomButton primary type="submit" isLoading={isLoading} disabled={isLoading || !isSaveEnabled} className="!h-[36px]">
                        {t('Save Changes')}
                    </CustomButton>
                </div>
            </form>
        </div>
    );
};

export default ClientNotifications;
