import React, { useEffect, useState } from 'react';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useAppSelector } from 'src/redux/hooks';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { selectCalendarData } from '../../Calendar.slice';
import { Plus, X } from '@untitled-ui/icons-react/build/cjs';
import CustomButton from 'src/components/CustomButton';
import { IProduct } from './Product.interface';

const Product = ({}) => {
    const shop = useAppSelector(currentShop);
    const shopId = shop.id;
    const calendarData = useAppSelector(selectCalendarData);
    const [products, setProducts] = useState<IProduct[]>([]);
    const [allProducts, setAllProducts] = useState<any[]>([]);

    const [productOptionShow, setProductOptionShow] = useState<boolean>(false);

    const { setValue } = useFormContext();
    const { fields, remove, append } = useFieldArray({
        keyName: 'uuid',
        name: 'products',
    });

    useEffect(() => {
        if (allProducts.length) {
            let aggregatedProducts = fields.reduce((acc: any, item: any) => {
                const id = item.variantInfo.inventory.id;
                if (acc[id]) {
                    acc[id].quantity += 1;
                } else {
                    acc[id] = {
                        ...item,
                        id,
                        quantity: 1,
                    };
                }
                return acc;
            }, {});
            aggregatedProducts = Object.values(aggregatedProducts);

            const variants = allProducts.reduce((variantsAcc: any[], variant: any) => {
                const inventories = variant.variantInfo.inventories.reduce((inventoriesAcc: any[], inventory: any) => {
                    const aggregatedProduct = aggregatedProducts.find((item: any) => item.id === inventory.id);
                    const quantity = inventory.in_stock_quantity - (aggregatedProduct ? aggregatedProduct.quantity : 0);

                    if (quantity > 0) {
                        inventoriesAcc.push({ ...inventory, in_stock_quantity: quantity });
                    }
                    return inventoriesAcc;
                }, []);

                const isOutOfStock = inventories.length <= 0;
                variantsAcc.push({
                    ...variant,
                    rightLabel: isOutOfStock ? 'Out of stock' : `$${inventories[0].price}`,
                    variantInfo: { ...variant.variantInfo, inventories, inventory: isOutOfStock ? null : inventories[0] },
                });
                return variantsAcc;
            }, []);

            setProducts(variants);
        }
    }, [allProducts, fields]);

    const mergeVariants = async (Allproducts: any[]) =>
        Allproducts.reduce(
            (
                acc: string | any[],
                product: {
                    unit_of_measure: any;
                    name: any;
                    variants: any;
                },
            ) => {
                const updatedVariants = product.variants.map((variant: any) => ({
                    ...product,
                    value: variant.inventory.id, // Assuming product has a variant_type attribute
                    label: `${product.name.slice(0, 10)}${product.name.length > 10 ? '...' : ''} (${variant.size} ${product.unit_of_measure})`,
                    rightLabel: variant.inventory.price,
                    variantInfo: { ...variant, value: variant.inventory.id },
                }));
                return acc.concat(updatedVariants);
            },
            [],
        );

    const handleChangeProduct = (value: any) => {
        const selectedProduct = products.find((product) => product.value === value.value);
        setProductOptionShow(false);
        append(selectedProduct);
    };
    const getProducts = async () => {
        let params = {
            shop_id: shopId,
            id: calendarData.selectedLocation?.id,
        };
        const payload = {
            booking_id: calendarData.calendarStep.updateAppointment ? calendarData.bookedSlotInfo.id : null,
        };

        await axiosGet(API.PRODUCT.LOCATION_PRODUCT, params, payload)
            .then(async (response) => {
                // await dispatch(getAllShopSettings({ shop_id: shop.id }));
                const result = response.data.data;
                const mergedVariants = await mergeVariants(result);
                setAllProducts(mergedVariants);

                // return;
            })
            .catch((error) => {
                // if (error.response.data.status === errorCode.unprocessable) {
                //     if (error.response.data.data) {
                //         Object.keys(error.response.data.data).forEach((field) => {
                //             setError(field as FieldKey, {
                //                 type: 'manual',
                //                 message: error.response.data.data[field][0],
                //             });
                //         });
                //     }
                //     return;
                // }
                // toast.error(error.response.data.message);
            })
            .finally(() => {});
    };
    useEffect(() => {
        setValue('products', []);
        getProducts();
    }, [calendarData.selectedLocation]);

    const handleRemoveProduct = (index: any) => async () => {
        await remove(index);
        const updatedFields = fields.filter((_, i) => i !== index);
        setValue('products', updatedFields);
    };
    const handleIsOptionDisabled = (option: any) => option.variantInfo.inventories.length <= 0;
    console.log('fields', fields);

    return (
        <>
            <div className="mb-4">
                <div className="flex justify-between">
                    <label htmlFor="newclient" className={`fl-field-title-label ${products.length > 0 ? 'text-gray-700 normal-case text-xs font-semibold leading-[18px] mb-1.5' : ''}`}>
                        Select Product(s){' '}
                    </label>

                    {!productOptionShow && (
                        <CustomButton
                            type="button"
                            onClick={() => setProductOptionShow(true)}
                            className="!text-xs text-btnBg font-semibold flex items-center gap-1.5 h-[15px] shadow-none pr-0.5 "
                            icon={<Plus width="16" />}
                        >
                            Add Product
                        </CustomButton>
                    )}
                </div>
                <div className="flex flex-col gap-2">
                    {(productOptionShow || fields.length === 0) && (
                        <SelectBox
                            isSearchable
                            name="product"
                            // errorText={!!errors?.services}
                            id="Product"
                            rightLabelClassName="text-error-500"
                            options={products}
                            onChangeFunc={handleChangeProduct}
                            placeholder="Select a Product"
                            handleIsOptionDisabled={handleIsOptionDisabled}
                        />
                    )}

                    {/* {errors.services && <p className="text-error">{errors.services.message}</p>} */}
                    {fields.length > 0 && (
                        <div className="gap-2 flex flex-col">
                            {fields.map((item: any, index: any) => (
                                <>
                                    <div className="flex justify-between items-center p-2 border border-gray-300 rounded-md shadow-sm text-xs" key={index}>
                                        <div className="flex font-medium select-box-custom ">
                                            <span className="font-medium">{`${item.name} (${item.variantInfo.size} ${item.unit_of_measure})`}</span>
                                        </div>

                                        <div className="flex items-center space-x-2">
                                            <span className="font-medium ">${item.variantInfo.inventory.price}</span>
                                            <button className="text-gray-500 hover:text-red-500" type="button" onClick={handleRemoveProduct(index)}>
                                                <X width={16} height={16} />
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Product;
