import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomButton';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IFormData } from './PaymentSettings.interface';
import * as Yup from 'yup';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { currentRole, currentShop, me } from 'src/redux/services/common/Common.slice';
import { ChevronDown, InfoCircle } from '@untitled-ui/icons-react/build/cjs';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import DateSelect from 'src/components/DateSelect/DateSelect';
import moment from 'moment';
import { getDayNumber } from 'src/utils/global-functions';
import { Tooltip } from 'flowbite-react';
import { ROLES } from 'src/constants/common';
import { GoDotFill } from 'react-icons/go';

const PaymentSettings = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const role = useAppSelector(currentRole);
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);

    const schema = Yup.object({
        commission_frequency: Yup.string().required(t('This field is required')),
        commission_day: Yup.string().when('commission_frequency', {
            is: (value: string) => value === 'weekly' || value === 'bi_weekly',
            then: (currentSchema) => currentSchema.required(t('This field is required when commission frequency is Weekly or Bi-Weekly')),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        commission_start_date: Yup.string().when('commission_frequency', {
            is: (value: string) => value === 'weekly' || value === 'bi_weekly',
            then: (currentSchema) =>
                currentSchema
                    .required(t('This field is required when commission frequency is Weekly or Bi-Weekly'))
                    .test('commission_day', t('The date must be the same as the payment day'), function (value) {
                        const { commission_day: day } = this.parent;
                        if (!value || !day) {
                            return true;
                        }
                        const dayNumber = getDayNumber(day);
                        const currentDay = moment(value).day();
                        return currentDay === dayNumber;
                    }),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        commission_month_day: Yup.string().when('commission_frequency', {
            is: (value: string) => value === 'monthly',
            then: (currentSchema) => currentSchema.required(t('This field is required when commission frequency is Monthly')),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        rent_frequency: Yup.string().required(t('This field is required')),
        rent_day: Yup.string().when('rent_frequency', {
            is: (value: string) => value === 'weekly' || value === 'bi_weekly',
            then: (currentSchema) => currentSchema.required(t('This field is required when rent frequency is Weekly or Bi-Weekly')),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        rent_start_date: Yup.string().when('rent_frequency', {
            is: (value: string) => value === 'weekly' || value === 'bi_weekly',
            then: (currentSchema) =>
                currentSchema.required(t('This field is required when rent frequency is Weekly or Bi-Weekly')).test('rent_day', t('The date must be the same as the payment day'), function (value) {
                    const { rent_day: day } = this.parent;
                    if (!value || !day) {
                        return true;
                    }
                    const dayNumber = getDayNumber(day);
                    const currentDay = moment(value).day();
                    return currentDay === dayNumber;
                }),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        rent_month_day: Yup.string().when('rent_frequency', {
            is: (value: string) => value === 'monthly',
            then: (currentSchema) => currentSchema.required(t('This field is required when rent frequency is Monthly')),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        tip_frequency: Yup.string().required(t('This field is required')),
        tip_day: Yup.string().when('tip_frequency', {
            is: (value: string) => value === 'weekly' || value === 'bi_weekly',
            then: (currentSchema) => currentSchema.required(t('This field is required when tip frequency is Weekly or Bi-Weekly')),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        tip_start_date: Yup.string().when('tip_frequency', {
            is: (value: string) => value === 'weekly' || value === 'bi_weekly',
            then: (currentSchema) =>
                currentSchema.required(t('This field is required when tip frequency is Weekly or Bi-Weekly')).test('tip_day', t('The date must be the same as the payment day'), function (value) {
                    const { tip_day: day } = this.parent;
                    if (!value || !day) {
                        return true;
                    }
                    const dayNumber = getDayNumber(day);
                    const currentDay = moment(value).day();
                    return currentDay === dayNumber;
                }),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        tip_month_day: Yup.string().when('tip_frequency', {
            is: (value: string) => value === 'monthly',
            then: (currentSchema) => currentSchema.required(t('This field is required when tip frequency is Monthly')),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        product_commission_frequency: Yup.string().required(t('This field is required')),
        product_commission_day: Yup.string().when('product_commission_frequency', {
            is: (value: string) => value === 'weekly' || value === 'bi_weekly',
            then: (currentSchema) => currentSchema.required(t('This field is required when product commission frequency is Weekly or Bi-Weekly')),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        product_commission_start_date: Yup.string().when('product_commission_frequency', {
            is: (value: string) => value === 'weekly' || value === 'bi_weekly',
            then: (currentSchema) =>
                currentSchema
                    .required(t('This field is required when product commission frequency is Weekly or Bi-Weekly'))
                    .test('product_commission_day', t('The date must be the same as the payment day'), function (value) {
                        const { product_commission_day: day } = this.parent;
                        if (!value || !day) {
                            return true;
                        }
                        const dayNumber = getDayNumber(day);
                        const currentDay = moment(value).day();
                        return currentDay === dayNumber;
                    }),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        product_commission_month_day: Yup.string().when('product_commission_frequency', {
            is: (value: string) => value === 'monthly',
            then: (currentSchema) => currentSchema.required(t('This field is required when product commission frequency is Monthly')),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
    }).required();

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as Resolver<IFormData>,
        defaultValues: {
            commission_frequency: shop.shop_payment.commission_frequency || null,
            commission_day: shop.shop_payment.commission_day || null,
            commission_start_date: shop.shop_payment.commission_start_date || null,
            commission_month_day: shop.shop_payment.commission_month_day || null,
            rent_frequency: shop.shop_payment.rent_frequency || null,
            rent_start_date: shop.shop_payment.rent_start_date || null,
            rent_day: shop.shop_payment.rent_day || null,
            rent_month_day: shop.shop_payment.rent_month_day || null,
            tip_frequency: shop.shop_payment.tip_frequency || null,
            tip_day: shop.shop_payment.tip_day || null,
            tip_start_date: shop.shop_payment.tip_start_date || null,
            tip_month_day: shop.shop_payment.tip_month_day || null,
            product_commission_frequency: shop.shop_payment.product_commission_frequency || null,
            product_commission_day: shop.shop_payment.product_commission_day || null,
            product_commission_start_date: shop.shop_payment.product_commission_start_date || null,
            product_commission_month_day: shop.shop_payment.product_commission_month_day || null,
        },
    });

    const { handleSubmit, watch, setError, control, reset } = methods;
    const commissionDay = watch('commission_day');
    const rentDay = watch('rent_day');
    const tipDay = watch('tip_day');
    const productCommissionDay = watch('product_commission_day');

    const commissionFrequency = watch('commission_frequency');
    const rentFrequency = watch('rent_frequency');
    const tipFrequency = watch('tip_frequency');
    const productCommissionFrequency = watch('product_commission_frequency');

    const handleSave = async (data: IFormData) => {
        setIsLoading(true);
        await axiosPost(API.PAYMENT.UPDATE, data, { shop_id: shop.id })
            .then(async () => {
                await dispatch(me());
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as keyof IFormData, {
                                type: 'manual',
                                message: response.data[field][0],
                            });
                        });
                    }
                    return;
                }
                toast.error(response.message);
            })
            .finally(() => setIsLoading(false));
    };

    const isWeekday = (day: string) => (date: Date) => {
        const dayNumber = getDayNumber(day);
        const currentDay = moment(date).day();
        return currentDay === dayNumber;
    };

    const frequencyOptions = useMemo(
        () => [
            {
                value: 'weekly',
                label: t('Weekly'),
            },
            {
                value: 'bi_weekly',
                label: t('Bi-Weekly'),
            },
            {
                value: 'monthly',
                label: t('Monthly'),
            },
        ],
        [t],
    );

    const monthOptions = useMemo(
        () => [
            {
                value: 'first',
                label: t('First day of month'),
            },
            {
                value: 'last',
                label: t('Last day of month'),
            },
        ],
        [t],
    );

    const dayOptions = useMemo(
        () => [
            {
                value: 'sunday',
                label: t('Sunday'),
            },
            {
                value: 'monday',
                label: t('Monday'),
            },
            {
                value: 'tuesday',
                label: t('Tuesday'),
            },
            {
                value: 'wednesday',
                label: t('Wednesday'),
            },
            {
                value: 'thursday',
                label: t('Thursday'),
            },
            {
                value: 'friday',
                label: t('Friday'),
            },
            {
                value: 'saturday',
                label: t('Saturday'),
            },
        ],
        [t],
    );

    const resetForm = () => {
        reset();
    };

    return (
        <div className="w-full flex flex-col flex-1">
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(handleSave)} className="w-full px-2 py-1">
                    <div className="detail-page-title-block !py-4 !px-0 border-borderSecondary">
                        <div className="flex flex-col gap-0">
                            <h2 className="flex flex-row gap-1.5 items-center">
                                <div className="title-text-header">{t('Payment Structure Settings')}</div>
                                <Tooltip className="bg-black text-white" content={role.name === ROLES.OWNER || role.name === ROLES.INDIVIDUAL ? 'Brand owner' : 'Team Member'}>
                                    <InfoCircle className="text-[#98A2B3] w-4 h-4 custom-tooltip-btn" />
                                </Tooltip>
                            </h2>
                            <p className="subtitle-text">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}</p>
                        </div>
                        {/* <div className="flex flex-row gap-3">
                            <CustomButton secondary disabled={isLoading} type="button" onClick={resetForm} className="!h-[36px]">
                                {t('Cancel')}
                            </CustomButton>
                            <CustomButton primary isLoading={isLoading} disabled={isLoading} type="submit" className="!h-[36px]">
                                {t('Save Changes')}
                            </CustomButton>
                        </div> */}
                    </div>
                    <div className="fl-data-block border-b border-borderSecondary py-4">
                        <div className="title-block min-w-[310px] max-w-[310px] w-[310px] flex flex-col gap-1">
                            <h3 className="title-text flex flex-row items-center gap-1">
                                {t('Commission payout frequency')}{' '}
                                <span className="ml-1 font-medium text-xs userCounter">
                                    <GoDotFill size={12} color="#17B26A" className="h-3 w-3 rounded-full flex mr-0.5" />
                                    {commissionFrequency.replace(/_/g, '-')}
                                </span>
                            </h3>
                            <p className="subtitle-text">{t('Set the frequency at which your staff will automatically be paid based on their commission split')}</p>
                        </div>
                        <div className="data-wrap-block w-full ">
                            {/* <h3 className="title-text">
                                {t('Commission payout frequency:')}{' '}
                                <span className="ml-1 font-medium text-xs userCounter">
                                    <GoDotFill size={12} color="#17B26A" className="h-3 w-3 rounded-full flex mr-0.5" />
                                    {commissionFrequency.replace(/_/g, '-')}
                                </span>
                            </h3>
                            <p className="subtitle-text">
                                {t('Set the commission payout frequency that all staff members on the commission payment structure will follow by default. This enables a consistent payout window.')}
                            </p> */}
                            <div className="  pb-4 ">
                                <div className="w-[600px] flex flex-row gap-4 justify-between  items-center">
                                    <h3 className="title-text">{t('Frequency')}</h3>
                                    <Controller
                                        name="commission_frequency"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div className="max-w-[320px] w-[320px]">
                                                <SelectBox
                                                    name="commission_frequency"
                                                    // label={t('Frequency')}
                                                    options={frequencyOptions}
                                                    onChangeFunc={(option: any) => onChange(option.value)}
                                                    value={frequencyOptions.find((option) => option.value === value)}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                />
                                                {error && <p className="text-error">{error?.message}</p>}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>

                            {commissionFrequency === 'monthly' && (
                                <div className="  pb-4 ">
                                    <div className="w-[600px] flex flex-row gap-4 justify-between  items-center">
                                        <h3 className="title-text">{t('Select day of month')}</h3>
                                        <Controller
                                            name="commission_month_day"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="max-w-[320px] w-[320px]">
                                                    <SelectBox
                                                        name="commission_month_day"
                                                        // label={t('Select day of month')}
                                                        options={monthOptions}
                                                        onChangeFunc={(option: any) => onChange(option.value)}
                                                        value={monthOptions.find((option) => option.value === value)}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                    />
                                                    {error && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            )}

                            {(commissionFrequency === 'weekly' || commissionFrequency === 'bi_weekly') && (
                                <>
                                    <div className="pb-4 ">
                                        <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                            <h3 className="title-text">{t('Payment day')}</h3>

                                            <Controller
                                                name="commission_day"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                    <div className="max-w-[320px] w-[320px]">
                                                        <SelectBox
                                                            name="commission_day"
                                                            // label={t('Payment day')}
                                                            options={dayOptions}
                                                            onChangeFunc={(option: any) => onChange(option.value)}
                                                            value={dayOptions.find((option) => option.value === value)}
                                                            isClearable={false}
                                                            isSearchable={false}
                                                        />
                                                        {error && <p className="text-error">{error.message}</p>}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="pb-4 ">
                                        <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                            <h3 className="title-text">{t('Window start date')}</h3>

                                            <Controller
                                                name="commission_start_date"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                    <div className="max-w-[320px] w-[320px] !h-[36px]">
                                                        <DateSelect
                                                            inputName="commission_start_date"
                                                            className={`form_control_date font-medium text-xs relative w-full ${error && 'is-invalid'}`}
                                                            placeholder={t('Select date')}
                                                            dateFormat="yyyy-MM-dd"
                                                            minDate={new Date()}
                                                            value={value}
                                                            onChangeValue={onChange}
                                                            filterDate={isWeekday(commissionDay || '')}
                                                            labelClassName="!mb-0 "
                                                            icon={<ChevronDown className="text-gray-700 w-[22px]" />}
                                                        />
                                                        {error && <p className="text-error">{error.message}</p>}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className=" flex flex-row bg-gray-50  items-center w-[600px] justify-center px-2 rounded-[8px] h-[36px] border border-borderSecondary ">
                                <span className="mr-[2px] font-medium text-xs ">
                                    <GoDotFill size={12} color="#3570F8" className="h-3 w-3 rounded-full flex mr-0.5" />
                                </span>
                                <div className="title-text">{t('Your next payment cycle will be')}</div>
                                <div className="ml-2 flex flex-row gap-1 items-center border border-borderSecondary  pl-2 pr-2 py-[2px]  title-text text-[#3570F8] rounded-[8px] shadow-sm bg-white">
                                    {t('1st January, 2024 - 15th January, 2024')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fl-data-block border-b border-borderSecondary py-4">
                        <div className="title-block w-[310px] max-w-[310px] min-w-[310px] flex flex-col  gap-1 ">
                            <h3 className="title-text flex flex-row gap-1 text-start">
                                {t('Tip payout frequency')}{' '}
                                <span className="ml-1 font-medium text-xs userCounter">
                                    <GoDotFill size={12} color="#17B26A" className="h-3 w-3 rounded-full flex mr-0.5" />
                                    {tipFrequency.replace(/_/g, '-')}
                                </span>
                            </h3>
                            <p className="subtitle-text">{t('Set the frequency at which your staff will automatically be paid based on their tip split')}</p>
                        </div>

                        <div className="data-wrap-block w-full max-2xl:w-[480px] max-w-[636px]">
                            {/* <h3 className="title-text">
                                {t('Tip payout frequency:')}{' '}
                                <span className="ml-1 font-medium text-xs userCounter">
                                    <GoDotFill size={12} color="#17B26A" className="h-3 w-3 rounded-full flex mr-0.5" />
                                    {tipFrequency.replace(/_/g, '-')}
                                </span>
                            </h3>
                            <p className="subtitle-text">
                                {t('Set the tip payout frequency that all staff members on the tip payment structure will follow by default. This enables a consistent payout window.')}
                            </p> */}
                            <div className="flex flex-col gap-4">
                                <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                    <h3 className="title-text">{t('Frequency')}</h3>
                                    <Controller
                                        name="tip_frequency"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div className="max-w-[320px] w-[320px]">
                                                <SelectBox
                                                    name="tip_frequency"
                                                    // label={t('Frequency')}
                                                    options={[{ value: 'daily', label: t('Daily') }].concat(frequencyOptions)}
                                                    onChangeFunc={(option: any) => onChange(option.value)}
                                                    value={[{ value: 'daily', label: t('Daily') }].concat(frequencyOptions).find((option) => option.value === value)}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                />
                                                {error && <p className="text-error">{error?.message}</p>}
                                            </div>
                                        )}
                                    />
                                </div>
                                {tipFrequency === 'monthly' && (
                                    <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                        <h3 className="title-text">{t('Select day of month')}</h3>
                                        <Controller
                                            name="tip_month_day"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="max-w-[320px] w-[320px]">
                                                    <SelectBox
                                                        name="tip_month_day"
                                                        // label={t('Select day of month')}
                                                        options={monthOptions}
                                                        onChangeFunc={(option: any) => onChange(option.value)}
                                                        value={monthOptions.find((option) => option.value === value)}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                    />
                                                    {error && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                )}
                                {(tipFrequency === 'weekly' || tipFrequency === 'bi_weekly') && (
                                    <>
                                        <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                            <h3 className="title-text">{t('Payment day')}</h3>
                                            <Controller
                                                name="tip_day"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                    <div className="max-w-[320px] w-[320px]">
                                                        <SelectBox
                                                            name="tip_day"
                                                            // label={t('Payment day')}
                                                            placeholder={t('Select payment day')}
                                                            options={dayOptions}
                                                            onChangeFunc={(option: any) => onChange(option.value)}
                                                            value={dayOptions.find((option) => option.value === value)}
                                                            isClearable={false}
                                                            isSearchable={false}
                                                        />
                                                        {error && <p className="text-error">{error.message}</p>}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                            <h3 className="title-text">{t('Window start date')}</h3>
                                            <Controller
                                                name="tip_start_date"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                    <div className="max-w-[320px] w-[320px]">
                                                        <DateSelect
                                                            // label={t('Window start date')}
                                                            inputName="tip_start_date"
                                                            className={`form_control_date font-medium text-xs relative w-full ${error && 'is-invalid'}`}
                                                            placeholder={t('Select date')}
                                                            dateFormat="yyyy-MM-dd"
                                                            minDate={new Date()}
                                                            value={value}
                                                            onChangeValue={onChange}
                                                            filterDate={isWeekday(tipDay || '')}
                                                            icon={<ChevronDown className="text-gray-700 w-[22px]" />}
                                                        />
                                                        {error && <p className="text-error">{error.message}</p>}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </>
                                )}
                                <div className=" flex flex-row bg-gray-50  items-center w-[600px] justify-center px-2 rounded-[8px] h-[36px] border border-borderSecondary ">
                                    <span className="mr-[2px] font-medium text-xs ">
                                        <GoDotFill size={12} color="#3570F8" className="h-3 w-3 rounded-full flex mr-0.5" />
                                    </span>
                                    <div className="title-text">{t('Your next payment cycle will be')}</div>
                                    <div className="ml-2 flex flex-row gap-1 items-center border border-borderSecondary  pl-2 pr-2 py-[2px]  title-text text-[#3570F8] rounded-[8px] shadow-sm bg-white">
                                        {t('1st January, 2024 - 15th January, 2024')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fl-data-block border-b border-borderSecondary py-4">
                        <div className="title-block min-w-[310px] max-w-[310px] w-[310px]">
                            <h3 className="title-text flex flex-row gap-1.5">
                                {t('Rent collection frequency:')}{' '}
                                <span className="ml-1 font-medium text-xs userCounter">
                                    <GoDotFill size={12} color="#17B26A" className="h-3 w-3 rounded-full flex mr-0.5" />
                                    {rentFrequency.replace(/_/g, '-')}
                                </span>
                            </h3>
                            <p className="subtitle-text">{t('Set the frequency at which you wish to automatically collect payments for all of your staff on rent.')}</p>
                        </div>

                        <div className="flex flex-col gap-4">
                            <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                <h3 className="title-text">{t('Frequency')}</h3>
                                <Controller
                                    name="rent_frequency"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                        <div className="max-w-[320px] w-[320px]">
                                            <SelectBox
                                                name="rent_frequency"
                                                options={frequencyOptions}
                                                onChangeFunc={(option: any) => onChange(option.value)}
                                                value={frequencyOptions.find((option) => option.value === value)}
                                                isClearable={false}
                                                isSearchable={false}
                                            />
                                            {error && <p className="text-error">{error?.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            {rentFrequency === 'monthly' && (
                                <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                    <h3 className="title-text">{t('Select day of month')}</h3>
                                    <Controller
                                        name="rent_month_day"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div className="max-w-[320px] w-[320px]">
                                                <SelectBox
                                                    name="rent_month_day"
                                                    options={monthOptions}
                                                    onChangeFunc={(option: any) => onChange(option.value)}
                                                    value={monthOptions.find((option) => option.value === value)}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                />
                                                {error && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                </div>
                            )}
                            {(rentFrequency === 'weekly' || rentFrequency === 'bi_weekly') && (
                                <>
                                    <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                        <h3 className="title-text">{t('Payment day')}</h3>
                                        <Controller
                                            name="rent_day"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="max-w-[320px] w-[320px]">
                                                    <SelectBox
                                                        name="rent_day"
                                                        placeholder={t('Select Payment day')}
                                                        options={dayOptions}
                                                        onChangeFunc={(option: any) => onChange(option.value)}
                                                        value={dayOptions.find((option) => option.value === value)}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                    />
                                                    {error && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                        <h3 className="title-text">{t('Window start date')}</h3>
                                        <Controller
                                            name="rent_start_date"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="max-w-[320px] w-[320px]">
                                                    <DateSelect
                                                        // label={t('Window start date')}
                                                        inputName="rent_start_date"
                                                        className={`form_control_date font-medium text-xs relative w-full ${error && 'is-invalid'}`}
                                                        placeholder={t('Select date')}
                                                        dateFormat="yyyy-MM-dd"
                                                        minDate={new Date()}
                                                        value={value}
                                                        onChangeValue={onChange}
                                                        filterDate={isWeekday(rentDay || '')}
                                                    />
                                                    {error && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </>
                            )}

                            <div className=" flex flex-row bg-gray-50  items-center w-[600px] justify-center px-2 rounded-[8px] h-[36px] border border-borderSecondary ">
                                <span className="mr-[2px] font-medium text-xs ">
                                    <GoDotFill size={12} color="#3570F8" className="h-3 w-3 rounded-full flex mr-0.5" />
                                </span>
                                <div className="title-text">{t('Your next payment cycle will be')}</div>
                                <div className="ml-2 flex flex-row gap-1 items-center border border-borderSecondary  pl-2 pr-2 py-[2px]  title-text text-[#3570F8] rounded-[8px] shadow-sm bg-white">
                                    {t('1st January, 2024 - 15th January, 2024')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fl-data-block border-b border-borderSecondary py-4">
                        <div className="title-block w-[310px]">
                            <h3 className="title-text">
                                {t('Product commission frequency')}
                                <span className="ml-1 font-medium text-xs userCounter">
                                    <GoDotFill size={12} color="#17B26A" className="h-3 w-3 rounded-full flex mr-0.5" />
                                    {productCommissionFrequency.replace(/_/g, '-')}
                                </span>
                            </h3>
                            <p className="subtitle-text">{t('Set the frequency at which your staff will automatically be paid based on their product commission split')}</p>
                        </div>

                        <div className="flex flex-col gap-4">
                            <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                <h3 className="title-text">{t('Frequency')}</h3>
                                <Controller
                                    name="product_commission_frequency"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                        <div className="max-w-[320px] w-[320px]">
                                            <SelectBox
                                                name="product_commission_frequency"
                                                options={frequencyOptions}
                                                onChangeFunc={(option: any) => onChange(option.value)}
                                                value={frequencyOptions.find((option) => option.value === value)}
                                                isClearable={false}
                                                isSearchable={false}
                                            />
                                            {error && <p className="text-error">{error?.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            {productCommissionFrequency === 'monthly' && (
                                <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                    <h3 className="title-text">{t('Select day of month')}</h3>
                                    <Controller
                                        name="product_commission_month_day"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div className="max-w-[320px] w-[320px]">
                                                <SelectBox
                                                    name="product_commission_month_day"
                                                    options={monthOptions}
                                                    onChangeFunc={(option: any) => onChange(option.value)}
                                                    value={monthOptions.find((option) => option.value === value)}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                />
                                                {error && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                </div>
                            )}

                            {(productCommissionFrequency === 'weekly' || productCommissionFrequency === 'bi_weekly') && (
                                <>
                                    <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                        <h3 className="title-text">{t('Payment day')}</h3>
                                        <Controller
                                            name="product_commission_day"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="max-w-[320px] w-[320px]">
                                                    <SelectBox
                                                        name="product_commission_day"
                                                        placeholder={t('Select Payment day')}
                                                        options={dayOptions}
                                                        onChangeFunc={(option: any) => onChange(option.value)}
                                                        value={dayOptions.find((option) => option.value === value)}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                    />
                                                    {error && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                        <h3 className="title-text">{t('Window start date')}</h3>
                                        <Controller
                                            name="product_commission_start_date"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="max-w-[320px] w-[320px]">
                                                    <DateSelect
                                                        inputName="product_commission_start_date"
                                                        className={`form_control_date font-medium text-xs relative w-full ${error && 'is-invalid'}`}
                                                        placeholder={t('Select date')}
                                                        dateFormat="yyyy-MM-dd"
                                                        minDate={new Date()}
                                                        value={value}
                                                        onChangeValue={onChange}
                                                        filterDate={isWeekday(productCommissionDay || '')}
                                                        icon={<ChevronDown className="text-gray-700 w-[22px]" />}
                                                    />
                                                    {error && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </>
                            )}
                            <div className=" flex flex-row bg-gray-50  items-center w-[600px] justify-center px-2 rounded-[8px] h-[36px] border border-borderSecondary ">
                                <span className="mr-[2px] font-medium text-xs ">
                                    <GoDotFill size={12} color="#3570F8" className="h-3 w-3 rounded-full flex mr-0.5" />
                                </span>
                                <div className="title-text">{t('Your next payment cycle will be')}</div>
                                <div className="ml-2 flex flex-row gap-1 items-center border border-borderSecondary  pl-2 pr-2 py-[2px]  title-text text-[#3570F8] rounded-[8px] shadow-sm bg-white">
                                    {t('1st January, 2024 - 15th January, 2024')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row gap-3 justify-end pt-5 mb-36 w-full">
                        <CustomButton secondary disabled={isLoading} type="button" onClick={resetForm} className="!h-[36px]">
                            {t('Cancel')}
                        </CustomButton>
                        <CustomButton primary isLoading={isLoading} disabled={isLoading} type="submit" className="!h-[36px]">
                            {t('Save Changes')}
                        </CustomButton>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};

export default PaymentSettings;
