export const currencyOptions = [
    { label: 'CAD', value: 'CAD' },
    { label: 'USD', value: 'USD' },
];

export const perPageOptions = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '75', value: 75 },
    { label: '100', value: 100 },
];

export const timeTypeOptions = [
    { value: 'am', label: 'AM' },
    { value: 'pm', label: 'PM' },
];

export const initAction = {
    delete: false,
    WorkingHours: false,
};

export type FieldName = 'profile_image_name' | 'location_image_name' | null;

export interface ExistingFormData {
    [key: string]: any;
}

export interface IFormData {
    name: string;
    phone_country_code: string;
    phone: string;
    timezone: string;
    currency: string;
    profile_image_name: string;
    location_image_name: string;
    tax_id: string;
    sales_tax_id: string;
    country: string;
    city: string;
    street: string;
    postal_code: string;
    latitude: string;
    longitude: string;
    tips_type: string;
    custom_tip?: string;
    tips: Array<string>;
    hours: Array<IWorkingHoursValue>;
}

export interface ISelectBox {
    label: string;
    value: string;
}

export interface ISalesTaxResponse {
    id: number;
    territory: string;
    type: string;
    gst: string;
    hst: string;
    pst: string;
}

export interface ILocation {
    fullAddress: string;
    street: string;
    city: string;
    country: string;
    postal_code: string;
    latitude: string | null;
    longitude: string | null;
}

export interface IWorkingHoursValue {
    day: 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';
    status: NonNullable<boolean | undefined>;
    from_time_hours: string;
    to_time_hours: string;
    from_time_type: 'am' | 'pm';
    to_time_type: 'am' | 'pm';
}

export const InitWorkingHours: IWorkingHoursValue[] = [
    {
        day: 'monday',
        status: false,
        from_time_hours: '00:00',
        to_time_hours: '00:00',
        from_time_type: 'am',
        to_time_type: 'pm',
    },
    {
        day: 'tuesday',
        status: false,
        from_time_hours: '00:00',
        to_time_hours: '00:00',
        from_time_type: 'am',
        to_time_type: 'pm',
    },
    {
        day: 'wednesday',
        status: false,
        from_time_hours: '00:00',
        to_time_hours: '00:00',
        from_time_type: 'am',
        to_time_type: 'pm',
    },
    {
        day: 'thursday',
        status: false,
        from_time_hours: '00:00',
        to_time_hours: '00:00',
        from_time_type: 'am',
        to_time_type: 'pm',
    },
    {
        day: 'friday',
        status: false,
        from_time_hours: '00:00',
        to_time_hours: '00:00',
        from_time_type: 'am',
        to_time_type: 'pm',
    },
    {
        day: 'saturday',
        status: false,
        from_time_hours: '00:00',
        to_time_hours: '00:00',
        from_time_type: 'am',
        to_time_type: 'pm',
    },
    {
        day: 'sunday',
        status: false,
        from_time_hours: '00:00',
        to_time_hours: '00:00',
        from_time_type: 'am',
        to_time_type: 'pm',
    },
];

export interface IViewStaffLocation {
    frequency_booking: string;
    id: number | null;
    hours: IWorkingHoursValue[];
}
