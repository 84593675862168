import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from 'src/components/PageHeader';
import SalesTable from './SalesTable';

const Sales = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        setIsLoading(true);
    }, []);

    return (
        <div className="inner-page-wrape pb-5">
            <PageHeader title={t('Sales')} subtitle={t('Manage your client and their account permissions here.')}></PageHeader>
            <div className="side-spaching flex flex-1 flex-col w-full  mt-1">
                <SalesTable />
            </div>
        </div>
    );
};

export default Sales;
