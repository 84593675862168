import React, { useState } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { IFormData } from './Support.interface';
import { errorCode } from 'src/constants/errorCode';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { useTranslation } from 'react-i18next';
import PageHeader from 'src/components/PageHeader';
import { Mail02, MarkerPin02, Phone } from '@untitled-ui/icons-react/build/cjs';
import SelectBox from 'src/components/SelectBox/SelectBox';
import CustomButton from 'src/components/CustomButton';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import InputWithLabel from 'src/components/InputWithLabel';

const typeOptions = [
    {
        label: 'General',
        value: 'general',
    },
    {
        label: 'Bug',
        value: 'bug',
    },
    {
        label: 'Feature',
        value: 'feature',
    },
    {
        label: 'Request',
        value: 'request',
    },
];

const Support = () => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);

    const schema = Yup.object().shape({
        shop_id: Yup.number().required('This field is required'),
        name: Yup.string().required('This field is required'),
        email: Yup.string().email('Please enter valid email address').required('This field is required'),
        type: Yup.string().required(t('This field is required')),
        message: Yup.string().required('This field is required'),
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        reset,
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            shop_id: shop.id,
            name: '',
            email: '',
            message: '',
            type: '',
        },
    });

    const resetForm = () => {
        reset();
    };

    const handleSave = async (data: IFormData, event: any) => {
        setIsLoading(true);
        axiosPost(API.USER.CREATE_CONTACT_US, data, { shop_id: shop.id })
            .then(async () => {
                reset();
            })
            .catch((error) => {
                if (error.data.status === errorCode.unprocessable) {
                    if (error.data.data) {
                        Object.keys(error.data.data).forEach((field) => {
                            setError(field as keyof IFormData, {
                                type: 'manual',
                                message: error.data.data[field][0],
                            });
                        });
                    }
                    return;
                }
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <div className="inner-page-wrape">
            <PageHeader title={'Support'} subtitle="Lorem ipsum dolor sit amet consectetur"></PageHeader>
            <form onSubmit={handleSubmit(handleSave)} className="side-spaching flex flex-1 flex-col w-full">
                <div className="w-full flex flex-row  justify-between border-b border-borderSecondary pt-2 pb-5">
                    <div className="flex flex-row gap-[32px] w-full">
                        <div className=" max-w-[280px] min-w-[280px] w-[280px]">
                            <p className="title-text-header ">{t('Reach out to us at')}</p>
                            <p className="subtitle-text">{t('Don`t hesitate to contact us')}</p>
                        </div>
                        <div className="flex flex-col w-full">
                            <div className="flex flex-row gap-4 justify-between items-center  pb-4 border-b">
                                <div className="flex flex-row gap-3 items-center">
                                    <div className="flex justify-center items-center w-[44px] h-[44px] rounded-lg border border-[#D0D5DD]">
                                        <MarkerPin02 className="w-6 h-6 text-black" />
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="text-sm text-[#344054] font-semibold">Address</div>
                                        <div className="text-sm text-[#667085] font-normal">{t('1234 Post Avenue Remington')}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row gap-4 justify-between items-center  py-4 border-b">
                                <div className="flex flex-row gap-3 items-center">
                                    <div className="flex justify-center items-center w-[44px] h-[44px] rounded-lg border border-[#D0D5DD]">
                                        <Mail02 className="w-6 h-6 text-black" />
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="text-sm text-[#344054] font-semibold">Email</div>
                                        <div className="text-sm text-[#667085] font-normal">{t('contact@flair.com')}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row gap-4 justify-between items-center  pt-4 pb-2">
                                <div className="flex flex-row gap-3 items-center">
                                    <div className="flex justify-center items-center w-[44px] h-[44px] rounded-lg border border-[#D0D5DD]">
                                        <Phone className="w-6 h-6 text-black" />
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="text-sm text-[#344054] font-semibold">Phone</div>
                                        <div className="text-sm text-[#667085] font-normal">{t('+1 234 567 890')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full flex flex-row  justify-between border-b border-borderSecondary py-5">
                    <div className="flex flex-row gap-[32px] w-full">
                        <div className=" max-w-[280px] min-w-[280px] w-[280px]">
                            <p className="title-text-header ">{t('Get in touch with us')}</p>
                            <p className="subtitle-text">{t('We welcome any questions, messages or suggestions.')}</p>
                        </div>
                        <div className="flex flex-col gap-4 w-[512px] max-2xl:w-[400px]">
                            <div className="flex flex-row gap-4">
                                <Controller
                                    name="name"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="w-1/2 ">
                                            <InputWithLabel name="name" placeholder={t('Your Name')} value={value} onChange={onChange} error={!!errors.name} />
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="w-1/2 ">
                                            <InputWithLabel name="email" placeholder={t('Email Address')} value={value} onChange={onChange} error={!!errors.email} />
                                        </div>
                                    )}
                                />
                            </div>
                            <Controller
                                name="type"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                    <div className="">
                                        <SelectBox
                                            name="type"
                                            id="type"
                                            placeholder={t('Select type')}
                                            required
                                            options={typeOptions}
                                            onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                            value={typeOptions.find((option) => option.value === value)}
                                            error={!!error}
                                            isSearchable={false}
                                            isClearable={false}
                                        />
                                    </div>
                                )}
                            />
                            <Controller
                                name="message"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="w-full">
                                        <InputWithLabel
                                            textArea
                                            rows={5}
                                            name="message"
                                            label={t('Message')}
                                            textAearClassName="rounded-3xl h-[150px]"
                                            placeholder={t('Enter a description...')}
                                            value={value}
                                            onChange={onChange}
                                            error={!!errors.message}
                                        />
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-row gap-3 pt-5 mb-36 justify-end w-full">
                    <CustomButton secondary type="reset" onClick={resetForm} disabled={isLoading}>
                        {t('Cancel')}
                    </CustomButton>
                    <CustomButton primary type="submit" isLoading={isLoading} disabled={isLoading}>
                        {t('Save Changes')}
                    </CustomButton>
                </div>
            </form>
        </div>
    );
};

export default Support;
