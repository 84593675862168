import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ColorPicker from 'src/components/ColorPicker';
import InputWithLabel from 'src/components/InputWithLabel';
import SelectBox from 'src/components/SelectBox/SelectBox';
import * as Yup from 'yup';
import { Dark, IconCheckdActive, Light, LightCurved, LightHorizontal, LightMinimal, LightSharp } from 'src/theme/Images';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { allShopSettings, currentShop, me } from 'src/redux/services/common/Common.slice';
import { toast } from 'react-toastify';
import { COLORS, FONT_FAMILY, IFormData, LANGUAGES } from './General.interface';
import CustomButton from 'src/components/CustomButton';
import { Tooltip } from 'flowbite-react';

const General = () => {
    const { t } = useTranslation();
    const shop: any = useAppSelector(currentShop);
    const shopSettingInfo = useAppSelector(allShopSettings);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();

    const schema = Yup.object().shape({
        // slug: Yup.string()
        //     .required(t('This field is required'))
        //     .matches(/^[a-zA-Z0-9-_]+$/, t('Special characters or space not allowed')),
        brand_color: Yup.string().required(t('This field is required')),
        brand_text_color: Yup.string().required(t('This field is required')),
        font_family: Yup.string().required(t('This field is required')),
        base_theme: Yup.string().required(t('This field is required')),
        menu_placement: Yup.string().required(t('This field is required')),
        element_style: Yup.string().required(t('This field is required')),
        primary_language: Yup.string().required(t('This field is required')),
        // secondary_language: Yup.string().required(t('This field is required')),
    });
    const {
        handleSubmit,
        control,
        formState: { errors },
        getValues,
        setValue,
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            // slug: shop.slug || '',
            brand_color: '',
            brand_text_color: '',
            font_family: '',
            base_theme: 'light',
            menu_placement: 'vertical_left',
            element_style: 'minimal',
            primary_language: LANGUAGES[0].value,
            secondary_language: '',
        },
    });

    useEffect(() => {
        const appearance = shopSettingInfo.find((setting: any) => setting.type === 'appearance');
        // setValue('slug', shop.slug);
        if (appearance) {
            setValue('brand_color', appearance?.value?.brand_color || '');
            setValue('brand_text_color', appearance?.value?.brand_text_color || '');
            setValue('font_family', appearance?.value?.font_family || '');
            setValue('base_theme', appearance?.value?.base_theme || 'light');
            setValue('menu_placement', appearance?.value?.menu_placement || 'vertical_left');
            setValue('element_style', appearance?.value?.element_style || 'minimal');
            setValue('primary_language', (appearance?.value?.languages && appearance?.value?.languages[0]) || LANGUAGES[0].value);
            setValue('secondary_language', (appearance?.value?.languages && appearance?.value?.languages[1]) || '');
        }
    }, [shop]);

    const handlePrimaryLanguageChange = (selectedLanguage: string) => {
        const currentSecondaryLanguage = getValues('secondary_language');
        if (selectedLanguage === currentSecondaryLanguage) {
            const newSecondaryLanguage = LANGUAGES.find((language) => language.value !== selectedLanguage)?.value || '';
            setValue('secondary_language', newSecondaryLanguage);
        }
    };

    const handleSecondaryLanguageChange = (selectedLanguage: string) => {
        const currentPrimaryLanguage = getValues('primary_language');
        if (selectedLanguage === currentPrimaryLanguage) {
            const newPrimaryLanguage = LANGUAGES.find((language) => language.value !== selectedLanguage)?.value || '';
            setValue('primary_language', newPrimaryLanguage);
        }
    };
    const handleChange = async (data: any) => {
        setIsLoading(true);
        const payload = {
            // slug: data.slug,
            brand_color: data.brand_color,
            brand_text_color: data.brand_text_color,
            font_family: data.font_family,
            base_theme: data.base_theme,
            menu_placement: data.menu_placement,
            element_style: data.element_style,
            languages: data.secondary_language ? [data.primary_language, data.secondary_language] : [data.primary_language],
        };

        try {
            const response = await axiosPost(API.THEME.GENERAL, payload, {
                shop_id: shop.id,
            });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                await dispatch(me());
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="w-full pt-5 px-2">
            <form onSubmit={handleSubmit(handleChange)} className="flex flex-col gap-5">
                {/* <div className="flex flex-row">
                    <div className="flex flex-col flex-1"> */}
                {/* <Controller
                            name={`slug`}
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <div className="fl-data-block ">
                                    <div className="title-block w-[310px]">
                                        <h3 className="title-text">{t('Website URL')}</h3>
                                        <p className="subtitle-text">{t('Configure the domain of your live website')}</p>
                                    </div>
                                    <div className="data-wrap-block max-w-[512px] gap-4 flex xxl:max-w-[700px]">
                                        <div className="w-full max-w-[310px]">
                                            <InputWithLabel
                                                labelIcon={<Url />}
                                                inputControlClassName="pl-0"
                                                labelEndIcon=".getflair.ca"
                                                labelEndIconClassName="font-medium"
                                                onChange={onChange}
                                                value={value}
                                                name="slug"
                                                error={!!errors.slug}
                                            />
                                            {errors?.slug && <p className="text-error">{errors?.slug?.message}</p>}
                                        </div>
                                        <div className="title-block w-[280px]">
                                            <CustomButton isLoading={isLoading} disabled={isLoading} secondary onClick={handlepublished} className="!px-4 py-[9px] rounded-lg shadow-InputAndButton">
                                                {shop.is_slug_active ? 'Unpublished' : 'Published'}
                                            </CustomButton>
                                        </div>
                                    </div>
                                </div>
                            )}
                        /> */}
                {/* </div>
                </div> */}

                {/* <hr className="divider" /> */}
                <Controller
                    name={`base_theme`}
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                        <div className="flex justify-between items-start ">
                            <div className="fl-data-block">
                                <div className="title-block w-[310px]">
                                    <h3 className="title-text">{t('Base theme')}</h3>
                                    <p className="subtitle-text">{t('Select the base color theme of your website')}</p>
                                </div>
                                <div className="data-wrap-block max-w-[512px] xxl:max-w-[700px]">
                                    <div className="flex gap-5">
                                        <div className="w-[196px]">
                                            <div className={`theme-box p-1 mb-3 ${value === 'light' ? 'active' : ''}`} onClick={() => onChange('light')}>
                                                <i className="icon">
                                                    <img src={IconCheckdActive} alt="" className="" />
                                                </i>
                                                <div className="theme-block-thumb rounded-xl overflow-hidden">
                                                    <img src={Light} height={'132px'} width={'192px'} alt="" />
                                                </div>
                                            </div>
                                            <p className="theme-box-title">Light</p>
                                        </div>
                                        <div className="w-[196px]">
                                            <div className={`theme-box p-1 mb-3 ${value === 'dark' ? 'active' : ''}`} onClick={() => onChange('dark')}>
                                                <i className="icon">
                                                    <img src={IconCheckdActive} alt="" className="" />
                                                </i>
                                                <div className="theme-block-thumb rounded-xl overflow-hidden">
                                                    <img src={Dark} height={'132px'} width={'192px'} alt="" />
                                                </div>
                                            </div>
                                            <p className="theme-box-title">Dark</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex gap-2">
                                <CustomButton secondary>{t('Discard')}</CustomButton>
                                <CustomButton primary isLoading={isLoading} disabled={isLoading} onClick={handleSubmit(handleChange)}>
                                    {t('Save changes')}
                                </CustomButton>
                            </div>
                        </div>
                    )}
                />
                <hr className="divider" />
                <Controller
                    name={`menu_placement`}
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                        <div className="fl-data-block">
                            <div className="title-block w-[310px]">
                                <h3 className="title-text">{t('Menu display')}</h3>
                                <p className="subtitle-text">{t('Select the menu placement of your website')}</p>
                            </div>
                            <div className="data-wrap-block max-w-[512px] xxl:max-w-[700px]">
                                <div className="flex gap-5">
                                    <div className="w-[196px]">
                                        <div className={`theme-box p-1 mb-3 ${value === 'horizontal_top' ? 'active' : ''}`} onClick={() => onChange('horizontal_top')}>
                                            <i className="icon">
                                                <img src={IconCheckdActive} alt="" className="" />
                                            </i>
                                            <div className="theme-block-thumb rounded-xl overflow-hidden">
                                                <img src={LightHorizontal} height={'132px'} width={'192px'} alt="" />
                                            </div>
                                        </div>
                                        <p className="theme-box-title">Horizontal (Top)</p>
                                    </div>
                                    <div className="w-[196px]">
                                        <div className={`theme-box p-1 mb-3 ${value === 'vertical_left' ? 'active' : ''}`} onClick={() => onChange('vertical_left')}>
                                            <i className="icon">
                                                <img src={IconCheckdActive} alt="" className="" />
                                            </i>
                                            <div className="theme-block-thumb rounded-xl overflow-hidden">
                                                <img src={Light} height={'132px'} width={'192px'} alt="" />
                                            </div>
                                        </div>
                                        <p className="theme-box-title">Vertical (Left)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                />
                <hr className="divider" />
                <Controller
                    name={`brand_color`}
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                        <div className="fl-data-block relative">
                            <div className="title-block w-[310px]">
                                <h3 className="title-text">{t('Brand color')}</h3>
                                <p className="subtitle-text">{t('Add a custom brand color')}</p>
                            </div>
                            <div className="data-wrap-block max-w-[512px] xl:max-w-[700px]">
                                <div className="flex gap-[22px] items-center">
                                    <div className="flex gap-4 items-center">
                                        {COLORS.map((color) => (
                                            <Tooltip key={color.hex} className="bg-black text-white" content={color.hex}>
                                                <div className="flex items-center justify-center relative">
                                                    <button
                                                        key={color.hex}
                                                        type="button"
                                                        style={{ backgroundColor: color.hex }}
                                                        className={`h-7 w-7 rounded-full focus:outline-none focus:ring-2 hover: focus:ring-offset-2 ${
                                                            value === color.hex ? 'ring-2 ring-offset-2 ' : ''
                                                        } `}
                                                        onClick={() => onChange(color.hex)}
                                                    />
                                                    {value === color.hex ? (
                                                        <i className="icon absolute top-[-9px] right-[-9px]">
                                                            <img src={IconCheckdActive} alt="" className="" />
                                                        </i>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </Tooltip>
                                        ))}
                                    </div>
                                    <div className={` flex items-center border-l  pl-6 gap-4`}>
                                        <span className="text-gray-700 text-sm font-medium whitespace-nowrap">Custom</span>
                                        <div className="flex items-center gap-3 relative">
                                            <InputWithLabel
                                                inputControlClassName="w-[130px] text-xs form-control-md"
                                                onChange={onChange}
                                                value={value}
                                                name="brand_color"
                                                placeholder="Enter color"
                                                error={!!errors?.brand_color}
                                            />
                                            <Tooltip className="bg-black text-white" content={getValues('brand_color')}>
                                                <ColorPicker
                                                    // error={fieldState.error && true}
                                                    className=""
                                                    setOneColor={value}
                                                    customBranding={(colorValue: any) => onChange(colorValue.hex)}
                                                    error={!!errors?.brand_color}
                                                />
                                            </Tooltip>
                                        </div>
                                        {errors?.brand_color && <p className="text-error">{errors?.brand_color?.message}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                />
                <hr className="divider" />
                <Controller
                    name={`brand_text_color`}
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                        <div className="fl-data-block relative">
                            <div className="title-block w-[310px]">
                                <h3 className="title-text">{t('Brand text color')}</h3>
                                <p className="subtitle-text">{t('Add a custom brand text color')}</p>
                            </div>
                            <div className="data-wrap-block max-w-[512px] xl:max-w-[700px]">
                                <div className="flex gap-[22px] items-center">
                                    <div className="flex gap-4 items-center">
                                        {COLORS.map((color) => (
                                            <Tooltip key={color.hex} className="bg-black text-white" content={color.hex}>
                                                <div className="flex items-center justify-center relative">
                                                    <button
                                                        key={color.hex}
                                                        type="button"
                                                        style={{ backgroundColor: color.hex }}
                                                        className={`h-7 w-7 rounded-full focus:outline-none focus:ring-2 hover: focus:ring-offset-2 ${
                                                            value === color.hex ? 'ring-2 ring-offset-2 ' : ''
                                                        }`}
                                                        onClick={() => onChange(color.hex)}
                                                    />
                                                    {value === color.hex ? (
                                                        <i className="icon absolute top-[-9px] right-[-9px]">
                                                            <img src={IconCheckdActive} alt="" className="" />
                                                        </i>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </Tooltip>
                                        ))}
                                    </div>
                                    <div className={` flex items-center border-l  pl-6 gap-4`}>
                                        <span className="text-gray-700 text-sm font-medium whitespace-nowrap">Custom</span>
                                        <div className="flex items-center gap-3 relative">
                                            <InputWithLabel
                                                inputControlClassName="w-[130px] text-xs form-control-md"
                                                onChange={onChange}
                                                value={value}
                                                name="brand_text_color"
                                                placeholder="Enter color"
                                                error={!!errors?.brand_text_color}
                                            />
                                            <Tooltip className="bg-black text-white" content={getValues('brand_text_color')}>
                                                <ColorPicker
                                                    // error={fieldState.error && true}
                                                    className=""
                                                    setOneColor={value}
                                                    customBranding={(colorValue: any) => onChange(colorValue.hex)}
                                                    error={!!errors?.brand_text_color}
                                                />
                                            </Tooltip>
                                        </div>
                                        {errors?.brand_text_color && <p className="text-error">{errors?.brand_text_color?.message}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                />
                <hr className="divider" />
                <Controller
                    name={`font_family`}
                    control={control}
                    render={({ field: { onChange, value }, fieldState }: any) => (
                        <div className="fl-data-block">
                            <div className="title-block w-[310px]">
                                <h3 className="title-text">{t('Font style')}</h3>
                                <p className="subtitle-text">{t('Add a custom font style')}</p>
                            </div>
                            <div className="data-wrap-block w-full max-w-[512px] xxl:max-w-[700px]">
                                <div className="w-[308px]">
                                    <SelectBox
                                        value={FONT_FAMILY.filter((item: any) => item.value === value)}
                                        name="font_family"
                                        id="font_family"
                                        options={FONT_FAMILY}
                                        onChangeFunc={(selectedOption: any) => onChange(selectedOption ? selectedOption.value : null)}
                                        placeholder="Select your font family"
                                        errorText={!!errors?.font_family}
                                        defaultMenuIsOpen="true"
                                        isFontFamily
                                        error={!!fieldState.error}
                                        isClearable={false}
                                        isSearchable={false}
                                    />
                                    {errors?.font_family && <p className="text-error">{errors?.font_family?.message}</p>}
                                </div>
                            </div>
                        </div>
                    )}
                />
                <hr className="divider" />
                <Controller
                    name={`element_style`}
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                        <div className="fl-data-block">
                            <div className="title-block w-[310px]">
                                <h3 className="title-text">{t('Element style')}</h3>
                                <p className="subtitle-text">{t('Select the general element style for the different components of your website')}</p>
                            </div>
                            <div className="data-wrap-block max-w-[700px] xxl:max-w-[700px]">
                                <div className="flex gap-5">
                                    <div className="w-[196px]">
                                        <div className={`theme-box p-1 mb-3 ${value === 'sharp' && 'active'}`} onClick={() => onChange('sharp')}>
                                            <i className="icon">
                                                <img src={IconCheckdActive} alt="" className="" />
                                            </i>
                                            <div className="theme-block-thumb rounded-xl overflow-hidden">
                                                <img src={LightSharp} alt="" className="w-[192px] min-h-[123.66px] h-[123.66px] max-h-[123.66px] object-cover" />
                                            </div>
                                        </div>
                                        <p className="theme-box-title">Sharp</p>
                                    </div>
                                    <div className="w-[196px]">
                                        <div className={`theme-box p-1 mb-3 ${value === 'minimal' && 'active'}`} onClick={() => onChange('minimal')}>
                                            <i className="icon">
                                                <img src={IconCheckdActive} alt="" className="" />
                                            </i>
                                            <div className="theme-block-thumb rounded-xl overflow-hidden">
                                                <img src={LightMinimal} className="w-[192px] min-h-[123.66px] h-[123.66px] max-h-[123.66px] object-cover" alt="" />
                                            </div>
                                        </div>
                                        <p className="theme-box-title">Minimal</p>
                                    </div>
                                    <div className="w-[196px]">
                                        <div className={`theme-box p-1 mb-3 ${value === 'curved' && 'active'}`} onClick={() => onChange('curved')}>
                                            <i className="icon">
                                                <img src={IconCheckdActive} alt="" className="" />
                                            </i>
                                            <div className="theme-block-thumb rounded-xl overflow-hidden">
                                                <img src={LightCurved} className="w-[192px] min-h-[123.66px] h-[123.66px] max-h-[123.66px] object-cover" alt="" />
                                            </div>
                                        </div>
                                        <p className="theme-box-title">Curved</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                />
                <hr className="divider" />
                <div className="fl-data-block">
                    <div className="title-block w-[310px]">
                        <h3 className="title-text">{t('Languages')}</h3>
                        <p className="subtitle-text">{t('Specify the different language(s) your website should be translated to')}</p>
                    </div>
                    <div className="data-wrap-block  max-w-[630px] w-[630px]">
                        <div className="flex w-full gap-4">
                            <Controller
                                name={`primary_language`}
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="w-1/2">
                                        <SelectBox
                                            name="primary_language"
                                            id="primary_language"
                                            value={LANGUAGES.filter((item: any) => item.value === value)}
                                            onChangeFunc={(selectedOption: any) => {
                                                onChange(selectedOption.value);
                                                if (getValues('secondary_language')) {
                                                    handlePrimaryLanguageChange(selectedOption.value);
                                                }
                                                // handlePrimaryLanguageChange(selectedOption.value);
                                            }}
                                            label="Primary language"
                                            options={LANGUAGES}
                                            isClearable={false}
                                            isSearchable={false}
                                            errorText={!!errors?.primary_language}
                                            placeholder="Select Primary language"
                                        />
                                        {errors?.primary_language && <p className="text-error">{errors?.primary_language?.message}</p>}
                                    </div>
                                )}
                            />
                            <Controller
                                name={`secondary_language`}
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="w-1/2">
                                        <SelectBox
                                            name="secondary_language"
                                            id="secondary_language"
                                            value={value ? LANGUAGES.filter((item: any) => item.value === value) : null}
                                            options={LANGUAGES}
                                            label="Secondary language"
                                            // isClearable={false}
                                            isSearchable={false}
                                            onChangeFunc={(selectedOption: any) => {
                                                if (selectedOption) {
                                                    onChange(selectedOption.value);
                                                    handleSecondaryLanguageChange(selectedOption.value);
                                                } else {
                                                    onChange(null);
                                                }
                                            }}
                                            errorText={!!errors?.secondary_language}
                                            placeholder="Select Secondary language"
                                        />
                                        {errors?.secondary_language && <p className="text-error">{errors?.secondary_language?.message}</p>}
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className="mb-36 pt-5 border-t justify-end flex">
                    <div className="flex gap-2">
                        <CustomButton secondary>{t('Discard')}</CustomButton>
                        <CustomButton primary isLoading={isLoading} disabled={isLoading} onClick={handleSubmit(handleChange)}>
                            {t('Save changes')}
                        </CustomButton>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default General;
