import React, { useEffect, useMemo, useRef, useState } from 'react';
import UpcomingAppointment from './Tables/UpcomingAppointment';
import UpcomingAppointmentDrawer from './Drawers/UpcomingAppointment';
import LatestBookingActivity from './Tables/LatestBookingActivity';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { graphOptions, IDashboardDetails, initAction, IPayload, selectAllOption, selectAllTeamOption } from './Dashboard.Interface';
import KpiCard from 'src/components/KpiCard';
import CommissionPopup from './CommissionPopup';
import EmptyMsgWithBtn from 'src/components/EmptyMsgWithBtn';
import LatestBookingActivityDrawer from './Drawers/LatestBookingActivityDrawer';
import AmChart from '../Analytics/AmChart';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { useAppSelector } from 'src/redux/hooks';
import { allShopLocations, allShopStaff, currentShop, userMe } from 'src/redux/services/common/Common.slice';
import StaffPerfomanceDrawer from './Drawers/StaffPerfomanceDrawer';
import { getDateRange, getSelectBoxOptions } from 'src/utils/global-functions';
import { ISelectedLocation } from '../Staff/Staff.interface';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { IconMoney, IconProfit, IconCalendar, IconProductivity } from 'src/theme/Images';
import PageHeader from 'src/components/PageHeader';
import { Inbox01 } from '@untitled-ui/icons-react/build/cjs';
import DateRangePicker from 'src/components/DateRangePicker/DateRangePicker';
import CustomButton from 'src/components/CustomButton';
import { GoDotFill } from 'react-icons/go';
import Notification from 'src/app/Notification/Notification';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import SChart from '../Skeleton/Payment/SChart';

const Dashboard = () => {
    const { t } = useTranslation();
    const notificationButtonRef = useRef(null);
    const [action, setAction] = useState(initAction);
    const [dashboardDetails, setDashboardDetails] = useState<IDashboardDetails | null>(null);
    const shop: any = useAppSelector(currentShop);
    const user: any = useAppSelector(userMe);
    const shopId = shop.id;
    const shopLocationList: any = useAppSelector(allShopLocations);
    const [isOpenCommissionStatementPopup, setIsOpenCommissionStatementPopup] = useState<boolean>();
    const [selectedTimeRange, setSelectedTimeRange] = useState<string>('custom');
    const [startDate, setStartDate] = useState<Date | undefined>(moment().toDate());
    const [endDate, setEndDate] = useState<Date | undefined>(moment().toDate());
    const [activeFilter, setActiveFilter] = useState('daily');
    const [locationOptions, setLocationOptions] = useState<any>([]);
    const [selectedLocation, setSelectedLocation] = useState<ISelectedLocation | null>(selectAllOption);
    const [boxLabel, setBoxLabel] = useState('Yesterday');
    const allShopStaffList = useAppSelector(allShopStaff);
    const [staffOptions, setStaffOptions] = useState<any[]>([]);
    const [selectedTeam, setSelectedTeam] = useState<ISelectedLocation | null>(selectAllTeamOption);
    const [selectedGraphOption, setSelectedGraphOption] = useState(graphOptions[0]);
    const [notificationCount, setNotificationCount] = useState({
        all: 0,
        general: 0,
        appointments: 0,
        statements: 0,
    });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const locationData = getSelectBoxOptions(shopLocationList, 'id', 'name', 'id', 'shortname', 'profile_image_url');
        const updatedLocationData = [selectAllOption, ...locationData];
        setLocationOptions(updatedLocationData);
        setSelectedLocation(updatedLocationData[0]);

        const staff = getSelectBoxOptions(allShopStaffList, 'id', 'full_name', 'id', 'full_name', 'profile_image_url');
        const updatedTeamData = [selectAllTeamOption, ...staff];
        setStaffOptions(updatedTeamData);
        setSelectedTeam(updatedTeamData[0]);
    }, [shop]);

    useEffect(() => {
        if (activeFilter !== '') {
            const { start, end } = getDateRange(activeFilter);
            setStartDate(start);
            setEndDate(end);
        }
        if (activeFilter === 'weekly') {
            setBoxLabel('last week');
            setSelectedTimeRange(activeFilter);
        } else if (activeFilter === 'monthly') {
            setBoxLabel('last month');
            setSelectedTimeRange(activeFilter);
        } else if (activeFilter === 'yearly') {
            setBoxLabel('last year');
            setSelectedTimeRange(activeFilter);
        } else if (activeFilter === 'daily') {
            setBoxLabel('yesterday');
            setSelectedTimeRange('custom');
        }
    }, [activeFilter]);

    useEffect(() => {
        if (startDate && endDate) {
            if (selectedTimeRange === 'all_time') {
                setBoxLabel('Previous period');
            } else if (selectedTimeRange === 'quarterly') {
                setBoxLabel('last quarter');
            } else if (activeFilter === '') {
                let days = moment(endDate).diff(moment(startDate), 'days');
                days = selectedTimeRange === 'last_30_days' || selectedTimeRange === 'last_90_days' || selectedTimeRange === 'last_7_days' || selectedTimeRange === 'custom' ? days + 1 : days;

                setBoxLabel(`Previous ${days} days`);
            }
        }
    }, [startDate, endDate]);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const handleViewClick = (drawerName: string) => async (event: { preventDefault: () => void }) => {
        setAction((prevAction) => ({
            ...initAction,
            [drawerName]: true,
        }));
        setIsDrawerOpen(true);
    };
    const tabs = useMemo(
        () => [
            {
                value: 'daily',
                name: 'Today',
            },
            {
                value: 'weekly',
                name: 'This Week',
            },
            {
                value: 'monthly',
                name: 'This Month',
            },
            {
                value: 'yearly',
                name: 'This Year',
            },
        ],
        [],
    );
    const navigate = useNavigate();
    const createBooking = () => {
        navigate(ROUTES.CALENDAR);
    };

    const processArray = async (array: any, groupBy: string) => {
        const resultMap: Record<string, any> = {};

        if (array.length === 0) {
            return [];
        }

        // Sort array based on booking_date
        array = array.sort((a: any, b: any) => moment(a.booking_date).valueOf() - moment(b.booking_date).valueOf());

        // Helper function to format date based on groupBy
        const formatDate = (bookingDate: string) => {
            let createdAt: string;
            let humanReadableDate: string;

            if (groupBy === 'yearly') {
                createdAt = moment(bookingDate).format('YYYY-MM');
                humanReadableDate = moment(bookingDate).format('MMMM YYYY'); // e.g., "August 2023"
            } else if (groupBy === 'daily') {
                createdAt = moment(bookingDate).format('YYYY-MM-DD-HH');
                humanReadableDate = moment(bookingDate).format('dddd, MMMM Do YYYY, h A'); // e.g., "Monday, August 14th 2023, 2 PM"
            } else {
                createdAt = moment(bookingDate).format('YYYY-MM-DD');
                humanReadableDate = moment(bookingDate).format('dddd, MMMM Do YYYY'); // e.g., "Monday, August 14th 2023"
            }

            return { createdAt, humanReadableDate };
        };

        // Define fields for each graph option type
        const handleItem = (item: any, result: any, fields: string[]) => {
            fields.forEach((field) => {
                if (field in item) {
                    result[field] = (result[field] || 0) + parseFloat(item[field] || 0);
                }
            });
        };

        const optionFieldsMap: Record<string, string[]> = {
            sales: ['total_amount', 'service_amount', 'product_amount', 'taxes_and_fees', 'other_amount', 'tip_amount'],
            bookings: ['total_bookings', 'canceled_amount', 'completed_amount', 'no_show_amount'],
            active_users: ['total_users', 'active_users', 'new_users'],
            retention_users: ['total_users', 'active_users', 'new_users'],
        };

        const graphOption = selectedGraphOption.value;
        const fields = optionFieldsMap[graphOption];

        array.forEach((item: any) => {
            const { createdAt, humanReadableDate } = formatDate(item.booking_date);
            if (!resultMap[createdAt]) {
                resultMap[createdAt] = {
                    booking_date: item.booking_date,
                    human_readable_date: humanReadableDate,
                };
                handleItem(item, resultMap[createdAt], fields);
            } else {
                handleItem(item, resultMap[createdAt], fields);
            }
        });

        const sortedArray = Object.entries(resultMap).sort(([, a], [, b]) => moment(a.booking_date).valueOf() - moment(b.booking_date).valueOf());

        return sortedArray.map(([, value]) => value);
    };

    const getDashboard = async () => {
        if (startDate && endDate) {
            setIsLoading(true);
            try {
                let payload: IPayload = {
                    type: selectedGraphOption.value,
                    start_date: moment(startDate).format('YYYY-MM-DD'),
                    end_date: moment(endDate).format('YYYY-MM-DD'),
                };
                if (selectedLocation && selectedLocation.value !== 0) {
                    payload.location_id = selectedLocation.id;
                }
                if (selectedTeam && selectedTeam.value !== 0) {
                    payload.shop_admin_id = selectedTeam.id;
                }

                const response = await axiosGet(
                    API.DASHBOARD.GET,
                    {
                        shop_id: shopId,
                    },
                    payload,
                );
                if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                    const data = response.data.data;
                    const sum = await processArray(data.graph, activeFilter);
                    const finalResult: any[] = Object.values(sum);
                    data.graph = finalResult;
                    setDashboardDetails(data);

                    return;
                }

                throw response.data;
            } catch (err: any) {
                toast.error(err?.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        getDashboard();
    }, [selectedLocation, startDate, endDate, selectedTeam, selectedGraphOption, shop]);

    const handleDatePickerChange = (date: any, timeRange?: string) => {
        if (timeRange === 'weekly' || timeRange === 'monthly' || timeRange === 'yearly') {
            setActiveFilter(timeRange);
        } else {
            setActiveFilter('');
            const [start, end] = date;

            setStartDate(start);
            setEndDate(end);
            if (!end) {
                setEndDate(start);
            }
        }
        if (timeRange) {
            setSelectedTimeRange(timeRange);
        }
    };

    const handleFilterTab = (value: string) => () => {
        setActiveFilter(value);
    };
    const handleTeam = (event: any) => {
        setSelectedTeam(event);
    };
    const handleLocation = (event: any) => {
        setSelectedLocation(event);
    };
    const handleGraphOptionChange = (e: any) => (event: any) => {
        setSelectedGraphOption(e ?? event);
    };
    const handleAction = (type: string) => () => {
        setAction((old) => ({ ...old, [type]: true }));
    };

    const handleClose = () => {
        setAction(initAction);
    };
    return (
        <div className="inner-page-wrape">
            {dashboardDetails && (
                <div className="inner-page-wrape">
                    <div className="w-full flex flex-1 flex-col">
                        <PageHeader
                            title={'Dashboard'}
                            titleGray={`Week of ${moment().startOf('isoWeek').format(' MMMM Do, YYYY')}`}
                            subtitle={`Welcome back, ${user.first_name}. Here’s an overview of your week so far`}
                        >
                            <div className=" cursor-pointer relative mb-[7px]" onClick={handleAction('notification')} ref={notificationButtonRef}>
                                <CustomButton secondary className="  h-[36px]   !min-w-max ">
                                    <div className="relative flex items-center">
                                        <Inbox01 className="w-5 h-5 !relative text-mainTextColor" />
                                        {Number(notificationCount.all) > 0 && (
                                            <div className="absolute  -top-[3px] right-[7px]">
                                                <GoDotFill size={16} color="#17B26A" className="h-3.5 w-3.5 rounded-full flex " />
                                            </div>
                                        )}
                                    </div>
                                    {Number(notificationCount.all) > 0 ? notificationCount.all : ''} Notifications
                                </CustomButton>
                            </div>

                            {/* <div className="flex flex-col ">
                                <div className="flex flex-row text-xs leading-[18px] font-semibold text-[#475467] items-center gap-2 ml-auto">
                                    Rent Collection in 3 days <ArrowRight className="text-[#475467] w-4" />
                                </div>
                                <div className="flex flex-row text-xs leading-[18px] font-semibold text-[#475467] items-center gap-2">
                                    Commission Payouts in 3 days <ArrowRight className="text-[#475467] w-4" />
                                </div>
                            </div> */}
                        </PageHeader>

                        <div className="side-spaching">
                            <div className="flex flex-row justify-between items-center mb-3 xxl:mb-4">
                                <div className="flex max-2xl:flex-col flex-row gap-3">
                                    <div className="fl-tab-btn-view2 h-[36px]  flex  ">
                                        {tabs.map((tab, index) => (
                                            <button
                                                key={index}
                                                type="button"
                                                className={`w-full flex fl-tab-link2 justify-center items-center min-h-[34px] !max-w-max xxl:!min-w-[91px] 2xl:!min-w-[72px] ${
                                                    activeFilter === tab.value ? 'active' : ''
                                                }`}
                                                onClick={handleFilterTab(tab.value)}
                                            >
                                                {tab.name}
                                            </button>
                                        ))}
                                    </div>

                                    <DateRangePicker
                                        isDisplayAsBox={false}
                                        handleDatePickerChange={handleDatePickerChange}
                                        selectedTimeRange={selectedTimeRange}
                                        startDate={startDate}
                                        endDate={endDate}
                                        allTimeStartDate={shop.created_at}
                                        showYearDropdown={false}
                                        isToday={activeFilter === 'daily'}
                                        isShowDropDownIcon={false}
                                        containerClassName="left-0 "
                                        parentClassName="sales-datepicker w-[280px] min-w-[280px] xlm:w-[310px] xlm:min-w-[310px] xxl:w-[340px] xxl:min-w-[340px]"
                                        setStartDate={setStartDate}
                                        setEndDate={setEndDate}
                                        activeFilter={activeFilter}
                                        setActiveFilter={setActiveFilter}
                                    />
                                </div>

                                <div className="flex flex-row gap-3 ml-auto ">
                                    <div className="w-[200px] 2xl:w-[180px] xxl:min-w-[200px]">
                                        <SelectBox
                                            options={locationOptions}
                                            value={selectedLocation}
                                            noOptionsMessage="No Locations Found"
                                            placeholder={t('All locations')}
                                            onChangeFunc={handleLocation}
                                            classComp=""
                                            isClearable={false}
                                            isSearchable={false}
                                        />
                                    </div>
                                    <div className=" w-[200px] 2xl:w-[180px] xxl:min-w-[200px]">
                                        <SelectBox
                                            options={staffOptions}
                                            noOptionsMessage="No Team Found"
                                            value={selectedTeam}
                                            placeholder={t('All Team members')}
                                            onChangeFunc={handleTeam}
                                            classComp="outline-select-box"
                                            isClearable={false}
                                            isSearchable={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 xxl:mb-4 max-2xl:grid-cols-2 grid grid-cols-4 gap-3 xxl:gap-4 ">
                                <div onClick={handleGraphOptionChange(graphOptions.find((option) => option.value === 'sales'))} className="active">
                                    <KpiCard
                                        headerIcon={IconMoney}
                                        headerTitle={t('Total Sales')}
                                        contentLable={`${dashboardDetails?.total?.amount}`}
                                        growth={dashboardDetails?.total?.amount_growth}
                                        fromDate={boxLabel}
                                        signIcon={'$'}
                                        growthDescription={dashboardDetails.total?.amount_projected ? `$${dashboardDetails.total?.amount_projected} ${t('Projected')} ` : '$0 Projected'}
                                        isActive={selectedGraphOption.value === 'sales'}
                                        isLoading={isLoading}
                                    />
                                </div>

                                <div onClick={handleGraphOptionChange(graphOptions.find((option) => option.value === 'bookings'))}>
                                    <KpiCard
                                        headerIcon={IconProfit}
                                        headerTitle={t('Total Appointments')}
                                        contentLable={`${dashboardDetails?.total?.bookings}`}
                                        growth={dashboardDetails?.total?.bookings_growth}
                                        fromDate={boxLabel}
                                        growthDescription={dashboardDetails.total?.bookings_projected ? `${dashboardDetails.total?.bookings_projected} ${t('Projected')} ` : '0 Projected'}
                                        isActive={selectedGraphOption.value === 'bookings'}
                                        isLoading={isLoading}
                                    />
                                </div>

                                <div onClick={handleGraphOptionChange(graphOptions.find((option) => option.value === 'active_users'))}>
                                    <KpiCard
                                        headerIcon={IconCalendar}
                                        headerTitle={t('Total Active Clients')}
                                        contentLable={`${dashboardDetails?.total?.active_users}`}
                                        contentDescription="bookings"
                                        growth={dashboardDetails?.total?.active_users_growth}
                                        fromDate={boxLabel}
                                        isActive={selectedGraphOption.value === 'active_users'}
                                        isLoading={isLoading}
                                    />
                                </div>
                                <div onClick={handleGraphOptionChange(graphOptions.find((option) => option.value === 'retention_users'))}>
                                    <KpiCard
                                        headerIcon={IconProductivity}
                                        headerTitle={t('Client Retention Rate')}
                                        contentLable={`${dashboardDetails?.total?.retention_rate}`}
                                        growth={dashboardDetails?.total?.retention_rate_growth}
                                        fromDate={dashboardDetails?.from_date}
                                        signIcon={'%'}
                                        isActive={selectedGraphOption.value === 'retention_users'}
                                        isLoading={isLoading}
                                    />
                                </div>
                            </div>

                            <div className="bg-white ">
                                {/* <h3 className="text-xl font-bold -tracking-[0.4px]">{t('Sales Analytics')}</h3> */}
                                {isLoading ? (
                                    <div className="h-[400px]">
                                        <SChart />
                                    </div>
                                ) : dashboardDetails.graph.length > 0 ? (
                                    <AmChart
                                        salesLineGraph={dashboardDetails.graph}
                                        seriesArray={selectedGraphOption.seriesArray}
                                        graphType={selectedGraphOption.value}
                                        valueXField={selectedGraphOption.valueXField}
                                        activeFilter={activeFilter}
                                    />
                                ) : (
                                    <>
                                        <div className="border border-borderSecondary rounded-xl shadow mb-5 p-5 ">
                                            <EmptyMsgWithBtn title={t('No Sales Data')} description={t('No_Sales_Data_description')} btnLabel={t('Create booking')} onClick={createBooking} />
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className=" flex mb-3">
                                <div className={`${dashboardDetails.past.length > 0 ? 'w-9/12' : 'w-full'}`}>
                                    <UpcomingAppointment isLoading={isLoading} />
                                </div>
                                {dashboardDetails.past.length > 0 && (
                                    <div className="ml-6 pr-1  w-3/12">
                                        <LatestBookingActivity handleViewClick={handleViewClick} latestData={dashboardDetails.past} isLoading={isLoading} />
                                    </div>
                                )}
                            </div>
                            {action.upcomingAppointment && <UpcomingAppointmentDrawer isOpen={isDrawerOpen} handleClose={() => setIsDrawerOpen(false)} />}
                            {action.latestBookingActivity && <LatestBookingActivityDrawer isOpen={isDrawerOpen} handleClose={() => setIsDrawerOpen(false)} />}
                            {action.staffPerfomance && <StaffPerfomanceDrawer isOpen={isDrawerOpen} handleClose={() => setIsDrawerOpen(false)} />}

                            <CommissionPopup
                                isOpenPopup={isOpenCommissionStatementPopup}
                                handleClose={() => {
                                    setIsOpenCommissionStatementPopup(false);
                                }}
                            />
                            <Notification
                                show={action.notification}
                                notificationButtonRef={notificationButtonRef}
                                notificationCount={notificationCount}
                                handleClose={handleClose}
                                setNotificationCount={setNotificationCount}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Dashboard;
