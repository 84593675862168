import React, { FC } from 'react';
import { SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { NoDataMessageProps } from './NoDataMessage.interface';

const NoDataMessage: FC<NoDataMessageProps> = ({ title, description, iconComponent = <SearchLg className="text-gray-700" /> }) => (
    <div className="flex justify-center items-center flex-1  border-t-0 border-gray-200 rounded-b-md text-center custom-height">
        <div className="flex flex-col  justify-center items-center text-center bg-banner bg-center bg-no-repeat">
            <div className="flex justify-center border shadow-sm border-gray-200 bg-white items-center h-12 w-12 mb-4 rounded-[10px] mt-12">{iconComponent}</div>
            <div className="text-gray-900 text-base font-semibold leading-[24px] mb-1">{title}</div>
            <span className="text-gray-600 text-sm leading-5 font-normal max-w-[352px] mb-6">{description}</span>
        </div>
    </div>
);

export default NoDataMessage;
