import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import InputWithLabel from 'src/components/InputWithLabel';
import { useAppSelector } from 'src/redux/hooks';
import { useTranslation } from 'react-i18next';
import { selectCalendarData } from 'src/app/Calendar/Calendar.slice';

interface TipSelectorProps {
    handleTipWithoutCalculation: (value: any, isFixed?: boolean) => () => void;
    control: any;
    errors: {
        message: string;
        status: boolean;
    };
}

const Tip: React.FC<TipSelectorProps> = ({ handleTipWithoutCalculation, control, errors }) => {
    const selectedLocation: any = useAppSelector(selectCalendarData).selectedLocation;
    // const selectedLocation: any = calendarData.selectedLocation;
    const { t } = useTranslation();
    const { getValues } = useFormContext();
    // console.log('calendarData', calendarData);

    return (
        <>
            {selectedLocation && (
                <>
                    <div className={` flex items-center gap-3 px-2.5 py-2 flex-row justify-between  w-full `}>
                        {selectedLocation?.tips.length > 0 && (
                            <>
                                <div className="flex flex-col gap-1.5">
                                    <p className="text-mainTextColor font-medium text-xs leading-[18px] ">Tip Amount</p>
                                    <div className="flex items-center gap-2">
                                        {selectedLocation.tips.map((item: any) => (
                                            <div className="pay-tab-btn-view cursor-pointer" key={item} onClick={handleTipWithoutCalculation(item, true)}>
                                                {/* <div
                                                    className={`pay-tab-link  ${
                                                        selectedLocation.tips_type !== 'value' && getValues('tip')
                                                            ? item ==
                                                              Number(
                                                                  ((Number(calendarData?.payments?.tip) ?? 0) /
                                                                      Number(calendarData.bookedSlotInfo.subtotal - +calendarData.bookedSlotInfo.product_amount)) *
                                                                      100,
                                                              )
                                                                ? 'active'
                                                                : ''
                                                            : item == calendarData?.payments?.tip
                                                            ? 'active'
                                                            : ''
                                                    }`}
                                                > */}
                                                <div className={`pay-tab-link `}>
                                                    {selectedLocation.tips_type === 'value' && '$'}
                                                    {item}
                                                    {selectedLocation.tips_type !== 'value' && '%'}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <Controller
                                    name="tip"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <div>
                                            <InputWithLabel
                                                type="number"
                                                name="tip"
                                                placeholder={t('$00.00')}
                                                onChange={(e: any) => {
                                                    let tipAmount = e.target.value;
                                                    if (tipAmount.length > 1) {
                                                        tipAmount = parseFloat(tipAmount).toString();
                                                    }
                                                    onChange(tipAmount);
                                                    handleTipWithoutCalculation(tipAmount)();
                                                }}
                                                error={!!errors.status}
                                                value={value}
                                                inputControlClassName="!h-[28px] !px-0 !text-center !bg-transparent !border-0 "
                                                inputTextWrapper="!h-[30px] !w-[70px] !p-0 !bg-transparent !text-center"
                                            />
                                        </div>
                                    )}
                                />
                            </>
                        )}
                    </div>
                    {errors?.status && <p className="text-error px-2.5 pb-1.5 !mt-0">{errors?.message}</p>}
                </>
            )}
        </>
    );
};

export default Tip;
