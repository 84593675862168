import { ISelectedLocation } from '../Staff/Staff.interface';

export type IActionType = {
    upcomingAppointment: boolean;
    staffPerfomance: boolean;
    latestBookingActivity: boolean;
    topServices: boolean;
    notification: boolean;
};

export interface IDashboardDetails {
    from_date: Date | null;
    total: {
        active_users: number;
        active_users_growth: number;
        amount: number;
        amount_growth: number;
        bookings: number;
        bookings_growth: number;
        retention_rate: number;
        retention_rate_growth: number;
        amount_projected: number;
        bookings_projected: number;
    };
    graph: any[];
    upcoming: any[];
    past: any[];
    staff: any[];
    services: any[];
}

export interface IFilterArray {
    value: string;
    name: string;
}
export const overviewPerPageOptions = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '150', value: 150 },
    { label: '200', value: 200 },
];
export const FilterArray: IFilterArray[] = [
    {
        value: 'daily',
        name: 'Today',
    },
    {
        value: 'weekly',
        name: 'This Week',
    },
    {
        value: 'monthly',
        name: 'This Month',
    },
    {
        value: 'yearly',
        name: 'This Year',
    },
];

export const initAction: IActionType = {
    upcomingAppointment: false,
    staffPerfomance: false,
    latestBookingActivity: false,
    topServices: false,
    notification: false,
};
export interface IPayload {
    type: string;
    location_id?: number;
    end_date?: any;
    start_date?: any;
    shop_admin_id?: number;
}

export interface IGraphOptions {
    label: string;
    value: string;
    valueXField: string;
    seriesArray: any[];
}
export const graphOptions: IGraphOptions[] = [
    {
        label: 'Total Sales',
        value: 'sales',
        valueXField: 'booking_date',
        seriesArray: [{ label: 'Total Sales', value: 'total_amount' }],
    },
    { label: 'Total Appointments', value: 'bookings', valueXField: 'booking_date', seriesArray: [{ label: 'Total Bookings', value: 'total_bookings' }] },
    { label: 'Total Active Clients', value: 'active_users', valueXField: 'booking_date', seriesArray: [{ label: 'Total Users', value: 'total_users' }] },
    { label: 'Client Retention Rate', value: 'retention_users', valueXField: 'booking_date', seriesArray: [{ label: 'Total Client Retention Rate', value: 'total_users' }] },
];
export const selectAllOption: ISelectedLocation = {
    value: 0,
    label: 'All locations ',
};
export const selectAllTeamOption: ISelectedLocation = {
    value: 0,
    label: 'All team members',
};
