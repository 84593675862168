import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputWithLabel from 'src/components/InputWithLabel';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { IAddExpenseForm, timeOption } from './Analytics.interface';

const ExpenseForm = ({ currentLocationExpense }: any) => {
    const { t } = useTranslation();
    const { control, watch, setValue } = useFormContext();
    const [isExpenseInputDisabled, setIsExpenseInputDisabled] = useState(false);

    const totalExpenses = watch('total');

    useEffect(() => {
        setIsExpenseInputDisabled(totalExpenses ? true : false);
    }, [totalExpenses]);

    useEffect(() => {
        if (currentLocationExpense) {
            const expenses = currentLocationExpense.expenses;
            if (expenses) {
                const existingData = {
                    rent: expenses.rent ?? null,
                    bills: expenses.bills ?? null,
                    tools: expenses.tools ?? null,
                    insurance: expenses.insurance ?? null,
                    maintenance: expenses.maintenance ?? null,
                    other: expenses.other ?? null,
                    total: currentLocationExpense.total ?? null,
                    type: currentLocationExpense.type ?? null,
                };
                (Object.keys(existingData) as Array<keyof Omit<IAddExpenseForm, 'shop_location_id'>>).forEach((key) => {
                    setValue(key, existingData[key]);
                });
            }
        } else {
            const existingData = {
                rent: 0,
                bills: 0,
                tools: 0,
                insurance: 0,
                maintenance: 0,
                other: 0,
                total: 0,
                type: 0,
            };
            (Object.keys(existingData) as Array<keyof Omit<IAddExpenseForm, 'shop_location_id'>>).forEach((key) => {
                setValue(key, existingData[key]);
            });
        }
    }, [currentLocationExpense]);

    return (
        <>
            <div className="border-b border-borderSecondary pb-4 w-full">
                <Controller
                    name={`type`}
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="">
                            <SelectBox
                                name={`type`}
                                id={`time`}
                                label={t('Select a Timeframe')}
                                required
                                placeholder={t('Select time')}
                                options={timeOption}
                                onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                value={timeOption.find((option) => option.value === value)}
                                error={!!error}
                            />
                        </div>
                    )}
                />
            </div>
            <div className=" w-full mt-4">
                <div className="grid grid-cols-2 gap-4 mb-4 ">
                    <div>
                        <Controller
                            name={`rent`}
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <InputWithLabel
                                    type="number"
                                    labelIcon={'$'}
                                    labelIconClassName="text-xs !pr-1 !mb-[2px]"
                                    inputControlClassName="pl-0"
                                    name={`rent`}
                                    id={`rent`}
                                    label={t('Location Rent')}
                                    placeholder={t('Enter store rent')}
                                    onChange={onChange}
                                    value={value}
                                    error={!!error}
                                    disabled={isExpenseInputDisabled}
                                />
                            )}
                        />
                    </div>

                    <div>
                        <Controller
                            name={`bills`}
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <InputWithLabel
                                    disabled={isExpenseInputDisabled}
                                    type="number"
                                    labelIcon={'$'}
                                    labelIconClassName="text-xs !pr-1 !mb-[2px]"
                                    inputControlClassName="pl-0"
                                    name={`bills`}
                                    id={`bills`}
                                    label={t('Utility & Hydro Bills')}
                                    placeholder={t('Enter utility & hydro Bills')}
                                    onChange={onChange}
                                    value={value}
                                    error={!!error}
                                />
                            )}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 gap-4 mb-4">
                    <div>
                        <Controller
                            name={`tools`}
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <InputWithLabel
                                    disabled={isExpenseInputDisabled}
                                    type="number"
                                    labelIcon={'$'}
                                    labelIconClassName="text-xs !pr-1 !mb-[2px]"
                                    inputControlClassName="pl-0"
                                    name={`tools`}
                                    id={`tools`}
                                    label={t('Equipment, Tools & Accessories')}
                                    placeholder={t('Enter equipment, tools & accessories spent')}
                                    onChange={onChange}
                                    value={value}
                                    error={!!error}
                                />
                            )}
                        />
                    </div>

                    <div>
                        <Controller
                            name={`insurance`}
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <InputWithLabel
                                    disabled={isExpenseInputDisabled}
                                    type="number"
                                    labelIcon={'$'}
                                    labelIconClassName="text-xs !pr-1 !mb-[2px]"
                                    inputControlClassName="pl-0"
                                    name={`insurance`}
                                    id={`insurance`}
                                    label={t('Insurance')}
                                    placeholder={t('Enter insurance spent')}
                                    onChange={onChange}
                                    value={value}
                                    error={!!error}
                                />
                            )}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 gap-4 mb-4 border-b border-borderSecondary pb-4">
                    <div>
                        <Controller
                            name={`maintenance`}
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <InputWithLabel
                                    disabled={isExpenseInputDisabled}
                                    type="number"
                                    labelIcon={'$'}
                                    labelIconClassName="text-xs !pr-1 !mb-[2px]"
                                    inputControlClassName="pl-0"
                                    name={`maintenance`}
                                    id={`maintenance`}
                                    label={t('Repair & Maintenance')}
                                    placeholder={t('Enter repair & maintenance spent')}
                                    onChange={onChange}
                                    value={value}
                                    error={!!error}
                                />
                            )}
                        />
                    </div>

                    <div>
                        <Controller
                            name={`other`}
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <InputWithLabel
                                    disabled={isExpenseInputDisabled}
                                    type="number"
                                    labelIcon={'$'}
                                    labelIconClassName="text-xs !pr-1 !mb-[2px]"
                                    inputControlClassName="pl-0"
                                    name={`other`}
                                    id={`other`}
                                    label={t('Other')}
                                    placeholder={t('Enter other expenses')}
                                    onChange={onChange}
                                    value={value}
                                    error={!!error}
                                />
                            )}
                        />
                    </div>
                </div>

                <div className="w-full">
                    <Controller
                        name={`total`}
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <InputWithLabel
                                type="number"
                                labelIcon={'$'}
                                labelIconClassName="text-xs !pr-1 !mb-[2px]"
                                inputControlClassName="pl-0"
                                name={`total`}
                                id={`total`}
                                label={t('Total Expenses')}
                                required
                                placeholder={t('Enter total expenses')}
                                onChange={onChange}
                                value={value}
                                error={!!error}
                            />
                        )}
                    />
                </div>
            </div>
        </>
    );
};

export default ExpenseForm;
