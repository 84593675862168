import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { ArrowLeft, XClose } from '@untitled-ui/icons-react/build/cjs';
import { PATH } from 'src/constants/path';
import ImageBoxView from '../Appearance/Template/Image/ImageBoxView';
import ImageInput from '../Appearance/Template/Image/ImageInput';
import { convertBase64ToFile, getSelectBoxOptions, onError } from 'src/utils/global-functions';
import CustomButton from 'src/components/CustomButton';
import { IconCheckdActive, defaultImage } from 'src/theme/Images';
import InputWithLabel from 'src/components/InputWithLabel';
import Switch from 'src/components/Switch/Switch';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { currentShop, me } from 'src/redux/services/common/Common.slice';
import { API } from 'src/constants/api';
import { axiosGet, axiosPatch, axiosPost } from 'src/utils/requestClient';
import { errorCode } from 'src/constants/errorCode';
import { s3Upload } from 'src/utils/s3Operations';
import { s3Path } from 'src/constants/s3Path';
import { toast } from 'react-toastify';
import { ExistingFormData, IFormData, ISelectBox, unitOptions } from './Products.interface';
import ProductDetailsForm from './ProductDetailsForm';
import ProductVariantsForm from './ProductVariantsForm';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';
import CropperModal from 'src/components/CropperModal/CropperModal';

const DEFAULT_FORM_DATA: IFormData = {
    name: '',
    description: '',
    brand: '',
    shop_product_category_id: null,
    shop_product_sub_category_id: null,
    unit_of_measure: '',
    is_active: true,
    main_image_name: '',
    images: null,
    variants: [{ size: null, quantity: null, color: '', cost: null, price: null, is_stock_alert: false, stock_alert_quantity: '', shop_location_id: '' }],
    details: [{ name: '', description: '' }],
    supplier_name: '',
    supplier_email: '',
    supplier_website: '',
};
const ProductsForm = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);
    const [allCategory, setAllCategory] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState<ISelectBox[]>([]);
    const [subCategoryOptions, setSubCategoryOptions] = useState<ISelectBox[]>([]);
    console.log('categoryOptions', categoryOptions);

    const schema = Yup.object({
        name: Yup.string().required(t('This field is required')),
        description: Yup.string().required(t('This field is required')),
        brand: Yup.string(),
        shop_product_category_id: Yup.string().required(t('This field is required')),
        shop_product_sub_category_id: Yup.string().required(t('This field is required')),
        unit_of_measure: Yup.string().required(t('This field is required')),
        is_active: Yup.boolean().required(t('This field is required')),
        main_image_name: Yup.string().required(t('This field is required')),
        images: Yup.array()
            .test({
                name: 'images',
                message: 'Please add at least one images',
                test: function (value) {
                    const { status } = this.parent;
                    if (status && (!value || value.length === 0)) {
                        return false;
                    }
                    return true;
                },
            })
            .of(
                Yup.object()
                    .shape({
                        name: Yup.string().required(t('This field is required')),
                        file: Yup.mixed().nullable(),
                        url: Yup.string().nullable(),
                    })
                    .test('image-validation', t('This field is required'), function (value) {
                        if (!value) {
                            return this.createError({
                                path: `${this.path}`,
                                message: t('This field is required'),
                            });
                        }
                        const { name, url } = value;
                        if (!name || !url) {
                            return this.createError({
                                path: `${this.path}`,
                                message: t('This field is required'),
                            });
                        }
                        return true;
                    }),
            )
            .required(),
        variants: Yup.array().when('unit_of_measure', ([unit_of_measure], sch: any) =>
            sch.of(
                Yup.object({
                    quantity: Yup.number().required(t('This field is required')).positive(t('quantity must be a positive value')).typeError('quantity must be a number'),
                    cost: Yup.number().required(t('This field is required')).positive(t('cost must be a positive value')).typeError('cost must be a number'),
                    price: Yup.number().required(t('This field is required')).positive(t('price must be a positive value')).typeError('price must be a number'),
                    is_stock_alert: Yup.boolean(),
                    stock_alert_quantity: Yup.string()
                        .nullable()
                        .when('is_stock_alert', ([is_stock_alert], customSchema) => (is_stock_alert ? customSchema.required('This field is required') : customSchema.nullable())),
                    color: unit_of_measure === 'units' ? Yup.string().required(t('This field is required')) : Yup.string().nullable(),
                    shop_location_id: Yup.string().required(t('This field is required')),
                    size:
                        unit_of_measure === 'units'
                            ? Yup.string().required(t('This field is required'))
                            : Yup.number().required(t('This field is required')).positive(t('cost must be a positive value')),
                }),
            ),
        ),
        details: Yup.array().of(
            Yup.object({
                name: Yup.string().required(t('This field is required')),
                description: Yup.string().required(t('This field is required')),
            }),
        ),
        supplier_name: Yup.string().nullable(),
        supplier_email: Yup.string().nullable().email(t('Please enter valid email address')),
        supplier_website: Yup.string().nullable(),
    }).required();

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as Resolver<IFormData>,
        defaultValues: DEFAULT_FORM_DATA,
    });

    const {
        handleSubmit,
        setValue,
        setError,
        trigger,
        watch,
        getValues,
        control,
        reset,
        formState: { errors },
    } = methods;
    const imageWatch = watch('images');
    const watchName = watch('name');
    const watchDescription = watch('description');
    const shopProductCategoryId = watch('shop_product_category_id');
    const mainImage = watch('main_image_name');

    useEffect(() => {
        reset(DEFAULT_FORM_DATA);
        getCategory();
        if (id) {
            getProduct();
        }
    }, [shop, id]);

    useEffect(() => {
        if (!mainImage && imageWatch && imageWatch.length > 0) {
            setValue('main_image_name', imageWatch[0].name);
        }
    }, [imageWatch]);

    useEffect(() => {
        const selectedCategory: any = allCategory.find((category: any) => category.id === shopProductCategoryId);
        if (selectedCategory) {
            if (!selectedCategory.sub_categories.some((subCategory: any) => subCategory.id === getValues('shop_product_sub_category_id'))) {
                setValue('shop_product_sub_category_id', selectedCategory.sub_categories[0].id);
            }
            const options: ISelectBox[] | [] = getSelectBoxOptions(selectedCategory.sub_categories, 'id');
            setSubCategoryOptions(options);
        } else {
            setValue('shop_product_sub_category_id', '');

            setSubCategoryOptions([]);
        }
    }, [shopProductCategoryId]);

    useEffect(() => {
        if (allCategory.length) {
            if (id) {
                const selectedCategory: any = allCategory.find((category: any) => category.id === getValues('shop_product_category_id'));
                if (selectedCategory) {
                    if (!selectedCategory.sub_categories.some((subCategory: any) => subCategory.id === getValues('shop_product_sub_category_id'))) {
                        setValue('shop_product_sub_category_id', selectedCategory.sub_categories[0].id);
                    }
                    const options: ISelectBox[] | [] = getSelectBoxOptions(selectedCategory.sub_categories, 'id');
                    setSubCategoryOptions(options);
                }
            }
        }
    }, [allCategory, id]);

    const getCategory = async () => {
        axiosGet(API.PRODUCT_CATEGORY.ALL, { shop_id: Number(shop.id) })
            .then((response) => {
                const data = response.data.data;
                setAllCategory(data);
                const options: ISelectBox[] | [] = getSelectBoxOptions(data, 'id');
                setCategoryOptions(options);
            })
            .catch(() => navigate(PATH.PRODUCT.LIST))
            .finally(() => setIsLoading(false));
    };

    const getProduct = () => {
        setIsLoading(true);
        axiosGet(API.PRODUCT.GET, { shop_id: shop.id, id })
            .then((response) => {
                const data = response.data.data;
                const existingData: ExistingFormData = {
                    name: data.name,
                    description: data.description,
                    brand: data.brand || '',
                    shop_product_category_id: data.shop_product_category_id,
                    shop_product_sub_category_id: data.shop_product_sub_category_id,
                    unit_of_measure: data.unit_of_measure,
                    is_active: data.is_active,
                    supplier_name: data.supplier_name || '',
                    supplier_email: data.supplier_email || '',
                    supplier_website: data.supplier_website || '',
                    main_image_name: data.images.find((image: any) => image.is_main === true)?.image_name,
                    images: data.images.map((image: any) => ({
                        name: image.image_name,
                        url: image.image_url,
                    })),
                    variants: data.variants.map((variant: any) => ({
                        id: variant.id,
                        size: variant.size,
                        quantity: variant.quantity,
                        color: variant.color || '',
                        cost: variant.cost,
                        price: variant.price,
                        is_stock_alert: variant.is_stock_alert,
                        stock_alert_quantity: variant.stock_alert_quantity || '',
                        shop_location_id: variant.shop_location_id,
                    })),
                    details: data.details.map((detail: any) => ({
                        id: detail.id,
                        name: detail.name,
                        description: detail.description,
                    })),
                };
                Object.keys(existingData).forEach((key) => {
                    setValue(key as keyof IFormData, existingData[key]);
                });
            })
            .catch(() => navigate(PATH.PRODUCT.LIST))
            .finally(() => setIsLoading(false));
    };

    const handleSave = async (data: IFormData) => {
        setIsLoading(true);
        let imageStatus = errorCode.updateSuccess;
        if (data.images && data.images.length) {
            const imagePromises = data.images.map((image: any) => (image.file && image.name ? s3Upload(image.file, `${s3Path.SHOP_PRODUCT}${image.name}`) : Promise.resolve(null)));
            if (imagePromises) {
                const imageResponses = await Promise.allSettled(imagePromises);
                const isSuccess = imageResponses.every((response: any) => response.status === 'fulfilled' && (response.value?.status === errorCode.updateSuccess || response?.value === null));
                imageStatus = isSuccess ? errorCode.updateSuccess : errorCode.internalError;
            }
        }
        if (imageStatus === errorCode.updateSuccess) {
            const images = data.images?.map((image: any) => image.name);
            const shopProductCategory = Number(data.shop_product_category_id);
            const shopProductSubCategory = Number(data.shop_product_sub_category_id);
            const convertedVariants = data.variants.map((variant: any) => ({
                ...variant,
                shop_location_id: Number(variant.shop_location_id),
            }));
            const payload = {
                ...data,
                images: images,
                shop_product_category_id: shopProductCategory,
                shop_product_sub_category_id: shopProductSubCategory,
                variants: convertedVariants,
            };
            if (id) {
                axiosPatch(API.PRODUCT.UPDATE, payload, { shop_id: shop.id, id })
                    .then(async () => {
                        await dispatch(me());
                        navigate(PATH.PRODUCT.LIST);
                    })
                    .catch((error) => {
                        const response = error.response.data;
                        if (response.status === errorCode.unprocessable) {
                            if (response.data) {
                                Object.keys(response.data).forEach((field) => {
                                    setError(field as keyof IFormData, {
                                        type: 'manual',
                                        message: response.data[field][0],
                                    });
                                });
                            }
                            return;
                        }
                        toast.error(response.message);
                    })
                    .finally(() => setIsLoading(false));
            } else {
                axiosPost(API.PRODUCT.CREATE, payload, { shop_id: shop.id })
                    .then(async () => {
                        await dispatch(me());
                        navigate(PATH.PRODUCT.LIST);
                    })
                    .catch((error) => {
                        if (error.data.status === errorCode.unprocessable) {
                            if (error.data.data) {
                                Object.keys(error.data.data).forEach((field) => {
                                    setError(field as keyof IFormData, {
                                        type: 'manual',
                                        message: error.data.data[field][0],
                                    });
                                });
                            }
                            return;
                        }
                    })
                    .finally(() => setIsLoading(false));
            }
        } else {
            setIsLoading(false);
        }
    };

    // const onChangeCrop = (fieldName: any) => (e: any) => {
    //     e.preventDefault();
    //     let files;

    //     if (e.dataTransfer) {
    //         files = e.dataTransfer.files;
    //     } else if (e.target) {
    //         files = e.target.files;
    //     }
    //     const promises = Array.from(files).map(
    //         (file: any) =>
    //             new Promise((resolve, reject) => {
    //                 const reader = new FileReader();
    //                 reader.onloadend = () => {
    //                     const newFile = convertBase64ToFile(reader.result);
    //                     if (newFile && newFile.filename && newFile.convertedFile) {
    //                         resolve({
    //                             file: newFile.convertedFile as File,
    //                             url: reader.result as string,
    //                             name: newFile.filename,
    //                         });
    //                     } else {
    //                         reject(new Error('Invalid file'));
    //                     }
    //                 };
    //                 reader.onerror = () => reject(reader.error);
    //                 reader.readAsDataURL(file);
    //             }),
    //     );
    //     Promise.all(promises).then((results: any) => {
    //         setValue('images', [...(imageWatch || []), ...results]);
    //     });
    //     e.target.value = null;
    // };

    const [image, setImage] = useState<any>('');
    const [isUpload, setIsUpload] = useState(false);
    const [fieldName, setFieldName] = useState<any>(null);

    const onChangeCrop = (type: any) => (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onloadstart = () => {
            setImage('');
            setIsUpload(false);
        };
        reader.onloadend = () => {
            setImage(reader.result as any);
            setIsUpload(true);
        };
        reader.readAsDataURL(files[0]);
        e.target.value = null;
        setFieldName(type);
    };

    const imageUpload = (data: any) => {
        const file = convertBase64ToFile(data);
        if (file && file.filename && fieldName) {
            const currentImages = imageWatch || [];
            const updatedImages: any = [...currentImages, { name: file.filename, file: file.convertedFile, url: data }];
            setValue('images', updatedImages);
            trigger([fieldName]);
        }
    };

    const handleCropData = (data: any) => {
        if (fieldName) {
        }
    };
    const handleRemoveImage = (index: number) => () => {
        const removeImage = imageWatch?.find((_: any, i: number) => i === index);
        if (removeImage && removeImage.name === mainImage) {
            if (imageWatch?.length === 1) {
                setValue('main_image_name', '');
            } else {
                if (imageWatch) {
                    if (imageWatch?.length - 1 === index) {
                        setValue('main_image_name', imageWatch[0].name);
                    } else {
                        setValue('main_image_name', imageWatch[index + 1].name);
                    }
                }
            }
        }
        const imageI: any = imageWatch?.filter((_: any, i: number) => i !== index);
        setValue('images', imageI);
    };
    const handleEditImage = (index: number) => () => {
        if (imageWatch && imageWatch.length > 0) {
            const imageName = imageWatch[index]?.name;
            setValue('main_image_name', imageName);
        }
    };

    const resetForm = (event: any) => {
        navigate(PATH.PRODUCT.LIST);
    };
    useFormErrorFocus<IFormData>({ errors });

    return (
        <>
            <div className="inner-page-wrape !px-0">
                <div className="flex-1 flex flex-col mb-4">
                    <div className="flex justify-between gray-banner-bg bg-gray-50 relative h-[130px] text-secondaryTxtColor pt-5 pb-3 xlm:px-[30px] px-5  gap-1.5 rounded-tl-3xl items-start">
                        <Link to={PATH.PRODUCT.LIST} className="flex items-center cursor-pointer text-gray-600 h-[36px] text-xs font-semibold gap-1.5">
                            <ArrowLeft className="w-4 cursor-pointer text-gray-600" /> {t('Back to Products')}
                        </Link>
                        <Link to={PATH.PRODUCT.LIST} className="close-page-btn">
                            <XClose className="xl:w-5 xl:h-5 w-4 h-4 text-gray-900" />
                        </Link>
                    </div>
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(handleSave)} className="flex flex-col ">
                            <div className="relative mb-[26px] w-full xl:pt-[10px] xlm:px-8 px-5 pt-2 pb-2 flex sms:gap-4 gap-2 xs:items-end items-start">
                                <img
                                    src={(imageWatch && imageWatch.length > 0 && imageWatch.find((imageField: any) => imageField.name === mainImage)?.url) || defaultImage}
                                    alt={watchName || t('Products Name')}
                                    title={watchName || t('Products Name')}
                                    onError={onError}
                                    className="w-[144px] h-[144px] rounded-xl object-cover border-4 border-white drop-shadow-lg flex-none -mt-[62px]"
                                />
                                <div className="flex justify-between items-center w-full gap-5">
                                    <div className="flex-1">
                                        <h1 className="mr-3 text-gray-900 xlm:text-3xl sm:text-xl text-base inline align-middle font-semibold tracking-[-0.72px]">{watchName || t('Product Name')}</h1>
                                        <p className="flex gap-2.5 text-gray-600 sm:text-sm text-xs mt-1 font-normal items-center ">{watchDescription || t('Product Description')}</p>
                                    </div>
                                    <div className="flex gap-[10px]">
                                        <CustomButton secondary type="reset" onClick={resetForm} disabled={isLoading}>
                                            {t('Cancel')}
                                        </CustomButton>
                                        <CustomButton primary type="submit" isLoading={isLoading} disabled={isLoading}>
                                            {t('Save Changes')}
                                        </CustomButton>
                                    </div>
                                </div>
                            </div>
                            <div className="detail-data-main xlm:px-8 px-5 border-t">
                                <div className="title-block w-[280px]">
                                    <h3 className="font-semibold text-sm text-gray-700">{t('Product details')}</h3>
                                    <p className="text-xs leading-[18px] text-secondaryTxtColor font-normal">
                                        {t('Update your profile photo this will be displayed on your profile. and details here. ')}
                                    </p>
                                </div>
                                <div className="data-wrap-block w-full max-w-[666px] flex flex-col gap-4">
                                    <div className="flex flex-col gap-4">
                                        <div className="grid grid-cols-2 gap-4">
                                            <Controller
                                                name="name"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                    <div className="flex-1">
                                                        <InputWithLabel
                                                            label={t('Product Name')}
                                                            id="name"
                                                            required
                                                            placeholder={t('Enter product name')}
                                                            onChange={onChange}
                                                            value={value}
                                                            name="name"
                                                            error={!!error}
                                                        />
                                                    </div>
                                                )}
                                            />
                                            <Controller
                                                name="shop_product_category_id"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                    <div className="">
                                                        <SelectBox
                                                            name="shop_product_category_id"
                                                            id="shop_product_category_id"
                                                            label={t('Category')}
                                                            required
                                                            placeholder={t('Select Category')}
                                                            options={categoryOptions}
                                                            onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                                            value={value ? categoryOptions.find((option) => option.value === value) : null}
                                                            error={!!error}
                                                        />
                                                    </div>
                                                )}
                                            />
                                            <Controller
                                                name="shop_product_sub_category_id"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                    <div className="">
                                                        <SelectBox
                                                            name="shop_product_sub_category_id"
                                                            id="shop_product_sub_category_id"
                                                            label={t('Sub Category')}
                                                            required
                                                            placeholder={t('Select Sub Category')}
                                                            options={subCategoryOptions}
                                                            onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                                            value={value ? subCategoryOptions.find((option) => option.value === value) : null}
                                                            error={!!error}
                                                        />
                                                    </div>
                                                )}
                                            />
                                            <Controller
                                                name="unit_of_measure"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                    <div className="">
                                                        <SelectBox
                                                            name="unit_of_measure"
                                                            id="unit_of_measure"
                                                            label={t('Unit of Measure')}
                                                            placeholder={t('Select Unit')}
                                                            required
                                                            options={unitOptions}
                                                            onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                                            value={unitOptions.find((option) => option.value === value)}
                                                            error={!!error}
                                                            isSearchable={false}
                                                            isClearable={false}
                                                        />
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        <Controller
                                            name="brand"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="w-full">
                                                    <InputWithLabel
                                                        id="brand"
                                                        label={t('Brand')}
                                                        placeholder={t('Enter product brand')}
                                                        onChange={onChange}
                                                        value={value}
                                                        name="brand"
                                                        error={!!error}
                                                    />
                                                </div>
                                            )}
                                        />
                                        <Controller
                                            name="description"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="w-full">
                                                    <InputWithLabel
                                                        textArea
                                                        rows={6}
                                                        required
                                                        label={t('Product Description')}
                                                        placeholder={t('Enter product description')}
                                                        onChange={onChange}
                                                        value={value}
                                                        name="description"
                                                        id="description"
                                                        error={!!error}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="detail-data-main xlm:px-8 px-5">
                                <div className="title-block w-full max-w-[280px]">
                                    <h3 className="location-form-label-heading">
                                        {t('Product images')}
                                        <span className="asterisk">*</span>
                                    </h3>
                                    <p className="location-form-label-subheading">
                                        {t('Product image should be in square or crop it in square for best view. For the best result please upload 1:1 aspect ratio image or 200pxX200px image.')}
                                    </p>
                                </div>
                                <div className="data-wrap-block w-full flex gap-5 flex-wrap max-w-full">
                                    <Controller
                                        name="images"
                                        control={control}
                                        render={({ field: { value }, fieldState: { error } }: any) => (
                                            <>
                                                {value && Array.isArray(value) && (
                                                    <>
                                                        {value.map((item: any, index: number) => (
                                                            <div
                                                                key={index}
                                                                className={` w-[109px] h-[109px] xl:w-[120px] xl:h-[120px] xxl:w-[150px] xxl:h-[150px] relative cursor-pointer rounded-xl `}
                                                            >
                                                                <ImageBoxView
                                                                    item={item}
                                                                    name="images"
                                                                    onChangeCrop={onChangeCrop}
                                                                    handleRemoveImage={handleRemoveImage(index)}
                                                                    key={index}
                                                                    isEdit={false}
                                                                    isPrimary={true}
                                                                    handleEditImage={handleEditImage(index)}
                                                                    className="border border-gray-200"
                                                                />
                                                                <div className={` -top-[7px] -right-[7px] ${mainImage === item.name ? 'absolute' : 'hidden'}`}>
                                                                    <i className="icon">
                                                                        <img src={IconCheckdActive} alt="" className="" />
                                                                    </i>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                )}
                                                <div>
                                                    <ImageInput imageMultiple={false} id="images" error={error} onChangeCrop={onChangeCrop} name="images" title="Products Picture" />
                                                </div>
                                            </>
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="detail-data-main xlm:px-8 px-5">
                                <div className="title-block w-full min-w-[280px]">
                                    <h3 className="location-form-label-heading">{t('Product Variants')}</h3>
                                    <p className="location-form-label-subheading">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit')}</p>
                                </div>
                                <div className="data-wrap-block w-full">
                                    <ProductVariantsForm />
                                </div>
                            </div>

                            <div className="detail-data-main xlm:px-8 px-5">
                                <div className="title-block w-full max-w-[280px]">
                                    <h3 className="location-form-label-heading">{t('Additional Information')}</h3>
                                    <p className="location-form-label-subheading">{t('This one goes under variants and is optional  ')}</p>
                                </div>
                                <div className="data-wrap-block w-full">
                                    <ProductDetailsForm />
                                </div>
                            </div>

                            <div className="detail-data-main xlm:px-8 px-5 ">
                                <div className="title-block w-[280px]">
                                    <h3 className="font-semibold text-sm text-gray-700">{t('Supplier Information')}</h3>
                                    <p className="text-xs leading-[18px] text-secondaryTxtColor font-normal ">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}</p>
                                </div>
                                <div className="data-wrap-block w-full max-w-[666px] flex flex-col gap-4">
                                    <div className="flex flex-wrap justify-between gap-4">
                                        <Controller
                                            name="supplier_name"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="min-w-[50%]  flex-1">
                                                    <InputWithLabel
                                                        label={t('First Name')}
                                                        placeholder={t('Enter first name')}
                                                        onChange={onChange}
                                                        value={value}
                                                        labelClassName="border-r"
                                                        name="supplier_name"
                                                        error={!!error}
                                                    />
                                                </div>
                                            )}
                                        />
                                        <Controller
                                            name="supplier_email"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="min-w-[50%] flex-1">
                                                    <InputWithLabel
                                                        id="supplier_email"
                                                        label={t('Email')}
                                                        placeholder={t('Enter email')}
                                                        onChange={onChange}
                                                        value={value}
                                                        name="supplier_email"
                                                        error={!!error}
                                                    />
                                                </div>
                                            )}
                                        />
                                        <Controller
                                            name="supplier_website"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="min-w-[50%]  flex-1">
                                                    <InputWithLabel
                                                        labelIcon="https://"
                                                        label={t('Website')}
                                                        placeholder="www.example.com"
                                                        onChange={onChange}
                                                        value={value}
                                                        labelIconClassName="border-r-[1px] border-gray-300 "
                                                        name="supplier_website"
                                                        error={!!error}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="detail-data-main xlm:px-8 px-5 ">
                                <div className="title-block w-full max-w-[280px]">
                                    <h3 className="font-semibold text-sm text-gray-700">{t('Product listing')}</h3>
                                    <p className="text-xs leading-[18px] text-secondaryTxtColor font-normal">{t('Toggle product listing to update its visibility in your online booking portal.')}</p>
                                </div>
                                <div className="data-wrap-block w-full max-w-[666px]">
                                    <div className="flex flex-col gap-4">
                                        <Controller
                                            name="is_active"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <div>
                                                    <div className="flex items-center gap-2 text-sm font-medium text-gray-700">
                                                        <Switch onChange={onChange} isChecked={value} />
                                                        <span>{t('List product')}</span>
                                                    </div>
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex gap-3 mt-5 xlm:px-8 px-5 justify-end mb-36">
                                <CustomButton secondary type="reset" onClick={resetForm} disabled={isLoading}>
                                    {t('Cancel')}
                                </CustomButton>
                                <CustomButton primary type="submit" isLoading={isLoading} disabled={isLoading}>
                                    {t('Save Changes')}
                                </CustomButton>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </div>
            {isUpload && fieldName && (
                <CropperModal
                    title="Adjust products image"
                    description="Image should be Square for best results"
                    defaultCropType="Square"
                    isCropType={false}
                    imageUrl={image}
                    imageUpload={imageUpload}
                    setUpload={setIsUpload}
                    setCropData={handleCropData}
                    btnTitle={t('Confirm')}
                />
            )}
        </>
    );
};

export default ProductsForm;
